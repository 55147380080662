.header {

    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    line-height: 1.2;
    background: #fff;
    box-shadow: 0 0 10px rgba(#000, .1);

    &__inner {
        display: flex;
        align-items: center;
        height: vwSp(142px);
        padding: vwSp(40px) vwSp(35px);
    }

    &__logo {
        transition: width 0.3s;
        width: vwSp(280px);

        img {
            max-width: 100%;
            max-height: 100%;
            margin-top: 0!important;
        }
    }
    &.scrolled{
        display: none;
    }

}

.menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    &.open {
        @include sp-layout {

            .menu__trigger {

                span:nth-child(1) {
                    transform: translateY(1.6vw) rotate(-45deg);
                }

                span:nth-child(2) {
                    background-color: rgba(255, 255, 255, 0);
                }

                span:nth-child(3) {
                    transform: translateY(1.6vw) rotate(45deg);
                }
            }

            .nav {
                top: 100%;
                display: block;
                opacity: 1;
            }
        }
    }

    &__trigger {
        position: relative;
        z-index: 20;
        width: 5.33333vw;
        height: 3.73333vw;
        margin-left: vwSp(40px);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        tap-highlight-color: rgba(0, 0, 0, 0);

        span {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: vwSp(36px);
            height: vwSp(5px);
            background-color: #65b393;
            border-radius: 100px;
            transition: all .2s ease;
            will-change: transform;
        }

        span:nth-child(1) {
            transform: rotate(0);
        }

        span:nth-child(2) {
            transform: translateY(1.6vw);
        }

        span:nth-child(3) {
            transform: translateY(3.2vw);
        }
    }
}

.menu-desc {

    &__lang {
        font-weight: 500;
        font-size: vwSp(28px);
        font-family: "Inter", sans-serif;
        text-transform: uppercase;

        a {
            display: block;
            text-decoration: none;
        }

        .active {
            display: none;
        }
    }

    &__navlink {
        display: none;
    }
}

.icon-arrow {
    position: relative;
    display: inline-block;
    width: vwSp(25px);
    height: vwSp(25px);
    flex-shrink: 0;

    &:after {
        position: absolute;
        top: 0;
        top: 0;
        left: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: url("/img/i-arrow-right-green.svg") center/contain no-repeat;
        content: "";
    }

    &.arrow-green {

        &:after {
            background-image: url("/img/i-arrow-right-green.svg");
        }
    }

    &.arrow-white {

        &:after {
            background-image: url("/img/i-arrow-right-white.svg");
        }
    }
}

.arrow-hover {

    &:hover {

        .icon-arrow:after {
            animation: arrowSlide .5s cubic-bezier(.215, .61, .355, 1) 0s 1 forwards;
        }
    }
}

.nav {
    position: absolute;
    top: -1000%;
    left: 0;
    width: 100%;
    height: calc(100vh - #{vwSp(142px)});
    padding-top: vwSp(55px);
    padding-bottom: vwSp(100px);
    overflow: auto;
    background: #ecece7;
    opacity: 0;
    transition: opacity .3s;

    a {
        text-decoration: none;
    }

    .nav-item {
        margin: 0 vwSp(40px);
    }

    .nav-link {
        display: flex;
        align-items: center;
        padding: vwSp(55px) 0 vwSp(25px);
        font-weight: bold;
        font-size: vwSp(40px);
        font-family: "Noto Sans JP", sans-serif;

        .icon-arrow {
            margin-right: vwSp(20px);
        }
    }
}

.icon-dropdown {
    position: relative;
    width: vwSp(55px);
    height: vwSp(55px);
    margin-left: auto;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        height: vwSp(6);
        background: #65b393;
        opacity: 1;
        transition: all .2s;
        content: "";
    }

    &:before {
        transform: translate3d(0, -50%, 0);
    }

    &:after {
        transform: translate3d(0, -50%, 0) rotate(90deg);
    }
}

.dropdown {

    &.open {
        @include sp-layout {

            >a {

                >.icon-dropdown {

                    &:after {
                        opacity: 0;
                    }
                }
            }

            .dropdown__content {
                display: block;
            }
        }
    }
    &:hover{
        .dropdown__content {
            padding: 40px 0 70px;
            animation-delay: 0s;
        }
    }
    &__desc {
        display: none;
    }

    &__content {
        display: none;
        animation-delay: 0.3s;
    }

    &__list {}

    &__item {

        a {
            display: flex;
            align-items: center;
            padding: vwSp(35px) 0;
            color: #222;
            font-size: vwSp(30px);
            border-bottom: 1px solid #e3e2df;
            position: relative;

            .icon-arrow {
                position: absolute;
                right: 0;
            }
        }
    }
}

.menu-desc-sp {
    margin: vwSp(65px) vwSp(40px) 0;

    a {
        position: relative;
        display: block;
        padding: vwSp(30px);
        padding-right: vwSp(50px);
        color: #fff;
        font-weight: bold;
        font-size: vwSp(30px);
        text-align: center;
        text-decoration: none;
        background: #e29f92;
        border-radius: 100px;

        .icon-arrow {
            position: absolute;
            top: 50%;
            right: vwSp(30px);
            display: auto;
            transform: translate3d(0, -50%, 0);
        }
    }
}

@include pc-layout {

    .header {
        position: absolute;
        &.hide{
            display: none;
            transform: translateY(-100%);
        }
        &__bg{
            width: 100%;
            position: absolute;
            background: #fff;
            transition: height 1s ease;
            height: 0;
        }
        &__inner {
            height: auto;
            margin: auto;
            padding: 0 vwPc(50px);
        }

        &__logo {
            width: vwPc(205px);
            padding: vwPc(13px) 0;

            img {
                max-width: 100%;
            }
        }

        &.scrolled {
            display: block;
            position: fixed;
            transform: translateY(-100%);
            &.show{
                transform: translateY(0);
                transition: all 0.3s ease;
            }
            .header__inner{
                max-width: 100%;
                flex-wrap: wrap;
            }

            .header__logo {
                width: vwPc(205px);
                padding: vwPc(13px) 0;
            }


            .menu-desc__navlink {
                position: absolute;
                top: 0;
                right: 0;
                width: vwPc(188px);
                height: calc(100% - #{vwPc(26)});
                margin: vwPc(13);
                margin-left: vwPc(50px);
                margin-right: vwPc(50px);
                line-height: 1.3;

                .icon-arrow {
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            .menu {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin-right: vwPc(210px);
                &.en{
                    margin-right: 0;
                    width: calc(100% - #{vwPc(205)});
                    justify-content: flex-start;
                    padding-left: vwPc(447);
                    .menu-desc{
                        margin-left: auto;
                        margin-right: 0;
                        .menu-desc__lang{
                            font-size: vwPc(18);
                            margin-top: 0;
                            margin-right: 0;
                        }
                    }
                }
                .nav {
                    padding-top: vwPc(28px);
                    .nav-link {
                        padding-bottom: vwPc(28px);
                    }
                }
            }
        }
    }
    .en{
        .menu-desc{
            .menu-desc__lang{
                margin-right: 0;
            }
        }
    }
    .menu {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-end;
        align-self: start;
        margin-left: auto;
        &__trigger {
            display: none;
        }
    }

    .menu-desc {
        display: flex;
        align-items: center;
        margin-top: vwPc(20px);
        margin-bottom: vwPc(16px);

        &__lang {
            margin-left: vwPc(20px);
            font-size: vwPc(16px);
            margin-top: vwPc(2);
            margin-right: vwPc(20);

            a {
                display: inline-block;
                color: #ccc;
                text-decoration: none;

                &:first-child {

                    &:after {
                        padding: 0 vwPc(7px);
                        content: "/";
                    }
                }
            }

            .active {
                display: inline-block;
                color: #333;
            }
        }

        &__navlink {
            position: relative;
            display: flex;
            align-items: center;
            padding: vwPc(15px) vwPc(25px);
            padding-right: vwPc(25px);
            color: #fff;
            font-weight: bold;
            font-size: vwPc(15px);
            text-decoration: none;
            background: #e29f92;
            border: 1px solid #e29f92;
            border-radius: 100px;
            transition: all .2s;


            span {
                color: #fff;
            }

            .icon-arrow {
                margin-left: vwPc(20px);
            }
        }
    }

    .icon-arrow {
        position: relative;
        display: inline-block;
        width: vwPc(12px);
        height: vwPc(12px);

        &:after {
            position: absolute;
            top: 0;
            top: 0;
            left: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: url("/img/i-arrow-right-green.svg") center/contain no-repeat;
            content: "";
        }

        &.arrow-green {

            &:after {
                background-image: url("/img/i-arrow-right-green.svg");
            }
        }

        &.arrow-white {

            &:after {
                background-image: url("/img/i-arrow-right-white.svg");
            }
        }
    }

    .arrow-hover {

        &:hover {

            .icon-arrow:after {
                animation: arrowSlide .5s cubic-bezier(.215, .61, .355, 1) 0s 1 forwards;
            }
        }
    }

    .nav {
        position: static;
        top: auto;
        left: auto;
        width: auto;
        height: auto;
        margin: 0 vwPc(-20px);
        padding-top: vwPc(0px);
        padding-bottom: vwPc(0px);
        overflow: auto;
        background: #fff;
        opacity: 1;

        a {
            text-decoration: none;
        }

        .nav-item {
            display: inline-block;
            margin: 0 0;
            background: linear-gradient(#65b393,#65b393) 0 100%/0 vwPc(3) no-repeat;
            background-position: vwPc(15) 100%;
            transition: background .4s cubic-bezier(.4,.1,.3,1);

            &:hover {
                background-size: calc(100% - #{vwPc(30)}) vwPc(3);

                .nav-link {
                    color: #65b393;
                }
            }
        }

        .nav-link {
            display: inline-flex;
            align-items: center;
            padding: vwPc(0px) vwPc(15) vwPc(22px);
            font-weight: bold;
            font-size: vwPc(16px);
            font-family: "Noto Sans JP", sans-serif;
            line-height: 1.2;
            transition: all .3s;

            >.icon-arrow {
                display: none;
            }

            .icon-arrow {
                display: none;
                margin-right: vwPc(20px);
            }
        }
    }

    .icon-dropdown {
        display: none;
    }

    .dropdown-bg{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: inset 0 10px 9px -10px rgba(#000, .1);
        padding: 0;
        height: vwPc(280);
        max-height: 0;
        transition:all 0.1s ease;
        transition-delay: 0.3s;
        overflow: hidden;
        display: block;
        @include large-layout(){
            height: 280px;
        }
        &.display{
            max-height: vwPc(280);
            transition-delay: 0s;
            @include large-layout(){
                max-height: 280px;
            }
        }
    }

    .dropdown {
        &:hover{
            .dropdown__content {
                z-index: 11!important;
            }
        }
        &:hover {

            .dropdown__content {
                display: block;
                padding: vwPc(40px) 0 vwPc(60px);
                animation-name: dropdown_content;
                animation-duration: 0.25s;
                max-height: 400px;
                z-index: 10;
                transition-delay: 0.1s;

            }
            .dropdown__desc{
                animation-name: dropdown_inside;
                animation-duration: .1s;
                animation-timing-function: ease-out;
            }
            .dropdown__list {
                animation-name: dropdown_inside;
                animation-duration: 0.25s;
                animation-timing-function: ease-out;
            }

        }

        &__desc {
            display: block;
            margin-bottom: vwPc(25px);
            font-weight: bold;
            font-size: vwPc(30px);
            text-align: center;
        }

        &__content {
            position: absolute;
            top: 95%;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: inset 0 10px 9px -10px rgba(#000, .1);
            padding: 0;
            max-height: 0;
            transition:all 0s ease;
            transition-delay: 0;
            overflow: hidden;
            display: block;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            max-width: vwPc(1190px);
            margin: auto;
            padding: 0 vwPc(20px);
        }

        &__item {
            flex-basis: 25%;
            padding: 0 vwPc(20px);

            a {
                display: flex;
                padding: vwPc(20px) 0;
                color: #222;
                font-weight: bold;
                font-size: vwPc(15px);
                border-bottom: 1px solid #e3e2df;

                &:hover {
                    color: #65b393;
                }

                .icon-arrow {
                    margin-left: auto;
                }
            }
        }
    }

    .menu-desc-sp {
        display: none;
    }
}

@include lg-layout {

    .header {

        &__inner {
            padding: 0 50px;
        }

        &__logo {
            width: 205px;
            padding: 13px 0;
        }

        &.scrolled {
            .header__inner{
                max-width: 100%;
            }

            .header__logo {
                width: 205px;
                padding: 13px 0;
            }

            .menu-desc__navlink {
                width: 188px;
                height: calc(100% - 26px);
                margin: 13px;
                margin-left: 50px;
                margin-right: 50px;

                .icon-arrow {
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            .menu {
                margin-right: 220px;
                &.en{
                    padding-left: 447px;
                    width: calc(100% - 205px);
                    .menu-desc{
                        .menu-desc__lang{
                            font-size:18px;
                            margin-top: 0;
                            margin-right: 0;
                        }
                    }
                }
                .nav {
                    padding-top: 28px;
                    .nav-link {
                        padding-bottom: 28px;
                    }
                }
            }
        }
    }
    .en{
        .menu-desc{
            .menu-desc__lang{
                margin-right: 0;
            }
        }
    }
    .menu-desc {
        margin-top: 20px;
        margin-bottom: 16px;

        &__lang {
            margin-left: 20px;
            font-size: 16px;
            margin-top: 2px;
            margin-right: 20px;
        }

        &__navlink {
            padding: 15px 25px;
            padding-right: 25px;
            font-size: 15px;
            border-radius: 100px;

            .icon-arrow {
                margin-left: 20px;
            }
        }
    }

    .icon-arrow {
        width: 12px;
        height: 12px;
    }
    .nav {
        margin-right: 30px;
        padding-top: 28px;
        .nav-link {
            padding-bottom: 28px;
        }
    }
    .nav {
        margin: 0 -20px;
        padding-top: 0px;
        padding-bottom: 0px;

        .nav-item {
            margin: 0 0;
            background: linear-gradient(#65b393,#65b393) 0 100%/0 3px no-repeat;
            background-position: 20px 100%;
            transition: background .4s cubic-bezier(.4,.1,.3,1);
            &:hover{
                background-size: calc(100% - 40px) 3px;
            }
        }

        .nav-link {
            padding: 0px 20px 22px;
            font-size: 16px;
            line-height: 1.2;

            .icon-arrow {
                margin-right: 20px;
            }
        }
    }

    .dropdown {
        &:hover {

            .dropdown__content {
                padding: 40px 0 60px;
            }

        }
        &__desc {
            margin-bottom: 25px;
            font-size: 30px;
        }

        &__content {
            padding: 0;
        }

        &__list {
            max-width: 1190px;
            padding: 0 20px;
        }

        &__item {
            padding: 0 20px;

            a {
                padding: 20px 0;
                font-size: 15px;
            }
        }
    }
}

@-webkit-keyframes dropdown_content {
    0% {
        padding: 0;
        max-height: 0;
    }

    75% {
        max-height: 5000px;
        @include pc-layout(){
            padding: vwPc(40) 0 vwPc(500);
        }
        @include large-layout(){
            padding: 40px 0 500px;
        }
    }

    100% {
        max-height: 5000px;
        transform: scaleY(1);
        @include pc-layout(){
            padding: vwPc(40) 0 vwPc(70);
        }
        @include large-layout(){

            padding: 40px 0 70px;
        }
    }
}

@keyframes dropdown_content {
    0% {
        padding: 0;
        max-height: 0;
    }

    75% {
        max-height: 5000px;
        @include pc-layout(){
            padding: vwPc(40) 0 vwPc(500);
        }
        @include large-layout(){
            padding: 40px 0 500px;
        }
    }

    100% {
        @include pc-layout(){
            padding: vwPc(40) 0 vwPc(70);
        }
        @include large-layout(){
            padding: 40px 0 70px;
        }
    }
}
@-webkit-keyframes dropdown_inside {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    50% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes dropdown_inside {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    50% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
