$sass-fonts: (
    large1: 30px,
    large2: 27px,
    large3: 24px,
    large4: 22px,
    p-large: 19px,
    p-small: 14px,
    p-ex-small: 12px
);

@each $modifier, $size in $sass-fonts {

    .font__#{$modifier} {
        font-size: $size;
        line-height: calc(1em + 8px);
    }
}

$sass-fonts-sp: (
    large3-sp: 18px,
    large4-sp: 16px,
    p-large-sp: 18px,
    p-small-sp: 12px,
    p-ex-small-sp: 10px
);

@each $modifier, $size in $sass-fonts-sp {

    .font__#{$modifier} {
        @include sp-layout {
            font-size: $size;
            line-height: calc(1em + 8px);
        }
    }
}
