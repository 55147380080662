.main{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: vwSp(142);
  @include pc-layout(){
    padding-top: vwPc(126);
    min-height: calc(100vh - #{vwPc(235)});
  }
  @include large-layout(){
    padding-top: 126px;
    min-height: calc(100vh - 235px);
  }
  &.en{
    @include pc-layout(){
      padding-top: vwPc(98);
      min-height: calc(100vh - #{vwPc(224)});
    }
    @include large-layout(){
      padding-top: 98px;
      min-height: calc(100vh - 224px);
    }
      &.scroll{
          padding-top: vwSp(284);
          @include pc-layout(){
              padding-top: vwPc(79);
          }
          @include large-layout(){
              padding-top: 79px;
          }
      }
      .page-anchor{
          @include pc-layout(){
              top:vwPc(98);
          }
          @include large-layout(){
              top: 98px;
          }
          &.scrolled{
              @include pc-layout(){
                  top:vwPc(78);
              }
              @include large-layout(){
                  top: 78px;
              }
          }
      }
  }
  &.scroll{
    padding-top: vwSp(284);
    @include pc-layout(){
      padding-top: vwPc(79);
    }
    @include large-layout(){
      padding-top: 79px;
    }
  }
  .page-anchor{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    transition: all 0.5s ease;
    position: fixed;
    z-index: 999;
    top: vwSp(142);
    &::-webkit-scrollbar {
      display: none;
    }
    ~ .content{
      padding-top: vwSp(142);
      @include pc-layout(){
        padding-top: vwPc(78);
      }
      @include large-layout(){
        padding-top:  78px;
      }
    }
    @include pc-layout(){
      background: #fff;
      justify-content: center;
      top:vwPc(126);
    }
    @include large-layout(){
      top: 126px;
    }
    &.scrolled{
      @include pc-layout(){
        top:vwPc(78);
      }
      @include large-layout(){
        top: 78px;
      }
    }
    ul{
      display: flex;
      flex-wrap: nowrap;
      padding: 0 vwSp(40);
      background: #fff;
      @include pc-layout(){
        padding:0 vwPc(100);
        width: 100%;
      }
      @include large-layout(){
        padding: 0 100px;
      }
      li{
        width: vwSp(315);
        height: vwSp(145);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-bottom: vwSp(6) solid transparent;
        @include pc-layout(){
          width: 100%;
          height: vwPc(80);
          border-bottom: 0;
          background: linear-gradient(#65b393,#65b393) 0 100%/0 vwPc(3) no-repeat;
          background-position: 0% 100%;
          transition: background .4s cubic-bezier(.4,.1,.3,1);
        }
        @include large-layout(){
          width: 100%;
          height: 80px;
          background: linear-gradient(#65b393,#65b393) 0 100%/0 3px no-repeat;
          background-position: 0% 100%;
          transition: background .4s cubic-bezier(.4,.1,.3,1);
        }
        &.active{
          border-bottom: vwSp(6) solid #65b393;
          @include pc-layout(){
            color: #65b393;
            border-bottom: vwPc(3) solid #65b393;
            &:hover{
              background: none;
            }
          }
          @include large-layout(){
            border-bottom: 3px solid #65b393;
          }
        }
        &:hover{
          a{
            opacity: 0.6;
            transition: opacity 0.3s ease;
          }
          @include pc-layout(){
            background-size: 100% vwPc(3);
          }
          @include large-layout(){
            background-size: 100% 3px;
          }
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        &:after{
          display: block;
          content: "";
          border-right: dotted 1px #b7b7b7;
          height: vwSp(67);
          width: vwSp(1);
          right: 0;
          top: vwSp(38);
          position: absolute;
          @include pc-layout(){
            height: vwPc(36);
            width: vwPc(1);
            right: 0;
            top: vwPc(25);
          }
          @include large-layout(){
            height: 36px;
            width: 1px;
            right: 0;
            top: 25px;
          }
        }
        a{
          font-size: vwSp(28);
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;
          width: 100%;
          height:100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include pc-layout(){
            font-size: vwPc(14);
          }
          @include large-layout(){
            font-size: 14px;
          }
        }
      }
    }
  }
  iframe{
    max-width: 100%;
  }
  h1{
    font-size: vwSp(56);
    line-height: 1.5;
    font-weight: bold;
  }

  .breadcrumb{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: vwSp(40);
    @include pc-layout(){
      margin-top: vwPc(25);
    }
    @include large-layout(){
      margin-top: 25px;
    }
    ul{
      position: relative;
      display: inline;
      flex-wrap: wrap;
      width: 100%;
      li{
        position: relative;
        display: inline;
        flex-wrap: wrap;
        padding-right: 0;
        margin-right: vwSp(20);
        @include pc-layout(){
          display: inline-flex;
          padding-right:0;
          margin-right: vwPc(10);
        }
        @include large-layout(){
          padding-right: 0;
          margin-right: 10px;
        }
        &:last-child{
          margin-right: 0;
          padding-right: 0;
          &:after{
            display: none;
          }
        }
        a,span{
          font-size: vwSp(26);
          color: #222222;
          line-height: 2;
          @include pc-layout(){
            font-size:vwPc(13);
          }
          @include large-layout(){
            font-size: 13px;
          }
        }
        a{
          color: #65b393;
          text-decoration: none;
          transition: all 0.3s ease;
          display: inline;
          &:hover{
            opacity: 0.6;
          }
        }
        &:after{
          content: "＞";
          display: inline;
          position: relative;
          right: 0;
          font-size: vwSp(26);
          color: #cccccc;
          line-height: 2;
          margin-left: vwSp(20);
          @include pc-layout(){
            font-size:vwPc(13);
            margin-left: vwPc(20);
          }
          @include large-layout(){
            font-size: 13px;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .content{
    width: 100%;
    position: relative;
    padding: 0 vwSp(40) vwSp(140);
      @include pc-layout(){
          padding:0;
      }
  }
  .block-head{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(100);
    padding: vwSp(40);
    font-weight: bold;
    font-size: vwSp(38);
    background: #e6e6e2;
    line-height: 1.6;
    @include pc-layout(){
      margin-top: vwPc(60);
      padding: vwPc(20) vwPc(26);
      font-size: vwPc(20);
    }
    @include large-layout(){
      margin-top: 60px;
      padding: 20px 26px ;
      font-size: 20px;
    }
  }
  h2{
    width: 100%;
    position: relative;
    color: #222222;
    font-size: vwSp(48);
    line-height: 1.5;
    font-weight: bold;
    padding-left: vwSp(170);
    @include pc-layout(){
      font-size: vwPc(36);
      padding-left: vwPc(90);
      + p{
        margin-top: vwPc(50);
      }
    }
    @include large-layout(){
      font-size: 36px;
      padding-left: 90px;
      + p{
        margin-top: 50px;
      }
    }
    &:before{
      content: "";
      display: block;
      width: vwSp(140);
      height: vwSp(2);
      background-color: #222222;
      position: absolute;
      top: vwSp(35);
      left: 0;
      @include pc-layout(){
        width: vwPc(80);
        height: vwPc(1);
        top: vwPc(27);
      }
      @include large-layout(){
        width: 80px;
        height: 1px;
        top: 27px;
      }
    }
    + .list{
      margin-top: 0;
      @include pc-layout(){
        margin-top: vwPc(40);

      }
      @include large-layout(){
        margin-top: 40px;

      }
    }
  }

  h3{
    font-size: vwSp(46);
    font-weight: bold;
    line-height: 1.6;
    @include pc-layout(){
      font-size: vwPc(30);
      + p{
        margin-top: vwPc(40);
      }
    }
    @include large-layout(){
      font-size: 30px;
      + p{
        margin-top: 40px;
      }
    }
    &.line{
      display: flex;
      width: 100%;
      position: relative;
      padding-bottom: vwSp(30);
      @include pc-layout(){
        padding-bottom: vwPc(25);
      }
      @include large-layout(){
        padding-bottom: 25px;
      }

      &:before,
      &:after{
        content: "";
        width: 100%;
        height: vwSp(4);
        display: block;
        position: absolute;
        bottom:0;
        left: 0;
        z-index: 1;
        background-color: #eae9e6;
        @include pc-layout(){
          height: vwPc(2);
        }
        @include large-layout(){
          height: 2px;
        }
      }
      &:after{
        width: vwSp(130);
        z-index: 2;
        background-color: #e29f92;
        @include pc-layout(){
          width: vwPc(80);
        }
        @include large-layout(){
          width: 80px;
        }
      }
    }
  }

  h4{
    font-size: vwSp(40);
    font-weight: bold;
    padding-left: vwSp(40);
    border-left: vwSp(6) solid #65b393;
    line-height: 1.3;
    + p{
      margin-top: vwSp(20);
    }
    @include pc-layout(){
      font-size: vwPc(24);
      padding-left: vwPc(20);
      border-left: vwPc(3) solid #65b393;
      + p{
        margin-top: vwPc(30);
      }
    }
    @include large-layout(){
      font-size: 24px;
      padding-left: 20px;
      border-left: 3px solid #65b393;
      + p{
        margin-top: 30px;
      }
    }
  }

  h5{
    font-weight: bold;
    font-size: vwSp(38);
    line-height: 1.6;
    + p{
      margin-top: vwSp(20);
    }
    @include pc-layout(){
      font-size: vwPc(20);
      + p{
        margin-top: vwPc(30);
      }
    }
    @include large-layout(){
      font-size: 20px;
      + p{
        margin-top: 30px;
      }
    }
  }

  p{
    font-size: vwSp(30);
    line-height: 2;
    @include pc-layout(){
      font-size: vwPc(17);
    }
    @include large-layout(){
      font-size: 17px;
    }
    &.bold{
      font-weight: bold;
    }
    a{
      text-decoration: none;
      color: #65b393;
      background: linear-gradient(#65b393,#65b393) 0 100%/100% 1px no-repeat;
      background-position: 100% 100%;
      transition: background .4s cubic-bezier(.4,.1,.3,1);
      &:hover{
        background-size: 0 1px;
      }
    }
    +.note{
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(10);
      }
      @include large-layout(){
        margin-top: 10px;
      }
    }
  }
  .note{
    font-size: vwSp(24);
    padding-left: vwSp(24);
    text-indent: vwSp(-24);
    line-height: 1.6;
    @include pc-layout(){
      font-size: vwPc(12);
      padding-left: vwPc(17);
      text-indent: vwPc(-17);
    }
    @include large-layout(){
      font-size: 12px;
      padding-left: 17px;
      text-indent: -17px;
    }
    &:before{
      content: "※";
      margin-right: vwSp(3);
      @include pc-layout(){
        margin-right: vwPc(3);
      }
      @include large-layout(){
        margin-right: 3px;
      }
    }
    &.hide{
      &:before{
        content: "";
      }
    }
    + .note{
      margin-top: 0;
    }
  }
  .list{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    li{
      font-size: vwSp(30);
      padding-left: vwSp(15);
      text-indent: vwSp(-15);
      line-height: 1.8;
      width: 100%;
      @include pc-layout(){
        font-size: vwPc(17);
        padding-left: vwPc(10);
        text-indent: vwPc(-10);
      }
      @include large-layout(){
        font-size: 17px;
        padding-left: 10px;
        text-indent: -10px;
      }
      &:before{
        content: "● ";
        font-size: vwSp(12);
        vertical-align: middle;
        margin-right: vwSp(3);
        @include pc-layout(){
          font-size: vwPc(6);
          margin-right: vwPc(3);
        }
        @include large-layout(){
          font-size: 6px;
          margin-right: 3px;
        }
      }
      + li{
        margin-top: vwSp(10);
        @include pc-layout(){
          margin-top: vwPc(10);
        }
        @include large-layout(){
          margin-top: 10px;
        }
      }
      a{
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover{
          opacity: 0.6;
        }
      }
      &.pdf,
      &.mail,
      &.tel,
      &.fax,
      &.zip,
      &.word,
      &.pp,
      &.excel
      {
        padding-left: vwSp(170);
        text-indent: 0;
        position: relative;
        font-weight: normal;
        margin-top: vwSp(50);
        min-height: vwSp(62);
        @include pc-layout(){
          padding-left: vwPc(92);
          min-height: vwPc(31);
          margin-top: vwPc(10);
        }
        @include large-layout(){
          padding-left: 92px;
          min-height: 31px;
          margin-top: 10px;
        }
        &:before{
          content: "";
          width:vwSp(136);
          height: vwSp(46);
          border-radius: vwSp(23);
          position: absolute;
          display: flex;
          left: 0;
          top: vwSp(10);
          font-family: Inter,sans-serif;
          font-size: vwSp(26);
          line-height: vwSp(46);
          font-weight: bold;
          color: #fff;
          justify-content: center;
          align-items: center;
          @include pc-layout(){
            width:vwPc(68);
            height: vwPc(23);
            line-height: vwPc(23);
            top: vwPc(6);
            border-radius: vwPc(12);
            font-size: vwPc(13);
          }
          @include large-layout(){
            width:68px;
            height: 23px;
            line-height: 23px;
            top:6px;
            border-radius: 12px;
            font-size: 13px;
          }
        }
      }
      &.pdf{
        padding-left: vwSp(60);
        @include pc-layout(){
          padding-left: vwPc(50);
        }
        @include large-layout(){
          padding-left: 50px;
        }
        &:before{
          content: "";
          width: vwSp(45);
          height: vwSp(45);
          background: url(../img/PDF.png) 0 50% no-repeat;
          background-size: vwSp(40);
          border-radius: 0;
          @include pc-layout(){
            width: vwPc(35);
            height: vwPc(35);
            top:calc((100% - #{vwPc(35)})/2);
            background-size: vwPc(30);
            border-radius: 0;
          }
          @include large-layout(){
            width: 35px;
            height: 35px;
            background-size: 30px;
            border-radius: 0;
            top:calc((100% - 35px)/2);
          }
        }
      }
      &.mail{
        &:before{
          background: #444;
          content: "MAIL";
        }
      }
      &.tel{
        &:before{
          background: #444;
          content: "TEL";
        }
      }
      &.fax{
        &:before{
          background: #444;
          content: "FAX";
        }
      }
      &.zip{
        padding-left: vwSp(60);
        @include pc-layout(){
          padding-left: vwPc(50);
        }
        @include large-layout(){
          padding-left: 50px;
        }
        &:before{
          width: vwSp(45);
          height: vwSp(45);
          background: url(../img/zip.png) 0 50% no-repeat;
          background-size: vwSp(40);
          content: "";
          border-radius: 0;
          @include pc-layout(){
            width: vwPc(35);
            height: vwPc(35);
            top:calc((100% - #{vwPc(35)})/2);
            background-size: vwPc(30);
            border-radius: 0;
          }
          @include large-layout(){
            width: 35px;
            height: 35px;
            background-size: 30px;
            border-radius: 0;
            top:calc((100% - 35px)/2);
          }
        }
      }

      &.word{
        padding-left: vwSp(60);
        @include pc-layout(){
          padding-left: vwPc(50);
        }
        @include large-layout(){
          padding-left: 50px;
        }
        &:before{
          width: vwSp(45);
          height: vwSp(45);
          background: url(../img/word.png) 0 50% no-repeat;
          background-size: vwSp(40);
          content: "";
          border-radius: 0;
          @include pc-layout(){
            width: vwPc(35);
            height: vwPc(35);
            top:calc((100% - #{vwPc(35)})/2);
            background-size: vwPc(30);
            border-radius: 0;
          }
          @include large-layout(){
            width: 35px;
            height: 35px;
            background-size: 30px;
            border-radius: 0;
            top:calc((100% - 35px)/2);
          }
        }
      }

      &.pp{
        padding-left: vwSp(60);
        @include pc-layout(){
          padding-left: vwPc(50);
        }
        @include large-layout(){
          padding-left: 50px;
        }
        &:before{
          width: vwSp(45);
          height: vwSp(45);
          background: url(../img/pp.png) 0 50% no-repeat;
          background-size: vwSp(40);
          content: "";
          border-radius: 0;
          @include pc-layout(){
            width: vwPc(35);
            height: vwPc(35);
            top:calc((100% - #{vwPc(35)})/2);
            background-size: vwPc(30);
            border-radius: 0;
          }
          @include large-layout(){
            width: 35px;
            height: 35px;
            background-size: 30px;
            border-radius: 0;
            top:calc((100% - 35px)/2);
          }
        }
      }

      &.excel{
        padding-left: vwSp(60);
        @include pc-layout(){
          padding-left: vwPc(50);
        }
        @include large-layout(){
          padding-left: 50px;
        }
        &:before{
          width: vwSp(45);
          height: vwSp(45);
          background: url(../img/excel.png) 0 50% no-repeat;
          background-size: vwSp(40);
          content: "";
          border-radius: 0;
          @include pc-layout(){
            width: vwPc(35);
            height: vwPc(35);
            top:calc((100% - #{vwPc(35)})/2);
            background-size: vwPc(30);
            border-radius: 0;
          }
          @include large-layout(){
            width: 35px;
            height: 35px;
            background-size: 30px;
            border-radius: 0;
            top:calc((100% - 35px)/2);
          }
        }
      }
    }
  }
  .num-list{
    padding-left: vwSp(34);
    text-indent: vwSp(-34);
    margin-top: 0;
    @include pc-layout(){
      padding-left: vwPc(18);
      text-indent: vwPc(-18);
      margin-top: 0;
    }
    @include large-layout(){
      padding-left: 18px;
      text-indent: -18px;
      margin-top: 0;
    }
  }
  .achievement{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(60);
    padding-bottom: vwSp(40);
    &:last-child{
      padding-bottom: 0;
    }
    @include pc-layout(){
      margin-top: vwPc(40);
      padding-bottom: 0;
      + .achievement{
        margin-top: vwPc(60);
      }
    }
    @include large-layout(){
      margin-top: 40px;
      + .achievement{
        margin-top: 60px;
      }
    }
    + h3{
      @include pc-layout(){
        margin-top: vwPc(80);
      }
      @include large-layout(){
        margin-top: 80px;
      }
    }
    .pdf_link {
      &:first-child{
        margin-top: 0;
        @include pc-layout(){
          margin-top: 0;
        }
        @include large-layout(){
          margin-top: 0;
        }
      }
    }
    .category {
      height: vwSp(50);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding:0 vwSp(30);
      border-radius: vwSp(25);
      background-color: #e3e3e3;
      font-size: vwSp(26);
      color: #888888;
      @include pc-layout(){
        height: vwPc(25);
        line-height: vwPc(25);
        padding:0 vwPc(15);
        border-radius: vwPc(13);
        font-size: vwPc(13);
      }
      @include large-layout(){
        height: 25px;
        line-height: 25px;
        padding:0 15px;
        border-radius: 13px;
        font-size: 13px;
      }
    }
    .award {
      width: 100%;
      position: relative;
      display: flex;
      font-weight: bold;
      font-size: vwSp(42);
      margin-top: vwSp(35);
      line-height: 1;
      &:first-child{
        margin-top: 0;
        @include pc-layout(){
          margin-top: 0;
        }
        @include large-layout(){
          margin-top: 0;
        }
      }
      @include pc-layout(){
        font-size: vwPc(26);
        margin-top: vwPc(20);
      }
      @include large-layout(){
        font-size: 26px;
        margin-top: 20px;
      }
    }
    .winner{
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-top: vwSp(40);
        @include pc-layout(){
            margin-top: vwPc(20);
        }
        @include large-layout(){
            margin-top: 20px;
        }
      +.pdf_link{
        margin-top: vwSp(75);
        @include pc-layout(){
          margin-top: vwPc(35);
        }
        @include large-layout(){
          margin-top: 35px;
        }
      }
      + h3{
        margin-top: vwSp(90);
        @include pc-layout(){
          margin-top: vwPc(80);
        }
        @include large-layout(){
          margin-top: 80px;
        }
      }
      .name {
        font-size: vwSp(30);
        line-height: 1.6;
        width: 100%;
        font-weight: normal;
          @include pc-layout(){
              font-size: vwPc(15);
          }
          @include large-layout(){
              font-size: 15px;
          }
      }
      .affiliation {
        font-size: vwSp(26);
        line-height: 1.6;
        color: #a6a6a6;
        font-weight: normal;
          @include pc-layout(){
              font-size: vwPc(13);
          }
          @include large-layout(){
              font-size: 13px;
          }
      }
      + .winner{
        margin-top: vwSp(30);
          @include pc-layout(){
              margin-top: vwPc(20);
          }
          @include large-layout(){
              margin-top: 20px;
          }
      }
    }
  }
  .pdf_link {
    width: 100%;
    text-decoration: none;
    padding: vwSp(40) vwSp(40) vwSp(120);
    margin-top: vwSp(45);
    background: #fff;
    font-size: vwSp(32);
    font-weight: bold;
    line-height: 1.6;
    border-top: vwSp(2) solid #e3e2df;
    border-bottom: vwSp(2) solid #e3e2df;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &:hover{
      background: rgba(#65b393, 0.2);
    }
    .title{
      width: 100%;
      @include pc-layout(){
        width: 50%;
      }
    }
    .right{
      @include pc-layout(){
        margin-top: 0;
        width: 50%;
        padding-left: vwPc(30);
      }
      @include large-layout(){
        padding-left: 30px;
      }
    }
    .subtitle{
      font-size: vwSp(30);
      width: 100%;
      margin-top: vwSp(30);
      font-weight: normal;
      @include pc-layout(){
        margin-top: 0;
        font-size: vwPc(15);
      }
      @include large-layout(){
        font-size: 15px;
      }
    }
    .detail{
      font-size: vwSp(26);
      width: 100%;
      margin-top: vwSp(10);
      color:#a6a6a6;
      font-weight: normal;
      @include pc-layout(){
        margin-top: 0;
        font-size: vwPc(13);
      }
      @include large-layout(){
        font-size: 13px;
      }
    }
    @include pc-layout(){
      padding: vwPc(30) vwPc(110) vwPc(30) vwPc(20);
      margin-top: vwPc(30);
      font-size: vwPc(16);
      border-top: vwPc(1) solid #e3e2df;
      border-bottom: vwPc(1) solid #e3e2df;
    }
    @include large-layout(){
      padding: 30px 110px 30px 20px;
      margin-top: 30px;
      font-size: 16px;
      border-top: 1px solid #e3e2df;
      border-bottom: 1px solid #e3e2df;
    }
    .winner{
      padding-right: vwSp(100);
      @include pc-layout(){
        padding-right: 0;
      }
    }
    &:after{
      content: "";
      height: vwSp(80);
      width: vwSp(80);
      position: absolute;
      top:calc(50% - #{vwSp(40)});
      right: vwSp(50);
      font-size: vwSp(26);
      font-weight: bold;
      color: #fff;
      font-family: 'Inter', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../img/PDF.png") no-repeat;
      background-size: 99%;
      @include pc-layout(){
        height: vwPc(40);
        width: vwPc(40);
        top:calc(50% - #{vwPc(20)});
        right: vwPc(25);
        font-size: vwPc(13);
      }
      @include large-layout(){
        height: 40px;
        width: 40px;
        top:calc(50% - 20px);
        right:25px;
        font-size: 13px;
      }
    }
    +.pdf_link{
      margin-top: vwSp(20);
    }
  }
  .archivement_link {
    width: 100%;
    text-decoration: none;
    padding: vwSp(40) vwSp(40) vwSp(120);
    margin-top: vwSp(0);
    background: #fff;
    font-size: vwSp(32);
    font-weight: bold;
    line-height: 1.6;
    border-top: vwSp(2) solid #e3e2df;
    border-bottom: vwSp(2) solid #e3e2df;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .title{
      @include pc-layout(){
        width: 50%;
      }
    }
    .right{
      @include pc-layout(){
        margin-top: 0;
        width: 50%;
        padding-left: vwPc(30);
      }
      @include large-layout(){
        padding-left: 30px;
      }
    }
    .subtitle{
      font-size: vwSp(30);
      width: 100%;
      margin-top: vwSp(30);
      font-weight: normal;
      @include pc-layout(){
        margin-top: 0;
        font-size: vwPc(15);
      }
      @include large-layout(){
        font-size: 15px;
      }
    }
    .detail{
      font-size: vwSp(26);
      width: 100%;
      margin-top: vwSp(10);
      color:#a6a6a6;
      font-weight: normal;
      @include pc-layout(){
        margin-top: 0;
        font-size: vwPc(13);
      }
      @include large-layout(){
        font-size: 13px;
      }
    }
    @include pc-layout(){
      padding: vwPc(30) vwPc(110) vwPc(30) vwPc(20);
      margin-top: vwPc(30);
      font-size: vwPc(16);
      border-top: vwPc(1) solid #e3e2df;
      border-bottom: vwPc(1) solid #e3e2df;
    }
    @include large-layout(){
      padding: 30px 110px 30px 20px;
      margin-top: 30px;
      font-size: 16px;
      border-top: 1px solid #e3e2df;
      border-bottom: 1px solid #e3e2df;
    }
  }
  img{
    display: block;
    max-width: 100%;
    margin-top: vwSp(60);
      @include pc-layout(){
          margin-top: vwPc(50);
      }
      @include large-layout(){
          margin-top: 50px;
      }
    &.center{
      margin: vwSp(60) auto 0;
        @include pc-layout(){
            margin: vwPc(50) auto 0;
        }
        @include large-layout(){
            margin: 50px auto 0;
        }
    }
    &.full{
      width: 100%;
    }
    &.small{
      max-width: vwSp(590);
        @include pc-layout(){
            max-width: vwPc(400);
        }
        @include large-layout(){
            max-width: 400px;
        }
    }
  }
  .collection{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    a{
      position: relative;
      display: block;
      flex-wrap: wrap;
        @include pc-layout(){
            width: 35%;
        }
        @include large-layout(){

        }
      img{
        width: 100%;
        margin-top: vwSp(80);
        @include pc-layout(){
          margin-top: vwPc(50);
        }
        @include large-layout(){
          margin-top: 50px;
        }
      }
      &:hover{
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
        background-size: 99%;
        bottom: vwSp(20);
        right: vwSp(40);
        @include pc-layout(){
          width: vwPc(11);
          height: vwPc(12);
          bottom: vwPc(15);
          right: vwPc(15);
        }
        @include large-layout(){
          width: 11px;
          height: 12px;
          bottom: 15px;
          right: 15px;
        }
      }
    }
    p{
        @include pc-layout(){
            width: 50%;
            padding:0 vwPc(30);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: vwPc(50);
        }
        @include large-layout(){
            padding:0 30px;
            margin-top: 50px;
        }
    }
  }
  .advertising{
     img{
       margin-top: vwSp(90);
       @include pc-layout(){
         margin-top: vwPc(60);
       }
       @include large-layout(){
         margin-top: 60px;
       }
     }
    .title{
      font-size: vwSp(41);
      margin-top: vwSp(90);
      line-height: 1.6;
      font-weight: bold;
      @include pc-layout(){
        font-size: vwPc(26);
        margin-top: vwPc(60);
      }
      @include large-layout(){
        font-size: 26px;
        margin-top: 60px;
      }
    }
  }
  .box-img-left{
      @include pc-layout(){
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin-top: vwPc(50);
      }
      .img-box{
        width: 100%;
        position: relative;
        display: block;
        img{
          @include pc-layout(){
            margin-top: 0;
          }
        }
        @include pc-layout(){
          width: vwPc(260);
          margin-right: vwPc(30);
          margin-top: 0;
        }
        @include large-layout(){
          width: 260px;
          margin-right: 30px;
        }
      }

    .content-right{
        @include pc-layout(){
            width: calc(100% - #{vwPc(290)});
        }
        @include large-layout(){
            width: calc(100% - 290px);
        }
      h5{
        margin-top: vwSp(40);
          @include pc-layout(){
             margin-top: 0;
          }
      }
      p{
        margin-top: vwSp(20);
          @include pc-layout(){
              margin-top: vwPc(20);
          }
          @include large-layout(){
              margin-top: 20px;
          }
      }
    }
  }
  .box-image{
      @include pc-layout(){
          position: relative;
          display: flex;
          flex-wrap: wrap;
      }
    .col{
        @include pc-layout(){
          width: calc((100% - #{vwPc(30)}) / 2);
          display: block;
            &:nth-child(even){
                margin-left: vwPc(30);
            }
        }
        @include large-layout(){
            width: calc((100% - 30px) / 2);
            display: block;
            &:nth-child(even){
                width: calc((100% - 30px) / 2);
                margin-left: 30px;
            }
        }
      .caption{
        font-size: vwSp(24);
        color: #666666;
        text-align: left;
        margin-top: vwSp(30);
        line-height: 2;
        width: 100%;
          @include pc-layout(){
              margin-top: vwPc(20);
              font-size: vwPc(12);
          }
          @include large-layout(){
              margin-top: 20px;
              font-size: 12px;
          }
        &.text-center{
          text-align: center;
        }
      }
      img{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .button-block{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: vwSp(60);
    &.center{
      @include pc-layout(){
        justify-content:center;
      }
      @include large-layout(){

      }
    }
      @include pc-layout(){
          margin-top: vwPc(50);
          margin-bottom: vwPc(-20);
      }
      @include large-layout(){
          margin-top: 50px;
          margin-bottom: -20px;
      }
    a{
      width: 100%;
      min-height: vwSp(110);
      border-radius: vwSp(110);
      background-color: #65b393;
      color: #fff;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
      font-weight: normal;
      font-size: vwSp(30);
      text-decoration: none;
      padding: vwSp(30) vwSp(80);
      line-height: 1.5;
        @include pc-layout(){
            min-width: vwPc(260);
            width: auto;
            min-height: vwPc(50);
            border-radius: vwPc(60);
            font-size: vwPc(15);
            text-decoration: none;
            padding: vwPc(15) vwPc(50);
            margin-bottom: vwPc(20);
            margin-right: vwPc(20);
        }
        @include large-layout(){
            min-width: 260px;
            min-height: 50px;
            border-radius: 60px;
            font-size: 15px;
            text-decoration: none;
            padding: 15px 50px ;
            margin-bottom: 20px;
            margin-right: 20px;
        }
      + a{
        margin-top: vwSp(60);
          @include pc-layout(){
              margin-top: 0;
          }
          @include large-layout(){
          }
      }
      &.pink{
        background-color: #e29f92;

      }
      &.auto{
        @include pc-layout(){
            min-width: auto;
            padding: vwPc(15) vwPc(50) vwPc(15) vwPc(35);
        }
        @include large-layout(){
            padding: 15px 50px 15px 35px;
        }
      }
     &.center{
        margin-left: auto;
        margin-right: auto;
     }
      &.window{
        transition: all 0.3s ease;
        &:hover{
          opacity: 0.7;
          &:after{
            animation:none;
          }
        }
        &:after {
          width: vwSp(30);
          height: vwSp(30);
          background: url("../img/ico_blank_white.png") 50% 50% no-repeat;
          background-size: 96%;
          top: calc(50% - #{vwSp(15)});
            @include pc-layout(){
                width: vwPc(17);
                height: vwPc(17);
                top: calc(50% - #{vwPc(9)});
                right: vwPc(20);
            }
            @include large-layout(){
                width: 17px;
                height: 17px;
                top: calc(50% - 9px);
                right: 20px;
            }
        }
      }
      &.pdf{
        padding-right: vwSp(110);
        transition: all 0.3s ease;
        &:hover{
          background-color: #84c2a9;
          &:after{
            animation:none;
          }
        }
          @include pc-layout(){
            padding: 0 vwPc(60);
          }
          @include large-layout(){
              padding: 0 60px;
          }
        &:after {
          width: vwSp(45);
          height: vwSp(16);
          background: url("../img/ico_pdf_white.svg") 50% 50% no-repeat;
          background-size: 99%;
          top: calc(50% - #{vwSp(8)});
          right: vwSp(55);
            @include pc-layout(){
                background: url("../img/ico_pdf_button.svg") 50% 50% no-repeat;
                background-size: 99%;
                width: vwPc(40);
                height: vwPc(18);
                top: calc(50% - #{vwPc(9)});
                right: vwPc(20);
            }
            @include large-layout(){
                width: 40px;
                height: 18px;
                top: calc(50% - 9px);
                right: 20px;
            }
        }
      }
      &:hover{
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
        background-size: 99%;
        top: calc(50% - #{vwSp(12)});
        right: vwSp(40);
          @include pc-layout(){
              width: vwPc(11);
              height: vwPc(12);
              top: calc(50% - #{vwPc(6)});
              right: vwPc(20);
          }
          @include large-layout(){
              width: 11px;
              height: 12px;
              top: calc(50% - 6px);
              right: 20px;
          }
      }
    }
  }
  .download-list{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: vwSp(20);
    @include pc-layout(){
      margin-top: vwPc(20);
    }
    @include large-layout(){
      margin-top: 20px;
    }
    .item{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      a{
        width: 100%;
        position: relative;
        display: flex;
        padding: vwSp(50) vwSp(50) vwSp(50) vwSp(180);
        border-bottom:vwSp(2) solid #e3e2df;
        background: #fff;
        text-decoration: none;
        font-size: vwSp(30);
        font-weight: bold;
        line-height: 1.5;
        transition: all 0.3s ease;
        @include pc-layout(){
          padding: vwPc(25) vwPc(25) vwPc(25) vwPc(90);
          border-bottom:vwPc(1) solid #e3e2df;
          font-size: vwPc(15);
        }
        @include large-layout(){
          padding: 25px 25px 25px 90px;
          border-bottom: 1px solid #e3e2df;
          font-size: 15px;
        }
        &:after{
          content: "";
          height: vwSp(80);
          width: vwSp(80);
          position: absolute;
          top:calc(50% - #{vwSp(40)});
          left: vwSp(50);
          font-size: vwSp(26);
          font-weight: bold;
          color: #fff;
          font-family: 'Inter', sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
          @include pc-layout(){
            height: vwPc(40);
            width: vwPc(40);
            top:calc(50% - #{vwPc(20)});
            left: vwPc(25);
            font-size: vwPc(13);
          }
          @include large-layout(){
            height: 40px;
            width: 40px;
            top:calc(50% - 20px);
            left:25px;
            font-size: 13px;
          }
        }
        &:hover{
          background: rgba(101,179,147,.2);
        }
      }
      &.word{
        a{
          &:after{
            background: url(../img/word.png) no-repeat;
            background-size: 99%;
          }
        }
      }
      &.excel{
        a{
          &:after{
            background: url(../img/excel.png) no-repeat;
            background-size: 99%;
          }
        }
      }
      &.pdf{
        a{
          &:after{
            background: url(../img/PDF.png) no-repeat;
            background-size: 99%;
          }
        }
      }
      &.pp{
        a{
          &:after{
            background: url(../img/pp.png) no-repeat;
            background-size: 99%;
          }
        }
      }
      &.zip{
        a{
          &:after{
            background: url(../img/zip.png) no-repeat;
            background-size: 99%;
          }
        }
      }
      &:first-child{
        a{
          border-top:vwSp(2) solid #e3e2df;
          @include pc-layout(){
            border-top:vwPc(1) solid #e3e2df;
          }
          @include large-layout(){
            border-top: 1px solid #e3e2df;
          }
        }
      }
    }
  }
  .tab{
    margin-top: vwSp(110);
      @include pc-layout(){
         margin-top: vwPc(50);
      }
      @include large-layout(){
         margin-top: 50px;
      }
    .tab-control{
      width: calc(100% + #{vwSp(80)});
      position: relative;
      display: flex;
      overflow: auto;
      margin-left: vwSp(-40);
        @include pc-layout(){
            width: 100%;
            margin-left: 0;
        }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .tab-content{
      display: none;
      &.active{
        display: block;
      }
    }
    ul{
      display: flex;
      flex-wrap: nowrap;
      padding: 0 vwSp(40);
        @include pc-layout(){
           width: 100%;
           padding: 0;
        }
      li{
        width: vwSp(315);
        min-height: vwSp(145);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top: vwSp(6) solid transparent;
        background: #e3e3e3;
        transition: all 0.3s ease;
        -ms-transition: none;
          @include pc-layout(){
              width: 100%;
              min-height: vwPc(72);
              border-top: vwPc(3) solid transparent;
          }
          @include large-layout(){
              min-height: 72px;
              border-top: 3px solid transparent;
          }
        +li{
          margin-left: vwSp(8);
            @include pc-layout(){
                margin-left: vwPc(4);
            }
            @include large-layout(){
                margin-left: 4px;
            }
        }
        &:hover{
          background:#65b393;
          a{
            color: #fff;
          }
        }
        &.active{
          border-top: vwSp(6) solid #65b393;
          background: #fafaf7;
            @include pc-layout(){

                border-top: vwPc(3) solid #65b393;
            }
            @include large-layout(){
                border-top: 3px solid #65b393;
            }
          a{
            color: #222222;
          }
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        a{
          font-size: vwSp(28);
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;
          color: #999999;
          padding: 0 vwSp(25);
          transition: all 0.3s ease;
          width: 100%;
          justify-content: center;
            @include pc-layout(){
                font-size: vwPc(14);
                padding: 0 vwPc(10);
                text-align: left;
                height: 100%;
                align-items: center;
                display: flex;
            }
            @include large-layout(){
                font-size: 14px;
                padding: 0 10px;
            }
        }
      }
    }
  }
  .anchor{
    margin-top: vwSp(110);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
    .anchor-control{
      width: calc(100% + #{vwSp(80)});
      position: relative;
      display: flex;
      overflow: auto;
      margin-left: vwSp(-40);
      @include pc-layout(){
        width: 100%;
        margin-left: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    ul{
      display: flex;
      flex-wrap: nowrap;
      padding: 0 vwSp(40);
      @include pc-layout(){
        width: 100%;
        padding: 0;
      }
      li{
        width: vwSp(315);
        min-height: vwSp(145);
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top: vwSp(6) solid transparent;
        background: #e3e3e3;
        transition: all 0.3s ease;
        -ms-transition: none;
        @include pc-layout(){
          width: 100%;
          min-height: vwPc(72);
          border-top: vwPc(3) solid transparent;
        }
        @include large-layout(){
          min-height: 72px;
          border-top: 3px solid transparent;
        }
        +li{
          margin-left: vwSp(8);
          @include pc-layout(){
            margin-left: vwPc(4);
          }
          @include large-layout(){
            margin-left: 4px;
          }
        }
        &:hover{
          background:#65b393;
          a{
            color: #fff;
          }
        }
        &.active{
          border-top: vwSp(6) solid #65b393;
          background: #fafaf7;
          @include pc-layout(){

            border-top: vwPc(3) solid #65b393;
          }
          @include large-layout(){
            border-top: 3px solid #65b393;
          }
          a{
            color: #222222;
          }
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
        a{
          font-size: vwSp(28);
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;
          color: #999999;
          padding: 0 vwSp(25);
          transition: all 0.3s ease;
          width: 100%;
          justify-content: center;
          @include pc-layout(){
            font-size: vwPc(14);
            padding: 0 vwPc(10);
            text-align: left;
            height: 100%;
            align-items: center;
            display: flex;
          }
          @include large-layout(){
            font-size: 14px;
            padding: 0 10px;
          }
        }
      }
    }
  }
  .detail-info{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(30);
    align-items: center;
      @include pc-layout(){
          margin-top: vwPc(30);
      }
      @include large-layout(){
          margin-top: 30;
      }
    .date{
      font-size: vwSp(28);
      color: #a6a6a6;
        @include pc-layout(){
            font-size: vwPc(14);
        }
        @include large-layout(){
            font-size: 14px;
        }
    }
    .tag{
      margin-left: vwSp(40);
      padding: 0 vwSp(20);
      height: vwSp(40);
      border-radius: vwSp(20);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #888888;
      background-color: #e3e3e3;
      font-size: vwSp(22);
        @include pc-layout(){
            margin-left: vwPc(20);
            padding: 0 vwPc(10);
            height: vwPc(22);
            border-radius: vwPc(10);
            font-size: vwPc(11);
            transition: all 0.3s ease;
        }
        @include large-layout(){
            margin-left: 20px;
            padding: 0 10px;
            height: 22px;
            border-radius: 10px;
            font-size: 11px;
        }
      + .tag{
        margin-left: vwSp(20);
          @include pc-layout(){
              margin-left: vwPc(10);
          }
          @include large-layout(){
              margin-left: 10px;
          }
      }
    }
    a{
      &.tag{
        text-decoration: none;
        &:hover{
          background: #65b393;
          color: #fff;
        }
      }
    }
  }
  .bussiness-group{
      @include pc-layout(){
          width: 100%;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin-top: vwPc(50);
      }
      @include large-layout(){
          margin-top: 50px;
      }
  }
  .business-box{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(60);
    background: #fff;
    padding: vwSp(40);
    border-top: vwSp(2) solid #e3e2df;
    border-bottom: vwSp(2) solid #e3e2df;
      @include pc-layout(){
          width: calc(50% - #{vwPc(15)});
          padding: vwPc(30) vwPc(20);
          border-top: vwPc(1) solid #e3e2df;
          border-bottom: vwPc(1) solid #e3e2df;
          margin-top: 0;
          display: block;
      }
      @include large-layout(){
          width: calc(50% - 15px);
          padding: 30px 20px;
          border-top: 1px solid #e3e2df;
          border-bottom: 1px solid #e3e2df;
      }
    + .business-box{
      margin-top: vwSp(40);
        @include pc-layout(){
            margin-top: 0;
            margin-left: vwPc(30);
        }
        @include large-layout(){
           margin-left: 30px;
        }
    }
    .box-head{
      width: 100%;
      position: relative;
      display: flex;
      padding-bottom: vwSp(40);
      border-bottom: vwSp(6) solid #65b393;
      font-size: vwSp(38);
      color: #65b393;
      font-weight: bold;
        @include pc-layout(){
            padding-bottom: vwPc(20);
            border-bottom: vwPc(3) solid #65b393;
            font-size: vwPc(20);
        }
        @include large-layout(){
            padding-bottom: 20px;
            border-bottom: 3px solid #65b393;
            font-size: 20px;
        }
        a{
          text-decoration: none;
        }
      &.pink{
        border-bottom: vwSp(6) solid #d56d59;
        color: #d56d59;
          @include pc-layout(){
              border-bottom: vwPc(3) solid #d56d59;
          }
          @include large-layout(){
              border-bottom: 3px solid #d56d59;
          }
      }
    }
    ul{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: vwSp(50);
      width: 100%;
        @include pc-layout(){
            margin-top: vwPc(20);
        }
        @include large-layout(){
            margin-top: 20px;
        }
      li{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-size: vwSp(34);
        font-weight: bold;
        a{
          text-decoration: none;
        }
          @include pc-layout(){
              font-size: vwPc(17);
          }
          @include large-layout(){
              font-size: 17px;
          }
        &:before{
          content: "● ";
          font-size: vwSp(16);
          margin-right: vwSp(5);
            @include pc-layout(){
                font-size: vwPc(8);
                margin-right: vwPc(5);
            }
            @include large-layout(){
                font-size: 8px;
                margin-right: 5px;
            }
        }

        ul{
          padding-left: vwSp(40);
          margin-top: vwSp(30);
            @include pc-layout(){
                padding-left: vwPc(20);
                margin-top: vwPc(20);
            }
            @include large-layout(){
                padding-left: 20px;
                margin-top: 20px;
            }
          li{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-size: vwSp(30);
            font-weight: normal;
              @include pc-layout(){
                  font-size: vwPc(15);
              }
              @include large-layout(){
                  font-size: 15px;
              }
            + li{
              margin-top: vwSp(30);
                @include pc-layout(){
                    margin-top: vwPc(20);
                }
                @include large-layout(){
                    margin-top: 20px;
                }
            }
            &:before{
              content: "○ ";
              font-size: vwSp(16);
              margin-right: vwSp(5);
                @include pc-layout(){
                    font-size: vwPc(6);
                    margin-right: vwPc(5);
                }
                @include large-layout(){
                    font-size: 6px;
                    margin-right: 5px;
                }
            }

          }
        }

        + li{
          margin-top: vwSp(40);
            @include pc-layout(){
                margin-top: vwPc(30);
            }
            @include large-layout(){
                margin-top: 30px;
            }
        }
      }
    }
  }
  .description{
    margin-top: vwSp(60);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    border-top: vwSp(2) solid #e3e2df;
    border-bottom: vwSp(2) solid #e3e2df;
    background: #fff;
    padding-top: vwSp(40);
    padding-bottom: vwSp(40);
    margin-bottom: 0;
      @include pc-layout(){
          margin-top: vwPc(50);
          border-top: vwPc(1) solid #e3e2df;
          border-bottom: vwPc(1) solid #e3e2df;
          padding-top: vwPc(30);
          padding-bottom: vwPc(30);
      }
      @include large-layout(){
          margin-top: 50px;
          border-top: 1px solid #e3e2df;
          border-bottom: 1px solid #e3e2df;
          padding-top: 30px;
          padding-bottom: 30px;
      }
    &.open{
      dt{
        padding-right: vwSp(150);
        background: url("../img/open.png") no-repeat;
        background-size: vwSp(50);
        background-position: top 50% right vwSp(40);
          @include pc-layout(){
              padding-right: vwPc(90);
              background-size: vwPc(27);
              background-position: top 50% right vwPc(20);
          }
          @include large-layout(){
              padding-right: 90px;
              background-size: 27px;
              background-position: top 50% right 20px;
          }
      }
    }
    dt,
    dd{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: 0;
      padding: 0 vwSp(40);
      line-height: 1.6;
        @include pc-layout(){
            padding:0 vwPc(20);
        }
        @include large-layout(){
            padding:0 20px;
        }
        .list{
          margin-top: 0;
        }
    }
    dt{
      padding-right: vwSp(150);
      background: url("../img/close.png") no-repeat;
      background-size: vwSp(50);
      background-position: top 50% right vwSp(40);
      cursor: pointer;
      font-weight: bold;
      font-size: vwSp(32);
        @include pc-layout(){
            padding-right: vwPc(90);
            background-size: vwPc(27);
            background-position: top 50% right vwPc(20);
            font-size: vwPc(16);
        }
        @include large-layout(){
            padding-right: 90px;
            background-size: 27px;
            background-position: top 50% right 20px;
            font-size: 16px;
        }
    }
    dd{
      display: none;
      overflow: hidden;
      transition: max-height 1s ease;
      font-size: vwSp(30);
      .list{
        li{
          margin-top: vwSp(40);
          @include pc-layout(){
            margin-top: vwPc(10);
          }
          @include large-layout(){
            margin-top: 10px;
          }
        }
      }
      @include pc-layout(){
        font-size: vwPc(15);
      }
      @include large-layout(){
        font-size: 15px;
      }
    }
    + .description{
      margin-top: 0;
      border-top: 0;
    }
  }

  .article-list{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: vwSp(60);
      @include pc-layout(){
          margin-top: vwPc(50);
      }
      @include large-layout(){
          margin-top: 50px;
      }
    .article-item{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: vwSp(50);
      border-bottom: vwSp(2) solid #eae9e6;
      padding-bottom: vwSp(50);
        @include pc-layout(){
            margin-top: vwPc(30);
            border-bottom: vwPc(1) solid #eae9e6;
            padding-bottom: vwPc(30);
        }
        @include large-layout(){
            margin-top: 30px;
            border-bottom: 1px solid #eae9e6;
            padding-bottom: 30px;
        }
      &:first-child{
        margin-top: 0;
      }
      a{
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        &:hover{
          .article-img{
            img{
              opacity: 0.6;
            }
          }
        }
      }
      .article-img{
        position: relative;
        display: block;
        overflow: hidden;
        width: vwSp(400);
        height: vwSp(400);
        margin: 0 auto;
          @include pc-layout(){
              width: vwPc(150);
              height: vwPc(150);
          }
          @include large-layout(){
              width: 150px;
              height: 150px;
          }
        img{
          width: 100%;
          height: 100%;
          transition: all .5s ease;
          margin-top: 0;
        }
      }
      .article-content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: vwSp(40);
          @include pc-layout(){
              width: calc(100% - #{vwPc(150)});
              margin-top: 0;
              padding-left: vwPc(30);
              display: block;
          }
          @include large-layout(){
              width: calc(100% - 150px);
              padding-left: 30px;
          }
        .content-tag{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
            @include pc-layout(){
                align-items: center;
            }
          .vol{
            font-family: 'Inter', sans-serif;
            font-weight: normal;
            font-size: vwSp(26);
            line-height: 1.2;
            width: 100%;
              @include pc-layout(){
                  font-size: vwPc(13);
                  width: auto;
              }
              @include large-layout(){
                  font-size: 13px;
              }
          }
          .tag{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 1;
            background: #e3e3e3;
            color: #888888;
            font-size: vwSp(22);
            margin-top: vwSp(25);
            height: vwSp(40);
            padding: 0 vwSp(25);
            border-radius: vwSp(20);
              @include pc-layout(){
                  margin-left: vwPc(30);
                  padding: 0 vwPc(10);
                  height: vwPc(22);
                  border-radius: vwPc(10);
                  font-size: vwPc(11);
                  margin-top: 0;
                  transition: all 0.3s ease;
              }
              @include large-layout(){
                  margin-left: 30px;
                  padding: 0 10px;
                  height: 22px;
                  border-radius: 10px;
                  font-size: 11px;
              }
            + .tag{
              margin-left:vwSp(10);
                @include pc-layout(){
                    margin-left:vwPc(10);
                }
                @include large-layout(){
                    margin-left:10px;
                }
            }
          }
          a{
            &.tag{
              text-decoration: none;
              &:hover{
                background: #65b393;
                color: #fff;
              }
            }
          }
        }
        .content-title{
          font-size: vwSp(34);
          font-weight: bold;
          line-height: 1.6;
          margin-top: vwSp(20);
            @include pc-layout(){
                font-size: vwPc(17);
                margin-top: vwPc(15);
            }
            @include large-layout(){
                font-size: 17px;
                margin-top: 15px;
            }
        }
        .content-detail{
          font-size: vwSp(28);
          font-weight: normal;
          line-height: 2;
          margin-top: vwSp(25);
            @include pc-layout(){
                font-size: vwPc(14);
                margin-top: vwPc(15);
            }
            @include large-layout(){
                font-size: 14px;
                margin-top: 15px;
            }
        }
      }
    }
  }
  .iframe-wrap{
    width: 100%;
    position: relative;
    display: block;
    overflow: auto;
    -webkit-overflow-scrolling:touch;
    iframe{
      @include pc-layout(){
        height: vwPc(800);
      }
      @include large-layout(){
        height: 800px;
      }
    }
  }
  iframe{
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: vwSp(515);
    margin-top: vwSp(60);
    overflow: auto;
    -webkit-overflow-scrolling:touch;
      @include pc-layout(){
          height: vwPc(500);
          margin-top: vwPc(50);
      }
      @include large-layout(){
          height: 500px;
          margin-top: 50px;
      }
  }
  .profile{
    position: relative;
    display: block;
    width: 100%;
    margin-top: vwSp(100);
    @include pc-layout(){
      margin-top: vwPc(70);
      display: flex;
      flex-wrap: wrap;
    }
    @include large-layout(){
      margin-top: 70px;
    }
    .about{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      @include pc-layout(){
        width: 50%;
      }
      @include large-layout(){
      }
      .img-box{
        width: vwSp(224);
        height: vwSp(224);
        position: relative;
        display: block;
        line-height: 0;
        @include pc-layout(){
          width: vwPc(150);
          height: vwPc(150);
        }
        @include large-layout(){
          width: 150px;
          height: 150px;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
          margin-top: 0;
          object-position: center top;
        }
      }
      .des-box{
        width: calc(100% - #{vwSp(224)});
        padding: 0 vwSp(40);
        position: relative;
        display: block;
        @include pc-layout(){
          width: calc(100% - #{vwPc(150)});
          padding: 0 0 0 vwPc(40);
        }
        @include large-layout(){
          width: calc(100% - 150px);
          padding: 0  0 0 40px;
        }
        .name{
          width: 100%;
          position: relative;
          display: block;
          font-size: vwSp(46);
          font-weight: bold;
          line-height: 1;
          top:-0.08em;
          @include pc-layout(){
            font-size: vwPc(30);
          }
          @include large-layout(){
            font-size: 30px;
          }
        }
        .position{
          width: 100%;
          position: relative;
          display: block;
          font-size: vwSp(38);
          line-height: 1.5;
          color: #222222;
          margin-top: vwSp(30);
          @include pc-layout(){
            font-size: vwPc(18);
            margin-top: vwPc(25);
          }
          @include large-layout(){
            font-size: 18px;
            margin-top: 25px;
          }
        }
      }
    }
    .summary{
      width: 100%;
      position: relative;
      display: block;
      margin-top: vwSp(40);
      @include pc-layout(){
        width: 50%;
        padding-left: vwPc(40);
        margin-top: 0;
      }
      @include large-layout(){
        padding-left: 40px;
      }
      .head{
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: vwSp(38);
        width: 100%;
        position: relative;
        display: block;
        padding-bottom: vwSp(10);
        line-height: 1.5;
        border-bottom: vwSp(2) solid #eae9e6;
        text-align: center;
        @include pc-layout(){
          top:-0.3em;
          font-size: vwPc(18);

          padding-bottom: vwPc(10);

          border-bottom: vwPc(1) solid #eae9e6;
        }
        @include large-layout(){
          font-size: 18px;

          padding-bottom: 10px;

          border-bottom: 1px solid #eae9e6;
        }
      }
      .text{
        position: relative;
        display: block;
        width:100%;
        font-size: vwSp(30);
        line-height: 2;
        margin-top: vwSp(10);
        @include pc-layout(){
          font-size: vwPc(15);
          margin-top: vwPc(10);
          text-align: left;
        }
        @include large-layout(){
          font-size: 15px;
          margin-top: 10px;
        }
      }
    }
  }

}
.two-col{
  background: #fafaf7;
  .content{
      @include pc-layout(){
          width: calc(100% - #{vwPc(600)});
          margin-left: vwPc(600);
          padding: 0 vwPc(100) vwPc(100);
      }
      @include large-layout(){
          width: calc(100% - 600px);
          margin-left: 600px;
          padding: 0 100px 100px;
      }
  }
  h1{
    width: 100%;
    position: relative;
    display: flex;
    padding:vwSp(40) vwSp(38) vwSp(40) vwSp(40);
    align-items: center;
    min-height: vwSp(450);
    background: #ecece7;
    font-weight: normal;
      &.absolute{
        @include pc-layout(){
          position: absolute;
          top: auto;
          bottom: 0;
        }
      }
      @include pc-layout(){
          position: fixed;
          top:0;
          left: 0;
          width: vwPc(600);
          background-color: #ecece7;
          height: 100vh;
          min-height: 0;
          padding: vwPc(170) vwPc(50) 0 vwPc(55);
          font-size:vwPc(41);
          align-items: flex-start;
          z-index: -1;
      }
      @include large-layout(){
          width: 600px;
          padding: 170px 50px 0 55px;
          font-size: 41px;
      }
      &.anchor{
        @include pc-layout(){
          padding: vwPc(250) vwPc(50) 0 vwPc(55);

        }
        @include large-layout(){
          padding: 250px 50px 0 55px;
        }
      }
  }
  h2{
    margin-top: vwSp(90);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
  }
  h3{
    margin-top: vwSp(90);
    @include pc-layout(){
      margin-top: vwPc(100);
    }
    @include large-layout(){
      margin-top: 100px;
    }
    + .list{
      margin-top: vwSp(20);
      :first-child{
        margin-top: vwSp(20);
        @include pc-layout(){
          margin-top: vwPc(10);
        }
        @include large-layout(){
          margin-top: 10px;
        }
      }
      @include pc-layout(){
        margin-top: vwPc(20);
      }
      @include large-layout(){
        margin-top: 20px;
      }
    }
  }
  h4{
    margin-top: vwSp(90);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
  }
  h5{
    margin-top: vwSp(30);
    @include pc-layout(){
      margin-top: vwPc(20);
    }
    @include large-layout(){
      margin-top: 20px;
    }
  }
  p{
    margin-top: vwSp(40);
    @include pc-layout(){
      margin-top: vwPc(30);
    }
    @include large-layout(){
      margin-top: 30px;
    }
    + p{
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(20);
      }
      @include large-layout(){
        margin-top: 20px;
      }
    }
    + .list{
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(20);
      }
      @include large-layout(){
        margin-top: 20px;
      }
    }
  }
  summary{
    margin-top: vwSp(30);
    outline: none;
    @include pc-layout(){
      margin-top: vwPc(20);
    }
    @include large-layout(){
      margin-top: 20px;
    }
    font-weight: bold;
    font-size: vwSp(38);
    line-height: 1.6;
    @include pc-layout(){
      font-size: vwPc(20);
    }
    @include large-layout(){
      font-size: 20px;
    }
    h5{
      width: auto;
      margin-top: 0;
      display: inline-block;
    }
  }
  .list{
    margin-top: vwSp(40);
    @include pc-layout(){
      margin-top: vwPc(40);
    }
    @include large-layout(){
      margin-top: 40px;
    }
  }
}

.one-col{
  @include pc-layout(){
      background: #fafaf7;
  }
  .content{
    @include pc-layout(){
        max-width: 1440px;
        padding: 0 vwPc(135) vwPc(100);
        margin: auto;
    }
    @include large-layout(){
        padding: 0 135px 100px;

    }
  }
    h1{
        margin-top: vwSp(40);
        @include pc-layout(){
            margin-top: vwPc(50);
            font-size: vwPc(41);
        }
        @include large-layout(){
            margin-top: 50px;
            font-size: 41px;
        }
        + p{
            @include pc-layout(){
                margin-top: vwPc(50);
            }
            @include large-layout(){
                margin-top: 50px;
            }
        }
    }
    h2{
        margin-top: vwSp(40);
        @include pc-layout(){
            margin-top: vwPc(50);
        }
        @include large-layout(){
            margin-top: 50px;
        }
        + p{
            @include pc-layout(){
                margin-top: vwPc(40);
            }
            @include large-layout(){
                margin-top: 40px;
            }
        }
        +.tab{
          margin-top: vwSp(60);
          @include pc-layout(){
            margin-top: vwPc(50);
          }
          @include large-layout(){
            margin-top: 50px;
          }
        }
    }
    h3{
        margin-top: vwSp(90);
        @include pc-layout(){
            margin-top: vwPc(50);
        }
        @include large-layout(){
            margin-top: 50px;
        }
        + p{
            @include pc-layout(){
                margin-top: vwPc(40);
            }
            @include large-layout(){
                margin-top: 40px;
            }
        }
        + .download-list{
          margin-top: vwSp(50);
          @include pc-layout(){
            margin-top: vwPc(50);
          }
          @include large-layout(){
            margin-top: 50px;
          }
        }

    }
    h4{
        margin-top: vwSp(90);
        @include pc-layout(){
            margin-top: vwPc(50);
        }
        @include large-layout(){
            margin-top: 50px;
        }
        + p{
            @include pc-layout(){
                margin-top: vwPc(30);
            }
            @include large-layout(){
                margin-top: 30px;
            }
        }
    }
    h5{
        margin-top: vwSp(40);
        @include pc-layout(){
            margin-top: vwPc(30);
        }
        @include large-layout(){
            margin-top: 30px;
        }
        + p{
            @include pc-layout(){
                margin-top: vwPc(30);
            }
            @include large-layout(){
                margin-top: 30px;
            }
        }
    }
    p{
      margin-top: vwSp(40);
      @include pc-layout(){
        margin-top: vwPc(50);
        font-size: vwPc(16);
      }
      @include large-layout(){
        font-size: 16px;
        margin-top: 50px;
      }
      + p{
        margin-top: vwSp(20);
        @include pc-layout(){
          margin-top: vwPc(20);
        }
        @include large-layout(){
          margin-top: 20px;
        }
      }
      + .list{
        margin-top: vwSp(20);
        @include pc-layout(){
          margin-top: vwPc(20);
        }
        @include large-layout(){
          margin-top: 20px;
        }
      }
      &.note{
        @include pc-layout(){
          font-size: vwPc(14);
        }
        @include large-layout(){
          font-size: 14px;
        }
      }
    }
    .list{
        margin-top: vwSp(40);
        @include pc-layout(){
            margin-top: vwPc(40);
        }
        @include large-layout(){
            margin-top: 40px;
        }
    }
  .list{
    margin-top: vwSp(40);
  }
  h3{
    margin-top: vwSp(60);
  }
  .article-head{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    border-bottom: vwSp(2) solid #eae9e6;
    padding-bottom: vwSp(50);
      @include pc-layout(){
          padding-bottom: vwPc(30);
          border-bottom: vwPc(1) solid #eae9e6;
      }
      @include large-layout(){
          padding-bottom: 30px;
          border-bottom: 1px solid #eae9e6;
      }
    p{
      margin-top: vwSp(40);
      font-size: vwSp(34);
      line-height: 2;
        &.name{
            font-weight: bold;
            width: 100%;
        }
        &.university{
            margin-top: 0;
        }
        @include pc-layout(){
            margin-top: vwPc(30);
            font-size: vwPc(16);
        }
        @include large-layout(){
            margin-top: 30px;
            font-size: 16px;
        }
    }
  }
  .article-detail{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(30);
    align-items: center;
    @include pc-layout(){
      margin-top: vwPc(15);
    }
    @include large-layout(){
      margin-top: 15px;
    }
    .info{
      color: #a6a6a6;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include pc-layout(){
        width: auto;
      }
      span{
        font-size: vwSp(26);
        padding-right: vwSp(26);
        display: flex;
        line-height: vwSp(30);
        @include pc-layout(){
          font-size: vwPc(13);
          padding-right: vwPc(20);
          line-height: vwPc(20);
        }
        @include large-layout(){
          font-size: 13px;
          padding-right: 20px;
          line-height: 20px;
        }
      }
    }
    .vol{
      width: 100%;
      position: relative;
      display: flex;
      margin-bottom: vwSp(30);
      font-family: 'Inter', sans-serif;
      left: 1;
      font-size: vwSp(26);
      line-height: 1.5;
      @include pc-layout(){
        font-size: vwPc(13);
        margin-bottom: vwPc(10);
      }
      @include large-layout(){
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
    .tag{
      margin-top: vwSp(30);
      margin-left: 0;
      padding: 0 vwSp(20);
      height: vwSp(40);
      border-radius: vwSp(20);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #888888;
      background-color: #e3e3e3;
      font-size: vwSp(22);
      text-decoration: none;
      @include pc-layout(){
        margin-top: 0;
        margin-left: vwPc(20);
        padding: 0 vwPc(10);
        height: vwPc(22);
        border-radius: vwPc(10);
        font-size: vwPc(11);
        transition: all 0.3s ease;
      }
      @include large-layout(){
        margin-left: 20px;
        padding: 0 10px;
        height: 22px;
        border-radius: 10px;
        font-size: 11px;
      }
      + .tag{
        margin-left: vwSp(15);
        @include pc-layout(){
          margin-left: vwPc(5);
        }
        @include large-layout(){
          margin-left: 5px;
        }
      }
    }
    a{
      &.tag{
        text-decoration: none;
        &:hover{
          background: #65b393;
          color: #fff;
        }
      }
    }
  }
  .article-content{
    margin-top: vwSp(100);
    position: relative;
    display: flex;
    flex-wrap: wrap;
      @include pc-layout(){
          margin-top: vwPc(60);
      }
      @include large-layout(){
          margin-top: 60px;
      }
    h4{
      margin-top: 0;
      border-left: 0;
      padding-left: 0;
      line-height: 1.6;
    }
    p{
      font-size: vwSp(32);
      line-height: 2;
      width: 100%;
        @include pc-layout(){
            font-size: vwPc(16);
            line-height: 2;
        }
        @include large-layout(){
            font-size: 16px;
        }
      &.head{
        font-size: vwSp(40);
        font-weight: bold;
        line-height: 1.3;
        width: 100%;
        margin-top: 0;
        @include pc-layout(){
          font-size: vwPc(24);
          margin-top: 0;
        }
        @include large-layout(){
          font-size: 24px;
          margin-top: 0;
        }
      }
      &.title{
        font-weight: bold;
        font-size: vwSp(38);
        line-height: 1.6;
        width:100%;

        + p{
          margin-top: vwSp(20);
        }
        @include pc-layout(){
          font-size: vwPc(20);
          + p{
            margin-top: vwPc(30);
          }
        }
        @include large-layout(){
          font-size: 20px;
          + p{
            margin-top: 30px;
          }
        }
      }

    }

  }
  .row{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    @include pc-layout(){
      width: 100%;
    }
    .col{
      width: 100%;
      @include pc-layout(){
        max-width: 700px;
        margin: auto;
      }
      img{
        width: 100%;
        @include pc-layout(){
          width: auto;
        }
        +.big{
          margin-top: vwSp(15);
          @include pc-layout(){
            margin-top: vwPc(10);
          }
          @include large-layout(){
            margin-top: 10px;
          }
        }
      }
      +p{
        margin-top: vwSp(30);
        @include pc-layout(){
          margin-top: vwPc(50);
        }
        @include large-layout(){
          margin-top: 50px;
        }
      }
      .big{
        font-weight: bold;
        font-size: vwSp(32);
        text-align: left;
        line-height: 1.5;
        @include pc-layout(){
          font-size: vwPc(16);
        }
        @include large-layout(){
          font-size: 16px;
        }
      }
      a{
        text-decoration: none;
      }
      .caption{
        font-size: vwSp(26);
        color: #666666;
        text-align: left;
        margin-top: vwSp(30);
        line-height: 2;
        @include pc-layout(){
          margin-top: vwPc(15);
          font-size: vwPc(13);
        }
        @include large-layout(){
          margin-top: 15px;
          font-size: 13px;
        }
        &.text-center{
          text-align: center;
        }
      }
    }
    .post-image{
      position: relative;
      line-height: 0;
      width: 100%;
      img{
        width: 100%;
      }
    }
    &.p-60{
      @include pc-layout(){
        flex-wrap: nowrap;
      }
      .col{
        @include pc-layout(){
          margin-left: vwPc(60);
          margin-top: 0;
          width: 100%;
        }
        @include large-layout(){
          margin-left: 60px;
        }
        &:first-child{
          @include pc-layout(){
            margin-left: 0;
          }
        }
      }
    }
    &.p-30{
      .col{
        width: calc((100% - #{vwSp(40)}) / 2 );
        margin-right: vwSp(40);
        @include pc-layout(){
          width: calc((100% - #{vwPc(90)}) / 4);
          margin-left: vwPc(30);
          margin-top: 0;
          margin-right: 0;
        }
        @include large-layout(){
          width: calc((100% - 90px) / 4);
          margin-left: 30px;
        }
        &:nth-child(4n+1),
        &:first-child{
          @include pc-layout(){
            margin-left: 0;
          }
        }
        a{
          img{
            transition: all 0.3s ease;
          }
          &:hover{
            img{
              opacity: 0.6;
            }
          }
        }
        .caption{
          margin-top: vwSp(20);
          @include pc-layout(){
            margin-top: vwPc(20);
          }
          @include large-layout(){
            margin-top: 20px;
          }
        }
        &:nth-child(even){
          margin-right: 0;
        }
        img{
          margin-top: vwSp(40);
          @include pc-layout(){
            margin-top: vwPc(40);
            max-width:100%;
            max-height: 80vh;
            object-fit: cover;
          }
          @include large-layout(){
            margin-top: 40px;
          }
        }
        .article-detail{
          margin-top: vwSp(30);
          @include pc-layout(){
            margin-top: vwPc(20);
          }
          @include large-layout(){
            margin-top: 20px;
          }
          .tag{
            margin: 0 vwSp(20) vwSp(20) 0;
            @include pc-layout(){
              margin: 0 vwPc(5) vwPc(5) 0;
            }
            @include large-layout(){
              margin: 0 5px 5px 0;
            }
          }
          +p{
            margin-top: 0;
          }
        }
      }
    }
    &.relate{
      .col{
        width: calc((100% - #{vwSp(40)}) / 2 );
        margin-right: vwSp(40);
        @include pc-layout(){
          width: calc((100% - #{vwPc(90)}) / 4);
          margin-left: vwPc(30);
          margin-top: 0;
          margin-right: 0;
        }
        @include large-layout(){
          width: calc((100% - 90px) / 4);
          margin-left: 30px;
        }
        &:nth-child(4n+1),
        &:first-child{
          @include pc-layout(){
            margin-left: 0;
          }
        }
        a{
          img{
            transition: all 0.3s ease;
            height: vwSp(220);
            object-fit: cover;
            width: 100%;
            @include pc-layout(){
              height: vwPc(190);
            }
            @include large-layout(){
              height: 190px;
            }
          }
          &:hover{
            img{
              opacity: 0.6;
            }
          }
        }
        .caption{
          margin-top: vwSp(20);
          @include pc-layout(){
            margin-top: vwPc(20);
          }
          @include large-layout(){
            margin-top: 20px;
          }
        }
        &:nth-child(even){
          margin-right: 0;
        }
        img{
          margin-top: vwSp(40);
          @include pc-layout(){
            margin-top: vwPc(40);
            max-width:100%;
            max-height: 80vh;
            object-fit: cover;
          }
          @include large-layout(){
            margin-top: 40px;
          }
        }
        .article-detail{
          margin-top: vwSp(30);
          @include pc-layout(){
            margin-top: vwPc(20);
          }
          @include large-layout(){
            margin-top: 20px;
          }
          .tag{
            margin: 0 vwSp(20) vwSp(20) 0;
            @include pc-layout(){
              margin: 0 vwPc(5) vwPc(5) 0;
            }
            @include large-layout(){
              margin: 0 5px 5px 0;
            }
          }
          +p{
            margin-top: 0;
          }
        }
      }
    }
  }

  .column{
    width: 100%;
    padding: vwSp(140) vwSp(40) vwSp(100);
    background-color: #ecece7;
      @include pc-layout(){
          padding: vwPc(100) vwPc(135);
      }
      @include large-layout(){
          padding: 100px 135px;
      }
    .content{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      padding: vwSp(100) vwSp(40) vwSp(20);
        @include pc-layout(){
            padding: vwPc(70) vwPc(100) vwPc(90);
            max-width: 1170px;
        }
        @include large-layout(){
            padding: 70px 100px 90px;
        }
      &:before{
        content: "COLUMN";
        font-size: vwSp(110);
        font-weight: bold;
        font-family: 'Inter', sans-serif;
        color: #bebeb2;
        line-height: vwSp(80);
        position: absolute;
        left: vwSp(40);
        top:vwSp(-40);
          @include pc-layout(){
              font-size: vwPc(68);
              line-height: vwPc(50);
              left: vwPc(100);
              top:vwPc(-25);
          }
          @include large-layout(){
              font-size: 68px;
              line-height: 50px;
              left: 100px;
              top:-25px;
          }
      }
      h3{
          margin-top: 0;
      }
      .detail{
        border-top: vwSp(6) solid #eae9e6;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-top: vwSp(80);
        margin-top: vwSp(40);
          @include pc-layout(){
              border-top: vwPc(3) solid #eae9e6;
              padding-top: vwPc(40);
              margin-top: vwPc(20);
          }
          @include large-layout(){
              border-top: 3px solid #eae9e6;
              padding-top: 40px;
              margin-top: 20px;
          }
        .head{
          font-size: vwSp(40);
          font-weight: bold;
          line-height: 1;
          margin-top: 0;
            @include pc-layout(){
                font-size: vwPc(24);
            }
            @include large-layout(){
                font-size: 24px;
            }
        }
        p{
          font-size: vwSp(32);
          line-height: 2;
            @include pc-layout(){
                font-size: vwPc(16);
            }
            @include large-layout(){
                font-size: 16px;
            }
          + p{
            margin-top: vwSp(30);
              @include pc-layout(){
                  margin-top: vwPc(20);
              }
              @include large-layout(){
                  margin-top: 20px;
              }
          }
        }
      }
    }
  }
  .question{
    margin-top: vwSp(60);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    border-top: vwSp(2) solid #e3e2df;
    border-bottom: vwSp(2) solid #e3e2df;
    background: #fff;
    padding-top: vwSp(40);
    padding-bottom: vwSp(40);
    margin-bottom: 0;
      @include pc-layout(){
          margin-top: vwPc(50);
          border-top: vwPc(1) solid #e3e2df;
          border-bottom: vwPc(1) solid #e3e2df;
          padding-top: vwPc(20);
          padding-bottom: vwPc(20);
      }
      @include large-layout(){
          margin-top: 50px;
          border-top: 1px solid #e3e2df;
          border-bottom: 1px solid #e3e2df;
          padding-top: 20px;
          padding-bottom: 20px;
      }
    +h2{
      margin-top: vwSp(80);
      @include pc-layout(){
        margin-top: vwPc(70);
      }
      @include large-layout(){
        margin-top: 70px;
      }
    }
    &.open{
      dt{
        padding-right: vwSp(150);
        background: url("../img/open.png") no-repeat;
        background-size: vwSp(50);
        background-position: top vwSp(36) right vwSp(40);
          @include pc-layout(){
              padding-right: vwPc(100);
              background-size: vwPc(25);
              background-position: top 50% right vwPc(40);
          }
          @include large-layout(){
              padding-right: 100px;
              background-size: 25px;
              background-position: top 50% right 40px;
          }
      }
    }
    dt,
    dd{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: 0;
      padding: 0 vwSp(150) 0 vwSp(120);
        @include pc-layout(){
            padding: 0 vwPc(100) 0 vwPc(100);
        }
        @include large-layout(){
            padding: 0 100px 0 100px;
        }
      p{
        margin-top: 0;
      }
    }
    dt{
      background: url("../img/close.png") no-repeat;
      background-size: vwSp(50);
      background-position: top vwSp(16) right vwSp(40);
      cursor: pointer;
      font-weight: bold;
      line-height: 2;
      font-size: vwSp(34);
        @include pc-layout(){
        background-size: vwPc(25);
        background-position: top 50% right vwPc(40);
        font-size: vwPc(18);
        }
        @include large-layout(){
            background-size: 25px;
            background-position: top 50% right 40px;
            font-size: 18px;
        }
      &:before{
        content: "Q";
        font-size: vwSp(50);
        font-weight: bold;
        position: absolute;
        color: #a6a6a6;
        left: vwSp(40);
        top:vwSp(11);
        line-height: 1;
          @include pc-layout(){
              font-size: vwPc(34);
              left: vwPc(40);
              top:calc(50% - #{vwPc(17)});
              line-height: 1;
          }
          @include large-layout(){
              font-size: 34px;
              top:calc(50% - 17px);
              left: 40px;
          }
      }
    }
    dd{
      display: none;
      overflow: hidden;
      transition: max-height 1s ease;
      margin-top: vwSp(54);
      font-size: vwSp(32);
        @include pc-layout(){
            margin-top: vwPc(30);
            font-size: vwPc(16);
        }
        @include large-layout(){
            margin-top: 30px;
            font-size: 16px;
        }
      &:before{
        content: "A";
        font-size: vwSp(50);
        font-weight: bold;
        position: absolute;
        color: #e29f92;
        left: vwSp(40);
        top:vwSp(11);
        line-height: 1;
          @include pc-layout(){
              font-size: vwPc(34);
              left: vwPc(40);
              top:calc(50% - #{vwPc(17)});
              line-height: 1;
          }
          @include large-layout(){
              font-size: 34px;
              left: 40px;
              top:calc(50% - 17px);
          }
      }
    }
    + .question{
      margin-top: 0;
      border-top: 0;
    }
  }
  .big-button{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: vwSp(60);
      @include pc-layout(){
         margin-top: vwPc(50);
      }
      @include large-layout(){
          margin-top: 50px;
      }
    .button{
      width: calc((100% - #{vwSp(40)}) / 2);
      margin-top: vwSp(40);
      margin-right: vwSp(40);
        @include pc-layout(){
            width:calc((100% - #{vwPc(168)}) / 4);
            margin-top: vwPc(60);
            margin-right: vwPc(56);
        }
        @include large-layout(){
            width: calc((100% - 168px) / 4);
            margin-top: 60px;
            margin-right: 56px;
        }
      &:nth-child(-n+2){
        margin-top: 0;
      }
      &:nth-child(-n+4){
          @include pc-layout(){
           margin-top: 0;
          }
      }
      &:nth-child(even){
        margin-right: 0;
          @include pc-layout(){
              margin-right: vwPc(56);
          }
          @include large-layout(){
              margin-right: 56px;
          }
      }
        &:nth-child(4n){
            @include pc-layout(){
                margin-right: 0;
            }
            @include large-layout(){
                margin-right: 0;
            }
        }
      &.disable{
        a{
          color: #e3e2df;
          border-color: #e3e2df;
          pointer-events: none;
          &:after{
            background: url("../img/disable-right-arrow.png") 50% 50% no-repeat;
            background-size: 99%;
          }
        }
      }
    }
    a{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: vwSp(280);
      text-decoration: none;
      text-align: center;
      border-radius: vwSp(40);
      border: vwSp(6) solid #65b393;
      background: #fff;
        @include pc-layout(){
            height: vwPc(160);
            border-radius: vwPc(20);
            border: vwPc(3) solid #65b393;
        }
        @include large-layout(){
            height: 160px;
            border-radius: 20px;
            border: 3px solid #65b393;
        }
      p{
        font-size: vwSp(34);
        line-height: 1.5;
        font-weight: bold;
        text-align: center;
        margin-top: 0;
          @include pc-layout(){
              font-size: vwPc(18);
          }
          @include large-layout(){
              font-size: 18px;
          }
        span{
          display: block;
          + span{
            margin-top: vwSp(5);
              @include pc-layout(){
                  margin-top: vwPc(5);
              }
              @include large-layout(){
                  margin-top: 5px;
              }
          }
        }
        .small{
          font-weight: normal;
          font-size: vwSp(28);
          display: inline-flex;
          text-align: center;
            @include pc-layout(){
                font-size: vwPc(14);
            }
            @include large-layout(){
                font-size: 14px;
            }
        }
        .big{
          font-size: vwSp(42);
          display: inline-flex;
          text-align: center;
          @include pc-layout(){
            font-size: vwPc(22);
          }
          @include large-layout(){
            font-size: 22px;
          }
        }
      }
      &:hover{
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
        background-size: 99%;
        bottom: vwSp(20);
        right: vwSp(20);
        @include pc-layout(){
          width: vwPc(11);
          height: vwPc(12);
          bottom: vwPc(20);
          right: vwPc(20);
        }
        @include large-layout(){
          width: 11px;
          height: 12px;
          bottom: 20px;
          right: 20px;
        }
      }
      &.pink{
        border: vwSp(6) solid #e29f92;
          @include pc-layout(){
              border: vwPc(3) solid #e29f92;
          }
          @include large-layout(){
              border: 3px solid #e29f92;
          }
        &:after{
          background: url("../img/pink-right-arrow.png") 50% 50% no-repeat;
          background-size: 99%;
        }
      }
    }
    .caption{
      font-size: vwSp(28);
      text-align: center;
      line-height: 1.5;
      margin-top: vwSp(20);
        @include pc-layout(){
            font-size: vwPc(14);
            margin-top: vwPc(5);
        }
        @include large-layout(){
            font-size: 14px;
            margin-top: 5px;
        }
    }
    +h2{
      margin-top: vwSp(90);
      @include pc-layout(){
        margin-top: vwPc(50);
      }
      @include large-layout(){
        margin-top: 50px;
      }
    }
  }
  .timeline{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(30);
    padding-left: vwSp(60);
    padding-bottom: vwSp(20);
      @include pc-layout(){
          margin-top: vwPc(30);
          padding-left: vwPc(60);
          padding-bottom: vwPc(20);
      }
      @include large-layout(){
          margin-top: 30px;
          padding-left: 60px;
          padding-bottom: 20px;
      }
    &:before{
      content: "";
      display: block;
      width: vwSp(10);
      height:100%;
      background: #e3e2df;
      position: absolute;
      top:0;
      left: vwSp(10);
        @include pc-layout(){
            width: vwPc(5);
            left: vwPc(25);
        }
        @include large-layout(){
            width: 5px;
            left: 25px;
        }
    }
    .milestones{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: vwSp(40);
      background: #fff;
      border: vwSp(2) solid #e3e2df;
      border-radius: vwSp(20);
      padding: vwSp(40) vwSp(40) vwSp(30);
        @include pc-layout(){
            margin-top: vwPc(20);
            border: vwPc(1) solid #e3e2df;
            border-radius: vwPc(10);
            padding: vwPc(20) vwPc(25);
        }
        @include large-layout(){
            margin-top: 20px;
            background: #fff;
            border: 1px solid #e3e2df;
            border-radius: 10px;
            padding: 20px 25px;
        }
        + .milestones{
            @include pc-layout(){
                margin-top: vwPc(30);
            }
            @include large-layout(){
                margin-top: 30px;
            }
        }
      &.pink{
        border: vwSp(2) solid #e29f92;
        color:#e29f92;
          @include pc-layout(){
              border: vwPc(1) solid #e29f92;
          }
          @include large-layout(){
              border: 1px solid #e29f92;
          }
        &:before{
          border-color: #e29f92;
        }
        &:after{
          border-left: vwSp(2) solid #e29f92;
          border-bottom: vwSp(2) solid #e29f92;
            @include pc-layout(){
                border-left: vwPc(1) solid #e29f92;
                border-bottom: vwPc(1) solid #e29f92;
            }
            @include large-layout(){
                border-left: 1px solid #e29f92;
                border-bottom: 1px solid #e29f92;
            }
        }
      }
      &:before{
        content: "";
        width: vwSp(30);
        height: vwSp(30);
        border-radius: vwSp(15);
        border: vwSp(6) solid #c1c0bc;
        background: #fff;
        position: absolute;
        left: vwSp(-62);
        top:vwSp(40);
          @include pc-layout(){
              width: vwPc(15);
              height: vwPc(15);
              border-radius: vwPc(8);
              border: vwPc(3) solid #c1c0bc;
              left: vwPc(-41);
              top:vwPc(27);
          }
          @include large-layout(){
              width: 15px;
              height: 15px;
              border-radius: 8px;
              border: 3px solid #c1c0bc;
              left: -41px;
              top: 27px;
          }
      }
      &:after{
        content: "";
        width: vwSp(15);
        height: vwSp(15);
        border-left: vwSp(2) solid #e3e2df;
        border-bottom: vwSp(2) solid #e3e2df;
        background: #fff;
        position: absolute;
        left: vwSp(-9);
        top:vwSp(47);
        transform: rotate(45deg);
          @include pc-layout(){
              width: vwPc(15);
              height: vwPc(15);
              border-left: vwPc(1) solid #e3e2df;
              border-bottom: vwPc(1) solid #e3e2df;
              left: vwPc(-8);
              top:vwPc(27);
          }
          @include large-layout(){
              width: 15px;
              height: 15px;
              border-left: 1px solid #e3e2df;
              border-bottom: 1px solid #e3e2df;
              left: -8px;
              top:27px;
          }
      }
      .time{
        font-weight: bold;
        font-size: vwSp(34);
        line-height: 1.1;
        padding-right: vwSp(25);
        position: relative;
        &:after{
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: calc(100% + 0.1em);
          top:0;
          right: 0;
          background-image: linear-gradient(black 33%, rgba(255,255,255,0) 0%);
          background-position: right;
          background-size: vwSp(2) vwSp(9);
          background-repeat: repeat-y;
          @include pc-layout(){
            background-size: vwPc(1) vwPc(4);
          }
          @include large-layout(){
            background-size: 1px 4px;
          }
        }
          @include pc-layout(){
              font-size: vwPc(18);
              padding-right: vwPc(25);
              line-height: 1.6;
              width: vwPc(140);
          }
          @include large-layout(){
              font-size: 18px;
              padding-right: 25px;
              width: 140px;
          }
      }
      .detail{
        width: 100%;
        position: relative;
        display: block;
        flex-wrap: wrap;
        font-size: vwSp(34);
        line-height: 2;
        font-weight: bold;
        margin-top: vwSp(20);
          @include pc-layout(){
              font-size: vwPc(18);
              line-height: 1.6;
              margin-top: 0;
              padding-left: vwPc(25);
              width: calc(100% - #{vwPc(140)});
          }
          @include large-layout(){
              font-size: 18px;
              padding-left: 25px;
              width: calc(100% - 140px);
          }
      }
    }
  }
  .list-item{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: vwSp(60);
      @include pc-layout(){
          margin-top: vwPc(50);
      }
      @include large-layout(){
          margin-top: 50px;
      }
    .item{
      width: 100%;
      position: relative;
      display: block;
      flex-wrap: wrap;
      padding: vwSp(60) vwSp(40);
      background: #fff;
      &:hover{
        box-shadow: 0 0 vwSp(20) 0 rgba(0, 0, 0, 0.1);
        @include pc-layout(){
          box-shadow: 0 0 vwPc(10) 0 rgba(0, 0, 0, 0.1);
        }
        @include large-layout(){
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
        @include pc-layout(){
            display: block;
            width: calc((100% - #{vwPc(120)}) / 3);
            margin-right: vwPc(60);
            padding: vwPc(60) vwPc(30) vwPc(130);
        }
        @include large-layout(){
            width: calc((100% - 120px) / 3);
            margin-right: 60px;
            padding: 60px 30px 130px;
        }
        &:nth-child(3n){
            @include pc-layout(){
                margin-right: 0;
            }
            @include large-layout(){
                margin-right: 0;
            }
        }
      + .item{
        margin-top: vwSp(60);
          @include pc-layout(){
              margin-top: 0;
          }
      }
      .image{
        width: 100%;
        position: relative;
        display: block;
        height: vwSp(330);
          @include pc-layout(){
              height: vwPc(230);
          }
          @include large-layout(){
              height: 230px;
          }
        img{
          width: auto;
          margin: auto;
          height: 100%;
        }
      }
      .item-content{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-top: vwSp(50);
        text-align: center;
          @include pc-layout(){
              margin-top: vwPc(50);
          }
          @include large-layout(){
              margin-top: 50px;
          }
        .title{
          font-weight: bold;
          font-size: vwSp(38);
          line-height: 1.5;
          width: 100%;
            @include pc-layout(){
                font-size: vwPc(20);
            }
            @include large-layout(){
                font-size: 20px;
            }
        }
        p{
          font-size: vwSp(30);
          margin-top: vwSp(30);
          text-align: left;
            @include pc-layout(){
                font-size: vwPc(15);
                margin-top: vwPc(20);
            }
            @include large-layout(){
                font-size: 15px;
                margin-top: 20px;
            }
        }
      }
      .button-block{
          @include pc-layout(){
              margin-top: 0;
              position: absolute;
              bottom: vwPc(40);
              left: 0;

          }
          @include large-layout(){
              margin-top: 0;
              bottom: 60px;
          }
      }
    }
  }
  .survey{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: vwSp(20);
      @include pc-layout(){
          padding-bottom: vwPc(10);
      }
      @include large-layout(){
          padding-bottom: 10px;
      }
    .survey-control{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: vwSp(60);
        @include pc-layout(){
            margin-top: vwPc(40);
        }
        @include large-layout(){
            margin-top: 40px;
        }
      select{
        appearance:none;
        width: vwSp(470);
        height: vwSp(90);
        line-height: vwSp(90);
        border: vwSp(2) solid #e3e2df;
        border-radius:  vwSp(10);
        padding: 0 vwSp(60) 0 vwSp(30);
        outline: none;
        font-weight: bold;
        margin-right: vwSp(30);
        font-size: vwSp(32);
        background:#fff url("../img/select-icon.png") vwSp(420) 50% no-repeat;
        background-size: vwSp(23);
        &::-ms-expand {
          display: none;
        }
          @include pc-layout(){
              width: vwPc(300);
              height: vwPc(40);
              line-height: vwPc(40);
              border: vwPc(1) solid #e3e2df;
              border-radius:  vwPc(10);
              padding: 0 vwPc(30) 0 vwPc(15);
              outline: none;
              margin-right: vwPc(20);
              font-size: vwPc(16);
              background:#fff url("../img/select-icon.png") vwPc(277) 50% no-repeat;
              background-size: vwPc(12);
          }
          @include large-layout(){
              width: 300px;
              height: 40px;
              line-height: 40px;
              border: 1px solid #e3e2df;
              border-radius: 10px;
              padding: 0 30px 0 15px;
              outline: none;
              margin-right: 20px;
              font-size: 16px;
              background:#fff url("../img/select-icon.png") 277px 50% no-repeat;
              background-size: 12px;
          }
      }
      .survey-button{
        width: calc(100% - #{vwSp(500)});
        appearance:none;
        text-decoration: none;
        text-align: center;
        height: vwSp(90);
        line-height: vwSp(90);
        border-radius: vwSp(45);
        background-color: #65b393;
        color: #fff;
        border: none;
        outline: none;
        font-size: vwSp(32);
          @include pc-layout(){
              width: vwPc(100);
              height: vwPc(40);
              line-height: vwPc(40);
              border-radius: vwPc(20);
              font-size: vwPc(16);
          }
          @include large-layout(){
              width: 100px;
              height: 40px;
              line-height: 40px;
              border-radius: 20px;
              font-size: 16px;
          }
      }
      .notice{
        width: 100%;
        position: relative;
        font-size: vwSp(32);
        margin-top: vwSp(30);
        line-height: 1.6;
          @include pc-layout(){
              width: auto;
              font-size: vwPc(16);
              margin-top: 0;
              margin-left: vwPc(40);
              line-height: vwPc(40);
          }
          @include large-layout(){
             font-size: 16px;
              margin-left: 40px;
              line-height: 40px;
          }
      }
    }
    h4{
      margin-top: vwSp(80);
        @include pc-layout(){
            margin-top: vwPc(40);
        }
        @include large-layout(){
            margin-top: 40px;
        }
    }
    .list{
        @include pc-layout(){
            margin-top: vwPc(20);
        }
        @include large-layout(){
            margin-top: 20px;
        }
    }
  }
  .voice{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: vwSp(40);
    background-color: #fff;
    margin-top: vwSp(50);
      @include pc-layout(){
          margin-top: vwPc(50);
          padding: vwPc(80);
      }
      @include large-layout(){
          margin-top: 50px;
          padding: 80px;
      }
    .head-box{
      padding-bottom: vwSp(50);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-bottom: vwSp(6) solid #eae9e6;
        @include pc-layout(){
            border-bottom: vwPc(3) solid #eae9e6;
            padding-bottom: vwPc(30);
        }
        @include large-layout(){
            border-bottom: 3px solid #eae9e6;
            padding-bottom: 30px;
        }
      .detail{
        font-weight: bold;
        font-size: vwSp(32);
        line-height: 2;
          @include pc-layout(){
              font-size: vwPc(16);
          }
          @include large-layout(){
              font-size: 16px;
          }
      }
      .head{
        font-weight: bold;
        font-size: vwSp(46);
        line-height: 1.6;
        margin-top: vwSp(30);
        width: 100%;
          @include pc-layout(){
              font-size: vwPc(30);
              margin-top: vwPc(20);
          }
          @include large-layout(){
              font-size: 30px;
              margin-top: 20px;
          }
      }
      .author{
        font-size: vwSp(30);
        color: #666666;
        margin-top: vwSp(40);
        line-height: 1.6;
          @include pc-layout(){
              font-size: vwPc(18);
              margin-top: vwPc(20);
          }
          @include large-layout(){
              font-size: 15px;
              margin-top: 20px;
          }
        span{
          + span{
            margin-left: vwSp(40);
              @include pc-layout(){
                  margin-left: vwPc(15);
              }
              @include large-layout(){
                  margin-left: 15px;
              }
          }
        }
      }
    }
    .content-box{
      margin-top: vwSp(60);
        @include pc-layout(){
            margin-top: vwPc(40);
        }
        @include large-layout(){
            margin-top: 40px;
        }
      p{
        font-size: vwSp(32);
        line-height: 2;
          @include pc-layout(){
              font-size: vwPc(16);
          }
          @include large-layout(){
              font-size: 16px;
          }
          &:first-child{
            margin-top: 0;
          }
      }
    }
  }
  .table-box{
      width: 100%;
      position: relative;
      display: flex;
      margin-top: vwSp(60);
      overflow: auto;
      @include pc-layout(){
          margin-top: vwPc(50);
          padding: 0 vwPc(90);
      }
      @include large-layout(){
          margin-top: 50px;
          padding: 0 90px;
      }
      table{
          width: 100%;
          position: relative;
          border: vwSp(2) solid #dcdcdc;
          @include pc-layout(){
             border: vwPc(1) solid #dcdcdc;
          }
          @include large-layout(){
              border: 1px solid #dcdcdc;
              border-collapse: collapse;
          }
          tr,
          td,
          th{
              line-height: 1.6;
              border: vwSp(2) solid #dcdcdc;
              position: relative;
              @include pc-layout(){
                  border: vwPc(1) solid #dcdcdc;
              }
              @include large-layout(){
                  border: 1px solid #dcdcdc;
                  border-collapse: collapse;
              }
              .list{
                &:first-child{
                  margin-top: 0;
                  li{
                    &:first-child{
                      margin-top: 0;
                    }
                  }
                }
              }
          }
          td,
          th{
              vertical-align: top;
              padding: vwSp(40);
              @include pc-layout(){
                 padding: vwPc(20);
              }
              @include large-layout(){
                  padding: 20px;
              }
              .small{
                  font-size: vwSp(26);
                  @include pc-layout(){
                      font-size: vwPc(13);
                  }
                  @include large-layout(){
                      font-size: 13px;
                  }
              }
          }
          th{
             text-align: left;
              font-weight: bold;
              background-color: #e5e5e5;
              font-size: vwSp(36);
              @include pc-layout(){
                  font-size: vwPc(18);
              }
              @include large-layout(){
                  font-size: 18px;
              }

          }
          td{
              text-align: left;
              background-color: #ffffff;
              font-size: vwSp(32);
              @include pc-layout(){
                  font-size: vwPc(16);
              }
              @include large-layout(){
                  font-size: 16px;
              }
              &.head{
                  font-weight: bold;
                  background-color: #f6f6f6;
                  font-size: vwSp(32);
                  width: vwSp(400);
                  white-space: nowrap;
                  @include pc-layout(){
                      font-size: vwPc(18);
                      width: vwPc(250);
                      white-space: normal;
                  }
                  @include large-layout(){
                      font-size: 18px;
                      width: 250px;
                  }
              }
          }

          .small-table{
              border: none;
              border-collapse: collapse;
              tr,
              td,
              th{
                  border: none;
                  background: none;
                  font-size: vwSp(26);
                  white-space: nowrap;
                  @include pc-layout(){
                      font-size: vwPc(13);
                      white-space: normal;
                  }
                  @include large-layout(){
                      font-size: 13px;
                  }
                &:first-child{
                  padding-left: 0;
                }
              }
              tr{
                  border-bottom: vwSp(2) solid #dcdcdc;
                  @include pc-layout(){
                      border-bottom: vwPc(1) solid #dcdcdc;
                  }
                  @include large-layout(){
                      border-bottom: 1px solid #dcdcdc;
                      border-collapse: collapse;
                  }
              }
          }
          .text-right{
              width: 100%;
              text-align: right;
              display: block;
              margin-top: vwSp(40);
              font-size: vwSp(24);
              line-height: 1.5;
              @include pc-layout(){
                  margin-top: vwPc(20);
                  font-size: vwPc(12);
              }
              @include large-layout(){
                  margin-top: 20px;
                  font-size: 12px;
              }
          }
      }
  }
}
.pagination{
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: vwSp(150);
  @include pc-layout(){
    margin-top: vwPc(100);
  }
  @include large-layout(){
    margin-top: 100px;
  }
  .sumpage{
    width: 100%;
    text-align: center;
    font-size: vwSp(28);
    line-height: 2;
    @include pc-layout(){
      font-size: vwPc(14);
      text-align: left;
    }
    @include large-layout(){
      font-size: 14px;
    }
    .big{
      font-weight: bold;
      font-size: vwSp(30);
      line-height: 2;
      @include pc-layout(){
        font-size: vwPc(22);
      }
      @include large-layout(){
        font-size: 22px;
      }
    }
  }
  .list-page{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(50);
    @include pc-layout(){
      width: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0;
      top:vwPc(6);
    }
    @include large-layout(){
      top:6px;
    }
      .detail-page{
          width: 100%;
          padding: 0 vwSp(120);
          height: vwSp(80);
          line-height: vwSp(80);
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: vwSp(30);
          z-index: 1;
          @include pc-layout(){
              font-size: vwPc(15);
              padding: 0 vwPc(60);
              height: vwPc(40);
              line-height: vwPc(40);
          }
          @include large-layout(){
              font-size: 15px;
              padding: 0 60px;
              height: 40px;
              line-height: 40px;
          }
          span{
              font-weight: bold;
          }
      }
  }
  .prev,
  .next{
    width: vwSp(80);
    height: vwSp(80);
    border-radius: vwSp(40);
    background: #65b393;
    position: absolute;
      z-index: 2;
    &.hide{
      display: none;
    }
    @include pc-layout(){
      width: vwPc(40);
      height: vwPc(40);
      border-radius: vwPc(20);
    }
    @include large-layout(){
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
    &:hover{
      &:after{
        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: vwSp(21);
      height: vwSp(23);
      background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
      background-size: 99%;
      bottom: vwSp(30);
      right: vwSp(30);
      @include pc-layout() {
        width: vwPc(11);
        height: vwPc(12);
        bottom: vwPc(14);
        right: vwPc(14);
      }
      @include large-layout() {
        width: 11px;
        height: 12px;
        bottom: 14px;
        right: 14px;
      }
    }
  }
  .prev{
    top: 0;
    left: 0;
    &:after{
      transform: rotate(180deg);
    }
    &:hover{
      &:after{
        animation: arrowSlideleft 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 backwards;
      }
    }
  }
  .next{
    top:0;
    right: 0;
  }
  ul{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 vwSp(80);
    height: vwSp(80);
    @include pc-layout(){
      width: auto;
      padding: 0 vwPc(80);
      height: vwPc(40);
    }
    @include large-layout(){
      padding: 0 80px;
      height: 40px;
    }
    li{
      width: vwSp(80);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      @include pc-layout(){
        width: vwPc(40);
      }
      @include large-layout(){
        width: 40px;
      }
      span,
      a{
        text-decoration: none;
        font-size: vwSp(30);
        color: #cccccc;
        @include pc-layout(){
          font-size: vwPc(15);
        }
        @include large-layout(){
          font-size: 15px;
        }
        &.active{
          color: #222222;
          font-weight: bold;
        }
        &.dot{
          padding-bottom: vwSp(20);
          @include pc-layout(){
            padding-bottom: vwPc(10);
          }
          @include large-layout(){
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  +.button-block{
    margin-top: vwSp(140);
    @include pc-layout(){
      margin-top: vwPc(80);
    }
    @include large-layout(){
      margin-top: 80px;
    }
  }
}
.news-list{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: vwSp(80);
  padding-bottom: vwSp(30);
  @include pc-layout(){
    margin-top: vwPc(40);
    padding-bottom:0;
  }
  @include large-layout(){
    margin-top: 40px;
  }
  .item{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: vwSp(40);
    padding-bottom: vwSp(40);
    border-bottom: vwSp(2) solid #eae9e6;
    &.arrow{
      &:hover{
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(11);
          height: vwPc(12);
          top: calc((100% - #{vwPc(12)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 11px;
          height: 12px;
          top: calc((100% - 12px)/2);
        }
      }
    }
    &.pdf{
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/PDF.png") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(20);
          height: vwPc(20);
          top: calc((100% - #{vwPc(20)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 20px;
          height: 20px;
          top: calc((100% - 20px)/2);
        }
      }
    }
    &.excel{
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/excel.png") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(20);
          height: vwPc(20);
          top: calc((100% - #{vwPc(20)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 20px;
          height: 20px;
          top: calc((100% - 20px)/2);
        }
      }
    }
    &.pp{
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/pp.png") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(20);
          height: vwPc(20);
          top: calc((100% - #{vwPc(20)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 20px;
          height: 20px;
          top: calc((100% - 20px)/2);
        }
      }
    }
    &.word{
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/word.png") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(20);
          height: vwPc(20);
          top: calc((100% - #{vwPc(20)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 20px;
          height: 20px;
          top: calc((100% - 20px)/2);
        }
      }
    }
    &.zip{
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/zip.png") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(20);
          height: vwPc(20);
          top: calc((100% - #{vwPc(20)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 20px;
          height: 20px;
          top: calc((100% - 20px)/2);
        }
      }
    }
    &.window{
      //&:hover{
      //    &:after{
      //        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
      //    }
      //}
      &:after{
        @include pc-layout(){
          content: "";
          display: block;
          position: absolute;
          background: url("../img/ico_blank.png") 50% 50% no-repeat;
          background-size: 99%;
          width: vwPc(14);
          height: vwPc(14);
          top: calc((100% - #{vwPc(14)})/2);
          right: 0;
        }
        @include large-layout(){
          width: 14px;
          height: 14px;
          top: calc((100% - 14px)/2);
        }
      }
    }
    @include pc-layout(){
      padding-top: vwPc(30);
      padding-bottom: vwPc(30);
      border-bottom: vwPc(1) solid #eae9e6;
    }
    @include large-layout(){
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #eae9e6;
    }
    &:first-child{
      margin-top: 0;
    }
    a{
      text-decoration: none;
      position: relative;
      &:hover{
        &.item-title{
          border-bottom: 1px solid #222;
        }
      }
    }
    .item-detail{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .date{
        font-family: 'Inter', sans-serif;
        font-weight: normal;
        color: #a6a6a6;
        font-size: vwSp(28);
        @include pc-layout(){
          font-size: vwPc(14);
        }
        @include large-layout(){
          font-size: 14px;
        }
      }
      .tag{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        line-height: 1;
        margin-left: vwSp(20);
        height: vwSp(40);
        padding: 0 vwSp(20) vwSp(2);
        border-radius: vwSp(20);
        background: #e3e3e3;
        color: #888888;
        font-size: vwSp(22);
        @include pc-layout(){
          margin-left: vwPc(10);
          height: vwPc(20);
          padding: 0 vwPc(10) 0;
          border-radius: vwPc(10);
          font-size: vwPc(11);
        }
        @include large-layout(){
          margin-left: 10px;
          height: 20px;
          padding: 0 10px 0;
          border-radius: 10px;
          font-size: 11px;
        }
      }
      a{
        &.tag{
          text-decoration: none;
          &:hover{
            background: #65b393;
            color: #fff;
          }
          &.pink{
            &:hover{
              background: #E29F92;
            }
          }
        }
      }
    }
    .item-title{
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      margin-top: vwSp(28);
      font-size: vwSp(30);
      line-height: 1.6;
      border-bottom: 1px solid transparent;
      @include pc-layout(){
        margin-top: vwPc(15);
        font-size: vwPc(15);
      }
      @include large-layout(){
        margin-top: 15px;
        font-size: 15px;
      }
    }
  }
}
.lightboxOverlay{
  background: #f9f9f5;
  opacity: 1;
}
.lightbox{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0!important;
  overflow: auto;
  padding-bottom: 50px;
  .lb-outerContainer{
    height: auto!important;
    width: vwSp(472)!important;
    @include pc-layout(){
      width: vwPc(410)!important;

    }
    @include large-layout(){
      width: 410px!important;

    }
  }
  .lb-closeContainer{
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    .lb-close{
      width: vwSp(100);
      height: vwSp(100);
      background: #65b393 url("../img/close-icon.png") 50% 50% no-repeat;
      background-size: vwSp(40);
      border-radius: vwSp(50);
      position: absolute;
      top:vwSp(40);
      right: vwSp(40);
      opacity: 1;
      @include pc-layout(){
        width: vwPc(50);
        height: vwPc(50);
        background-size: vwPc(20);
        border-radius: vwPc(25);
        top:vwPc(30);
        right: vwPc(30);
      }
      @include large-layout(){
        width: 50px;
        height: 50px;
        background-size: 20px;
        border-radius:25px;
        top:30px;
        right: 30px;
      }
    }
  }
}
.lb-container{
  margin-top: 20vh;
  position: relative;
  @include pc-layout(){
    margin-top: vwPc(160);
  }
  @include large-layout(){
    margin-top: 160px;
  }
  .lb-image{
    width: vwSp(475)!important;
    height: auto!important;
    @include pc-layout(){
      width: vwPc(410)!important;
    }
    @include large-layout(){
      width: 410px!important;
    }
  }
  .lb-nav{
    position: absolute;
    bottom: vwSp(-70);
    display: flex;
    margin: vwSp(50) auto 0;
    width: vwSp(350);
    height: 0;
    left: vwSp(62);
    top: inherit;
    @include pc-layout(){
      width: vwPc(700);
      top: calc(50% - #{vwPc(20)});
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }
    @include large-layout(){
      width: 700px;
      top: calc(50% - 20px);
    }
    .lb-prev,
    .lb-next{
      width: vwSp(80);
      height: vwSp(80);
      border-radius: vwSp(40);
      background: #65b393;
      position: absolute;
      opacity: 1;
      &.hide{
        display: none;
      }
      @include pc-layout(){
        width: vwPc(40);
        height: vwPc(40);
        border-radius: vwPc(20);
      }
      @include large-layout(){
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
      &:hover{
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
        background-size: 99%;
        bottom: vwSp(28);
        right: vwSp(30);
        @include pc-layout() {
          width: vwPc(11);
          height: vwPc(12);
          bottom: vwPc(14);
          right: vwPc(14);
        }
        @include large-layout() {
          width: 11px;
          height: 12px;
          bottom: 14px;
          right: 14px;
        }
      }
      &:before{
        display: none;
      }
    }
    .lb-prev{
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      &:after{
        transform: rotate(180deg);
      }
      &:hover{
        &:after{
          animation: arrowSlideleft 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 backwards;
        }
      }
    }
    .lb-next{
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}
.lb-dataContainer{
  .lb-data{
    .lb-details{
      width: 100%;
      margin-top: vwSp(40);
      @include pc-layout(){
        margin-top: vwPc(30);
      }
      @include large-layout(){
        margin-top: 30px;
      }
    }
    .lb-number{
      text-align: center;
      color: #222222;
      font-size: vwSp(30);
      @include pc-layout(){
        font-size: vwPc(15);
      }
      @include large-layout(){
        font-size: 15px;
      }
    }
  }
}
.collapse{
  .collapse-box{
    max-height: 0;
    transition: all 0.5s ease;
    overflow: hidden;
  }
  label{
    position: relative;
    h5{
      position: relative;
      &:after{
        content: "";
        display: inline-block;
        margin-left: vwSp(20);
        height: vwSp(30);
        width: vwSp(30);
        background: url(../img/close.png) 0 50% no-repeat;
        background-size: vwSp(30);
        position: absolute;
        top: calc((100% - #{vwSp(30)})/2);
        @include pc-layout(){
          height: vwPc(15);
          width: vwPc(15);
          margin-left: vwPc(10);
          background-size: vwPc(15);
          vertical-align: middle;
          top: calc((100% - #{vwPc(15)})/2);
        }
        @include large-layout(){
          height: 15px;
          width: 15px;
          margin-left: 10px;
          background-size: 15px;
          top: calc((100% - 15px)/2);
        }
      }
    }
    span{
      width: auto;
      min-height: vwSp(110);
      border-radius: vwSp(110);
      background-color: #65b393;
      color: #fff;
      position: relative;
      display: inline-block;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
      font-weight: normal;
      font-size: vwSp(30);
      text-decoration: none;
      padding: vwSp(30) vwSp(80) vwSp(30) vwSp(40);
      line-height: 1.5;
      margin-top: vwSp(30);
      @include pc-layout(){
        width: auto;
        min-height: vwPc(50);
        border-radius: vwPc(60);
        font-size: vwPc(15);
        text-decoration: none;
        padding: vwPc(15) vwPc(50) vwPc(15) vwPc(30);
        margin-bottom: vwPc(20);
        margin-right: vwPc(20);
        margin-top: vwSp(50);
      }
      @include large-layout(){
        min-height: 50px;
        border-radius: 60px;
        font-size: 15px;
        text-decoration: none;
        padding: 15px 50px 15px 30px;
        margin-bottom: 20px;
        margin-right: 20px;
        margin-top: 50px;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
        background-size: 99%;
        top: calc(50% - #{vwSp(12)});
        right: vwSp(40);
        transform: rotate(90deg);
        @include pc-layout(){
          width: vwPc(11);
          height: vwPc(12);
          top: calc(50% - #{vwPc(6)});
          right: vwPc(20);
        }
        @include large-layout(){
          width: 11px;
          height: 12px;
          top: calc(50% - 6px);
          right: 20px;
        }
      }
    }
  }
  input:checked ~ label{
    h5{
      &:after{
        background: url(../img/open.png) 0 50% no-repeat;
        background-size: vwSp(30);
        @include pc-layout(){
          background-size: vwPc(15);
        }
        @include large-layout(){
          background-size: 15px;
        }
      }
    }
    span{
      &:after{
        transform: rotate(-90deg);
      }
    }
  }
  input:checked ~ .collapse-box{
    max-height: 1500px;
  }
}
