@function image-url($url) {
    @return url("#{$img_dir}#{$url}");
}

@function strip-unit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

@function vwPc($pixel) {
    $rate: 100/1440;
    @return $rate * strip-unit($pixel) * 1vw;
}
@function vwSp($pixel) {
    $rate: 100/750;
    @return $rate * strip-unit($pixel) * 1vw;
}
