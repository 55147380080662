#sitemap{
  h1{
    padding: vwSp(190) vwSp(80);
    @include pc-layout(){
      padding: vwPc(220) vwPc(55) 0;
    }
    @include large-layout(){
      padding: 220px 55px 0;
    }
  }
  .breadcrumb{
    +.row{
      margin-top: vwSp(50);
      @include pc-layout(){
       margin-top: vwPc(50);
      }
      @include large-layout(){
        margin-top: 50px;
      }
      .col{
        a{
          font-size: vwSp(32);
          font-weight: bold;
          @include pc-layout(){
            font-size: vwPc(16);
          }
          @include large-layout(){
            font-size: 16px;
          }
        }
      }
    }
  }
  .row{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .col{
      width: calc((100% - #{vwSp(40)}) / 2);
      margin-right: vwSp(40);
      @include pc-layout(){
        width: calc((100% - #{vwPc(100)}) / 3);
        margin-right: vwPc(50);
      }
      @include large-layout(){
        width: calc((100% - 100px) / 3);
        margin-right: 50px;
      }
      a{
        text-decoration: none;
        font-size: vwSp(26);
        color: #65b393;
        line-height: 1.5;
        display: flex;
        width: 100%;
        margin-top: vwSp(30);
        transition: all 0.3s ease;
        @include pc-layout(){
          margin-top: vwPc(20);
          font-size: vwPc(13);
        }
        @include large-layout(){
          margin-top: 20px;
          font-size: 13px;
        }
        &:hover{
          opacity: 0.6;
          transition: all 0.3s ease;
        }
        &.parent{
          margin-top: vwSp(60);
          font-weight: bold;
          font-size: vwSp(30);
          padding-bottom: vwSp(20);
          border-bottom: vwSp(2) solid #eae9e6;
          @include pc-layout(){
            margin-top: vwPc(30);
            border-bottom: vwPc(1) solid #eae9e6;
            padding-bottom: vwPc(15);
            font-size: vwPc(15);
          }
          @include large-layout(){
            margin-top: 30px;
            border-bottom: 1px solid #eae9e6;
            padding-bottom: 15px;
            font-size: 15px;
          }
        }
      }
      &:nth-child(even){
        margin-right: 0;
        @include pc-layout(){
          margin-right: vwPc(50);
        }
        @include large-layout(){
          margin-right: 50px;
        }
      }
      &:nth-child(3n){
        @include pc-layout(){
          margin-right: 0!important;
        }
      }
    }
    +.row{
      margin-top: vwSp(40);
      @include pc-layout(){
        margin-top: vwPc(70);
      }
      @include large-layout(){
        margin-top: 70px;
      }
    }
  }
  h3.line{
    a{
      text-decoration: none;
      padding-left: vwSp(40);
      transition: all 0.3s ease;
      @include pc-layout(){
        padding-left: vwPc(20);
      }
      @include large-layout(){
        padding-left: 20px;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
        background-size: cover;
        top: vwSp(25);
        left: 0;
        @include pc-layout(){
          width: vwPc(11);
          height: vwPc(12);
          top: vwPc(20);
        }
        @include large-layout(){
          width: 11px;
          height: 12px;
          bottom: 20px;
          top: 20px;
        }
      }
      &:hover{
        opacity: 0.6;
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
    }
  }
}
#admt{
  h5{
    border-bottom: vwSp(2) solid #222;
    display: inline-block;
    @include pc-layout(){
      border-bottom: vwPc(1) solid #222;
    }
    @include large-layout(){

      border-bottom: 1px solid #222;
    }
    + h3{
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(10);
      }
      @include large-layout(){
        margin-top: 10px;
      }
    }
  }
  .box-image{
    align-self: center;
    .center{
      overflow: hidden;
      position: relative;
      margin: vwSp(60) auto 0;
      @include pc-layout(){
        margin: vwPc(50) auto 0;
      }
      @include large-layout(){
        margin: 50px auto 0;
      }
      img{
        margin-top: 0;
      }
      .sidebg{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 100%;
        top:0;
        background: #E29F92;
      }
    }
    .logo{
      max-width: vwSp(413);
      align-self: center;
      @include pc-layout(){
        max-width: vwPc(280);
      }
      @include large-layout(){
        max-width: 280px;
      }
    }
    .col{
      overflow: hidden;
      position: relative;
      margin-top: vwSp(60);
      @include pc-layout(){
        margin-top: vwPc(50);
      }
      @include large-layout(){
        margin-top: 50px;
      }
      img{
        margin-top: 0;
      }
      .sidebg{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 100%;
        top:0;
        background:#E29F92;
      }
    }
    + h5{
      margin-top: vwSp(90);
      @include pc-layout(){
        margin-top: vwPc(100);
      }
      @include large-layout(){
        margin-top: 100px;
      }
    }
  }
}