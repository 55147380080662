#aid-awards{
  .note{
    margin-top: vwSp(80);
    @include pc-layout(){
      margin-top: vwPc(45);
    }
    @include large-layout(){
      margin-top: 45px;
    }
    + h3.line{
      margin-top: vwSp(60);
      @include pc-layout(){
        margin-top: vwPc(40);

      }
      @include large-layout(){
        margin-top: 40px;
      }
    }
  }
  h3{
    @include pc-layout(){
      margin-top: vwPc(80);
    }
    @include large-layout(){
      margin-top: 80px;
    }
  }
  .main{
    img{
      @include pc-layout(){
        margin-top: vwPc(40);
      }
      @include large-layout(){
        margin-top: 40px;
      }
      + .achievement{
        margin-top: vwSp(80);
        @include pc-layout(){
          margin-top: vwPc(52);
        }
        @include large-layout(){
          margin-top: 52px;
        }
      }
      &.full{
        width: 70%;
      }
    }
  }
  .winner{
    .affiliation{
      color: #a6a6a6;
    }
    +.pdf_link{
        margin-top: vwSp(75);
        @include pc-layout(){
          margin-top: vwPc(35);
        }
        @include large-layout(){
          margin-top: 35px;
        }
    }
  }
}
#aid-awards_publication{
  .box-image{
    margin-top: vwSp(80);
    @include pc-layout(){
      margin-top:0;
    }
    @include large-layout(){
      margin-top: 0;
    }
    img{
      @include pc-layout(){
        width: vwPc(180);
        height: vwPc(255);
        object-fit: cover;
      }
      @include large-layout(){
        width: 180px;
        height: 255px;
      }
    }
  }
  .achievement{
    padding-bottom: 0;
  }
}
#aid-awards_entity{
  .achievement{
    padding-bottom: 0;
    margin-top: vwSp(60);
    @include pc-layout(){
      margin-top: vwPc(40);
    }
    @include large-layout(){
      margin-top: 40px;
    }
    .archivement_link{
      margin-top: 0;
    }
  }
}
#sp2014{
  .main-info{
    align-items: flex-start;
  }
}
#aid-data-detail{
  .top-note{
    margin-top: 0;
    position: absolute;
    top: vwSp(20);
    right: vwSp(40);
    font-size: vwSp(24);
    @include pc-layout(){
      top: vwPc(10);
      right: vwPc(20);
      font-size: vwPc(12);
    }
    @include large-layout(){
      top: 10px;
      right: 20px;
      font-size: 12px;
    }
  }
}