#news{
  .news-list{
    margin-top: vwSp(20);
    @include pc-layout(){
      margin-top: vwPc(10);

    }
    @include large-layout(){
      margin-top: 10px;
    }
    .item{
      &.arrow{
        padding-right: vwSp(140);
        @include pc-layout(){
          padding-right: 0;
        }
        @include large-layout(){
          padding-right: 0;
        }
        &:hover{
          &:after{
            animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
          }
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: cover;
          width: vwSp(22);
          height: vwSp(23);
          top: calc((100% - #{vwSp(23)})/2);
          right: 0;
          @include pc-layout(){
            width: vwPc(11);
            height: vwPc(12);
            top: calc((100% - #{vwPc(12)})/2);
            right: 0;
          }
          @include large-layout(){
            width: 11px;
            height: 12px;
            top: calc((100% - 12px)/2);
          }
        }
      }
      &.pdf{
        padding-right: vwSp(140);
        @include pc-layout(){
          padding-right: 0;
        }
        @include large-layout(){
          padding-right: 0;
        }
        &:after{
          @include pc-layout(){
            content: "";
            display: block;
            position: absolute;
            background: url("../img/PDF.png") 50% 50% no-repeat;
            background-size: 99%;
            width: vwPc(20);
            height: vwPc(20);
            top: calc((100% - #{vwPc(20)})/2);
            right: 0;
          }
          @include large-layout(){
            width: 20px;
            height: 20px;
            top: calc((100% - 20px)/2);
          }
        }
      }
      &.window{
        padding-right: vwSp(140);
        @include pc-layout(){
          padding-right: 0;
        }
        @include large-layout(){
          padding-right: 0;
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          background: url("../img/ico_blank.png") 50% 50% no-repeat;
          background-size: contain;
          width: vwSp(29);
          height: vwSp(29);
          top: calc((100% - #{vwSp(28)})/2);
          right: 0;
          @include pc-layout(){
            width: vwPc(15);
            height: vwPc(15);
            top: calc((100% - #{vwPc(14)})/2);
            right: 0;
          }
          @include large-layout(){
            width: 15px;
            height: 15px;
            top: calc((100% - 14px)/2);
          }
        }
      }
    }
  }
}