//reset
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

a {
    color: inherit;

    &:visited {
        color: inherit;
    }
}

// root setting

:root {
    font: 16px / calc(1em + 8px) sans-serif;
}

// global setting

body {
    display: block;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    color: $black;
    font-family: "Noto Sans JP", sans-serif;
    background: #fafaf7;
    @include pc-layout {
        background: #fff;
    }
}

.show-sp {
    @include pc-layout {
        display: none !important;
    }
}

.show-pc {
    @include sp-layout {
        display: none !important;
    }
}

.serif {
    font-family: "Noto Sans JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
        "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
