#outline-history{
  .top-box{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 vwSp(40);
    background: #ecece7;
    margin: 0;
    z-index: 9;
    @include pc-layout(){
      width: 100%;
      padding: 0;
      margin-top: vwPc(-5);
    }
    @include large-layout(){
      margin-top: -5px;
    }
    .breadcrumb{
      width: 100%;
      @include pc-layout(){
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 vwPc(135);
      }
      @include large-layout(){
        padding:0 135px;
      }
    }
    h1{
      display: flex;
      align-items: center;
      margin-top: 0;
      height: vwSp(355);
      width: 100%;
      padding-bottom: vwSp(40);
      @include pc-layout(){
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        height: vwPc(210);
        padding: 0 vwPc(135) vwPc(30);
      }
      @include large-layout(){
        height: 210px;
        padding:0 135px 30px;
      }
    }
  }
  .timeline{
    padding-left: 0;
    padding-bottom: 0;
    margin-top: vwSp(130);
    @include pc-layout(){
      margin-top: vwPc(90);
    }
    @include large-layout(){
      margin-top: 90px;
    }
    &:before{
      left: vwSp(177);
      display: none;
      @include pc-layout(){
        left: vwPc(195);
      }
      @include large-layout(){
        left: 195px;
      }
    }
    .line{
      display: block;
      width: vwSp(10);
      height:0;
      background: #e3e2df;
      position: absolute;
      top:0;
      left: vwSp(177);
      transition: height 0.3s ease;
      z-index: 1;
      max-height: 100%;
      @include pc-layout(){
        width: vwPc(5);
        left: vwPc(195);
      }
      @include large-layout(){
        width: 5px;
        left: 195px;
      }
    }
    .milestones{
      background: none;
      border: none;
      margin-top: vwSp(145);
      padding: 0;
      &.current{
        &:before{
          background: #65b393;
          border-color: #65b393;
        }
      }
      @include pc-layout(){
        margin-top: vwPc(90);
        padding-right: vwPc(100);
      }
      @include large-layout(){
        margin-top: 90px;
        padding-right: 100px;
      }
      &:first-child{
        margin-top: 0;

      }
      &:before{
        left: vwSp(166);
        top: 0;
        z-index: 2;
        @include pc-layout(){
          left: vwPc(190);
        }
        @include large-layout(){
          left: 190px;
        }
      }
      &:after{
        display: none;
      }
      .time{
        width: vwSp(177);
        font-size: vwSp(54);
        margin-top: vwSp(-19);
        @include pc-layout(){
          width: vwPc(190);
          font-size: vwPc(40);
          margin-top: vwPc(-27);
          padding-left: vwPc(60);
        }
        @include large-layout(){
          width: 190px;
          font-size: 40px;
          margin-top: -27px;
          padding-left: 60px;
        }
        &:after{
          display: none;
        }
      }
      .detail{
        width: calc(100% - #{vwSp(177)});
        margin-top: 0;
        padding-left: vwSp(60);
        @include pc-layout(){
          width: calc(100% - #{vwPc(190)});
          margin-top: 0;
          padding-left: vwPc(50);
        }
        @include large-layout(){
          width: calc(100% - 190px);
          margin-top: 0;
          padding-left:50px;
        }
        :first-child{
          margin-top: vwSp(-20);
          @include pc-layout(){
            margin-top: vwPc(-10);
          }
          @include large-layout(){

            margin-top: -10px;
          }
        }
        p{
          font-size: vwSp(32);
          line-height: 2;
          @include pc-layout(){
            width: 100%;
            font-size: vwPc(16);
          }
          @include large-layout(){
            font-size: 16px;
          }
        }
        img{
          max-width: 100%;
          margin-top: vwSp(40);
          @include pc-layout(){
            margin-top: vwPc(40);
          }
          @include large-layout(){
            margin-top: 40px;
          }
          + p{
            margin-top: vwSp(40);
            @include pc-layout(){
              margin-top: vwPc(40);
            }
            @include large-layout(){
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
  .button-block{
    margin-top: 0;
    background: #ecece7;
    padding: vwSp(100) vwSp(40) vwSp(140);
    @include pc-layout(){
      padding: vwPc(60) 0 vwPc(100);
    }
    @include large-layout(){
      padding: 60px 0 100px;
    }
  }
  .content{
    max-width: 100%;
    @include pc-layout(){
      max-width: 100%;
      padding: 0 0 vwPc(100);
    }
    @include large-layout(){
      padding: 0 0 100px;
    }
    .wrap-box{
      .left-box{
        &.absolute{
          position: absolute;
          bottom: 0;
          top:auto;
        }
        display: none;
        @include pc-layout(){
          display: block;
          position: fixed;
          left: 0;
          width: vwPc(268px);
          top: 0;
          height: 100vh;
          background: #ecece7;
          padding-top: vwPc(390);
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        @include large-layout(){
          width: 268px;
          padding-top: 390px;
        }
        .timeline{
          padding-left: 0;
          padding-bottom: 0;
          margin-top: 0;
          a{
            text-decoration: none;
          }
          &:before{
            left: vwPc(203);
            width: 1px;
            background-image: linear-gradient(black 20%, rgba(255,255,255,0) 0%);
            background-position: right;
            background-size: vwPc(1) vwPc(5);
            background-repeat: repeat-y;
            display: block;
            @include large-layout(){
              left: 203px;
              background-size: 1px 5px;
            }
          }
          .milestones{
            background: none;
            border: none;
            margin-top: vwPc(25);
            padding: 0;
            @include large-layout(){
              margin-top: 25px;
            }
            &:first-child{
              margin-top: 0;
            }
            &:before{
              left: vwPc(199);
              width: vwPc(9);
              height: vwPc(9);
              border-radius: vwPc(5);
              border-width: vwPc(2);
              z-index: 2;
              @include large-layout(){
                left: 199px;
                width: 9px;
                height: 9px;
                border-radius: 5px;
                border-width: 2px;
              }
            }
            &:after{
              display: none;
            }
            .time{
              width: vwPc(204);
              font-size: vwPc(16);
              padding-left: vwPc(135);
              line-height: 1;
              margin-top: vwPc(-5);
              &.active{
                color: #65b393;
              }
              @include large-layout(){
                padding-left: 135px;
                width: 204px;
                font-size: 16px;
                margin-top: -5px;
              }
              &:after{
                display: block;
                width: vwPc(20);
                height: vwPc(1);
                background-image: linear-gradient(to left, black 20%, rgba(255,255,255,0) 0%);
                background-position: top;
                background-size: vwPc(5) vwPc(1);
                background-repeat: repeat-X;
                top:50%;
                @include large-layout(){
                  width: 20px;
                  height: 1px;
                  background-size: 5px 1px;
                }
              }
            }
          }
        }
      }
      .right-box{
        @include pc-layout(){
          width: calc(100% - #{vwPc(268)});
          margin-left: vwPc(268);
        }
        @include large-layout(){
          width: calc(100% - 268px);
          margin-left: 268px;
        }
      }
    }
  }
}
#outline-biography{
  background: #fafaf7;
  @include pc-layout(){
    background: #fffffd;
  }
  .main{
    @include pc-layout(){
      padding-top: 0;
    }
    @include large-layout(){
      padding-top: 0;
    }
    &.en{
        .first-view{
            .wrap-box{
                .content-box{
                    @include pc-layout(){
                        padding: vwPc(98) vwPc(100) 0;
                        align-items: flex-start;
                        justify-content: flex-start;
                        height: 100%;
                    }
                    @include large-layout(){
                        padding: 98px 100px 0;
                    }
                }
            }
        }
    }
  }
  section{
    padding-bottom: 0;
    &:nth-child(2){
      &:before{
        @include pc-layout(){
          height: 0;
          top:50%;
        }
      }
    }
    &:nth-last-child(2){
      &:before{
        @include pc-layout(){
          height: 50%!important;
          top: 0;
        }
      }
    }
    &.fp-auto-height{
      &:before{
        display: none;
      }
    }
    &:before{
      @include pc-layout(){
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 0;
        left: calc(25% - 1px);
        background: #eae9e6;
        z-index: 1;
        transition: height 1.5s ease;
      }
    }
    &.done{
      &:before{
        @include pc-layout(){
          height: 100%;
        }
      }
    }
    &.footeractive{
      .wrap-box{
        .image{
          z-index: 999;
          opacity: 1;
        }
      }
    }
    &.active{
      &:before{
        @include pc-layout(){
          height: 100%;
        }
      }
      &:nth-child(2){
        &:before{
          @include pc-layout(){
            height: 50%;
          }
        }
      }
      &:last-child{
        &:before{
          @include pc-layout(){
            height: 50%;
          }
        }
      }
      .wrap-box{
        .image{
          z-index: 999;
          opacity: 1;
          transition: all 0.5s ease;
        }
      }
    }
  }
  .first-view{
    background: #bdb5a3;
    &:before{
      display: none;
    }
    .fp-tableCell{
      vertical-align: top;
      position: relative;
    }
    .breadcrumb{
      @include pc-layout(){
        margin-left: vwPc(-50);
      }
      @include large-layout(){
        margin-left: -50px;
      }
      ul{
        li{
          span{
            color: rgba(255,255,255,0.6);
          }
          a{
            color: #FFF;
            &:hover{
              opacity: 0.6;
            }
          }
          &:after{
            color: rgba(255,255,255,0.6);
          }
        }
      }
    }
    .wrap-box{
      background: #bdb5a3;
      @include pc-layout(){
        align-items: flex-start;
        height: 100%;
      }
      @include large-layout(){
      }
      .center-box{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .content-box{
        background: #bdb5a3;
        transition: all 0.3s ease;
        @include pc-layout(){
          padding: vwPc(126) vwPc(100) 0;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
        }
        @include large-layout(){
          padding: 126px 100px 0;
        }
      }

      .head {
        position: relative;
        display: inline-flex;
        margin: vwSp(100) auto 0;
        border: vwSp(2) solid #fff;
        font-size: vwSp(38);
        line-height: 1;
        padding: vwSp(25) vwSp(90);
        color: #fff;
        @include pc-layout(){
          margin: 15vh auto 0;
          border: vwPc(1) solid #fff;
          font-size: vwPc(20);
          line-height: 1;
          padding: vwPc(12) vwPc(45);
          color: #fff;
        }
        @include large-layout(){
          margin: 15vh auto 0;
          border: 1px solid #fff;
          font-size: 20px;
          line-height: 1;
          padding: 12px 45px;
          color: #fff;
        }
      }
      .title {
        width: 100%;
        text-align: center;
        font-size: vwSp(56);
        font-weight: bold;
        color: #fff;
        line-height: vwSp(56);
        @include pc-layout(){
          font-size: vwPc(41);
          line-height: vwPc(41);
        }
        @include large-layout(){
          font-size: 41px;
          line-height: 41px;
        }
      }
      .subtitle {
        width: 100%;
        text-align: center;
        font-size: vwSp(32);
        line-height: vwSp(32);
        color: #fff;
        @include pc-layout(){
          font-size: vwPc(16);
          line-height: vwPc(16);
        }
        @include large-layout(){
          font-size: 16px;
          line-height: 16px;
        }
      }
      .line {
        display: block;
        width: vwSp(160);
        height: vwSp(6);
        background: #fff;
        margin-top: vwSp(50);
        margin-left: auto;
        margin-right: auto;
        @include pc-layout(){
          width: vwPc(80);
          height: vwPc(3);
          margin-top: vwPc(40);
        }
        @include large-layout(){
          width: 80px;
          height: 3px;
          margin-top: 40px;
        }
      }
      p{
        color: #fff;

      }
      .next{
        padding-bottom: 0!important;
        margin-left: auto;
        margin-right: auto;
        width: vwSp(80);
        height: vwSp(80);
        border-radius: vwSp(40);
        margin-top: vwSp(40);
        border:vwSp(2) solid #fff;
        position: relative;
        @include pc-layout(){
          width: vwPc(40);
          height: vwPc(40);
          border-radius: vwPc(20);
          margin-top: 0;
          position: absolute;
          bottom: vwPc(30);
          left: calc(25% - #{vwPc(20)});
          z-index: 10;
          border:vwPc(1) solid #fff;
        }
        @include large-layout(){
          width: 40px;
          height: 40px;
          bottom: 30px;
          left: calc(25% - 20px);
          border:1px solid #fff;
        }
        &:after {
          position: absolute;
          top: calc((100% - #{vwSp(25)})/2);
          left: calc((100% - #{vwSp(25)})/2);
          display: block;
          width: vwSp(25px);
          height: vwSp(25px);
          background: url("/img/i-arrow-right-white.svg") center/contain no-repeat;
          transform: rotate(90deg);
          content: "";
          @include pc-layout(){
            top: calc(50% - #{vwPc(6)});
            left: calc(50% - #{vwPc(6)});
            width: vwPc(12px);
            height: vwPc(12px);
          }
          @include large-layout(){
            top: calc(50% - 6px);
            left: calc(50% - 6px);
            width: 12px;
            height: 12px;
          }
        }
        &:hover{
          &:after{
            animation: arrowSlideDown .5s cubic-bezier(.215, .61, .355, 1) 0s
            1 forwards;
          }
        }
      }
      .image{
        img{
          @include sp-layout(){
            width: 100%;
            margin-top: vwSp(40);
          }
          @include pc-layout(){
            object-position: center top;
          }
        }
      }
    }
  }
  .timeline{
    padding-left: 0;
    padding-bottom: 0;
    margin-top: vwSp(60);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
    &:first-child{
      margin-top: vwSp(140) ;
      @include pc-layout(){
        margin-top: 0 ;
      }
    }
    &:before{
      left: vwSp(218);
      display: none;
      @include pc-layout(){
        left: vwPc(195);
      }
      @include large-layout(){
        left: 195px;
      }
    }
    .milestones{
      background: none;
      border: none;
      margin-top: 0;
      padding-top: vwSp(90);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      @include pc-layout(){
        padding-top: vwPc(50);
      }
      @include large-layout(){
        padding-top: 50px;
      }
      &:first-child{
        padding-top: 0;
        &:before{
            top: 0;
        }
      }
      &:last-child{
        &:after{
          height: vwSp(100);
          @include pc-layout(){
            height: vwPc(50);
          }
          @include large-layout(){
            height: 50px;
          }
        }
      }
      &:after{
        content: "";
        display: block;
        width: vwSp(10);
        height:100%;
        background: #e3e2df;
        position: absolute;
        top:0;
        left: vwSp(216);
        transform: none;
        z-index: 1;
        @include pc-layout(){
          width: vwPc(5);
          left: vwPc(100);
        }
        @include large-layout(){
          width: 5px;
          left: 100px;
        }
      }
      &:before{
        left: vwSp(206);
        top: vwSp(90);
        z-index: 2;
        @include pc-layout(){
          left: vwPc(95);
          top:vwPc(50);
        }
        @include large-layout(){
          left: 95px;
          top:50px;
        }
      }
      .time{
        width: vwSp(218);
        font-size: vwSp(32);
        margin-top: vwSp(-6);
        @include pc-layout(){
          width: vwPc(100);
          font-size: vwPc(16);
          margin-top: vwPc(-5);
          padding-left: 0;
          padding-right: 0;
        }
        @include large-layout(){
          width: 100px;
          font-size: 16px;
          margin-top: -5px;
          padding-left: 0;
        }
        &:after{
          display: none;
        }
      }
      .detail{
        width: calc(100% - #{vwSp(218)});
        margin-top: 0;
        padding-left: vwSp(50);
        @include pc-layout(){
          width: calc(100% - #{vwPc(100)});
          margin-top: 0;
          padding-left: vwPc(40);
        }
        @include large-layout(){
          width: calc(100% - 100px);
          margin-top: 0;
          padding-left:40px;
        }
        :first-child{
          margin-top: vwSp(-18);
          @include pc-layout(){
            margin-top: vwPc(-8);
          }
          @include large-layout(){

            margin-top: -8px;
          }
        }
        p{
          font-size: vwSp(30);
          line-height: 2;
          @include pc-layout(){
            width: 100%;
            font-size: vwPc(15);
          }
          @include large-layout(){
            font-size: 15px;
          }
        }
        .button-block{
          @include pc-layout(){
            margin-top: vwPc(30);
          }
          @include large-layout(){
            margin-top: 30px;
          }
          a{
            font-size: vwSp(24);
            padding: vwSp(20) vwSp(70) vwSp(20) vwSp(30);
            @include pc-layout(){
              width: auto;
              margin-left: 0;
              font-size: vwPc(14);
              padding: vwPc(15) vwPc(50);
            }
            @include large-layout(){
              font-size: 14px;
              padding: 15px 50px;
            }
            &:after{
              right: vwSp(30);
              @include pc-layout(){
                right: vwPc(20);
              }
              @include large-layout(){
                right: 20px;
              }
            }
          }
        }
        img{
          max-width: 100%;
          @include pc-layout(){
            margin-top: vwPc(30);
          }
          @include large-layout(){
            margin-top: 30px;
          }
        }
      }
    }
  }
  .wrap-box{
    display: block;
    width: 100%;
    @include pc-layout(){
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
    @include large-layout(){

    }
    .content-box{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 vwSp(40);
      @include pc-layout(){
        width: 50%;
        padding: vwPc(120) vwPc(100);
        background: #fffffd;
        z-index: 2;
      }
      @include large-layout(){
        width: 50%;
        padding: 120px 100px;
      }
      >:last-child{
        padding-bottom: vwSp(80);
        @include pc-layout(){
          padding-bottom: 0;
        }
        @include large-layout(){

        }
      }
    }
    .image{
      @include pc-layout(){
        width: 50%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 50%;
        opacity: 0;
        transition: all 0.5s ease;
        padding-top: vwPc(76);
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-top: 0;
          object-position: left top;
        }
      }
      @include large-layout(){
        padding-top: 76px;
        width: 50%;
      }
      &.absolute{
        position: absolute;
        bottom: 0;
        top: 0;
      }
    }
    .head {
      position: relative;
      display: inline-flex;
      margin: vwSp(140) auto 0;
      border: vwSp(2) solid #444444;
      font-size: vwSp(36);
      line-height: 1;
      padding: vwSp(20) vwSp(50);
      font-weight: bold;
      @include pc-layout(){
        margin: auto;
        border: vwPc(1) solid #444444;
        font-size: vwPc(18);
        padding: vwPc(10) vwPc(25);
      }
      @include large-layout(){
        margin: auto;
        border: 1px solid #444444;
        font-size: 18px;
        padding: 10px 25px;
      }
      +p{
        margin-top: vwSp(50);
        @include pc-layout(){
          margin-top: vwPc(30);
        }
        @include large-layout(){
          margin-top: 30px;
        }
      }
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: vwSp(48);
      font-weight: bold;
      line-height: 1;
      margin-top: vwSp(50);
      @include pc-layout(){
        font-size: vwPc(36);
        margin-top: vwPc(30);
      }
      @include large-layout(){
        font-size: 36px;
        margin-top: 30px;
      }
      &:first-child{
        margin-top: vwSp(140);
        @include pc-layout(){
          margin-top: 0;
        }
        @include large-layout(){
          margin-top: 0;
        }
      }
    }
    .subtitle {
      width: 100%;
      text-align: center;
      font-size: vwSp(32);
      line-height: 1;
      margin-top: vwSp(40);
      @include pc-layout(){
        font-size: vwPc(16);
        margin-top: vwPc(10);
      }
      @include large-layout(){
        font-size: 16px;
        margin-top: 10px;
      }
    }
    p{
      font-size: vwSp(30);
      margin-top: vwSp(50);
      width: 100%;
      @include pc-layout(){
        font-size: vwPc(15);
        margin-top: vwPc(40);
      }
      @include large-layout(){
        font-size: 15px;
        margin-top: 40px;
      }
      &.center{
        text-align: center;
      }
    }
    .list{
        margin-top: 0;
        @include pc-layout(){
          margin-top: vwPc(35);
        }
        @include large-layout(){
          margin-top: 35px;
        }
        li{

          &:last-child{
            @include pc-layout(){
              margin-left: auto;
              margin-right: 0;
            }
          }

          a{
            font-weight: bold;
          }
        }
      +.button-block{
        margin-top: vwSp(100);
        @include pc-layout(){
          margin-top: vwPc(60);
        }
        @include large-layout(){
          margin-top: 60px;
        }
      }
    }
    .button-block{
      width: 100%;
      +.button-block{
        @include pc-layout(){
          margin-top: vwPc(30);
        }
        @include large-layout(){
          margin-top: 30px;
        }
      }
      a{
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: vwSp(30) vwSp(100) vwSp(30) vwSp(85);
        &.center{
          padding: vwSp(30) vwSp(80) vwSp(30) vwSp(80);
          text-align: center;
          @include pc-layout(){
            padding: vwPc(15) vwPc(50);
          }
          @include large-layout(){
            padding: 15px 50px ;
          }
        }
        @include pc-layout(){
          text-align: center;
          min-width: vwPc(330);
          padding: vwPc(15) vwPc(50);
        }
        @include large-layout(){
          min-width: 330px;
          padding: 15px 50px ;
        }
      }
    }
    .list{
      li{
        margin-top: vwSp(30);
        @include pc-layout(){
          width: auto;
          margin-top: 0;
        }
        @include large-layout(){
        }
      }
    }
  }
  #fp-nav{
    @include pc-layout(){
      transform: none;
      bottom: vwPc(100);
      top:auto;
      z-index: 9991;
    }
    @include large-layout(){
      bottom: 100px;
      top:auto;
    }
    ul{
      li{
        &:first-child{
          display: none;
        }
        a{
          margin: 10px 0;
          span{
            background: rgba(255,255,255,0.4);
            @include pc-layout(){
              width: vwPc(6);
              height: vwPc(6);
              margin: auto;
            }
            @include large-layout(){
              width: 6px;
              height: 6px;
            }
          }
          &:hover,
          &.active{
            span{
              background: #fff;
              @include pc-layout(){
                width: vwPc(6);
                height: vwPc(6);
              }
              @include large-layout(){
                width: 6px;
                height: 6px;
              }
            }
          }
        }
      }
    }
  }
}
