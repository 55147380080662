#book{
  .button-block{
    a{
      @include pc-layout(){
        min-width: vwPc(335);
      }
      @include large-layout(){
        min-width: 335px;
      }
    }
  }
}
#book-detail{
  .book-detail{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @include pc-layout(){
      margin-top: vwPc(65);
      min-height: vwPc(690);
      align-items: flex-start;
      justify-content: flex-start;
    }
    @include large-layout(){
      margin-top: 65px;
      min-height: 690px;
    }
    h1{
      margin-top: vwSp(90);
      @include pc-layout(){
        margin-top: 0;
      }
      @include large-layout(){

      }
      +.row-book{
        @include pc-layout(){
          margin-top: vwPc(40);
        }
        @include large-layout(){
          margin-top: 40px;
        }
      }
    }
    .box-image{
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: vwSp(70);
      padding-bottom: vwSp(30);
      @include pc-layout(){
        position: relative;
        top:vwPc(15);
        left: 0;
        width: vwPc(560);
        margin-top: 0;
        padding: 0;
      }
      @include large-layout(){
        top:15px;
        width: 560px;
      }
      .top-slideshow{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 0 vwSp(90);
        height: vwSp(590);
        @include pc-layout(){
          padding: 0 vwPc(45);
          height: vwPc(520);
        }
        @include large-layout(){
          padding: 0 45px;
          height: 520px;
        }
        .slick-track{
          display: flex;
          flex-wrap: wrap;
        }
        .slick-slide{
          height: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content:center;
          align-items: center;
          >div{
            line-height: 0;
          }
        }
        .item{
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          a{
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            margin: auto;
            outline: none;
            &:after{
              @include pc-layout(){
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -46%);
                width: vwPc(50);
                height: vwPc(50);
                border-radius: vwSp(25);
                background:#fafaf7 url("../img/icon_zoom.png") 50% 50% no-repeat;
                background-size: vwPc(20);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
                opacity: 0;
                transition: all 0.3s ease;
              }
              @include large-layout(){
                width: 50px;
                height: 50px;
                background-size: 20px;
                border-radius: 25px;
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
              }
            }
            &:hover{
              &:after{
                @include pc-layout(){
                  box-shadow: 0 0 vwPc(10) 0 rgba(0, 0, 0, 0.2);
                  opacity: 1;
                  transition: all 0.3s ease;
                }
                @include large-layout(){
                  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                }
              }
              img{
                @include pc-layout(){
                  opacity: 0.6;
                }
              }
            }
            img{
              margin: auto;
              width: auto;
              max-height: vwSp(590);
              max-width: vwSp(450);
              height: auto;
              @include pc-layout(){
                max-height: vwPc(520);
                max-width: vwPc(364);
                transition: all 0.3s ease;
              }
              @include large-layout(){
                max-height: 520px;
                max-width: 364px;
              }
            }
          }
        }
      }
      .bottom-slideshow{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-top: vwSp(80);
        @include pc-layout(){
          margin-top: vwPc(35);
        }
        @include large-layout(){
          margin-top: 35px;
        }
        .item{
          width: vwSp(95);
          height: vwSp(95);
          position: relative;
          display: flex!important;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          background: #fff;
          border-style: solid;
          border-width: vwSp(2);
          border-color: rgb(234, 233, 230);
          @include pc-layout(){
            width: vwPc(84);
            height: vwPc(84);
            border-width: vwPc(1);
            justify-content: space-between;
          }
          @include large-layout(){
            width: 84px;
            height: 84px;
            border-width: 1px;
          }
          img{
            width: vwSp(55);
            margin: auto;
            @include pc-layout(){
              width: vwPc(48);
            }
            @include large-layout(){
              width: 48px;
            }
          }
        }
        .slick-track{
          width: 100%!important;
          transform: none!important;
        }
        .slick-slide{
          width: vwSp(95) !important;
          height: vwSp(95);
          margin-right: vwSp(20);
          margin-bottom: vwSp(20);
          transform: none!important;

          @include pc-layout(){
            width: vwPc(84)!important;
            height: vwPc(84);
            margin-right: vwPc(10);
            margin-bottom: vwPc(10);
          }
          @include large-layout(){
            width: 84px!important;;
            height: 84px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
          &:nth-child(6n){
            margin-right: 0;
          }
          &.slick-current{
            .item{
              border-style: solid;
              border-width: vwSp(2);
              border-color: rgb(101, 179, 147);
              background-color: rgb(255, 255, 255);
              @include pc-layout(){
                border-width: vwPc(1);
              }
              @include large-layout(){
                border-width: 1px;
              }
            }
          }
        }
      }
      .show-all{
        position: relative;
        display: flex;
        color: #65b393;
        font-size: vwSp(26);
        text-decoration: none;
        border-bottom: vwSp(2) solid #65b393;
        line-height: 1.5;
        margin-top: vwSp(20);
        @include pc-layout(){
          font-size: vwPc(12);
          border-width: vwPc(1);
          margin-top: 0;
        }
        @include large-layout(){
          font-size: 13px;
          border-width: 1px;
        }
      }
    }
    .box-content-detail{
      @include pc-layout(){
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        width: calc(100% - #{vwPc(620)});
        margin-left: vwPc(59);
      }
      @include large-layout(){
        width: calc(100% - 620px);
        margin-left: 59px;
      }
    }
    .row-book{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: vwSp(50);
      @include pc-layout(){
        margin-top: vwPc(20);
      }
      @include large-layout(){
        margin-top: 20px;
      }
      .head{
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        font-size: vwSp(30);
        font-weight: bold;
        line-height: 1.6;
        @include pc-layout(){
          font-size: vwPc(15);
          width: vwPc(140);
        }
        @include large-layout(){
          font-size: 15px;
          width: 140px;
        }
      }
      .detail{
        width: 100%;
        position: relative;
        display: block;
        font-size: vwSp(30);
        line-height: 1.6;
        margin-top: vwSp(20);
        @include pc-layout(){
          font-size: vwPc(15);
          width: calc(100% - #{vwPc(140)});
          margin-top: 0;
        }
        @include large-layout(){
          font-size: 15px;
          width: calc(100% - 140px);
        }
        p{
          font-size: vwSp(30);
          @include pc-layout(){
            font-size: vwPc(15);
          }
          @include large-layout(){
            font-size: 15px;
          }
          &:first-child{
            margin-top: 0;
          }
        }
        .note{
          font-size: vwSp(26);
          margin-top: vwSp(20);
          padding-left: vwSp(30);
          text-indent: vwSp(-30);
          line-height: 2;
          @include pc-layout(){
            font-size: vwPc(13);
            margin-top: vwPc(10);
            padding-left: vwPc(16);
            text-indent: vwPc(-16);
          }
          @include large-layout(){
            font-size: 13px;
            margin-top: 10px;
            padding-left: 16px;
            text-indent: -16px;
          }
        }
        a{
          margin-top: vwSp(20);
          color: #65b393;
          font-size: vwSp(26);
          text-decoration: none;
          border-bottom: vwSp(2) solid #65b393;
          @include pc-layout(){
            font-size: vwPc(13);
            margin-top: vwPc(10);
            border-bottom: vwPc(1) solid #65b393;
          }
          @include large-layout(){
            font-size: 13px;
            margin-top: 10px;
            border-bottom: 1px solid #65b393;
          }
        }
      }
      +p{
        margin-top: vwSp(90);
        @include pc-layout(){
          margin-top: vwPc(40);
        }
        @include large-layout(){
          margin-top: 40px;
        }
      }
    }
    p{
      margin-top: vwSp(20);
      line-height: 1.6;
      @include pc-layout(){
        margin-top: vwPc(20);
        font-size: vwPc(15);
      }
      @include large-layout(){
        margin-top: 20px;
        font-size: 15px;
      }
      &:first-child{
        @include pc-layout(){
          margin-top: 0;
        }
        @include large-layout(){
          margin-top: 0;
        }
      }
    }
  }
  .relate-book{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(120);
    @include pc-layout(){
      margin-top: vwPc(90);
    }
    @include large-layout(){
      margin-top: 90px;
    }
    .head{
      font-size: vwSp(40);
      font-weight: bold;
      width: 100%;
      padding-bottom: vwSp(40);
      border-bottom: vwSp(2) solid #eae9e6;
      line-height: 1.2;
      @include pc-layout(){
        font-size: vwPc(24);
        padding-bottom: vwPc(25);
        border-bottom: vwPc(1) solid #eae9e6;
      }
      @include large-layout(){
        font-size: 24px;
        padding-bottom: 25px;
        border-bottom: 1px solid #eae9e6;
      }
    }
    .list-item{
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(10);
      }
      @include large-layout(){
        margin-top: 10px;
      }
      .item{
        margin-top: vwSp(40);
        width: calc((100% - #{vwSp(40)}) / 2);
        margin-right: vwSp(40);
        padding: vwSp(60) vwSp(20);
        @include pc-layout(){
          width: calc((100% - #{vwPc(120)}) / 5);
          margin-right: vwPc(30);
          padding: vwPc(40);
          margin-top: vwPc(30);
        }
        @include large-layout(){
          width: calc((100% - 120px) / 5);
          margin-right: 30px;
          padding: 40px;
          margin-top: 30px;
        }
        .image{
          padding: 0 vwSp(56);
          height: auto;
          @include pc-layout(){
            padding:0 vwPc(10);
          }
          @include large-layout(){
            padding:0 10px;
          }
          img{
            height: auto;
          }
        }
        .item-content{
          justify-content: center;
          margin-top: vwSp(30);
          @include pc-layout(){
            margin-top: vwPc(30);
          }
          @include large-layout(){
            margin-top: 30px;
          }
          .title{
            font-weight: bold;
            font-size: vwSp(34);
            line-height: 1.5;
            text-decoration: none;
            @include pc-layout(){
              font-size: vwPc(17);
            }
            @include large-layout(){
              font-size: 17px;
            }
          }
        }
        &:nth-child(even){
          margin-right: 0;
          @include pc-layout(){
            margin-right: vwPc(30);
          }
          @include large-layout(){
            margin-right: 30px;
          }
        }
        &:nth-child(5n){
          @include pc-layout(){
            margin-right:0;
          }
          @include large-layout(){
            margin-right: 0;
          }
        }
      }
    }
  }
}
.slick-arrow{
  width: vwSp(80);
  height: vwSp(80);
  border-radius: vwSp(40);
  background: #65b393;
  position: absolute;
  transition:all 0.3s ease;
  opacity: 1;
  &.hide{
    display: none;
  }
  @include pc-layout(){
    width: vwPc(40);
    height: vwPc(40);
    border-radius: vwPc(20);
  }
  @include large-layout(){
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  &:hover{
    &:after{
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: vwSp(21);
    height: vwSp(23);
    background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
    background-size: cover;
    bottom: vwSp(30);
    right: vwSp(30);
    @include pc-layout() {
      width: vwPc(11);
      height: vwPc(12);
      bottom: vwPc(14);
      right: vwPc(14);
    }
    @include large-layout() {
      width: 11px;
      height: 12px;
      bottom: 14px;
      right: 14px;
    }
  }
  &:before{
    display: none;
  }
}
.slick-disabled{
  opacity: 0;
  transition:all 0.3s ease;
  pointer-events: none;
}
.slick-prev{
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  &:after{
    transform: rotate(180deg);
  }
  &:hover{
    &:after{
      animation: arrowSlideleft 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 backwards;
    }
  }
}
.slick-next{
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.modal{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top:0;
  left: 0;
  background: #f9f9f5;
  overflow: auto;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease;
  &.show{
    opacity: 1;
    transform: translateY(0);
  }
  .close-button{
    width: vwSp(100);
    height: vwSp(100);
    background: #65b393 url("../img/close-icon.png") 50% 50% no-repeat;
    background-size: vwSp(40);
    border-radius: vwSp(50);
    position: fixed;
    top:vwSp(40);
    right: vwSp(40);
    opacity: 1;
    display: block;
    @include pc-layout(){
      width: vwPc(50);
      height: vwPc(50);
      background-size: vwPc(20);
      border-radius: vwPc(25);
      top:vwPc(30);
      right: vwPc(30);
    }
    @include large-layout(){
      width: 50px;
      height: 50px;
      background-size: 20px;
      border-radius:25px;
      top:30px;
      right: 30px;
    }
  }
  .modal-content{
    position: relative;
    margin: 20vh auto 0;
    padding-bottom: vwSp(50);
    width: vwSp(472);
    @include pc-layout(){
      width: vwPc(800);
      padding-bottom: vwPc(100);
      margin: 15vh auto 0;
    }
    @include large-layout(){
      padding-bottom: 100px;
      width: 800px;
    }
    .profile-modal{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      a,p{
        width: 100%;
        text-align: center;
        margin-top: vwSp(60);
        text-underline-offset: 0.1em;
        @include pc-layout(){
          margin-top: vwPc(30);
        }
        @include large-layout(){
          margin-top: 30px;
        }
      }

      .summary{
        width: 100%;
        position: relative;
        display: block;
        margin-top: vwSp(40);
        @include pc-layout(){
          width: 100%;
          padding-left: 0;
          margin-top: vwPc(50);

        }
        @include large-layout(){
          padding-left:0;
          margin-top: 50px;
        }
        .head{
          font-family: 'Inter', sans-serif;
          font-weight: bold;
          font-size: vwSp(38);
          width: 100%;
          position: relative;
          display: block;
          padding-bottom: vwSp(10);
          line-height: 1.5;
          border-bottom: vwSp(2) solid #eae9e6;
          text-align: center;
          @include pc-layout(){
            top:-0.3em;
            font-size: vwPc(18);

            padding-bottom: vwPc(10);

            border-bottom: vwPc(1) solid #eae9e6;
          }
          @include large-layout(){
            font-size: 18px;

            padding-bottom: 10px;

            border-bottom: 1px solid #eae9e6;
          }
        }
        .text{
          position: relative;
          display: block;
          width:100%;
          font-size: vwSp(30);
          line-height: 2;
          margin-top: vwSp(10);
          @include pc-layout(){
            font-size: vwPc(15);
            margin-top: vwPc(10);
            text-align: left;
          }
          @include large-layout(){
            font-size: 15px;
            margin-top: 10px;
          }
        }
      }
    }
    .slick-track{
      display: flex;
    }
    .slick-slide{
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content:center;
      align-items: center;
      width: 100%!important;
      img{
        margin-top: 0;
        @include pc-layout(){
          max-height: 60vh;
          max-width: 100%;
        }
      }
    }
    .slick-arrow{
      bottom: vwSp(-140);
      top: auto;
      @include pc-layout(){
        top: 50%;
      }
    }
    .slick-prev{
      left: vwSp(70);
      @include pc-layout(){
        left: vwPc(-150);
      }
      @include large-layout(){
        left: -150px;
      }
    }
    .slick-next{
      right: vwSp(70);
      @include pc-layout(){
        right: vwPc(-150);
      }
      @include large-layout(){
        right: -150px;
      }
    }
    .count{
      margin-top: vwSp(40);
      text-align: center;
      color: #222222;
      font-size: vwSp(30);
      @include pc-layout(){
        font-size: vwPc(15);
        margin-top: vwPc(30);
      }
      @include large-layout(){
        margin-top: 30px;
        font-size: 15px;
      }
    }
  }
}
