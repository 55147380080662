$pc_min_width: 769px;

// see /src/constants.yml
$img_dir: "/img/";

$largedestop: 1441px;
$desktop: $pc_min_width;
$mobile: ($pc_min_width - 1);
$ipad: 768px;
$ipad_large: 1024px;

//color
$black: #222;
$salmon: #f66;
$brownish-grey: #757575;
$very-light-pink: #e0e0e0;
$topaz: #1ab4d3;
$very-light-pink: #bdbdbd;
$very-light-pink: #f2f2f2;
$blue-zodiac: #0e224b;
$harvest-gold: #dbc27d;
$white: #fff;
$mine_shaft: #333;
$tussock: #cb9b42;
