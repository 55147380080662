#application-aid_outline{
  .page-anchor{
    ul{
      width: 100%;
      li{
        width: 100%;
      }
    }
  }
  h1{
    margin-top: vwSp(100);
    @include pc-layout(){
      margin-top: vwPc(80);
    }
    @include large-layout(){
      margin-top: 80px;
    }
  }
  h2{
    margin-top: vwSp(70);
    @include pc-layout(){
      margin-top: vwPc(60);
    }
    @include large-layout(){
      margin-top: 60px;
    }
    +h3{
      margin-top: vwSp(50);
      @include pc-layout(){
        margin-top: vwPc(50);
      }
      @include large-layout(){
        margin-top: 50px;
      }
    }
  }
  h3{
    margin-top: vwSp(90);
    @include pc-layout(){
      margin-top: vwPc(60);
    }
    @include large-layout(){
      margin-top: 60px;
    }
    +.list{
      margin-top: vwSp(50);
      @include pc-layout(){
        margin-top: vwPc(40);
      }
      @include large-layout(){
        margin-top: 40px;
      }
    }
  }
  p{
    @include pc-layout(){
      font-size: vwPc(17);
    }
    @include large-layout(){
      font-size: 17px;
    }
    &.note{
      @include pc-layout(){
        font-size: vwPc(14);
      }
      @include large-layout(){
        font-size: 14px;
      }
    }
  }
  table{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(60);
    border-top: vwSp(2) solid #dcdcdc;
    @include pc-layout(){
      margin-top: vwPc(40);
      display: table;
      border-top: vwPc(1) solid #dcdcdc;
    }
    @include large-layout(){
      margin-top: 40px;
      border-top: 1px solid #dcdcdc;
    }
    tr,
    td{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-collapse: collapse;
      vertical-align: text-top;
      @include pc-layout(){
        display: table;
      }
    }
    td{
      font-size: vwSp(36);
      padding: vwSp(30);
      border: vwSp(2) solid #dcdcdc;
      border-top: 0;
      background: #fff;
      line-height: 1.6;
      @include pc-layout(){
        width: calc(100% - #{vwPc(250)});
        display: table-cell;
        border: vwPc(1) solid #dcdcdc;
        font-size: vwPc(18);
        padding: vwPc(25);
        border-top: 0;
      }
      @include large-layout(){
        width: calc(100% - 250px);
        font-size: 18px;
        padding: 25px;
        border: 1px solid #dcdcdc;
        border-top: 0;
      }
      &:first-child{
        @include pc-layout(){
          width: vwPc(250);
          border: vwPc(1) solid #dcdcdc;
          border-top: 0;
        }
        @include large-layout(){
          width: 250px;
          border: 1px solid #dcdcdc;
          border-top: 0;
        }
      }
      p{
        font-size:vwSp(32);
        margin-top: vwSp(10);
        line-height: 1.6;
        @include pc-layout(){
          font-size: vwPc(17);
          margin-top: vwPc(10);
          width: 100%;
        }
        @include large-layout(){
          font-size: 17px;
          margin-top: 10px;
        }
        &.small{
          font-size:vwSp(30);
          @include pc-layout(){
            font-size: vwPc(15);
          }
          @include large-layout(){
            font-size: 15px;
          }
        }
        &.big{
          font-weight: bold;
          margin-top: vwSp(15);
          &:first-child{
            margin-top: 0;
          }
          @include pc-layout(){
            margin-top: vwPc(10);
          }
          @include large-layout(){
            margin-top: 10px;
          }
          +p{
            margin-top: vwSp(5);
            @include pc-layout(){
              margin-top: 0;
            }
            @include large-layout(){
              margin-top: 0;
            }
          }
        }
        &:first-child{
          margin-top: 0;
        }
      }
      .list{
        .pdf{
          font-weight: bold;
        }
      }
      &:first-child{
        font-weight: bold;
        background: #f6f6f6;

      }
    }
    + h3{
      @include pc-layout(){
        margin-top: vwPc(75);
      }
      @include large-layout(){
        margin-top: 75px;
      }
    }
  }
  .list{
    margin-top:0;
    .pdf{
      margin-top: vwSp(30);
      font-weight: bold;
      &:before{
      }
      @include pc-layout(){
        margin-top: vwPc(20);
      }
      @include large-layout(){
        margin-top: 20px;
      }
    }
  }
  .application{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    font-size: vwSp(42);
    font-weight: bold;
    margin-top: vwSp(40);
    @include pc-layout(){
      margin-top: vwPc(30);
      font-size: vwPc(26);
    }
    @include large-layout(){
      margin-top: 30px;
      font-size: 26px;
    }
  }
  .button-block{
    margin-top: vwSp(80);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
  }
}
#application-publication_outline{
  p{
    span.note{
      display: block;
    }
    @include pc-layout(){
      font-size: vwPc(17);
    }
    @include large-layout(){
      font-size: 17px;
    }
    &.note{
      @include pc-layout(){
        font-size: vwPc(14);
      }
      @include large-layout(){
        font-size: 14px;
      }
    }
  }
  h3{
    + .num-list{
      margin-top: vwSp(40);
      @include pc-layout(){
        margin-top: vwPc(40);
      }
      @include large-layout(){
        margin-top: 40px;
      }
    }
  }
  .fixedwidth{
    display: inline-block;
    width: vwSp(100);
    @include pc-layout(){
      width: vwPc(60);
    }
    @include large-layout(){
      width: 60px;
    }
  }
}
#application-outline{
  h2{
    margin-top: vwSp(80);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
  }
  .step-box{
    width:100%;
    position: relative;
    display: block;
    margin-top: vwSp(80);
    margin-bottom: vwSp(120);
    @include pc-layout(){
      margin-top: vwPc(30);
      margin-bottom: vwPc(80);
    }
    @include large-layout(){
      margin-top: 30px;
      margin-bottom: 80px;
    }
    .step-wrap{
      display: none;
      &.active{
        display: block;
      }
      .step{
        &:first-child{
          margin-top: vwSp(150);
          @include pc-layout(){
            margin-top: vwPc(70);
          }
          @include large-layout(){
            margin-top: 70px;
          }
          &:before {
            display: block;
          }
        }
      }
    }
    > :first-child{
      margin-top: 0;
      .title{
        @include pc-layout(){
          justify-content: flex-start;
        }
        p{
          @include pc-layout(){
            margin-left: 0;
          }
          @include large-layout(){
            margin-left: 0;
          }
        }
      }
      &:before{
        display: none;
      }
    }
    .step{
      margin-top: vwSp(150);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: vwSp(40) vwSp(40) vwSp(100);
      background: #ffffff;
      @include pc-layout(){
        margin-top: vwPc(70);
        padding: vwPc(30) vwPc(40) vwPc(60);
      }
      @include large-layout(){
        margin-top: 70px;
        padding: 30px 40px 60px;
      }
      &:before{
        content: "";
        height: vwSp(30);
        width: vwSp(50);
        background: url("../img/step.svg") no-repeat;
        background-size: 99% 99%;
        left: calc(50% - #{vwSp(25)});
        top: vwSp(-90);
        position: absolute;
        display: block;
        @include pc-layout(){
          height: vwPc(15);
          width: vwPc(26);
          left: calc(50% - #{vwPc(13)});
          top: vwPc(-42);
        }
        @include large-layout(){
          height: 15px;
          width: 26px;
          left: calc(50% - 13px);
          top: -42px;
        }
      }
      &:last-child{
        padding-bottom: vwSp(60);
        @include pc-layout(){
          padding-bottom: vwPc(60);
        }
        @include large-layout(){
          padding-bottom: 60px;
        }
      }
      .head{
        width: 100%;
        position: relative;
        display: block;
        text-align: center;
        font-family: 'Inter', Noto Sans JP, sans-serif;
        font-weight: bold;
        color: #222;
        font-size: vwSp(46);
        line-height: 1.2;
        padding-bottom: vwSp(30);
        border-bottom: vwSp(6) solid #eae9e6;
        @include pc-layout(){
          font-size: vwPc(30);
          border-bottom: vwPc(3) solid #eae9e6;
          padding-bottom: vwPc(25);
        }
        @include large-layout(){
          font-size: vwPc(30);
          border-bottom: vwPc(3) solid #eae9e6;
          padding-bottom: 25px;
        }
      }
      .title{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: vwSp(60);
        &:first-child{
          margin-top: vwSp(20);
          @include pc-layout(){
            margin-top: vwPc(30);
          }
          @include large-layout(){
            margin-top: 30px;
          }
        }
        @include pc-layout(){
          margin-top: vwPc(40);
        }
        @include large-layout(){
          margin-top: 40px;
        }
        .tag{
          height: vwSp(45);
          border-radius: vwSp(25);
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;
          padding: 0 vwSp(40);
          color: #fff;
          font-size: vwSp(26);
          background: #65b393;
          @include pc-layout(){
            height: vwPc(23);
            border-radius: vwPc(12);
            padding: 0 vwPc(13);
            font-size: vwPc(13);
            min-width: vwPc(70);
          }
          @include large-layout(){
            height: 23px;
            border-radius: 12px;
            padding: 0 13px;
            font-size: 13px;
            min-width: 70px;
          }
          + .tag{
            margin-left: vwSp(20);
            @include pc-layout(){
              margin-left: vwPc(10);
            }
            @include large-layout(){
              margin-left: 10px;
            }
          }
        }
        p{
          width: 100%;
          position: relative;
          display: flex;
          font-weight: bold;
          font-size: vwSp(38);
          margin-top: vwSp(30);
          line-height: 1.6;
          justify-content: center;
          color: #222;
          text-align: center;
          @include pc-layout(){
            margin-top: 0;
            font-size: vwPc(20);
            margin-left: vwPc(20);
            width: auto;
          }
          @include large-layout(){
            margin-top: 0;
            font-size: 20px;
            margin-left: 20px;
          }
          &:first-child{
            margin-top: 0;
            @include pc-layout(){
              margin-left: 0;
            }
            @include large-layout(){
              margin-left: 0;
            }
          }
        }
      }
      .box-button{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: vwSp(60);
        @include pc-layout(){
          margin-top: vwPc(20);
        }
        @include large-layout(){
          margin-top: 20px;
        }
        a{
          width: 100%;
          height: vwSp(110);
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          font-size: vwSp(34);
          font-weight: bold;
          border: vwSp(6) solid #e3e2df;
          margin-top: vwSp(40);
          text-decoration: none;
          border-radius: vwSp(40);
          color: #e3e2df;
          @include pc-layout(){
            margin-top: 0;
            width: calc((100% - #{vwPc(40)})/3);
            height: vwPc(73);
            font-size: vwPc(18);
            border: vwPc(3) solid #e3e2df;
            border-radius: vwPc(20);
            margin-right: vwPc(20);
          }
          @include large-layout(){
            margin-top: 0;
            width: calc((100% - 40px)/3);
            height: 73px;
            font-size: 18px;
            border: 3px solid #e3e2df;
            border-radius: 20px;
            margin-right: 20px;
          }
          &:nth-child(3n){
            @include pc-layout(){
              margin-right: 0;
            }
            @include large-layout(){
              margin-right: 0;
            }
          }
          &:first-child{
            margin-top: 0;
          }
          &.active{
            color: #222;
            border-color: #65b393;
            pointer-events: none;
          }
        }
      }
      > p{
        font-size: vwSp(30);
        color: rgba(34,34,34,0.5);
        width: 100%;
        line-height: 1.6;
        margin-top: vwSp(25);
        @include pc-layout(){
          font-size: vwPc(15);
          margin-top: vwPc(20);
          text-align: center;
        }
        @include large-layout(){
          font-size: 15px;
          margin-top: 20px;
        }
        &.big{
          font-weight: bold;
          font-size: vwSp(38);
          color: #222;
          margin: vwSp(20) 0 vwSp(10);
          @include pc-layout(){
            font-size: vwPc(20);
            margin-top: vwPc(30);
            margin-bottom: 0;
            text-align: left;
          }
          @include large-layout(){
            font-size: 20px;
            margin-top: 30px;
            margin-bottom: 0;
          }
        }
        &.center{
          text-align: center;
          color: #222;
        }
        +.title{
          margin-top: vwSp(20);
          @include pc-layout(){
            margin-top: vwPc(30);
          }
          @include large-layout(){
            margin-top:30px;
          }
        }
      }
    }
  }
  .big-button{
    margin-top: vwSp(80);
    @include pc-layout(){
      margin-top: vwPc(30);
    }
    @include large-layout(){
      margin-top: 30px;
    }
    .button{
      @include pc-layout(){
        width: calc((100% - #{vwPc(120)}) / 3) ;
        margin-right: vwPc(60);
      }
      @include large-layout(){
        width: calc((100% - 120px) / 3) ;
        margin-right: 60px;
      }
      &:nth-child(3n){
        @include pc-layout(){
          margin-right: 0;
        }
        @include large-layout(){
          margin-right: 0;
        }
      }
      &:nth-child(4n){
        @include pc-layout(){
          margin-right: vwPc(60);
        }
        @include large-layout(){
          margin-right: 60px;
        }
      }
      a{
        color: #e3e2df;
        border-color: #e3e2df;
        pointer-events: none;
        &:after{
          background: url("../img/disable-right-arrow.png") 50% 50% no-repeat;
          background-size: 99%;
        }
      }
      &.active{
        a{
          color: #222;
          border: vwSp(6) solid #e29f92;
          pointer-events:all;
          @include pc-layout(){
            border: vwPc(6) solid #e29f92;
          }
          @include large-layout(){
            border: 3px solid #e29f92;
          }
          &:after{
            background: url("../img/pink-right-arrow.png") 50% 50% no-repeat;
            background-size: 99%;
          }
        }
      }
    }
  }
  .button-block{
    margin-top: vwSp(100);
    @include pc-layout(){
      margin-top: vwPc(50);
    }
    @include large-layout(){
      margin-top: 50px;
    }
  }
  .download-list{
    .item{
      a{
        padding: vwSp(50);
        justify-content: center;
        align-items: center;
        @include pc-layout(){
          padding: vwPc(25);
        }
        @include large-layout(){
          padding: 25px;
        }
        &:after{
          position: relative;
          order:  -1;
          margin-right: vwSp(20);
          top:0;
          left: 0;
          @include pc-layout(){
            margin-right: vwPc(10);
          }
          @include large-layout(){
            margin-right: 10px;
          }
        }
      }
    }
  }
  #groupA{
    .step{
      .title{
        @include pc-layout(){
          justify-content: flex-start;
          padding-left: 33%;
        }
        @include large-layout(){
          justify-content: flex-start;
          padding-left: 33%;
        }
      }
    }
  }
  #groupC{
    .step{
      .title{
        @include pc-layout(){
          justify-content: flex-start;
          padding-left: 40.2%;
        }
        @include large-layout(){
          justify-content: flex-start;
          padding-left: 40.2%;
        }
      }
    }
  }
}
#application-voice{
  .big-button{
    .button{
      @include pc-layout(){
        width: calc((100% - #{vwPc(120)}) / 3) ;
        margin-right: vwPc(60);
      }
      @include large-layout(){
        width: calc((100% - 120px) / 3) ;
        margin-right: 60px;
      }
      &:nth-child(3n){
        @include pc-layout(){
          margin-right: 0;
        }
        @include large-layout(){
          margin-right: 0;
        }
      }
      &:nth-child(4n){
        @include pc-layout(){
          margin-right: vwPc(60);
        }
        @include large-layout(){
          margin-right: 60px;
        }
      }
    }
  }
}
#application-entity_outline{
  p{
    @include pc-layout(){
      font-size: vwPc(17);
    }
    @include large-layout(){
      font-size: 17px;
    }
    &.note{
      @include pc-layout(){
        font-size: vwPc(14);
      }
      @include large-layout(){
        font-size: 14px;
      }
    }
  }
}
#application{
  .big-button{
    .button{
      @include pc-layout(){
        width: calc((100% - #{vwPc(120)}) / 3) ;
        margin-right: vwPc(60);
        border-width: vwPc(6);
        margin-top: vwPc(60);
      }
      @include large-layout(){
        width: calc((100% - 120px) / 3) ;
        margin-right: 60px;
        border-width: 6px;
        margin-top: 60px;
      }
      &:nth-child(-n+3){
        @include pc-layout(){
          margin-top: 0;
        }
        @include large-layout(){
          margin-top: 0;
        }
      }
      &:nth-child(3n){
        @include pc-layout(){
          margin-right: 0;
        }
        @include large-layout(){
          margin-right: 0;
        }
      }
      &:nth-child(4n){
        @include pc-layout(){
          margin-right: vwPc(60);

        }
        @include large-layout(){
          margin-right: 60px;
        }
      }
      a{
        @include pc-layout(){
          border-width: vwPc(6);
          margin-bottom: vwPc(30);
        }
        @include large-layout(){
          border-width: 6px;
          margin-bottom: 30px;
        }
        &.pink{
          @include pc-layout(){
            margin-bottom: 0;
          }
          @include large-layout(){
            margin-bottom: 0;
          }
        }
      }
      //.caption{
      //  @include pc-layout(){
      //    margin-bottom: vwPc(30);
      //  }
      //  @include large-layout(){
      //    margin-bottom: 30px;
      //  }
      //}
    }
  }
}