@charset "UTF-8";
@import url(../../node_modules/sanitize.css/sanitize.css);
@import url(../../node_modules/fullpage.js/dist/fullpage.css);
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap");
@import url(lightbox.css);
.font__large1 {
  font-size: 30px;
  line-height: calc(1em + 8px); }

.font__large2 {
  font-size: 27px;
  line-height: calc(1em + 8px); }

.font__large3 {
  font-size: 24px;
  line-height: calc(1em + 8px); }

.font__large4 {
  font-size: 22px;
  line-height: calc(1em + 8px); }

.font__p-large {
  font-size: 19px;
  line-height: calc(1em + 8px); }

.font__p-small {
  font-size: 14px;
  line-height: calc(1em + 8px); }

.font__p-ex-small {
  font-size: 12px;
  line-height: calc(1em + 8px); }

@media (max-width: 768px) {
  .font__large3-sp {
    font-size: 18px;
    line-height: calc(1em + 8px); } }

@media (max-width: 768px) {
  .font__large4-sp {
    font-size: 16px;
    line-height: calc(1em + 8px); } }

@media (max-width: 768px) {
  .font__p-large-sp {
    font-size: 18px;
    line-height: calc(1em + 8px); } }

@media (max-width: 768px) {
  .font__p-small-sp {
    font-size: 12px;
    line-height: calc(1em + 8px); } }

@media (max-width: 768px) {
  .font__p-ex-small-sp {
    font-size: 10px;
    line-height: calc(1em + 8px); } }

@keyframes arrowSlide {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  50.0% {
    transform: translate3d(60%, 0, 0);
    opacity: 0; }
  50.1% {
    transform: translate3d(-60%, 0, 0);
    opacity: 0; }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes arrowSlideleft {
  0% {
    transform: translate3d(0, 0, 0) rotate(180deg);
    opacity: 1; }
  50.0% {
    transform: translate3d(-60%, 0, 0) rotate(180deg);
    opacity: 0; }
  50.1% {
    transform: translate3d(60%, 0, 0) rotate(180deg);
    opacity: 0; }
  100% {
    transform: translate3d(0, 0, 0) rotate(180deg);
    opacity: 1; } }

@keyframes arrowSlideUp {
  0% {
    transform: translate3d(0, 0, 0) rotate(-90deg);
    opacity: 1; }
  50.0% {
    transform: translate3d(0, -60%, 0) rotate(-90deg);
    opacity: 0; }
  50.1% {
    transform: translate3d(0, 60%, 0) rotate(-90deg);
    opacity: 0; }
  100% {
    transform: translate3d(0, 0, 0) rotate(-90deg);
    opacity: 1; } }

@keyframes arrowSlideDown {
  0% {
    transform: translate3d(0, 0, 0) rotate(90deg);
    opacity: 1; }
  50.0% {
    transform: translate3d(0, 60%, 0) rotate(90deg);
    opacity: 0; }
  50.1% {
    transform: translate3d(0, -60%, 0) rotate(90deg);
    opacity: 0; }
  100% {
    transform: translate3d(0, 0, 0) rotate(90deg);
    opacity: 1; } }

@-webkit-keyframes admtimg {
  0% {
    opacity: 0;
    transform: translateX(-20%); }
  49% {
    overflow: hidden;
    opacity: 0;
    transform: translateX(-20%); }
  50% {
    overflow: hidden;
    opacity: 1;
    transform: translateX(-20%); }
  100% {
    overflow: hidden;
    opacity: 1;
    transform: translateX(0); } }

@keyframes admtimg {
  0% {
    opacity: 0;
    transform: translateX(-20%); }
  49% {
    overflow: hidden;
    opacity: 0;
    transform: translateX(-20%); }
  50% {
    overflow: hidden;
    opacity: 1;
    transform: translateX(-20%); }
  100% {
    overflow: hidden;
    opacity: 1;
    transform: translateX(0); } }

.admtimg {
  -webkit-animation-name: admtimg;
  animation-name: admtimg;
  animation-timing-function: ease; }

@-webkit-keyframes admtbg {
  0% {
    left: 100%; }
  50% {
    left: 0; }
  100% {
    left: 100%; } }

@keyframes admtbg {
  0% {
    left: 100%; }
  50% {
    left: 0; }
  100% {
    left: 100%; } }

.admtbg {
  -webkit-animation-name: admtbg;
  animation-name: admtbg;
  animation-timing-function: cubic-bezier(0.81, 0.15, 0.26, 0.99); }

@-webkit-keyframes pageanchor {
  0% {
    @media (min-width: 769px) {
      top: 0vw; } }
  100% {
    @media (min-width: 769px) {
      top: 5.41667vw; }
    @media (min-width: 1441px) {
      top: 78px; } } }

@keyframes pageanchor {
  0% {
    @media (min-width: 769px) {
      top: 0vw; } }
  100% {
    @media (min-width: 769px) {
      top: 5.41667vw; }
    @media (min-width: 1441px) {
      top: 78px; } } }

.pageanchor {
  -webkit-animation-name: pageanchor;
  animation-name: pageanchor;
  animation-timing-function: ease; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: inherit; }

ol,
ul {
  margin: 0;
  padding: 0; }

li {
  list-style-type: none; }

a {
  color: inherit; }
  a:visited {
    color: inherit; }

:root {
  font: 16px/calc(1em + 8px) sans-serif; }

body {
  display: block;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  color: #222;
  font-family: "Noto Sans JP", sans-serif;
  background: #fafaf7; }
  @media (min-width: 769px) {
    body {
      background: #fff; } }

@media (min-width: 769px) {
  .show-sp {
    display: none !important; } }

@media (max-width: 768px) {
  .show-pc {
    display: none !important; } }

.serif {
  font-family: "Noto Sans JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce; }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); } }

.swing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  70% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  70% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  70% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(30px); }
  90% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  70% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  80% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(30px); }
  90% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -ms-transform: scale(0.3);
    transform: scale(0.3); } }

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    filter: brightness(100%); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    filter: brightness(100%); } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    filter: brightness(100%);
    transform: scale(1); } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    filter: brightness(100%);
    transform: scale(1); } }

.zoomIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-iteration-count: 1; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  transition-timing-function: ease-in; }

@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  transition-timing-function: ease-in; }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    -webkit-transform: translateY(700px);
    transform: translateY(700px);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40% {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  100% {
    -webkit-transform: translateY(700px);
    -ms-transform: translateY(700px);
    transform: translateY(700px);
    opacity: 0; } }

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

/** animation for Keyvitual **/
@-webkit-keyframes backgroundanimation {
  0% {
    width: 0;
    height: 2px; }
  50% {
    width: 100%;
    height: 2px; }
  100% {
    width: 100%;
    height: 100%; } }

@keyframes backgroundanimation {
  0% {
    width: 0;
    height: 2px; }
  50% {
    width: 100%;
    height: 2px; }
  100% {
    width: 100%;
    height: 100%; } }

.backgroundanimation {
  -webkit-animation-name: backgroundanimation;
  animation-name: backgroundanimation; }

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox; }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg); }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes fadeInandOut {
  0% {
    opacity: 0; }
  25% {
    opacity: 1;
    filter: brightness(100%); }
  75% {
    opacity: 1;
    filter: brightness(100%); }
  100% {
    opacity: 0; } }

@keyframes fadeInandOut {
  0% {
    opacity: 0; }
  25% {
    opacity: 1;
    filter: brightness(100%); }
  75% {
    opacity: 1;
    filter: brightness(100%); }
  100% {
    opacity: 0; } }

.fadeInandOut {
  -webkit-animation-name: fadeInandOut;
  animation-name: fadeInandOut;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes bgright {
  0% {
    width: 100%; }
  100% {
    @media (min-width: 769px) {
      width: calc(100% - 41.66667vw); }
    @media (min-width: 1441px) {
      width: calc(100% - 600px); } } }

@keyframes bgright {
  0% {
    width: 100%; }
  100% {
    @media (min-width: 769px) {
      width: calc(100% - 41.66667vw); }
    @media (min-width: 1441px) {
      width: calc(100% - 600px); } } }

.bgright {
  -webkit-animation-name: bgright;
  animation-name: bgright;
  animation-timing-function: ease; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  line-height: 1.2;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .header__inner {
    display: flex;
    align-items: center;
    height: 18.93333vw;
    padding: 5.33333vw 4.66667vw; }
  .header__logo {
    transition: width 0.3s;
    width: 37.33333vw; }
    .header__logo img {
      max-width: 100%;
      max-height: 100%;
      margin-top: 0 !important; }
  .header.scrolled {
    display: none; }

.menu {
  display: flex;
  align-items: center;
  margin-left: auto; }
  @media (max-width: 768px) {
    .menu.open .menu__trigger span:nth-child(1) {
      transform: translateY(1.6vw) rotate(-45deg); }
    .menu.open .menu__trigger span:nth-child(2) {
      background-color: rgba(255, 255, 255, 0); }
    .menu.open .menu__trigger span:nth-child(3) {
      transform: translateY(1.6vw) rotate(45deg); }
    .menu.open .nav {
      top: 100%;
      display: block;
      opacity: 1; } }
  .menu__trigger {
    position: relative;
    z-index: 20;
    width: 5.33333vw;
    height: 3.73333vw;
    margin-left: 5.33333vw;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0); }
    .menu__trigger span {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 4.8vw;
      height: 0.66667vw;
      background-color: #65b393;
      border-radius: 100px;
      transition: all .2s ease;
      will-change: transform; }
    .menu__trigger span:nth-child(1) {
      transform: rotate(0); }
    .menu__trigger span:nth-child(2) {
      transform: translateY(1.6vw); }
    .menu__trigger span:nth-child(3) {
      transform: translateY(3.2vw); }

.menu-desc__lang {
  font-weight: 500;
  font-size: 3.73333vw;
  font-family: "Inter", sans-serif;
  text-transform: uppercase; }
  .menu-desc__lang a {
    display: block;
    text-decoration: none; }
  .menu-desc__lang .active {
    display: none; }

.menu-desc__navlink {
  display: none; }

.icon-arrow {
  position: relative;
  display: inline-block;
  width: 3.33333vw;
  height: 3.33333vw;
  flex-shrink: 0; }
  .icon-arrow:after {
    position: absolute;
    top: 0;
    top: 0;
    left: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url("/img/i-arrow-right-green.svg") center/contain no-repeat;
    content: ""; }
  .icon-arrow.arrow-green:after {
    background-image: url("/img/i-arrow-right-green.svg"); }
  .icon-arrow.arrow-white:after {
    background-image: url("/img/i-arrow-right-white.svg"); }

.arrow-hover:hover .icon-arrow:after {
  animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }

.nav {
  position: absolute;
  top: -1000%;
  left: 0;
  width: 100%;
  height: calc(100vh - 18.93333vw);
  padding-top: 7.33333vw;
  padding-bottom: 13.33333vw;
  overflow: auto;
  background: #ecece7;
  opacity: 0;
  transition: opacity .3s; }
  .nav a {
    text-decoration: none; }
  .nav .nav-item {
    margin: 0 5.33333vw; }
  .nav .nav-link {
    display: flex;
    align-items: center;
    padding: 7.33333vw 0 3.33333vw;
    font-weight: bold;
    font-size: 5.33333vw;
    font-family: "Noto Sans JP", sans-serif; }
    .nav .nav-link .icon-arrow {
      margin-right: 2.66667vw; }

.icon-dropdown {
  position: relative;
  width: 7.33333vw;
  height: 7.33333vw;
  margin-left: auto; }
  .icon-dropdown:before, .icon-dropdown:after {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 0.8vw;
    background: #65b393;
    opacity: 1;
    transition: all .2s;
    content: ""; }
  .icon-dropdown:before {
    transform: translate3d(0, -50%, 0); }
  .icon-dropdown:after {
    transform: translate3d(0, -50%, 0) rotate(90deg); }

@media (max-width: 768px) {
  .dropdown.open > a > .icon-dropdown:after {
    opacity: 0; }
  .dropdown.open .dropdown__content {
    display: block; } }

.dropdown:hover .dropdown__content {
  padding: 40px 0 70px;
  animation-delay: 0s; }

.dropdown__desc {
  display: none; }

.dropdown__content {
  display: none;
  animation-delay: 0.3s; }

.dropdown__item a {
  display: flex;
  align-items: center;
  padding: 4.66667vw 0;
  color: #222;
  font-size: 4vw;
  border-bottom: 1px solid #e3e2df;
  position: relative; }
  .dropdown__item a .icon-arrow {
    position: absolute;
    right: 0; }

.menu-desc-sp {
  margin: 8.66667vw 5.33333vw 0; }
  .menu-desc-sp a {
    position: relative;
    display: block;
    padding: 4vw;
    padding-right: 6.66667vw;
    color: #fff;
    font-weight: bold;
    font-size: 4vw;
    text-align: center;
    text-decoration: none;
    background: #e29f92;
    border-radius: 100px; }
    .menu-desc-sp a .icon-arrow {
      position: absolute;
      top: 50%;
      right: 4vw;
      display: auto;
      transform: translate3d(0, -50%, 0); }

@media (min-width: 769px) {
  .header {
    position: absolute; }
    .header.hide {
      display: none;
      transform: translateY(-100%); }
    .header__bg {
      width: 100%;
      position: absolute;
      background: #fff;
      transition: height 1s ease;
      height: 0; }
    .header__inner {
      height: auto;
      margin: auto;
      padding: 0 3.47222vw; }
    .header__logo {
      width: 14.23611vw;
      padding: 0.90278vw 0; }
      .header__logo img {
        max-width: 100%; }
    .header.scrolled {
      display: block;
      position: fixed;
      transform: translateY(-100%); }
      .header.scrolled.show {
        transform: translateY(0);
        transition: all 0.3s ease; }
      .header.scrolled .header__inner {
        max-width: 100%;
        flex-wrap: wrap; }
      .header.scrolled .header__logo {
        width: 14.23611vw;
        padding: 0.90278vw 0; }
      .header.scrolled .menu-desc__navlink {
        position: absolute;
        top: 0;
        right: 0;
        width: 13.05556vw;
        height: calc(100% - 1.80556vw);
        margin: 0.90278vw;
        margin-left: 3.47222vw;
        margin-right: 3.47222vw;
        line-height: 1.3; }
        .header.scrolled .menu-desc__navlink .icon-arrow {
          margin-left: auto;
          margin-right: 0; }
      .header.scrolled .menu {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-right: 14.58333vw; }
        .header.scrolled .menu.en {
          margin-right: 0;
          width: calc(100% - 14.23611vw);
          justify-content: flex-start;
          padding-left: 31.04167vw; }
          .header.scrolled .menu.en .menu-desc {
            margin-left: auto;
            margin-right: 0; }
            .header.scrolled .menu.en .menu-desc .menu-desc__lang {
              font-size: 1.25vw;
              margin-top: 0;
              margin-right: 0; }
        .header.scrolled .menu .nav {
          padding-top: 1.94444vw; }
          .header.scrolled .menu .nav .nav-link {
            padding-bottom: 1.94444vw; }
  .en .menu-desc .menu-desc__lang {
    margin-right: 0; }
  .menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    align-self: start;
    margin-left: auto; }
    .menu__trigger {
      display: none; }
  .menu-desc {
    display: flex;
    align-items: center;
    margin-top: 1.38889vw;
    margin-bottom: 1.11111vw; }
    .menu-desc__lang {
      margin-left: 1.38889vw;
      font-size: 1.11111vw;
      margin-top: 0.13889vw;
      margin-right: 1.38889vw; }
      .menu-desc__lang a {
        display: inline-block;
        color: #ccc;
        text-decoration: none; }
        .menu-desc__lang a:first-child:after {
          padding: 0 0.48611vw;
          content: "/"; }
      .menu-desc__lang .active {
        display: inline-block;
        color: #333; }
    .menu-desc__navlink {
      position: relative;
      display: flex;
      align-items: center;
      padding: 1.04167vw 1.73611vw;
      padding-right: 1.73611vw;
      color: #fff;
      font-weight: bold;
      font-size: 1.04167vw;
      text-decoration: none;
      background: #e29f92;
      border: 1px solid #e29f92;
      border-radius: 100px;
      transition: all .2s; }
      .menu-desc__navlink span {
        color: #fff; }
      .menu-desc__navlink .icon-arrow {
        margin-left: 1.38889vw; }
  .icon-arrow {
    position: relative;
    display: inline-block;
    width: 0.83333vw;
    height: 0.83333vw; }
    .icon-arrow:after {
      position: absolute;
      top: 0;
      top: 0;
      left: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: url("/img/i-arrow-right-green.svg") center/contain no-repeat;
      content: ""; }
    .icon-arrow.arrow-green:after {
      background-image: url("/img/i-arrow-right-green.svg"); }
    .icon-arrow.arrow-white:after {
      background-image: url("/img/i-arrow-right-white.svg"); }
  .arrow-hover:hover .icon-arrow:after {
    animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
  .nav {
    position: static;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    margin: 0 -1.38889vw;
    padding-top: 0vw;
    padding-bottom: 0vw;
    overflow: auto;
    background: #fff;
    opacity: 1; }
    .nav a {
      text-decoration: none; }
    .nav .nav-item {
      display: inline-block;
      margin: 0 0;
      background: linear-gradient(#65b393, #65b393) 0 100%/0 0.20833vw no-repeat;
      background-position: 1.04167vw 100%;
      transition: background 0.4s cubic-bezier(0.4, 0.1, 0.3, 1); }
      .nav .nav-item:hover {
        background-size: calc(100% - 2.08333vw) 0.20833vw; }
        .nav .nav-item:hover .nav-link {
          color: #65b393; }
    .nav .nav-link {
      display: inline-flex;
      align-items: center;
      padding: 0vw 1.04167vw 1.52778vw;
      font-weight: bold;
      font-size: 1.11111vw;
      font-family: "Noto Sans JP", sans-serif;
      line-height: 1.2;
      transition: all .3s; }
      .nav .nav-link > .icon-arrow {
        display: none; }
      .nav .nav-link .icon-arrow {
        display: none;
        margin-right: 1.38889vw; }
  .icon-dropdown {
    display: none; }
  .dropdown-bg {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: inset 0 10px 9px -10px rgba(0, 0, 0, 0.1);
    padding: 0;
    height: 19.44444vw;
    max-height: 0;
    transition: all 0.1s ease;
    transition-delay: 0.3s;
    overflow: hidden;
    display: block; } }
  @media (min-width: 769px) and (min-width: 1441px) {
    .dropdown-bg {
      height: 280px; } }

@media (min-width: 769px) {
    .dropdown-bg.display {
      max-height: 19.44444vw;
      transition-delay: 0s; } }
    @media (min-width: 769px) and (min-width: 1441px) {
      .dropdown-bg.display {
        max-height: 280px; } }

@media (min-width: 769px) {
  .dropdown:hover .dropdown__content {
    z-index: 11 !important; }
  .dropdown:hover .dropdown__content {
    display: block;
    padding: 2.77778vw 0 4.16667vw;
    animation-name: dropdown_content;
    animation-duration: 0.25s;
    max-height: 400px;
    z-index: 10;
    transition-delay: 0.1s; }
  .dropdown:hover .dropdown__desc {
    animation-name: dropdown_inside;
    animation-duration: .1s;
    animation-timing-function: ease-out; }
  .dropdown:hover .dropdown__list {
    animation-name: dropdown_inside;
    animation-duration: 0.25s;
    animation-timing-function: ease-out; }
  .dropdown__desc {
    display: block;
    margin-bottom: 1.73611vw;
    font-weight: bold;
    font-size: 2.08333vw;
    text-align: center; }
  .dropdown__content {
    position: absolute;
    top: 95%;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: inset 0 10px 9px -10px rgba(0, 0, 0, 0.1);
    padding: 0;
    max-height: 0;
    transition: all 0s ease;
    transition-delay: 0;
    overflow: hidden;
    display: block; }
  .dropdown__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 82.63889vw;
    margin: auto;
    padding: 0 1.38889vw; }
  .dropdown__item {
    flex-basis: 25%;
    padding: 0 1.38889vw; }
    .dropdown__item a {
      display: flex;
      padding: 1.38889vw 0;
      color: #222;
      font-weight: bold;
      font-size: 1.04167vw;
      border-bottom: 1px solid #e3e2df; }
      .dropdown__item a:hover {
        color: #65b393; }
      .dropdown__item a .icon-arrow {
        margin-left: auto; }
  .menu-desc-sp {
    display: none; } }

@media (min-width: 1441px) {
  .header__inner {
    padding: 0 50px; }
  .header__logo {
    width: 205px;
    padding: 13px 0; }
  .header.scrolled .header__inner {
    max-width: 100%; }
  .header.scrolled .header__logo {
    width: 205px;
    padding: 13px 0; }
  .header.scrolled .menu-desc__navlink {
    width: 188px;
    height: calc(100% - 26px);
    margin: 13px;
    margin-left: 50px;
    margin-right: 50px; }
    .header.scrolled .menu-desc__navlink .icon-arrow {
      margin-left: auto;
      margin-right: 0; }
  .header.scrolled .menu {
    margin-right: 220px; }
    .header.scrolled .menu.en {
      padding-left: 447px;
      width: calc(100% - 205px); }
      .header.scrolled .menu.en .menu-desc .menu-desc__lang {
        font-size: 18px;
        margin-top: 0;
        margin-right: 0; }
    .header.scrolled .menu .nav {
      padding-top: 28px; }
      .header.scrolled .menu .nav .nav-link {
        padding-bottom: 28px; }
  .en .menu-desc .menu-desc__lang {
    margin-right: 0; }
  .menu-desc {
    margin-top: 20px;
    margin-bottom: 16px; }
    .menu-desc__lang {
      margin-left: 20px;
      font-size: 16px;
      margin-top: 2px;
      margin-right: 20px; }
    .menu-desc__navlink {
      padding: 15px 25px;
      padding-right: 25px;
      font-size: 15px;
      border-radius: 100px; }
      .menu-desc__navlink .icon-arrow {
        margin-left: 20px; }
  .icon-arrow {
    width: 12px;
    height: 12px; }
  .nav {
    margin-right: 30px;
    padding-top: 28px; }
    .nav .nav-link {
      padding-bottom: 28px; }
  .nav {
    margin: 0 -20px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .nav .nav-item {
      margin: 0 0;
      background: linear-gradient(#65b393, #65b393) 0 100%/0 3px no-repeat;
      background-position: 20px 100%;
      transition: background 0.4s cubic-bezier(0.4, 0.1, 0.3, 1); }
      .nav .nav-item:hover {
        background-size: calc(100% - 40px) 3px; }
    .nav .nav-link {
      padding: 0px 20px 22px;
      font-size: 16px;
      line-height: 1.2; }
      .nav .nav-link .icon-arrow {
        margin-right: 20px; }
  .dropdown:hover .dropdown__content {
    padding: 40px 0 60px; }
  .dropdown__desc {
    margin-bottom: 25px;
    font-size: 30px; }
  .dropdown__content {
    padding: 0; }
  .dropdown__list {
    max-width: 1190px;
    padding: 0 20px; }
  .dropdown__item {
    padding: 0 20px; }
    .dropdown__item a {
      padding: 20px 0;
      font-size: 15px; } }

@-webkit-keyframes dropdown_content {
  0% {
    padding: 0;
    max-height: 0; }
  75% {
    max-height: 5000px;
    @media (min-width: 769px) {
      padding: 2.77778vw 0 34.72222vw; }
    @media (min-width: 1441px) {
      padding: 40px 0 500px; } }
  100% {
    max-height: 5000px;
    transform: scaleY(1);
    @media (min-width: 769px) {
      padding: 2.77778vw 0 4.86111vw; }
    @media (min-width: 1441px) {
      padding: 40px 0 70px; } } }

@keyframes dropdown_content {
  0% {
    padding: 0;
    max-height: 0; }
  75% {
    max-height: 5000px;
    @media (min-width: 769px) {
      padding: 2.77778vw 0 34.72222vw; }
    @media (min-width: 1441px) {
      padding: 40px 0 500px; } }
  100% {
    @media (min-width: 769px) {
      padding: 2.77778vw 0 4.86111vw; }
    @media (min-width: 1441px) {
      padding: 40px 0 70px; } } }

@-webkit-keyframes dropdown_inside {
  0% {
    opacity: 0;
    transform: translateY(30px); }
  50% {
    opacity: 0;
    transform: translateY(30px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes dropdown_inside {
  0% {
    opacity: 0;
    transform: translateY(30px); }
  50% {
    opacity: 0;
    transform: translateY(30px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.footer {
  line-height: 1.2;
  width: 100%;
  background: #fff;
  padding-top: 13.33333vw; }
  .footer__lang {
    font-weight: 500;
    font-size: 3.73333vw;
    font-family: "Inter", sans-serif;
    text-transform: uppercase; }
    @media (min-width: 769px) {
      .footer__lang {
        margin-left: 1.38889vw;
        font-size: 0.90278vw;
        margin-top: 0;
        font-weight: bold;
        display: inline-block; } }
    @media (min-width: 1441px) {
      .footer__lang {
        margin-left: 20px;
        font-size: 13px;
        margin-top: 0; } }
    .footer__lang a {
      display: block;
      text-decoration: none; }
      @media (min-width: 769px) {
        .footer__lang a {
          display: inline-block;
          color: #ccc;
          text-decoration: none; } }
      @media (min-width: 769px) {
        .footer__lang a:first-child:after {
          padding: 0 0.48611vw;
          content: "/"; } }
      @media (min-width: 1441px) {
        .footer__lang a:first-child:after {
          padding: 0 7px;
          content: "/"; } }
    .footer__lang .active {
      display: none; }
      @media (min-width: 769px) {
        .footer__lang .active {
          display: inline-block;
          color: #333; } }
  .footer__inner {
    padding: 0 5.33333vw 8vw; }
  .footer__logo {
    width: 60.13333vw;
    margin-bottom: 5.33333vw; }
    .footer__logo img {
      max-width: 100%;
      width: 100%;
      margin-top: 0 !important; }
  .footer__addr {
    margin-bottom: 5.33333vw;
    font-size: 3.46667vw;
    line-height: 6.4vw;
    color: #666; }
  .footer__nav li {
    display: inline-block;
    min-width: 34.66667vw; }
  .footer__link {
    font-weight: bold;
    font-size: 2.66667vw;
    line-height: 8.8vw;
    text-decoration: none; }
    .footer__link:hover {
      color: #65b393; }
  .footer__banner {
    display: flex;
    margin: 6.66667vw -2.66667vw 12.66667vw; }
    .footer__banner-img {
      width: 50%;
      padding: 0 2.66667vw; }
      .footer__banner-img img {
        max-width: 100%;
        width: 100%;
        margin-top: 0 !important; }
  .footer__copyright {
    color: #666;
    font-size: 2.66667vw;
    font-family: "Inter", sans-serif;
    line-height: 2;
    text-align: center; }

.go-top {
  position: fixed;
  right: 2.66667vw;
  bottom: 2.66667vw;
  z-index: 999;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 13.33333vw;
  height: 13.33333vw;
  color: #fff;
  font-size: 2.13333vw;
  text-decoration: none;
  background: #65b393;
  border-radius: 100px; }
  .go-top span.text {
    display: inline-block;
    margin-top: 0.66667vw;
    color: #fff; }

.icon-arrow-up {
  position: relative;
  display: inline-block;
  width: 3.33333vw;
  height: 3.33333vw; }
  .icon-arrow-up:after {
    position: absolute;
    top: 0;
    top: 0;
    left: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url("/img/i-arrow-right-white.svg") center/contain no-repeat;
    transform: rotate(-90deg);
    content: ""; }
  .icon-arrow-up.arrow-white:after {
    background-image: url("/img/i-arrow-right-white.svg");
    transform: rotate(-90deg); }

.arrow-up-hover:hover {
  opacity: 1 !important; }
  .arrow-up-hover:hover .icon-arrow-up:after {
    animation: arrowSlideUp 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }

@media (min-width: 769px) {
  .footer {
    z-index: 999;
    padding-top: 3.47222vw;
    position: relative; }
    .footer__inner {
      margin: auto;
      padding: 0 3.47222vw 1.38889vw; }
    .footer__content {
      display: flex;
      margin-bottom: 0; }
    .footer__left {
      width: 39.09722vw;
      padding-top: 0.55556vw; }
    .footer__logo {
      width: 16.38889vw;
      margin-bottom: 1.73611vw; }
    .footer__addr {
      margin-bottom: 0vw;
      font-size: 0.90278vw;
      line-height: 1.38889vw; }
    .footer__right {
      margin-left: auto; }
    .footer__nav {
      display: inline-block; }
      .footer__nav li {
        min-width: auto;
        margin-left: 3.125vw; }
        .footer__nav li:first-child {
          margin-left: 0; }
    .footer__link {
      font-size: 0.90278vw;
      line-height: 1.94444vw; }
    .footer__banner {
      display: flex;
      margin: 2.08333vw -0.69444vw 0vw; }
      .footer__banner-img {
        width: auto;
        max-width: 15.97222vw;
        padding: 0 0.69444vw; }
      .footer__banner a {
        transition: all 0.3s ease; }
        .footer__banner a:hover {
          opacity: 0.6; }
    .footer__copyright {
      font-size: 0.76389vw;
      text-align: left;
      margin-top: 2.08333vw; }
  .go-top {
    right: 1.38889vw;
    bottom: 1.38889vw;
    width: 4.16667vw;
    height: 4.16667vw;
    font-size: 0.90278vw; }
    .go-top.fixed {
      position: absolute;
      top: -5.55556vw; }
    .go-top span.text {
      margin-top: 0.48611vw; }
  .icon-arrow-up {
    width: 0.83333vw;
    height: 0.83333vw; } }

@media (min-width: 1441px) {
  .footer {
    padding-top: 50px; }
    .footer__inner {
      margin: auto;
      padding: 0 50px 20px; }
    .footer__content {
      display: flex; }
    .footer__left {
      width: 563px;
      padding-top: 8px; }
    .footer__logo {
      width: 236px;
      margin-bottom: 25px; }
    .footer__addr {
      margin-bottom: 0px;
      font-size: 13px;
      line-height: 20px; }
    .footer__right {
      margin-left: auto; }
    .footer__nav li {
      min-width: auto;
      margin-left: 45px; }
      .footer__nav li:first-child {
        margin-left: 0; }
    .footer__link {
      font-size: 13px;
      line-height: 28px; }
    .footer__banner {
      display: flex;
      margin: 30px -10px 0px; }
      .footer__banner-img {
        width: auto;
        max-width: 230px;
        padding: 0 10px; }
    .footer__copyright {
      font-size: 11px;
      text-align: left;
      margin-top: 30px; }
  .go-top {
    right: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    font-size: 13px; }
    .go-top.fixed {
      position: absolute;
      top: -80px; }
    .go-top span.text {
      margin-top: 7px; }
  .icon-arrow-up {
    width: 12px;
    height: 12px; } }

section {
  position: relative;
  width: 100%;
  padding-bottom: 18.66667vw; }
  @media (min-width: 769px) {
    section {
      padding-bottom: 6.94444vw; } }
  @media (min-width: 1441px) {
    section {
      padding-bottom: 100px; } }
  section.flex-box {
    display: flex;
    flex-wrap: wrap;
    background: #fafaf7; }
    @media (min-width: 769px) {
      section.flex-box {
        background: none; } }
  section.book-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: unset;
    padding-bottom: 0;
    overflow: hidden; }
    section.book-box .book-wrap {
      width: 100%; }
      @media (min-width: 769px) {
        section.book-box .book-wrap {
          margin: auto;
          display: flex;
          flex-wrap: wrap; } }
      section.book-box .book-wrap .right-box {
        background: #fafaf7;
        padding-bottom: 18.66667vw; }
        @media (min-width: 769px) {
          section.book-box .book-wrap .right-box {
            padding-bottom: 6.94444vw; } }
        @media (min-width: 1441px) {
          section.book-box .book-wrap .right-box {
            padding-bottom: 100px; }
            section.book-box .book-wrap .right-box:after {
              display: block;
              content: "";
              height: 100%;
              position: absolute;
              top: 0;
              width: calc((100vw - 1440px)/2);
              right: calc((-100vw + 1440px)/2);
              background: #fafaf7; } }
  section.first-box {
    padding-top: 18.66667vw; }
    @media (min-width: 769px) {
      section.first-box {
        padding-top: 10.41667vw; } }
    @media (min-width: 1441px) {
      section.first-box {
        padding-top: 150px; } }
    @media (min-width: 769px) {
      section.first-box .left-box {
        padding-top: 10.41667vw;
        display: block; } }
    @media (min-width: 1441px) {
      section.first-box .left-box {
        padding-top: 150px; } }
    section.first-box .right-box {
      padding-top: 12vw; }
      @media (min-width: 769px) {
        section.first-box .right-box {
          padding-top: 20vh;
          height: calc(100vh - 10.41667vw); } }
      @media (min-width: 1441px) {
        section.first-box .right-box {
          padding-top: 25vh;
          height: calc(100vh - 150px); } }
      section.first-box .right-box .content-block {
        padding: 0 5.33333vw 6.66667vw; }
        @media (min-width: 769px) {
          section.first-box .right-box .content-block {
            padding: 0 0 3.47222vw; } }
        @media (min-width: 1441px) {
          section.first-box .right-box .content-block {
            padding: 0 0 50px; } }
        @media (min-width: 769px) {
          section.first-box .right-box .content-block p {
            font-size: 1.45833vw; } }
        @media (min-width: 1441px) {
          section.first-box .right-box .content-block p {
            font-size: 21px; } }
  section.gray-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: #bdb5a3;
    margin-bottom: 18.66667vw;
    padding-bottom: 0; }
    @media (min-width: 769px) {
      section.gray-box {
        margin-bottom: 2.08333vw;
        max-width: 100%; } }
    @media (min-width: 1441px) {
      section.gray-box {
        margin-bottom: 30px; } }
    @media (min-width: 769px) {
      section.gray-box.last {
        margin-bottom: 0; } }
    @media (min-width: 1441px) {
      section.gray-box.last {
        margin-bottom: 0; } }
    section.gray-box .wrap-content {
      width: 100%; }
      @media (min-width: 769px) {
        section.gray-box .wrap-content {
          margin: auto; } }
    section.gray-box .scroll-text {
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      overflow: hidden; }
      section.gray-box .scroll-text:last-child span:first-child {
        margin-left: -200vw; }
        @media (min-width: 769px) {
          section.gray-box .scroll-text:last-child span:first-child {
            margin-left: -104.16667vw; } }
        @media (min-width: 1441px) {
          section.gray-box .scroll-text:last-child span:first-child {
            margin-left: -1500px; } }
      section.gray-box .scroll-text span {
        font-size: 29.33333vw;
        display: block;
        width: auto;
        white-space: nowrap;
        line-height: 0.9;
        margin-right: 6.66667vw;
        color: #b7af9d;
        font-family: 'Inter', sans-serif;
        font-weight: bold; }
        @media (min-width: 769px) {
          section.gray-box .scroll-text span {
            font-size: 15.27778vw;
            margin-right: 4.86111vw; } }
        @media (min-width: 1441px) {
          section.gray-box .scroll-text span {
            font-size: 220px;
            margin-right: 70px; } }
    section.gray-box .box-items {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 14.66667vw 5.33333vw 10.66667vw; }
      @media (min-width: 769px) {
        section.gray-box .box-items {
          max-width: 1440px;
          margin: auto;
          padding: 5.55556vw 6.94444vw 6.94444vw; } }
      @media (min-width: 1441px) {
        section.gray-box .box-items {
          padding: 80px 100px 100px; } }
      section.gray-box .box-items a {
        width: 100%;
        text-decoration: none; }
      section.gray-box .box-items .item {
        width: 100%;
        margin-top: 14.66667vw; }
        @media (min-width: 769px) {
          section.gray-box .box-items .item {
            width: 50%;
            margin-top: 0;
            padding: 0 35px; } }
        section.gray-box .box-items .item:first-child {
          margin-top: 0; }
        section.gray-box .box-items .item a {
          width: 100%; }
          section.gray-box .box-items .item a:hover .item-img img {
            opacity: 0.6; }
          section.gray-box .box-items .item a:hover .item-title:after {
            animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
        section.gray-box .box-items .item .item-img {
          width: 100%;
          position: relative;
          display: block;
          overflow: hidden; }
          section.gray-box .box-items .item .item-img img {
            max-width: 100%;
            width: 100%;
            transition: all 0.5s ease; }
        section.gray-box .box-items .item .item-title {
          position: relative;
          width: 100%;
          height: 14.66667vw;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-weight: bold;
          color: #fff;
          font-size: 5.06667vw;
          border-bottom: 0.53333vw solid #fff; }
          @media (min-width: 769px) {
            section.gray-box .box-items .item .item-title {
              height: 5.55556vw;
              font-size: 1.38889vw;
              border-bottom: 0.13889vw solid #fff; } }
          @media (min-width: 1441px) {
            section.gray-box .box-items .item .item-title {
              height: 80px;
              font-size: 20px;
              border-bottom: 2px solid #fff; } }
          section.gray-box .box-items .item .item-title:after {
            content: "";
            display: block;
            position: absolute;
            width: 2.8vw;
            height: 3.06667vw;
            background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
            background-size: 99%;
            top: 5.86667vw;
            right: 0; }
            @media (min-width: 769px) {
              section.gray-box .box-items .item .item-title:after {
                width: 0.76389vw;
                height: 0.83333vw;
                top: 2.36111vw;
                right: 0; } }
            @media (min-width: 1441px) {
              section.gray-box .box-items .item .item-title:after {
                width: 11px;
                height: 12px;
                top: 34px; } }
  section .img-box {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 12vw; }
    @media (min-width: 769px) {
      section .img-box {
        opacity: 0; } }
    @media (min-width: 769px) {
      section .img-box.absolute {
        position: absolute;
        bottom: 0;
        top: auto;
        padding: 0;
        height: auto; } }
    @media (min-width: 769px) {
      section .img-box {
        width: 41.66667vw;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 5.41667vw;
        padding-bottom: 0;
        top: 0;
        height: 100vh;
        position: fixed;
        opacity: 0;
        background: #fff;
        pointer-events: none; } }
    @media (min-width: 769px) {
      section .img-box.show {
        transition: opacity 1s ease;
        opacity: 0.7; } }
    @media (min-width: 1441px) {
      section .img-box {
        padding-top: 78px;
        width: 600px; } }
    section .img-box img {
      max-width: 100%; }
      @media (min-width: 769px) {
        section .img-box img {
          width: 100%;
          height: calc(100vh - 5.41667vw);
          object-fit: cover; } }
      @media (min-width: 1441px) {
        section .img-box img {
          height: calc(100vh - 78px); } }
  section .left-box {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 5.33333vw;
    min-height: 103.33333vw;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #ecece7;
    transition: none; }
    section .left-box.show {
      opacity: 1;
      transition: opacity 1s ease; }
    @media (min-width: 769px) {
      section .left-box {
        padding: 0 3.47222vw;
        height: 100vh;
        position: fixed;
        top: 0;
        width: 41.66667vw;
        min-height: auto;
        align-items: flex-start;
        justify-content: flex-start;
        background: none;
        opacity: 0; } }
    @media (min-width: 1441px) {
      section .left-box {
        min-height: auto;
        padding: 0 50px;
        width: 600px; } }
    section .left-box .title {
      font-size: 7.46667vw;
      font-weight: normal;
      line-height: 1.5; }
      @media (min-width: 769px) {
        section .left-box .title {
          padding-top: 4.86111vw;
          font-size: 2.84722vw; } }
      @media (min-width: 1441px) {
        section .left-box .title {
          padding-top: 70px;
          font-size: 41px; } }
  section .right-box {
    position: relative;
    display: block;
    padding: 0 5.33333vw 0;
    width: 100%; }
    @media (min-width: 769px) {
      section .right-box {
        width: calc(100% - 41.66667vw);
        padding: 4.86111vw 6.94444vw 0;
        background: none;
        margin-left: 41.66667vw; } }
    @media (min-width: 1441px) {
      section .right-box {
        width: calc(100% - 600px);
        padding: 70px 100px 0;
        margin-left: 600px; } }
    section .right-box .content-block {
      padding: 0 0 6.66667vw; }
      @media (min-width: 769px) {
        section .right-box .content-block {
          padding: 0 0 3.47222vw; } }
      @media (min-width: 1441px) {
        section .right-box .content-block {
          padding: 0 0 50px; } }
      section .right-box .content-block .head-content {
        font-size: 6.4vw;
        font-weight: 600;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
        @media (min-width: 769px) {
          section .right-box .content-block .head-content {
            font-size: 2.5vw;
            line-height: 1.5; } }
        @media (min-width: 1441px) {
          section .right-box .content-block .head-content {
            font-size: 36px; } }
        section .right-box .content-block .head-content .dash {
          position: relative;
          display: inline-block;
          width: 18.66667vw;
          height: 0.26667vw;
          background: #222;
          margin-right: 4vw; }
          @media (min-width: 769px) {
            section .right-box .content-block .head-content .dash {
              width: 5.55556vw;
              height: 0.06944vw;
              margin-right: 0.69444vw; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .head-content .dash {
              width: 80px;
              height: 1px;
              margin-right: 10px; } }
      section .right-box .content-block p {
        font-size: 4.26667vw;
        line-height: 2.3;
        font-weight: 400;
        font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
        margin-top: 5.33333vw; }
        @media (min-width: 769px) {
          section .right-box .content-block p {
            font-size: 1.04167vw;
            margin-top: 1.73611vw; } }
        @media (min-width: 1441px) {
          section .right-box .content-block p {
            font-size: 15px;
            margin-top: 25px; } }
        section .right-box .content-block p:first-child {
          margin-top: 0; }
      section .right-box .content-block img {
        max-width: 100%; }
      section .right-box .content-block .bussiness-list {
        margin-top: 10.66667vw; }
        @media (min-width: 769px) {
          section .right-box .content-block .bussiness-list {
            margin-top: 3.47222vw; } }
        @media (min-width: 1441px) {
          section .right-box .content-block .bussiness-list {
            margin-top: 50px; } }
      section .right-box .content-block .bussiness-item {
        margin-top: 12vw; }
        @media (min-width: 769px) {
          section .right-box .content-block .bussiness-item {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-top: 2.77778vw; } }
        @media (min-width: 1441px) {
          section .right-box .content-block .bussiness-item {
            margin-top: 40px; } }
        section .right-box .content-block .bussiness-item:first-child {
          margin-top: 0; }
        section .right-box .content-block .bussiness-item a {
          text-decoration: none;
          width: 100%;
          display: flex;
          flex-wrap: wrap; }
          section .right-box .content-block .bussiness-item a:hover .business-image {
            opacity: 0.6; }
        @media (min-width: 769px) {
          section .right-box .content-block .bussiness-item .business-content {
            width: calc(100% - 17.22222vw);
            padding-left: 2.08333vw; } }
        @media (min-width: 1441px) {
          section .right-box .content-block .bussiness-item .business-content {
            width: calc(100% - 248px);
            padding-left: 30px; } }
        section .right-box .content-block .bussiness-item .head-item {
          margin-top: 6.66667vw;
          font-size: 5.06667vw;
          font-weight: 600;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center; }
          @media (min-width: 769px) {
            section .right-box .content-block .bussiness-item .head-item {
              font-size: 1.38889vw;
              margin-top: 0; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .bussiness-item .head-item {
              font-size: 20px; } }
        section .right-box .content-block .bussiness-item p {
          font-size: 3.73333vw;
          line-height: 2;
          font-weight: 400;
          font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
          margin-top: 4vw; }
          @media (min-width: 769px) {
            section .right-box .content-block .bussiness-item p {
              font-size: 0.97222vw;
              margin-top: 1.38889vw; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .bussiness-item p {
              font-size: 14px;
              margin-top: 20px; } }
          section .right-box .content-block .bussiness-item p:first-child {
            margin-top: 0; }
        section .right-box .content-block .bussiness-item .business-image {
          position: relative;
          display: block;
          opacity: 1;
          transition: all 0.3s ease; }
          @media (min-width: 769px) {
            section .right-box .content-block .bussiness-item .business-image {
              width: 17.22222vw;
              height: 11.59722vw; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .bussiness-item .business-image {
              width: 248px;
              height: 167px; } }
        @media (min-width: 769px) {
          section .right-box .content-block .bussiness-item img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
      section .right-box .content-block .book-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-radius: 2.66667vw;
        background: #fff;
        margin-top: 28vw;
        padding: 24vw 0 10.66667vw;
        margin-bottom: 4vw;
        box-shadow: 0 0 2.66667vw 0 rgba(0, 0, 0, 0.1); }
        @media (min-width: 769px) {
          section .right-box .content-block .book-item {
            box-shadow: none;
            border-radius: 0.69444vw;
            margin-top: 6.25vw;
            padding: 1.73611vw 2.43056vw 2.43056vw 17.36111vw;
            min-height: 13.88889vw;
            margin-bottom: 0; } }
        @media (min-width: 1441px) {
          section .right-box .content-block .book-item {
            border-radius: 10px;
            margin-top: 90px;
            padding: 25px 35px 35px 250px;
            min-height: 200px; } }
        section .right-box .content-block .book-item a {
          text-decoration: none;
          width: 100%; }
        section .right-box .content-block .book-item .book-image {
          width: 28.26667vw;
          position: absolute;
          top: -20vw;
          left: calc((100% - 28.26667vw) / 2); }
          @media (min-width: 769px) {
            section .right-box .content-block .book-item .book-image {
              width: 11.11111vw;
              top: -3.81944vw;
              left: 2.43056vw; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .book-item .book-image {
              width: 160px;
              top: -55px;
              left: 35px; } }
          section .right-box .content-block .book-item .book-image img {
            width: 100%; }
        section .right-box .content-block .book-item .book-detail {
          width: 100%;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 0 8vw; }
          @media (min-width: 769px) {
            section .right-box .content-block .book-item .book-detail {
              padding: 0; } }
          section .right-box .content-block .book-item .book-detail .book_tag {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 4vw 0.26667vw;
            height: 6.66667vw;
            color: #fff;
            font-size: 3.46667vw;
            border-radius: 6.66667vw;
            background: #e29f92;
            line-height: 1; }
            @media (min-width: 769px) {
              section .right-box .content-block .book-item .book-detail .book_tag {
                padding: 0 1.04167vw;
                height: 1.80556vw;
                font-size: 0.90278vw;
                border-radius: 0.90278vw;
                position: absolute;
                bottom: 0.34722vw;
                right: -1.04167vw; } }
            @media (min-width: 1441px) {
              section .right-box .content-block .book-item .book-detail .book_tag {
                padding: 0 15px;
                height: 26px;
                font-size: 13px;
                border-radius: 13px;
                bottom: 5px;
                right: -15px; } }
          section .right-box .content-block .book-item .book-detail .book_title {
            display: flex;
            position: relative;
            margin-left: 2.66667vw;
            font-size: 4vw; }
            @media (min-width: 769px) {
              section .right-box .content-block .book-item .book-detail .book_title {
                font-size: 1.04167vw;
                margin-left: 0; } }
            @media (min-width: 1441px) {
              section .right-box .content-block .book-item .book-detail .book_title {
                font-size: 15px; } }
        section .right-box .content-block .book-item .book-title {
          width: 100%;
          padding: 0 8vw;
          position: relative;
          display: flex;
          margin-top: 4vw;
          font-size: 5.33333vw;
          font-weight: 600; }
          @media (min-width: 769px) {
            section .right-box .content-block .book-item .book-title {
              padding: 0;
              font-size: 1.52778vw;
              margin-top: 1.73611vw; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .book-item .book-title {
              font-size: 22px;
              margin-top: 25px; } }
        section .right-box .content-block .book-item:hover {
          box-shadow: 0 0 2.66667vw 0 rgba(0, 0, 0, 0.1); }
          @media (min-width: 769px) {
            section .right-box .content-block .book-item:hover {
              box-shadow: 0 0 1.38889vw 0 rgba(0, 0, 0, 0.1); } }
          @media (min-width: 1441px) {
            section .right-box .content-block .book-item:hover {
              box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); } }
          section .right-box .content-block .book-item:hover:after {
            animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
        section .right-box .content-block .book-item:after {
          content: "";
          display: block;
          position: absolute;
          width: 2.8vw;
          height: 3.06667vw;
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: 99%;
          bottom: 4vw;
          right: 4vw; }
          @media (min-width: 769px) {
            section .right-box .content-block .book-item:after {
              width: 0.76389vw;
              height: 0.83333vw;
              bottom: 1.38889vw;
              right: 1.38889vw; } }
          @media (min-width: 1441px) {
            section .right-box .content-block .book-item:after {
              width: 11px;
              height: 12px;
              bottom: 20px;
              right: 20px; } }
      section .right-box .content-block .article {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 3.47222vw; }
        @media (min-width: 1441px) {
          section .right-box .content-block .article {
            margin-top: 50px; } }
        section .right-box .content-block .article .article-head {
          position: relative;
          display: flex;
          width: 100%;
          font-size: 1.38889vw;
          font-weight: bold; }
        section .right-box .content-block .article .article-list {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 2.77778vw; }
          @media (min-width: 1441px) {
            section .right-box .content-block .article .article-list {
              margin-top: 40px; } }
          section .right-box .content-block .article .article-list .article-item {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin-top: 2.08333vw;
            border-bottom: 1px solid #eae9e6;
            padding-bottom: 2.08333vw; }
            @media (min-width: 1441px) {
              section .right-box .content-block .article .article-list .article-item {
                margin-top: 30px;
                padding-bottom: 30px; } }
            section .right-box .content-block .article .article-list .article-item:first-child {
              margin-top: 0; }
            section .right-box .content-block .article .article-list .article-item:hover .article-img img {
              opacity: 0.6; }
            section .right-box .content-block .article .article-list .article-item a {
              display: flex;
              flex-wrap: wrap;
              text-decoration: none; }
            section .right-box .content-block .article .article-list .article-item .article-img {
              position: relative;
              display: block;
              overflow: hidden;
              width: 10.41667vw;
              height: 10.41667vw; }
              @media (min-width: 1441px) {
                section .right-box .content-block .article .article-list .article-item .article-img {
                  width: 150px;
                  height: 150px; } }
              section .right-box .content-block .article .article-list .article-item .article-img img {
                width: 100%;
                height: 100%;
                transition: all .5s ease; }
            section .right-box .content-block .article .article-list .article-item .article-content {
              width: calc(100% - 10.41667vw);
              display: flex;
              flex-wrap: wrap;
              padding-left: 2.08333vw; }
              @media (min-width: 1441px) {
                section .right-box .content-block .article .article-list .article-item .article-content {
                  width: calc(100% - 150px);
                  padding-left: 30px; } }
              section .right-box .content-block .article .article-list .article-item .article-content .content-tag {
                display: flex;
                flex-wrap: wrap;
                width: 100%; }
                section .right-box .content-block .article .article-list .article-item .article-content .content-tag .vol {
                  font-family: 'Inter', sans-serif;
                  font-weight: normal;
                  font-size: 0.90278vw;
                  margin-right: 0.34722vw;
                  line-height: 1.38889vw; }
                  @media (min-width: 1441px) {
                    section .right-box .content-block .article .article-list .article-item .article-content .content-tag .vol {
                      font-size: 13px;
                      margin-right: 5px;
                      line-height: 20px; } }
                section .right-box .content-block .article .article-list .article-item .article-content .content-tag .tag {
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  line-height: 1;
                  background: #e3e3e3;
                  color: #888888; }
                  section .right-box .content-block .article .article-list .article-item .article-content .content-tag .tag:hover {
                    background: #65b393;
                    color: #fff; }
                  @media (min-width: 769px) {
                    section .right-box .content-block .article .article-list .article-item .article-content .content-tag .tag {
                      margin-left: 0.34722vw;
                      height: 1.38889vw;
                      padding: 0 0.69444vw 0;
                      border-radius: 0.69444vw;
                      font-size: 0.76389vw; } }
                  @media (min-width: 1441px) {
                    section .right-box .content-block .article .article-list .article-item .article-content .content-tag .tag {
                      margin-left: 5px;
                      height: 20px;
                      padding: 0 10px 0;
                      border-radius: 10px;
                      font-size: 11px; } }
              section .right-box .content-block .article .article-list .article-item .article-content .content-title {
                font-size: 1.18056vw;
                font-weight: bold; }
                @media (min-width: 1441px) {
                  section .right-box .content-block .article .article-list .article-item .article-content .content-title {
                    font-size: 17px; } }
              section .right-box .content-block .article .article-list .article-item .article-content .content-detail {
                font-size: 0.97222vw;
                font-weight: normal;
                margin-top: 0; }
                @media (min-width: 1441px) {
                  section .right-box .content-block .article .article-list .article-item .article-content .content-detail {
                    font-size: 14px;
                    margin-top: 0; } }
    section .right-box .button-block {
      position: relative;
      display: flex;
      width: 100%; }
      @media (min-width: 769px) {
        section .right-box .button-block {
          justify-content: flex-end; } }
      section .right-box .button-block a {
        width: 100%;
        height: 14.66667vw;
        border-radius: 7.33333vw;
        background-color: #65b393;
        color: #fff;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
        font-weight: normal;
        font-size: 4vw;
        text-decoration: none; }
        @media (min-width: 769px) {
          section .right-box .button-block a {
            width: 16.66667vw;
            height: 3.47222vw;
            border-radius: 1.73611vw;
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          section .right-box .button-block a {
            width: 240px;
            height: 50px;
            border-radius: 25px;
            font-size: 15px; } }
        section .right-box .button-block a:hover:after {
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
        section .right-box .button-block a:after {
          content: "";
          display: block;
          position: absolute;
          width: 2.8vw;
          height: 3.06667vw;
          background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
          background-size: 99%;
          top: 5.86667vw;
          right: 4vw; }
          @media (min-width: 769px) {
            section .right-box .button-block a:after {
              width: 0.76389vw;
              height: 0.83333vw;
              top: 1.31944vw;
              right: 1.38889vw; } }
          @media (min-width: 1441px) {
            section .right-box .button-block a:after {
              width: 11px;
              height: 12px;
              top: 19px;
              right: 20px; } }
  section .message-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 5.33333vw;
    margin-top: 16vw;
    z-index: 999; }
    section .message-box.hide {
      opacity: 0; }
    @media (min-width: 769px) {
      section .message-box {
        position: absolute;
        padding: 0 3.47222vw;
        left: 0;
        width: 41.66667vw;
        bottom: 3.47222vw;
        opacity: 1;
        transition: opacity 0.5s ease; } }
    @media (min-width: 1441px) {
      section .message-box {
        position: absolute;
        padding: 0 50px;
        width: 600px;
        bottom: 50px; } }
    section .message-box .message-head {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: 4.26667vw; }
      @media (min-width: 769px) {
        section .message-box .message-head {
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        section .message-box .message-head {
          font-size: 16px; } }
    section .message-box .message-content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        section .message-box .message-content {
          margin-top: 0.69444vw; } }
      @media (min-width: 1441px) {
        section .message-box .message-content {
          margin-top: 10px; } }
      section .message-box .message-content a {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background: #fff;
        padding: 5.33333vw;
        text-decoration: none;
        font-size: 3.73333vw;
        line-height: 1.5;
        border-radius: 2.66667vw;
        font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif; }
        @media (min-width: 769px) {
          section .message-box .message-content a {
            padding: 1.38889vw;
            font-size: 1.11111vw;
            border-radius: 0.69444vw; } }
        @media (min-width: 1441px) {
          section .message-box .message-content a {
            padding: 20px;
            font-size: 16px;
            border-radius: 10px; } }
        section .message-box .message-content a:hover:after {
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
        section .message-box .message-content a:after {
          content: "";
          display: block;
          position: absolute;
          width: 2.8vw;
          height: 3.06667vw;
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: 99%;
          bottom: 2.66667vw;
          right: 2.66667vw; }
          @media (min-width: 769px) {
            section .message-box .message-content a:after {
              width: 0.76389vw;
              height: 0.83333vw;
              bottom: 1.04167vw;
              right: 1.04167vw; } }
          @media (min-width: 1441px) {
            section .message-box .message-content a:after {
              width: 11px;
              height: 12px;
              bottom: 15px;
              right: 15px; } }

.bg-left,
.bg-right {
  position: fixed;
  z-index: -1;
  height: 100vh;
  top: 0; }

.bg-left {
  width: 41.66667vw;
  left: 0;
  background: #ecece7; }
  @media (min-width: 1441px) {
    .bg-left {
      width: 600px; } }

.bg-right {
  width: calc(100% - 41.66667vw);
  right: 0;
  background: #fafaf7; }
  @media (min-width: 1441px) {
    .bg-right {
      width: calc(100% - 600px); } }

@keyframes pcScroll {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(calc(-100% - 6.66667vw));
    @media (min-width: 769px) {
      transform: translateX(calc(-100% - 4.86111vw)); }
    @media (min-width: 1441px) {
      transform: translateX(calc(-100% - 70px)); } } }

#top .start-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center; }
  #top .start-page img {
    max-width: 90%;
    opacity: 0; }
    @media (min-width: 769px) {
      #top .start-page img {
        max-width: 24.58333vw; } }
  @media (min-width: 769px) and (min-width: 1441px) {
    #top .start-page img {
      max-width: 354px; } }

.main {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 18.93333vw; }
  @media (min-width: 769px) {
    .main {
      padding-top: 8.75vw;
      min-height: calc(100vh - 16.31944vw); } }
  @media (min-width: 1441px) {
    .main {
      padding-top: 126px;
      min-height: calc(100vh - 235px); } }
  @media (min-width: 769px) {
    .main.en {
      padding-top: 6.80556vw;
      min-height: calc(100vh - 15.55556vw); } }
  @media (min-width: 1441px) {
    .main.en {
      padding-top: 98px;
      min-height: calc(100vh - 224px); } }
  .main.en.scroll {
    padding-top: 37.86667vw; }
    @media (min-width: 769px) {
      .main.en.scroll {
        padding-top: 5.48611vw; } }
    @media (min-width: 1441px) {
      .main.en.scroll {
        padding-top: 79px; } }
  @media (min-width: 769px) {
    .main.en .page-anchor {
      top: 6.80556vw; } }
  @media (min-width: 1441px) {
    .main.en .page-anchor {
      top: 98px; } }
  @media (min-width: 769px) {
    .main.en .page-anchor.scrolled {
      top: 5.41667vw; } }
  @media (min-width: 1441px) {
    .main.en .page-anchor.scrolled {
      top: 78px; } }
  .main.scroll {
    padding-top: 37.86667vw; }
    @media (min-width: 769px) {
      .main.scroll {
        padding-top: 5.48611vw; } }
    @media (min-width: 1441px) {
      .main.scroll {
        padding-top: 79px; } }
  .main .page-anchor {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    transition: all 0.5s ease;
    position: fixed;
    z-index: 999;
    top: 18.93333vw; }
    .main .page-anchor::-webkit-scrollbar {
      display: none; }
    .main .page-anchor ~ .content {
      padding-top: 18.93333vw; }
      @media (min-width: 769px) {
        .main .page-anchor ~ .content {
          padding-top: 5.41667vw; } }
      @media (min-width: 1441px) {
        .main .page-anchor ~ .content {
          padding-top: 78px; } }
    @media (min-width: 769px) {
      .main .page-anchor {
        background: #fff;
        justify-content: center;
        top: 8.75vw; } }
    @media (min-width: 1441px) {
      .main .page-anchor {
        top: 126px; } }
    @media (min-width: 769px) {
      .main .page-anchor.scrolled {
        top: 5.41667vw; } }
    @media (min-width: 1441px) {
      .main .page-anchor.scrolled {
        top: 78px; } }
    .main .page-anchor ul {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 5.33333vw;
      background: #fff; }
      @media (min-width: 769px) {
        .main .page-anchor ul {
          padding: 0 6.94444vw;
          width: 100%; } }
      @media (min-width: 1441px) {
        .main .page-anchor ul {
          padding: 0 100px; } }
      .main .page-anchor ul li {
        width: 42vw;
        height: 19.33333vw;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-bottom: 0.8vw solid transparent; }
        @media (min-width: 769px) {
          .main .page-anchor ul li {
            width: 100%;
            height: 5.55556vw;
            border-bottom: 0;
            background: linear-gradient(#65b393, #65b393) 0 100%/0 0.20833vw no-repeat;
            background-position: 0% 100%;
            transition: background 0.4s cubic-bezier(0.4, 0.1, 0.3, 1); } }
        @media (min-width: 1441px) {
          .main .page-anchor ul li {
            width: 100%;
            height: 80px;
            background: linear-gradient(#65b393, #65b393) 0 100%/0 3px no-repeat;
            background-position: 0% 100%;
            transition: background 0.4s cubic-bezier(0.4, 0.1, 0.3, 1); } }
        .main .page-anchor ul li.active {
          border-bottom: 0.8vw solid #65b393; }
          @media (min-width: 769px) {
            .main .page-anchor ul li.active {
              color: #65b393;
              border-bottom: 0.20833vw solid #65b393; }
              .main .page-anchor ul li.active:hover {
                background: none; } }
          @media (min-width: 1441px) {
            .main .page-anchor ul li.active {
              border-bottom: 3px solid #65b393; } }
        .main .page-anchor ul li:hover a {
          opacity: 0.6;
          transition: opacity 0.3s ease; }
        @media (min-width: 769px) {
          .main .page-anchor ul li:hover {
            background-size: 100% 0.20833vw; } }
        @media (min-width: 1441px) {
          .main .page-anchor ul li:hover {
            background-size: 100% 3px; } }
        .main .page-anchor ul li:last-child:after {
          display: none; }
        .main .page-anchor ul li:after {
          display: block;
          content: "";
          border-right: dotted 1px #b7b7b7;
          height: 8.93333vw;
          width: 0.13333vw;
          right: 0;
          top: 5.06667vw;
          position: absolute; }
          @media (min-width: 769px) {
            .main .page-anchor ul li:after {
              height: 2.5vw;
              width: 0.06944vw;
              right: 0;
              top: 1.73611vw; } }
          @media (min-width: 1441px) {
            .main .page-anchor ul li:after {
              height: 36px;
              width: 1px;
              right: 0;
              top: 25px; } }
        .main .page-anchor ul li a {
          font-size: 3.73333vw;
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media (min-width: 769px) {
            .main .page-anchor ul li a {
              font-size: 0.97222vw; } }
          @media (min-width: 1441px) {
            .main .page-anchor ul li a {
              font-size: 14px; } }
  .main iframe {
    max-width: 100%; }
  .main h1 {
    font-size: 7.46667vw;
    line-height: 1.5;
    font-weight: bold; }
  .main .breadcrumb {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 5.33333vw; }
    @media (min-width: 769px) {
      .main .breadcrumb {
        margin-top: 1.73611vw; } }
    @media (min-width: 1441px) {
      .main .breadcrumb {
        margin-top: 25px; } }
    .main .breadcrumb ul {
      position: relative;
      display: inline;
      flex-wrap: wrap;
      width: 100%; }
      .main .breadcrumb ul li {
        position: relative;
        display: inline;
        flex-wrap: wrap;
        padding-right: 0;
        margin-right: 2.66667vw; }
        @media (min-width: 769px) {
          .main .breadcrumb ul li {
            display: inline-flex;
            padding-right: 0;
            margin-right: 0.69444vw; } }
        @media (min-width: 1441px) {
          .main .breadcrumb ul li {
            padding-right: 0;
            margin-right: 10px; } }
        .main .breadcrumb ul li:last-child {
          margin-right: 0;
          padding-right: 0; }
          .main .breadcrumb ul li:last-child:after {
            display: none; }
        .main .breadcrumb ul li a, .main .breadcrumb ul li span {
          font-size: 3.46667vw;
          color: #222222;
          line-height: 2; }
          @media (min-width: 769px) {
            .main .breadcrumb ul li a, .main .breadcrumb ul li span {
              font-size: 0.90278vw; } }
          @media (min-width: 1441px) {
            .main .breadcrumb ul li a, .main .breadcrumb ul li span {
              font-size: 13px; } }
        .main .breadcrumb ul li a {
          color: #65b393;
          text-decoration: none;
          transition: all 0.3s ease;
          display: inline; }
          .main .breadcrumb ul li a:hover {
            opacity: 0.6; }
        .main .breadcrumb ul li:after {
          content: "＞";
          display: inline;
          position: relative;
          right: 0;
          font-size: 3.46667vw;
          color: #cccccc;
          line-height: 2;
          margin-left: 2.66667vw; }
          @media (min-width: 769px) {
            .main .breadcrumb ul li:after {
              font-size: 0.90278vw;
              margin-left: 1.38889vw; } }
          @media (min-width: 1441px) {
            .main .breadcrumb ul li:after {
              font-size: 13px;
              margin-left: 20px; } }
  .main .content {
    width: 100%;
    position: relative;
    padding: 0 5.33333vw 18.66667vw; }
    @media (min-width: 769px) {
      .main .content {
        padding: 0; } }
  .main .block-head {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 13.33333vw;
    padding: 5.33333vw;
    font-weight: bold;
    font-size: 5.06667vw;
    background: #e6e6e2;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .main .block-head {
        margin-top: 4.16667vw;
        padding: 1.38889vw 1.80556vw;
        font-size: 1.38889vw; } }
    @media (min-width: 1441px) {
      .main .block-head {
        margin-top: 60px;
        padding: 20px 26px;
        font-size: 20px; } }
  .main h2 {
    width: 100%;
    position: relative;
    color: #222222;
    font-size: 6.4vw;
    line-height: 1.5;
    font-weight: bold;
    padding-left: 22.66667vw; }
    @media (min-width: 769px) {
      .main h2 {
        font-size: 2.5vw;
        padding-left: 6.25vw; }
        .main h2 + p {
          margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main h2 {
        font-size: 36px;
        padding-left: 90px; }
        .main h2 + p {
          margin-top: 50px; } }
    .main h2:before {
      content: "";
      display: block;
      width: 18.66667vw;
      height: 0.26667vw;
      background-color: #222222;
      position: absolute;
      top: 4.66667vw;
      left: 0; }
      @media (min-width: 769px) {
        .main h2:before {
          width: 5.55556vw;
          height: 0.06944vw;
          top: 1.875vw; } }
      @media (min-width: 1441px) {
        .main h2:before {
          width: 80px;
          height: 1px;
          top: 27px; } }
    .main h2 + .list {
      margin-top: 0; }
      @media (min-width: 769px) {
        .main h2 + .list {
          margin-top: 2.77778vw; } }
      @media (min-width: 1441px) {
        .main h2 + .list {
          margin-top: 40px; } }
  .main h3 {
    font-size: 6.13333vw;
    font-weight: bold;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .main h3 {
        font-size: 2.08333vw; }
        .main h3 + p {
          margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      .main h3 {
        font-size: 30px; }
        .main h3 + p {
          margin-top: 40px; } }
    .main h3.line {
      display: flex;
      width: 100%;
      position: relative;
      padding-bottom: 4vw; }
      @media (min-width: 769px) {
        .main h3.line {
          padding-bottom: 1.73611vw; } }
      @media (min-width: 1441px) {
        .main h3.line {
          padding-bottom: 25px; } }
      .main h3.line:before, .main h3.line:after {
        content: "";
        width: 100%;
        height: 0.53333vw;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: #eae9e6; }
        @media (min-width: 769px) {
          .main h3.line:before, .main h3.line:after {
            height: 0.13889vw; } }
        @media (min-width: 1441px) {
          .main h3.line:before, .main h3.line:after {
            height: 2px; } }
      .main h3.line:after {
        width: 17.33333vw;
        z-index: 2;
        background-color: #e29f92; }
        @media (min-width: 769px) {
          .main h3.line:after {
            width: 5.55556vw; } }
        @media (min-width: 1441px) {
          .main h3.line:after {
            width: 80px; } }
  .main h4 {
    font-size: 5.33333vw;
    font-weight: bold;
    padding-left: 5.33333vw;
    border-left: 0.8vw solid #65b393;
    line-height: 1.3; }
    .main h4 + p {
      margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .main h4 {
        font-size: 1.66667vw;
        padding-left: 1.38889vw;
        border-left: 0.20833vw solid #65b393; }
        .main h4 + p {
          margin-top: 2.08333vw; } }
    @media (min-width: 1441px) {
      .main h4 {
        font-size: 24px;
        padding-left: 20px;
        border-left: 3px solid #65b393; }
        .main h4 + p {
          margin-top: 30px; } }
  .main h5 {
    font-weight: bold;
    font-size: 5.06667vw;
    line-height: 1.6; }
    .main h5 + p {
      margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .main h5 {
        font-size: 1.38889vw; }
        .main h5 + p {
          margin-top: 2.08333vw; } }
    @media (min-width: 1441px) {
      .main h5 {
        font-size: 20px; }
        .main h5 + p {
          margin-top: 30px; } }
  .main p {
    font-size: 4vw;
    line-height: 2; }
    @media (min-width: 769px) {
      .main p {
        font-size: 1.18056vw; } }
    @media (min-width: 1441px) {
      .main p {
        font-size: 17px; } }
    .main p.bold {
      font-weight: bold; }
    .main p a {
      text-decoration: none;
      color: #65b393;
      background: linear-gradient(#65b393, #65b393) 0 100%/100% 1px no-repeat;
      background-position: 100% 100%;
      transition: background 0.4s cubic-bezier(0.4, 0.1, 0.3, 1); }
      .main p a:hover {
        background-size: 0 1px; }
    .main p + .note {
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .main p + .note {
          margin-top: 0.69444vw; } }
      @media (min-width: 1441px) {
        .main p + .note {
          margin-top: 10px; } }
  .main .note {
    font-size: 3.2vw;
    padding-left: 3.2vw;
    text-indent: -3.2vw;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .main .note {
        font-size: 0.83333vw;
        padding-left: 1.18056vw;
        text-indent: -1.18056vw; } }
    @media (min-width: 1441px) {
      .main .note {
        font-size: 12px;
        padding-left: 17px;
        text-indent: -17px; } }
    .main .note:before {
      content: "※";
      margin-right: 0.4vw; }
      @media (min-width: 769px) {
        .main .note:before {
          margin-right: 0.20833vw; } }
      @media (min-width: 1441px) {
        .main .note:before {
          margin-right: 3px; } }
    .main .note.hide:before {
      content: ""; }
    .main .note + .note {
      margin-top: 0; }
  .main .list {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap; }
    .main .list li {
      font-size: 4vw;
      padding-left: 2vw;
      text-indent: -2vw;
      line-height: 1.8;
      width: 100%; }
      @media (min-width: 769px) {
        .main .list li {
          font-size: 1.18056vw;
          padding-left: 0.69444vw;
          text-indent: -0.69444vw; } }
      @media (min-width: 1441px) {
        .main .list li {
          font-size: 17px;
          padding-left: 10px;
          text-indent: -10px; } }
      .main .list li:before {
        content: "● ";
        font-size: 1.6vw;
        vertical-align: middle;
        margin-right: 0.4vw; }
        @media (min-width: 769px) {
          .main .list li:before {
            font-size: 0.41667vw;
            margin-right: 0.20833vw; } }
        @media (min-width: 1441px) {
          .main .list li:before {
            font-size: 6px;
            margin-right: 3px; } }
      .main .list li + li {
        margin-top: 1.33333vw; }
        @media (min-width: 769px) {
          .main .list li + li {
            margin-top: 0.69444vw; } }
        @media (min-width: 1441px) {
          .main .list li + li {
            margin-top: 10px; } }
      .main .list li a {
        text-decoration: none;
        transition: all 0.3s ease; }
        .main .list li a:hover {
          opacity: 0.6; }
      .main .list li.pdf, .main .list li.mail, .main .list li.tel, .main .list li.fax, .main .list li.zip, .main .list li.word, .main .list li.pp, .main .list li.excel {
        padding-left: 22.66667vw;
        text-indent: 0;
        position: relative;
        font-weight: normal;
        margin-top: 6.66667vw;
        min-height: 8.26667vw; }
        @media (min-width: 769px) {
          .main .list li.pdf, .main .list li.mail, .main .list li.tel, .main .list li.fax, .main .list li.zip, .main .list li.word, .main .list li.pp, .main .list li.excel {
            padding-left: 6.38889vw;
            min-height: 2.15278vw;
            margin-top: 0.69444vw; } }
        @media (min-width: 1441px) {
          .main .list li.pdf, .main .list li.mail, .main .list li.tel, .main .list li.fax, .main .list li.zip, .main .list li.word, .main .list li.pp, .main .list li.excel {
            padding-left: 92px;
            min-height: 31px;
            margin-top: 10px; } }
        .main .list li.pdf:before, .main .list li.mail:before, .main .list li.tel:before, .main .list li.fax:before, .main .list li.zip:before, .main .list li.word:before, .main .list li.pp:before, .main .list li.excel:before {
          content: "";
          width: 18.13333vw;
          height: 6.13333vw;
          border-radius: 3.06667vw;
          position: absolute;
          display: flex;
          left: 0;
          top: 1.33333vw;
          font-family: Inter,sans-serif;
          font-size: 3.46667vw;
          line-height: 6.13333vw;
          font-weight: bold;
          color: #fff;
          justify-content: center;
          align-items: center; }
          @media (min-width: 769px) {
            .main .list li.pdf:before, .main .list li.mail:before, .main .list li.tel:before, .main .list li.fax:before, .main .list li.zip:before, .main .list li.word:before, .main .list li.pp:before, .main .list li.excel:before {
              width: 4.72222vw;
              height: 1.59722vw;
              line-height: 1.59722vw;
              top: 0.41667vw;
              border-radius: 0.83333vw;
              font-size: 0.90278vw; } }
          @media (min-width: 1441px) {
            .main .list li.pdf:before, .main .list li.mail:before, .main .list li.tel:before, .main .list li.fax:before, .main .list li.zip:before, .main .list li.word:before, .main .list li.pp:before, .main .list li.excel:before {
              width: 68px;
              height: 23px;
              line-height: 23px;
              top: 6px;
              border-radius: 12px;
              font-size: 13px; } }
      .main .list li.pdf {
        padding-left: 8vw; }
        @media (min-width: 769px) {
          .main .list li.pdf {
            padding-left: 3.47222vw; } }
        @media (min-width: 1441px) {
          .main .list li.pdf {
            padding-left: 50px; } }
        .main .list li.pdf:before {
          content: "";
          width: 6vw;
          height: 6vw;
          background: url(../img/PDF.png) 0 50% no-repeat;
          background-size: 5.33333vw;
          border-radius: 0; }
          @media (min-width: 769px) {
            .main .list li.pdf:before {
              width: 2.43056vw;
              height: 2.43056vw;
              top: calc((100% - 2.43056vw)/2);
              background-size: 2.08333vw;
              border-radius: 0; } }
          @media (min-width: 1441px) {
            .main .list li.pdf:before {
              width: 35px;
              height: 35px;
              background-size: 30px;
              border-radius: 0;
              top: calc((100% - 35px)/2); } }
      .main .list li.mail:before {
        background: #444;
        content: "MAIL"; }
      .main .list li.tel:before {
        background: #444;
        content: "TEL"; }
      .main .list li.fax:before {
        background: #444;
        content: "FAX"; }
      .main .list li.zip {
        padding-left: 8vw; }
        @media (min-width: 769px) {
          .main .list li.zip {
            padding-left: 3.47222vw; } }
        @media (min-width: 1441px) {
          .main .list li.zip {
            padding-left: 50px; } }
        .main .list li.zip:before {
          width: 6vw;
          height: 6vw;
          background: url(../img/zip.png) 0 50% no-repeat;
          background-size: 5.33333vw;
          content: "";
          border-radius: 0; }
          @media (min-width: 769px) {
            .main .list li.zip:before {
              width: 2.43056vw;
              height: 2.43056vw;
              top: calc((100% - 2.43056vw)/2);
              background-size: 2.08333vw;
              border-radius: 0; } }
          @media (min-width: 1441px) {
            .main .list li.zip:before {
              width: 35px;
              height: 35px;
              background-size: 30px;
              border-radius: 0;
              top: calc((100% - 35px)/2); } }
      .main .list li.word {
        padding-left: 8vw; }
        @media (min-width: 769px) {
          .main .list li.word {
            padding-left: 3.47222vw; } }
        @media (min-width: 1441px) {
          .main .list li.word {
            padding-left: 50px; } }
        .main .list li.word:before {
          width: 6vw;
          height: 6vw;
          background: url(../img/word.png) 0 50% no-repeat;
          background-size: 5.33333vw;
          content: "";
          border-radius: 0; }
          @media (min-width: 769px) {
            .main .list li.word:before {
              width: 2.43056vw;
              height: 2.43056vw;
              top: calc((100% - 2.43056vw)/2);
              background-size: 2.08333vw;
              border-radius: 0; } }
          @media (min-width: 1441px) {
            .main .list li.word:before {
              width: 35px;
              height: 35px;
              background-size: 30px;
              border-radius: 0;
              top: calc((100% - 35px)/2); } }
      .main .list li.pp {
        padding-left: 8vw; }
        @media (min-width: 769px) {
          .main .list li.pp {
            padding-left: 3.47222vw; } }
        @media (min-width: 1441px) {
          .main .list li.pp {
            padding-left: 50px; } }
        .main .list li.pp:before {
          width: 6vw;
          height: 6vw;
          background: url(../img/pp.png) 0 50% no-repeat;
          background-size: 5.33333vw;
          content: "";
          border-radius: 0; }
          @media (min-width: 769px) {
            .main .list li.pp:before {
              width: 2.43056vw;
              height: 2.43056vw;
              top: calc((100% - 2.43056vw)/2);
              background-size: 2.08333vw;
              border-radius: 0; } }
          @media (min-width: 1441px) {
            .main .list li.pp:before {
              width: 35px;
              height: 35px;
              background-size: 30px;
              border-radius: 0;
              top: calc((100% - 35px)/2); } }
      .main .list li.excel {
        padding-left: 8vw; }
        @media (min-width: 769px) {
          .main .list li.excel {
            padding-left: 3.47222vw; } }
        @media (min-width: 1441px) {
          .main .list li.excel {
            padding-left: 50px; } }
        .main .list li.excel:before {
          width: 6vw;
          height: 6vw;
          background: url(../img/excel.png) 0 50% no-repeat;
          background-size: 5.33333vw;
          content: "";
          border-radius: 0; }
          @media (min-width: 769px) {
            .main .list li.excel:before {
              width: 2.43056vw;
              height: 2.43056vw;
              top: calc((100% - 2.43056vw)/2);
              background-size: 2.08333vw;
              border-radius: 0; } }
          @media (min-width: 1441px) {
            .main .list li.excel:before {
              width: 35px;
              height: 35px;
              background-size: 30px;
              border-radius: 0;
              top: calc((100% - 35px)/2); } }
  .main .num-list {
    padding-left: 4.53333vw;
    text-indent: -4.53333vw;
    margin-top: 0; }
    @media (min-width: 769px) {
      .main .num-list {
        padding-left: 1.25vw;
        text-indent: -1.25vw;
        margin-top: 0; } }
    @media (min-width: 1441px) {
      .main .num-list {
        padding-left: 18px;
        text-indent: -18px;
        margin-top: 0; } }
  .main .achievement {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8vw;
    padding-bottom: 5.33333vw; }
    .main .achievement:last-child {
      padding-bottom: 0; }
    @media (min-width: 769px) {
      .main .achievement {
        margin-top: 2.77778vw;
        padding-bottom: 0; }
        .main .achievement + .achievement {
          margin-top: 4.16667vw; } }
    @media (min-width: 1441px) {
      .main .achievement {
        margin-top: 40px; }
        .main .achievement + .achievement {
          margin-top: 60px; } }
    @media (min-width: 769px) {
      .main .achievement + h3 {
        margin-top: 5.55556vw; } }
    @media (min-width: 1441px) {
      .main .achievement + h3 {
        margin-top: 80px; } }
    .main .achievement .pdf_link:first-child {
      margin-top: 0; }
      @media (min-width: 769px) {
        .main .achievement .pdf_link:first-child {
          margin-top: 0; } }
      @media (min-width: 1441px) {
        .main .achievement .pdf_link:first-child {
          margin-top: 0; } }
    .main .achievement .category {
      height: 6.66667vw;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0 4vw;
      border-radius: 3.33333vw;
      background-color: #e3e3e3;
      font-size: 3.46667vw;
      color: #888888; }
      @media (min-width: 769px) {
        .main .achievement .category {
          height: 1.73611vw;
          line-height: 1.73611vw;
          padding: 0 1.04167vw;
          border-radius: 0.90278vw;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        .main .achievement .category {
          height: 25px;
          line-height: 25px;
          padding: 0 15px;
          border-radius: 13px;
          font-size: 13px; } }
    .main .achievement .award {
      width: 100%;
      position: relative;
      display: flex;
      font-weight: bold;
      font-size: 5.6vw;
      margin-top: 4.66667vw;
      line-height: 1; }
      .main .achievement .award:first-child {
        margin-top: 0; }
        @media (min-width: 769px) {
          .main .achievement .award:first-child {
            margin-top: 0; } }
        @media (min-width: 1441px) {
          .main .achievement .award:first-child {
            margin-top: 0; } }
      @media (min-width: 769px) {
        .main .achievement .award {
          font-size: 1.80556vw;
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .achievement .award {
          font-size: 26px;
          margin-top: 20px; } }
    .main .achievement .winner {
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        .main .achievement .winner {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .achievement .winner {
          margin-top: 20px; } }
      .main .achievement .winner + .pdf_link {
        margin-top: 10vw; }
        @media (min-width: 769px) {
          .main .achievement .winner + .pdf_link {
            margin-top: 2.43056vw; } }
        @media (min-width: 1441px) {
          .main .achievement .winner + .pdf_link {
            margin-top: 35px; } }
      .main .achievement .winner + h3 {
        margin-top: 12vw; }
        @media (min-width: 769px) {
          .main .achievement .winner + h3 {
            margin-top: 5.55556vw; } }
        @media (min-width: 1441px) {
          .main .achievement .winner + h3 {
            margin-top: 80px; } }
      .main .achievement .winner .name {
        font-size: 4vw;
        line-height: 1.6;
        width: 100%;
        font-weight: normal; }
        @media (min-width: 769px) {
          .main .achievement .winner .name {
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          .main .achievement .winner .name {
            font-size: 15px; } }
      .main .achievement .winner .affiliation {
        font-size: 3.46667vw;
        line-height: 1.6;
        color: #a6a6a6;
        font-weight: normal; }
        @media (min-width: 769px) {
          .main .achievement .winner .affiliation {
            font-size: 0.90278vw; } }
        @media (min-width: 1441px) {
          .main .achievement .winner .affiliation {
            font-size: 13px; } }
      .main .achievement .winner + .winner {
        margin-top: 4vw; }
        @media (min-width: 769px) {
          .main .achievement .winner + .winner {
            margin-top: 1.38889vw; } }
        @media (min-width: 1441px) {
          .main .achievement .winner + .winner {
            margin-top: 20px; } }
  .main .pdf_link {
    width: 100%;
    text-decoration: none;
    padding: 5.33333vw 5.33333vw 16vw;
    margin-top: 6vw;
    background: #fff;
    font-size: 4.26667vw;
    font-weight: bold;
    line-height: 1.6;
    border-top: 0.26667vw solid #e3e2df;
    border-bottom: 0.26667vw solid #e3e2df;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .main .pdf_link:hover {
      background: rgba(101, 179, 147, 0.2); }
    .main .pdf_link .title {
      width: 100%; }
      @media (min-width: 769px) {
        .main .pdf_link .title {
          width: 50%; } }
    @media (min-width: 769px) {
      .main .pdf_link .right {
        margin-top: 0;
        width: 50%;
        padding-left: 2.08333vw; } }
    @media (min-width: 1441px) {
      .main .pdf_link .right {
        padding-left: 30px; } }
    .main .pdf_link .subtitle {
      font-size: 4vw;
      width: 100%;
      margin-top: 4vw;
      font-weight: normal; }
      @media (min-width: 769px) {
        .main .pdf_link .subtitle {
          margin-top: 0;
          font-size: 1.04167vw; } }
      @media (min-width: 1441px) {
        .main .pdf_link .subtitle {
          font-size: 15px; } }
    .main .pdf_link .detail {
      font-size: 3.46667vw;
      width: 100%;
      margin-top: 1.33333vw;
      color: #a6a6a6;
      font-weight: normal; }
      @media (min-width: 769px) {
        .main .pdf_link .detail {
          margin-top: 0;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        .main .pdf_link .detail {
          font-size: 13px; } }
    @media (min-width: 769px) {
      .main .pdf_link {
        padding: 2.08333vw 7.63889vw 2.08333vw 1.38889vw;
        margin-top: 2.08333vw;
        font-size: 1.11111vw;
        border-top: 0.06944vw solid #e3e2df;
        border-bottom: 0.06944vw solid #e3e2df; } }
    @media (min-width: 1441px) {
      .main .pdf_link {
        padding: 30px 110px 30px 20px;
        margin-top: 30px;
        font-size: 16px;
        border-top: 1px solid #e3e2df;
        border-bottom: 1px solid #e3e2df; } }
    .main .pdf_link .winner {
      padding-right: 13.33333vw; }
      @media (min-width: 769px) {
        .main .pdf_link .winner {
          padding-right: 0; } }
    .main .pdf_link:after {
      content: "";
      height: 10.66667vw;
      width: 10.66667vw;
      position: absolute;
      top: calc(50% - 5.33333vw);
      right: 6.66667vw;
      font-size: 3.46667vw;
      font-weight: bold;
      color: #fff;
      font-family: 'Inter', sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../img/PDF.png") no-repeat;
      background-size: 99%; }
      @media (min-width: 769px) {
        .main .pdf_link:after {
          height: 2.77778vw;
          width: 2.77778vw;
          top: calc(50% - 1.38889vw);
          right: 1.73611vw;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        .main .pdf_link:after {
          height: 40px;
          width: 40px;
          top: calc(50% - 20px);
          right: 25px;
          font-size: 13px; } }
    .main .pdf_link + .pdf_link {
      margin-top: 2.66667vw; }
  .main .archivement_link {
    width: 100%;
    text-decoration: none;
    padding: 5.33333vw 5.33333vw 16vw;
    margin-top: 0vw;
    background: #fff;
    font-size: 4.26667vw;
    font-weight: bold;
    line-height: 1.6;
    border-top: 0.26667vw solid #e3e2df;
    border-bottom: 0.26667vw solid #e3e2df;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    @media (min-width: 769px) {
      .main .archivement_link .title {
        width: 50%; } }
    @media (min-width: 769px) {
      .main .archivement_link .right {
        margin-top: 0;
        width: 50%;
        padding-left: 2.08333vw; } }
    @media (min-width: 1441px) {
      .main .archivement_link .right {
        padding-left: 30px; } }
    .main .archivement_link .subtitle {
      font-size: 4vw;
      width: 100%;
      margin-top: 4vw;
      font-weight: normal; }
      @media (min-width: 769px) {
        .main .archivement_link .subtitle {
          margin-top: 0;
          font-size: 1.04167vw; } }
      @media (min-width: 1441px) {
        .main .archivement_link .subtitle {
          font-size: 15px; } }
    .main .archivement_link .detail {
      font-size: 3.46667vw;
      width: 100%;
      margin-top: 1.33333vw;
      color: #a6a6a6;
      font-weight: normal; }
      @media (min-width: 769px) {
        .main .archivement_link .detail {
          margin-top: 0;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        .main .archivement_link .detail {
          font-size: 13px; } }
    @media (min-width: 769px) {
      .main .archivement_link {
        padding: 2.08333vw 7.63889vw 2.08333vw 1.38889vw;
        margin-top: 2.08333vw;
        font-size: 1.11111vw;
        border-top: 0.06944vw solid #e3e2df;
        border-bottom: 0.06944vw solid #e3e2df; } }
    @media (min-width: 1441px) {
      .main .archivement_link {
        padding: 30px 110px 30px 20px;
        margin-top: 30px;
        font-size: 16px;
        border-top: 1px solid #e3e2df;
        border-bottom: 1px solid #e3e2df; } }
  .main img {
    display: block;
    max-width: 100%;
    margin-top: 8vw; }
    @media (min-width: 769px) {
      .main img {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main img {
        margin-top: 50px; } }
    .main img.center {
      margin: 8vw auto 0; }
      @media (min-width: 769px) {
        .main img.center {
          margin: 3.47222vw auto 0; } }
      @media (min-width: 1441px) {
        .main img.center {
          margin: 50px auto 0; } }
    .main img.full {
      width: 100%; }
    .main img.small {
      max-width: 78.66667vw; }
      @media (min-width: 769px) {
        .main img.small {
          max-width: 27.77778vw; } }
      @media (min-width: 1441px) {
        .main img.small {
          max-width: 400px; } }
  .main .collection {
    position: relative;
    display: flex;
    flex-wrap: wrap; }
    .main .collection a {
      position: relative;
      display: block;
      flex-wrap: wrap; }
      @media (min-width: 769px) {
        .main .collection a {
          width: 35%; } }
      .main .collection a img {
        width: 100%;
        margin-top: 10.66667vw; }
        @media (min-width: 769px) {
          .main .collection a img {
            margin-top: 3.47222vw; } }
        @media (min-width: 1441px) {
          .main .collection a img {
            margin-top: 50px; } }
      .main .collection a:hover:after {
        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
      .main .collection a:after {
        content: "";
        display: block;
        position: absolute;
        width: 2.8vw;
        height: 3.06667vw;
        background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
        background-size: 99%;
        bottom: 2.66667vw;
        right: 5.33333vw; }
        @media (min-width: 769px) {
          .main .collection a:after {
            width: 0.76389vw;
            height: 0.83333vw;
            bottom: 1.04167vw;
            right: 1.04167vw; } }
        @media (min-width: 1441px) {
          .main .collection a:after {
            width: 11px;
            height: 12px;
            bottom: 15px;
            right: 15px; } }
    @media (min-width: 769px) {
      .main .collection p {
        width: 50%;
        padding: 0 2.08333vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main .collection p {
        padding: 0 30px;
        margin-top: 50px; } }
  .main .advertising img {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      .main .advertising img {
        margin-top: 4.16667vw; } }
    @media (min-width: 1441px) {
      .main .advertising img {
        margin-top: 60px; } }
  .main .advertising .title {
    font-size: 5.46667vw;
    margin-top: 12vw;
    line-height: 1.6;
    font-weight: bold; }
    @media (min-width: 769px) {
      .main .advertising .title {
        font-size: 1.80556vw;
        margin-top: 4.16667vw; } }
    @media (min-width: 1441px) {
      .main .advertising .title {
        font-size: 26px;
        margin-top: 60px; } }
  @media (min-width: 769px) {
    .main .box-img-left {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 3.47222vw; } }
  .main .box-img-left .img-box {
    width: 100%;
    position: relative;
    display: block; }
    @media (min-width: 769px) {
      .main .box-img-left .img-box img {
        margin-top: 0; } }
    @media (min-width: 769px) {
      .main .box-img-left .img-box {
        width: 18.05556vw;
        margin-right: 2.08333vw;
        margin-top: 0; } }
    @media (min-width: 1441px) {
      .main .box-img-left .img-box {
        width: 260px;
        margin-right: 30px; } }
  @media (min-width: 769px) {
    .main .box-img-left .content-right {
      width: calc(100% - 20.13889vw); } }
  @media (min-width: 1441px) {
    .main .box-img-left .content-right {
      width: calc(100% - 290px); } }
  .main .box-img-left .content-right h5 {
    margin-top: 5.33333vw; }
    @media (min-width: 769px) {
      .main .box-img-left .content-right h5 {
        margin-top: 0; } }
  .main .box-img-left .content-right p {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .main .box-img-left .content-right p {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      .main .box-img-left .content-right p {
        margin-top: 20px; } }
  @media (min-width: 769px) {
    .main .box-image {
      position: relative;
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 769px) {
    .main .box-image .col {
      width: calc((100% - 2.08333vw) / 2);
      display: block; }
      .main .box-image .col:nth-child(even) {
        margin-left: 2.08333vw; } }
  @media (min-width: 1441px) {
    .main .box-image .col {
      width: calc((100% - 30px) / 2);
      display: block; }
      .main .box-image .col:nth-child(even) {
        width: calc((100% - 30px) / 2);
        margin-left: 30px; } }
  .main .box-image .col .caption {
    font-size: 3.2vw;
    color: #666666;
    text-align: left;
    margin-top: 4vw;
    line-height: 2;
    width: 100%; }
    @media (min-width: 769px) {
      .main .box-image .col .caption {
        margin-top: 1.38889vw;
        font-size: 0.83333vw; } }
    @media (min-width: 1441px) {
      .main .box-image .col .caption {
        margin-top: 20px;
        font-size: 12px; } }
    .main .box-image .col .caption.text-center {
      text-align: center; }
  .main .box-image .col img {
    margin-left: auto;
    margin-right: auto; }
  .main .button-block {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 8vw; }
    @media (min-width: 769px) {
      .main .button-block.center {
        justify-content: center; } }
    @media (min-width: 769px) {
      .main .button-block {
        margin-top: 3.47222vw;
        margin-bottom: -1.38889vw; } }
    @media (min-width: 1441px) {
      .main .button-block {
        margin-top: 50px;
        margin-bottom: -20px; } }
    .main .button-block a {
      width: 100%;
      min-height: 14.66667vw;
      border-radius: 14.66667vw;
      background-color: #65b393;
      color: #fff;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
      font-weight: normal;
      font-size: 4vw;
      text-decoration: none;
      padding: 4vw 10.66667vw;
      line-height: 1.5; }
      @media (min-width: 769px) {
        .main .button-block a {
          min-width: 18.05556vw;
          width: auto;
          min-height: 3.47222vw;
          border-radius: 4.16667vw;
          font-size: 1.04167vw;
          text-decoration: none;
          padding: 1.04167vw 3.47222vw;
          margin-bottom: 1.38889vw;
          margin-right: 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .button-block a {
          min-width: 260px;
          min-height: 50px;
          border-radius: 60px;
          font-size: 15px;
          text-decoration: none;
          padding: 15px 50px;
          margin-bottom: 20px;
          margin-right: 20px; } }
      .main .button-block a + a {
        margin-top: 8vw; }
        @media (min-width: 769px) {
          .main .button-block a + a {
            margin-top: 0; } }
      .main .button-block a.pink {
        background-color: #e29f92; }
      @media (min-width: 769px) {
        .main .button-block a.auto {
          min-width: auto;
          padding: 1.04167vw 3.47222vw 1.04167vw 2.43056vw; } }
      @media (min-width: 1441px) {
        .main .button-block a.auto {
          padding: 15px 50px 15px 35px; } }
      .main .button-block a.center {
        margin-left: auto;
        margin-right: auto; }
      .main .button-block a.window {
        transition: all 0.3s ease; }
        .main .button-block a.window:hover {
          opacity: 0.7; }
          .main .button-block a.window:hover:after {
            animation: none; }
        .main .button-block a.window:after {
          width: 4vw;
          height: 4vw;
          background: url("../img/ico_blank_white.png") 50% 50% no-repeat;
          background-size: 96%;
          top: calc(50% - 2vw); }
          @media (min-width: 769px) {
            .main .button-block a.window:after {
              width: 1.18056vw;
              height: 1.18056vw;
              top: calc(50% - 0.625vw);
              right: 1.38889vw; } }
          @media (min-width: 1441px) {
            .main .button-block a.window:after {
              width: 17px;
              height: 17px;
              top: calc(50% - 9px);
              right: 20px; } }
      .main .button-block a.pdf {
        padding-right: 14.66667vw;
        transition: all 0.3s ease; }
        .main .button-block a.pdf:hover {
          background-color: #84c2a9; }
          .main .button-block a.pdf:hover:after {
            animation: none; }
        @media (min-width: 769px) {
          .main .button-block a.pdf {
            padding: 0 4.16667vw; } }
        @media (min-width: 1441px) {
          .main .button-block a.pdf {
            padding: 0 60px; } }
        .main .button-block a.pdf:after {
          width: 6vw;
          height: 2.13333vw;
          background: url("../img/ico_pdf_white.svg") 50% 50% no-repeat;
          background-size: 99%;
          top: calc(50% - 1.06667vw);
          right: 7.33333vw; }
          @media (min-width: 769px) {
            .main .button-block a.pdf:after {
              background: url("../img/ico_pdf_button.svg") 50% 50% no-repeat;
              background-size: 99%;
              width: 2.77778vw;
              height: 1.25vw;
              top: calc(50% - 0.625vw);
              right: 1.38889vw; } }
          @media (min-width: 1441px) {
            .main .button-block a.pdf:after {
              width: 40px;
              height: 18px;
              top: calc(50% - 9px);
              right: 20px; } }
      .main .button-block a:hover:after {
        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
      .main .button-block a:after {
        content: "";
        display: block;
        position: absolute;
        width: 2.8vw;
        height: 3.06667vw;
        background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
        background-size: 99%;
        top: calc(50% - 1.6vw);
        right: 5.33333vw; }
        @media (min-width: 769px) {
          .main .button-block a:after {
            width: 0.76389vw;
            height: 0.83333vw;
            top: calc(50% - 0.41667vw);
            right: 1.38889vw; } }
        @media (min-width: 1441px) {
          .main .button-block a:after {
            width: 11px;
            height: 12px;
            top: calc(50% - 6px);
            right: 20px; } }
  .main .download-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .main .download-list {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      .main .download-list {
        margin-top: 20px; } }
    .main .download-list .item {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap; }
      .main .download-list .item a {
        width: 100%;
        position: relative;
        display: flex;
        padding: 6.66667vw 6.66667vw 6.66667vw 24vw;
        border-bottom: 0.26667vw solid #e3e2df;
        background: #fff;
        text-decoration: none;
        font-size: 4vw;
        font-weight: bold;
        line-height: 1.5;
        transition: all 0.3s ease; }
        @media (min-width: 769px) {
          .main .download-list .item a {
            padding: 1.73611vw 1.73611vw 1.73611vw 6.25vw;
            border-bottom: 0.06944vw solid #e3e2df;
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          .main .download-list .item a {
            padding: 25px 25px 25px 90px;
            border-bottom: 1px solid #e3e2df;
            font-size: 15px; } }
        .main .download-list .item a:after {
          content: "";
          height: 10.66667vw;
          width: 10.66667vw;
          position: absolute;
          top: calc(50% - 5.33333vw);
          left: 6.66667vw;
          font-size: 3.46667vw;
          font-weight: bold;
          color: #fff;
          font-family: 'Inter', sans-serif;
          display: flex;
          justify-content: center;
          align-items: center; }
          @media (min-width: 769px) {
            .main .download-list .item a:after {
              height: 2.77778vw;
              width: 2.77778vw;
              top: calc(50% - 1.38889vw);
              left: 1.73611vw;
              font-size: 0.90278vw; } }
          @media (min-width: 1441px) {
            .main .download-list .item a:after {
              height: 40px;
              width: 40px;
              top: calc(50% - 20px);
              left: 25px;
              font-size: 13px; } }
        .main .download-list .item a:hover {
          background: rgba(101, 179, 147, 0.2); }
      .main .download-list .item.word a:after {
        background: url(../img/word.png) no-repeat;
        background-size: 99%; }
      .main .download-list .item.excel a:after {
        background: url(../img/excel.png) no-repeat;
        background-size: 99%; }
      .main .download-list .item.pdf a:after {
        background: url(../img/PDF.png) no-repeat;
        background-size: 99%; }
      .main .download-list .item.pp a:after {
        background: url(../img/pp.png) no-repeat;
        background-size: 99%; }
      .main .download-list .item.zip a:after {
        background: url(../img/zip.png) no-repeat;
        background-size: 99%; }
      .main .download-list .item:first-child a {
        border-top: 0.26667vw solid #e3e2df; }
        @media (min-width: 769px) {
          .main .download-list .item:first-child a {
            border-top: 0.06944vw solid #e3e2df; } }
        @media (min-width: 1441px) {
          .main .download-list .item:first-child a {
            border-top: 1px solid #e3e2df; } }
  .main .tab {
    margin-top: 14.66667vw; }
    @media (min-width: 769px) {
      .main .tab {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main .tab {
        margin-top: 50px; } }
    .main .tab .tab-control {
      width: calc(100% + 10.66667vw);
      position: relative;
      display: flex;
      overflow: auto;
      margin-left: -5.33333vw; }
      @media (min-width: 769px) {
        .main .tab .tab-control {
          width: 100%;
          margin-left: 0; } }
      .main .tab .tab-control::-webkit-scrollbar {
        display: none; }
    .main .tab .tab-content {
      display: none; }
      .main .tab .tab-content.active {
        display: block; }
    .main .tab ul {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 5.33333vw; }
      @media (min-width: 769px) {
        .main .tab ul {
          width: 100%;
          padding: 0; } }
      .main .tab ul li {
        width: 42vw;
        min-height: 19.33333vw;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top: 0.8vw solid transparent;
        background: #e3e3e3;
        transition: all 0.3s ease;
        -ms-transition: none; }
        @media (min-width: 769px) {
          .main .tab ul li {
            width: 100%;
            min-height: 5vw;
            border-top: 0.20833vw solid transparent; } }
        @media (min-width: 1441px) {
          .main .tab ul li {
            min-height: 72px;
            border-top: 3px solid transparent; } }
        .main .tab ul li + li {
          margin-left: 1.06667vw; }
          @media (min-width: 769px) {
            .main .tab ul li + li {
              margin-left: 0.27778vw; } }
          @media (min-width: 1441px) {
            .main .tab ul li + li {
              margin-left: 4px; } }
        .main .tab ul li:hover {
          background: #65b393; }
          .main .tab ul li:hover a {
            color: #fff; }
        .main .tab ul li.active {
          border-top: 0.8vw solid #65b393;
          background: #fafaf7; }
          @media (min-width: 769px) {
            .main .tab ul li.active {
              border-top: 0.20833vw solid #65b393; } }
          @media (min-width: 1441px) {
            .main .tab ul li.active {
              border-top: 3px solid #65b393; } }
          .main .tab ul li.active a {
            color: #222222; }
        .main .tab ul li:last-child:after {
          display: none; }
        .main .tab ul li a {
          font-size: 3.73333vw;
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;
          color: #999999;
          padding: 0 3.33333vw;
          transition: all 0.3s ease;
          width: 100%;
          justify-content: center; }
          @media (min-width: 769px) {
            .main .tab ul li a {
              font-size: 0.97222vw;
              padding: 0 0.69444vw;
              text-align: left;
              height: 100%;
              align-items: center;
              display: flex; } }
          @media (min-width: 1441px) {
            .main .tab ul li a {
              font-size: 14px;
              padding: 0 10px; } }
  .main .anchor {
    margin-top: 14.66667vw; }
    @media (min-width: 769px) {
      .main .anchor {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main .anchor {
        margin-top: 50px; } }
    .main .anchor .anchor-control {
      width: calc(100% + 10.66667vw);
      position: relative;
      display: flex;
      overflow: auto;
      margin-left: -5.33333vw; }
      @media (min-width: 769px) {
        .main .anchor .anchor-control {
          width: 100%;
          margin-left: 0; } }
      .main .anchor .anchor-control::-webkit-scrollbar {
        display: none; }
    .main .anchor ul {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 5.33333vw; }
      @media (min-width: 769px) {
        .main .anchor ul {
          width: 100%;
          padding: 0; } }
      .main .anchor ul li {
        width: 42vw;
        min-height: 19.33333vw;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top: 0.8vw solid transparent;
        background: #e3e3e3;
        transition: all 0.3s ease;
        -ms-transition: none; }
        @media (min-width: 769px) {
          .main .anchor ul li {
            width: 100%;
            min-height: 5vw;
            border-top: 0.20833vw solid transparent; } }
        @media (min-width: 1441px) {
          .main .anchor ul li {
            min-height: 72px;
            border-top: 3px solid transparent; } }
        .main .anchor ul li + li {
          margin-left: 1.06667vw; }
          @media (min-width: 769px) {
            .main .anchor ul li + li {
              margin-left: 0.27778vw; } }
          @media (min-width: 1441px) {
            .main .anchor ul li + li {
              margin-left: 4px; } }
        .main .anchor ul li:hover {
          background: #65b393; }
          .main .anchor ul li:hover a {
            color: #fff; }
        .main .anchor ul li.active {
          border-top: 0.8vw solid #65b393;
          background: #fafaf7; }
          @media (min-width: 769px) {
            .main .anchor ul li.active {
              border-top: 0.20833vw solid #65b393; } }
          @media (min-width: 1441px) {
            .main .anchor ul li.active {
              border-top: 3px solid #65b393; } }
          .main .anchor ul li.active a {
            color: #222222; }
        .main .anchor ul li:last-child:after {
          display: none; }
        .main .anchor ul li a {
          font-size: 3.73333vw;
          font-weight: bold;
          line-height: 1.6;
          text-align: center;
          text-decoration: none;
          color: #999999;
          padding: 0 3.33333vw;
          transition: all 0.3s ease;
          width: 100%;
          justify-content: center; }
          @media (min-width: 769px) {
            .main .anchor ul li a {
              font-size: 0.97222vw;
              padding: 0 0.69444vw;
              text-align: left;
              height: 100%;
              align-items: center;
              display: flex; } }
          @media (min-width: 1441px) {
            .main .anchor ul li a {
              font-size: 14px;
              padding: 0 10px; } }
  .main .detail-info {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4vw;
    align-items: center; }
    @media (min-width: 769px) {
      .main .detail-info {
        margin-top: 2.08333vw; } }
    @media (min-width: 1441px) {
      .main .detail-info {
        margin-top: 30; } }
    .main .detail-info .date {
      font-size: 3.73333vw;
      color: #a6a6a6; }
      @media (min-width: 769px) {
        .main .detail-info .date {
          font-size: 0.97222vw; } }
      @media (min-width: 1441px) {
        .main .detail-info .date {
          font-size: 14px; } }
    .main .detail-info .tag {
      margin-left: 5.33333vw;
      padding: 0 2.66667vw;
      height: 5.33333vw;
      border-radius: 2.66667vw;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #888888;
      background-color: #e3e3e3;
      font-size: 2.93333vw; }
      @media (min-width: 769px) {
        .main .detail-info .tag {
          margin-left: 1.38889vw;
          padding: 0 0.69444vw;
          height: 1.52778vw;
          border-radius: 0.69444vw;
          font-size: 0.76389vw;
          transition: all 0.3s ease; } }
      @media (min-width: 1441px) {
        .main .detail-info .tag {
          margin-left: 20px;
          padding: 0 10px;
          height: 22px;
          border-radius: 10px;
          font-size: 11px; } }
      .main .detail-info .tag + .tag {
        margin-left: 2.66667vw; }
        @media (min-width: 769px) {
          .main .detail-info .tag + .tag {
            margin-left: 0.69444vw; } }
        @media (min-width: 1441px) {
          .main .detail-info .tag + .tag {
            margin-left: 10px; } }
    .main .detail-info a.tag {
      text-decoration: none; }
      .main .detail-info a.tag:hover {
        background: #65b393;
        color: #fff; }
  @media (min-width: 769px) {
    .main .bussiness-group {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .main .bussiness-group {
      margin-top: 50px; } }
  .main .business-box {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8vw;
    background: #fff;
    padding: 5.33333vw;
    border-top: 0.26667vw solid #e3e2df;
    border-bottom: 0.26667vw solid #e3e2df; }
    @media (min-width: 769px) {
      .main .business-box {
        width: calc(50% - 1.04167vw);
        padding: 2.08333vw 1.38889vw;
        border-top: 0.06944vw solid #e3e2df;
        border-bottom: 0.06944vw solid #e3e2df;
        margin-top: 0;
        display: block; } }
    @media (min-width: 1441px) {
      .main .business-box {
        width: calc(50% - 15px);
        padding: 30px 20px;
        border-top: 1px solid #e3e2df;
        border-bottom: 1px solid #e3e2df; } }
    .main .business-box + .business-box {
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        .main .business-box + .business-box {
          margin-top: 0;
          margin-left: 2.08333vw; } }
      @media (min-width: 1441px) {
        .main .business-box + .business-box {
          margin-left: 30px; } }
    .main .business-box .box-head {
      width: 100%;
      position: relative;
      display: flex;
      padding-bottom: 5.33333vw;
      border-bottom: 0.8vw solid #65b393;
      font-size: 5.06667vw;
      color: #65b393;
      font-weight: bold; }
      @media (min-width: 769px) {
        .main .business-box .box-head {
          padding-bottom: 1.38889vw;
          border-bottom: 0.20833vw solid #65b393;
          font-size: 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .business-box .box-head {
          padding-bottom: 20px;
          border-bottom: 3px solid #65b393;
          font-size: 20px; } }
      .main .business-box .box-head a {
        text-decoration: none; }
      .main .business-box .box-head.pink {
        border-bottom: 0.8vw solid #d56d59;
        color: #d56d59; }
        @media (min-width: 769px) {
          .main .business-box .box-head.pink {
            border-bottom: 0.20833vw solid #d56d59; } }
        @media (min-width: 1441px) {
          .main .business-box .box-head.pink {
            border-bottom: 3px solid #d56d59; } }
    .main .business-box ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 6.66667vw;
      width: 100%; }
      @media (min-width: 769px) {
        .main .business-box ul {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .business-box ul {
          margin-top: 20px; } }
      .main .business-box ul li {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-size: 4.53333vw;
        font-weight: bold; }
        .main .business-box ul li a {
          text-decoration: none; }
        @media (min-width: 769px) {
          .main .business-box ul li {
            font-size: 1.18056vw; } }
        @media (min-width: 1441px) {
          .main .business-box ul li {
            font-size: 17px; } }
        .main .business-box ul li:before {
          content: "● ";
          font-size: 2.13333vw;
          margin-right: 0.66667vw; }
          @media (min-width: 769px) {
            .main .business-box ul li:before {
              font-size: 0.55556vw;
              margin-right: 0.34722vw; } }
          @media (min-width: 1441px) {
            .main .business-box ul li:before {
              font-size: 8px;
              margin-right: 5px; } }
        .main .business-box ul li ul {
          padding-left: 5.33333vw;
          margin-top: 4vw; }
          @media (min-width: 769px) {
            .main .business-box ul li ul {
              padding-left: 1.38889vw;
              margin-top: 1.38889vw; } }
          @media (min-width: 1441px) {
            .main .business-box ul li ul {
              padding-left: 20px;
              margin-top: 20px; } }
          .main .business-box ul li ul li {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-size: 4vw;
            font-weight: normal; }
            @media (min-width: 769px) {
              .main .business-box ul li ul li {
                font-size: 1.04167vw; } }
            @media (min-width: 1441px) {
              .main .business-box ul li ul li {
                font-size: 15px; } }
            .main .business-box ul li ul li + li {
              margin-top: 4vw; }
              @media (min-width: 769px) {
                .main .business-box ul li ul li + li {
                  margin-top: 1.38889vw; } }
              @media (min-width: 1441px) {
                .main .business-box ul li ul li + li {
                  margin-top: 20px; } }
            .main .business-box ul li ul li:before {
              content: "○ ";
              font-size: 2.13333vw;
              margin-right: 0.66667vw; }
              @media (min-width: 769px) {
                .main .business-box ul li ul li:before {
                  font-size: 0.41667vw;
                  margin-right: 0.34722vw; } }
              @media (min-width: 1441px) {
                .main .business-box ul li ul li:before {
                  font-size: 6px;
                  margin-right: 5px; } }
        .main .business-box ul li + li {
          margin-top: 5.33333vw; }
          @media (min-width: 769px) {
            .main .business-box ul li + li {
              margin-top: 2.08333vw; } }
          @media (min-width: 1441px) {
            .main .business-box ul li + li {
              margin-top: 30px; } }
  .main .description {
    margin-top: 8vw;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    border-top: 0.26667vw solid #e3e2df;
    border-bottom: 0.26667vw solid #e3e2df;
    background: #fff;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    margin-bottom: 0; }
    @media (min-width: 769px) {
      .main .description {
        margin-top: 3.47222vw;
        border-top: 0.06944vw solid #e3e2df;
        border-bottom: 0.06944vw solid #e3e2df;
        padding-top: 2.08333vw;
        padding-bottom: 2.08333vw; } }
    @media (min-width: 1441px) {
      .main .description {
        margin-top: 50px;
        border-top: 1px solid #e3e2df;
        border-bottom: 1px solid #e3e2df;
        padding-top: 30px;
        padding-bottom: 30px; } }
    .main .description.open dt {
      padding-right: 20vw;
      background: url("../img/open.png") no-repeat;
      background-size: 6.66667vw;
      background-position: top 50% right 5.33333vw; }
      @media (min-width: 769px) {
        .main .description.open dt {
          padding-right: 6.25vw;
          background-size: 1.875vw;
          background-position: top 50% right 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .description.open dt {
          padding-right: 90px;
          background-size: 27px;
          background-position: top 50% right 20px; } }
    .main .description dt,
    .main .description dd {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: 0;
      padding: 0 5.33333vw;
      line-height: 1.6; }
      @media (min-width: 769px) {
        .main .description dt,
        .main .description dd {
          padding: 0 1.38889vw; } }
      @media (min-width: 1441px) {
        .main .description dt,
        .main .description dd {
          padding: 0 20px; } }
      .main .description dt .list,
      .main .description dd .list {
        margin-top: 0; }
    .main .description dt {
      padding-right: 20vw;
      background: url("../img/close.png") no-repeat;
      background-size: 6.66667vw;
      background-position: top 50% right 5.33333vw;
      cursor: pointer;
      font-weight: bold;
      font-size: 4.26667vw; }
      @media (min-width: 769px) {
        .main .description dt {
          padding-right: 6.25vw;
          background-size: 1.875vw;
          background-position: top 50% right 1.38889vw;
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        .main .description dt {
          padding-right: 90px;
          background-size: 27px;
          background-position: top 50% right 20px;
          font-size: 16px; } }
    .main .description dd {
      display: none;
      overflow: hidden;
      transition: max-height 1s ease;
      font-size: 4vw; }
      .main .description dd .list li {
        margin-top: 5.33333vw; }
        @media (min-width: 769px) {
          .main .description dd .list li {
            margin-top: 0.69444vw; } }
        @media (min-width: 1441px) {
          .main .description dd .list li {
            margin-top: 10px; } }
      @media (min-width: 769px) {
        .main .description dd {
          font-size: 1.04167vw; } }
      @media (min-width: 1441px) {
        .main .description dd {
          font-size: 15px; } }
    .main .description + .description {
      margin-top: 0;
      border-top: 0; }
  .main .article-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 8vw; }
    @media (min-width: 769px) {
      .main .article-list {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main .article-list {
        margin-top: 50px; } }
    .main .article-list .article-item {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 6.66667vw;
      border-bottom: 0.26667vw solid #eae9e6;
      padding-bottom: 6.66667vw; }
      @media (min-width: 769px) {
        .main .article-list .article-item {
          margin-top: 2.08333vw;
          border-bottom: 0.06944vw solid #eae9e6;
          padding-bottom: 2.08333vw; } }
      @media (min-width: 1441px) {
        .main .article-list .article-item {
          margin-top: 30px;
          border-bottom: 1px solid #eae9e6;
          padding-bottom: 30px; } }
      .main .article-list .article-item:first-child {
        margin-top: 0; }
      .main .article-list .article-item a {
        display: flex;
        flex-wrap: wrap;
        text-decoration: none; }
        .main .article-list .article-item a:hover .article-img img {
          opacity: 0.6; }
      .main .article-list .article-item .article-img {
        position: relative;
        display: block;
        overflow: hidden;
        width: 53.33333vw;
        height: 53.33333vw;
        margin: 0 auto; }
        @media (min-width: 769px) {
          .main .article-list .article-item .article-img {
            width: 10.41667vw;
            height: 10.41667vw; } }
        @media (min-width: 1441px) {
          .main .article-list .article-item .article-img {
            width: 150px;
            height: 150px; } }
        .main .article-list .article-item .article-img img {
          width: 100%;
          height: 100%;
          transition: all .5s ease;
          margin-top: 0; }
      .main .article-list .article-item .article-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 5.33333vw; }
        @media (min-width: 769px) {
          .main .article-list .article-item .article-content {
            width: calc(100% - 10.41667vw);
            margin-top: 0;
            padding-left: 2.08333vw;
            display: block; } }
        @media (min-width: 1441px) {
          .main .article-list .article-item .article-content {
            width: calc(100% - 150px);
            padding-left: 30px; } }
        .main .article-list .article-item .article-content .content-tag {
          display: flex;
          flex-wrap: wrap;
          width: 100%; }
          @media (min-width: 769px) {
            .main .article-list .article-item .article-content .content-tag {
              align-items: center; } }
          .main .article-list .article-item .article-content .content-tag .vol {
            font-family: 'Inter', sans-serif;
            font-weight: normal;
            font-size: 3.46667vw;
            line-height: 1.2;
            width: 100%; }
            @media (min-width: 769px) {
              .main .article-list .article-item .article-content .content-tag .vol {
                font-size: 0.90278vw;
                width: auto; } }
            @media (min-width: 1441px) {
              .main .article-list .article-item .article-content .content-tag .vol {
                font-size: 13px; } }
          .main .article-list .article-item .article-content .content-tag .tag {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 1;
            background: #e3e3e3;
            color: #888888;
            font-size: 2.93333vw;
            margin-top: 3.33333vw;
            height: 5.33333vw;
            padding: 0 3.33333vw;
            border-radius: 2.66667vw; }
            @media (min-width: 769px) {
              .main .article-list .article-item .article-content .content-tag .tag {
                margin-left: 2.08333vw;
                padding: 0 0.69444vw;
                height: 1.52778vw;
                border-radius: 0.69444vw;
                font-size: 0.76389vw;
                margin-top: 0;
                transition: all 0.3s ease; } }
            @media (min-width: 1441px) {
              .main .article-list .article-item .article-content .content-tag .tag {
                margin-left: 30px;
                padding: 0 10px;
                height: 22px;
                border-radius: 10px;
                font-size: 11px; } }
            .main .article-list .article-item .article-content .content-tag .tag + .tag {
              margin-left: 1.33333vw; }
              @media (min-width: 769px) {
                .main .article-list .article-item .article-content .content-tag .tag + .tag {
                  margin-left: 0.69444vw; } }
              @media (min-width: 1441px) {
                .main .article-list .article-item .article-content .content-tag .tag + .tag {
                  margin-left: 10px; } }
          .main .article-list .article-item .article-content .content-tag a.tag {
            text-decoration: none; }
            .main .article-list .article-item .article-content .content-tag a.tag:hover {
              background: #65b393;
              color: #fff; }
        .main .article-list .article-item .article-content .content-title {
          font-size: 4.53333vw;
          font-weight: bold;
          line-height: 1.6;
          margin-top: 2.66667vw; }
          @media (min-width: 769px) {
            .main .article-list .article-item .article-content .content-title {
              font-size: 1.18056vw;
              margin-top: 1.04167vw; } }
          @media (min-width: 1441px) {
            .main .article-list .article-item .article-content .content-title {
              font-size: 17px;
              margin-top: 15px; } }
        .main .article-list .article-item .article-content .content-detail {
          font-size: 3.73333vw;
          font-weight: normal;
          line-height: 2;
          margin-top: 3.33333vw; }
          @media (min-width: 769px) {
            .main .article-list .article-item .article-content .content-detail {
              font-size: 0.97222vw;
              margin-top: 1.04167vw; } }
          @media (min-width: 1441px) {
            .main .article-list .article-item .article-content .content-detail {
              font-size: 14px;
              margin-top: 15px; } }
  .main .iframe-wrap {
    width: 100%;
    position: relative;
    display: block;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media (min-width: 769px) {
      .main .iframe-wrap iframe {
        height: 55.55556vw; } }
    @media (min-width: 1441px) {
      .main .iframe-wrap iframe {
        height: 800px; } }
  .main iframe {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 68.66667vw;
    margin-top: 8vw;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    @media (min-width: 769px) {
      .main iframe {
        height: 34.72222vw;
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .main iframe {
        height: 500px;
        margin-top: 50px; } }
  .main .profile {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 13.33333vw; }
    @media (min-width: 769px) {
      .main .profile {
        margin-top: 4.86111vw;
        display: flex;
        flex-wrap: wrap; } }
    @media (min-width: 1441px) {
      .main .profile {
        margin-top: 70px; } }
    .main .profile .about {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start; }
      @media (min-width: 769px) {
        .main .profile .about {
          width: 50%; } }
      .main .profile .about .img-box {
        width: 29.86667vw;
        height: 29.86667vw;
        position: relative;
        display: block;
        line-height: 0; }
        @media (min-width: 769px) {
          .main .profile .about .img-box {
            width: 10.41667vw;
            height: 10.41667vw; } }
        @media (min-width: 1441px) {
          .main .profile .about .img-box {
            width: 150px;
            height: 150px; } }
        .main .profile .about .img-box img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
          margin-top: 0;
          object-position: center top; }
      .main .profile .about .des-box {
        width: calc(100% - 29.86667vw);
        padding: 0 5.33333vw;
        position: relative;
        display: block; }
        @media (min-width: 769px) {
          .main .profile .about .des-box {
            width: calc(100% - 10.41667vw);
            padding: 0 0 0 2.77778vw; } }
        @media (min-width: 1441px) {
          .main .profile .about .des-box {
            width: calc(100% - 150px);
            padding: 0  0 0 40px; } }
        .main .profile .about .des-box .name {
          width: 100%;
          position: relative;
          display: block;
          font-size: 6.13333vw;
          font-weight: bold;
          line-height: 1;
          top: -0.08em; }
          @media (min-width: 769px) {
            .main .profile .about .des-box .name {
              font-size: 2.08333vw; } }
          @media (min-width: 1441px) {
            .main .profile .about .des-box .name {
              font-size: 30px; } }
        .main .profile .about .des-box .position {
          width: 100%;
          position: relative;
          display: block;
          font-size: 5.06667vw;
          line-height: 1.5;
          color: #222222;
          margin-top: 4vw; }
          @media (min-width: 769px) {
            .main .profile .about .des-box .position {
              font-size: 1.25vw;
              margin-top: 1.73611vw; } }
          @media (min-width: 1441px) {
            .main .profile .about .des-box .position {
              font-size: 18px;
              margin-top: 25px; } }
    .main .profile .summary {
      width: 100%;
      position: relative;
      display: block;
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        .main .profile .summary {
          width: 50%;
          padding-left: 2.77778vw;
          margin-top: 0; } }
      @media (min-width: 1441px) {
        .main .profile .summary {
          padding-left: 40px; } }
      .main .profile .summary .head {
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 5.06667vw;
        width: 100%;
        position: relative;
        display: block;
        padding-bottom: 1.33333vw;
        line-height: 1.5;
        border-bottom: 0.26667vw solid #eae9e6;
        text-align: center; }
        @media (min-width: 769px) {
          .main .profile .summary .head {
            top: -0.3em;
            font-size: 1.25vw;
            padding-bottom: 0.69444vw;
            border-bottom: 0.06944vw solid #eae9e6; } }
        @media (min-width: 1441px) {
          .main .profile .summary .head {
            font-size: 18px;
            padding-bottom: 10px;
            border-bottom: 1px solid #eae9e6; } }
      .main .profile .summary .text {
        position: relative;
        display: block;
        width: 100%;
        font-size: 4vw;
        line-height: 2;
        margin-top: 1.33333vw; }
        @media (min-width: 769px) {
          .main .profile .summary .text {
            font-size: 1.04167vw;
            margin-top: 0.69444vw;
            text-align: left; } }
        @media (min-width: 1441px) {
          .main .profile .summary .text {
            font-size: 15px;
            margin-top: 10px; } }

.two-col {
  background: #fafaf7; }
  @media (min-width: 769px) {
    .two-col .content {
      width: calc(100% - 41.66667vw);
      margin-left: 41.66667vw;
      padding: 0 6.94444vw 6.94444vw; } }
  @media (min-width: 1441px) {
    .two-col .content {
      width: calc(100% - 600px);
      margin-left: 600px;
      padding: 0 100px 100px; } }
  .two-col h1 {
    width: 100%;
    position: relative;
    display: flex;
    padding: 5.33333vw 5.06667vw 5.33333vw 5.33333vw;
    align-items: center;
    min-height: 60vw;
    background: #ecece7;
    font-weight: normal; }
    @media (min-width: 769px) {
      .two-col h1.absolute {
        position: absolute;
        top: auto;
        bottom: 0; } }
    @media (min-width: 769px) {
      .two-col h1 {
        position: fixed;
        top: 0;
        left: 0;
        width: 41.66667vw;
        background-color: #ecece7;
        height: 100vh;
        min-height: 0;
        padding: 11.80556vw 3.47222vw 0 3.81944vw;
        font-size: 2.84722vw;
        align-items: flex-start;
        z-index: -1; } }
    @media (min-width: 1441px) {
      .two-col h1 {
        width: 600px;
        padding: 170px 50px 0 55px;
        font-size: 41px; } }
    @media (min-width: 769px) {
      .two-col h1.anchor {
        padding: 17.36111vw 3.47222vw 0 3.81944vw; } }
    @media (min-width: 1441px) {
      .two-col h1.anchor {
        padding: 250px 50px 0 55px; } }
  .two-col h2 {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      .two-col h2 {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .two-col h2 {
        margin-top: 50px; } }
  .two-col h3 {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      .two-col h3 {
        margin-top: 6.94444vw; } }
    @media (min-width: 1441px) {
      .two-col h3 {
        margin-top: 100px; } }
    .two-col h3 + .list {
      margin-top: 2.66667vw; }
      .two-col h3 + .list :first-child {
        margin-top: 2.66667vw; }
        @media (min-width: 769px) {
          .two-col h3 + .list :first-child {
            margin-top: 0.69444vw; } }
        @media (min-width: 1441px) {
          .two-col h3 + .list :first-child {
            margin-top: 10px; } }
      @media (min-width: 769px) {
        .two-col h3 + .list {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .two-col h3 + .list {
          margin-top: 20px; } }
  .two-col h4 {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      .two-col h4 {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .two-col h4 {
        margin-top: 50px; } }
  .two-col h5 {
    margin-top: 4vw; }
    @media (min-width: 769px) {
      .two-col h5 {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      .two-col h5 {
        margin-top: 20px; } }
  .two-col p {
    margin-top: 5.33333vw; }
    @media (min-width: 769px) {
      .two-col p {
        margin-top: 2.08333vw; } }
    @media (min-width: 1441px) {
      .two-col p {
        margin-top: 30px; } }
    .two-col p + p {
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .two-col p + p {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .two-col p + p {
          margin-top: 20px; } }
    .two-col p + .list {
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .two-col p + .list {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .two-col p + .list {
          margin-top: 20px; } }
  .two-col summary {
    margin-top: 4vw;
    outline: none;
    font-weight: bold;
    font-size: 5.06667vw;
    line-height: 1.6; }
    @media (min-width: 769px) {
      .two-col summary {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      .two-col summary {
        margin-top: 20px; } }
    @media (min-width: 769px) {
      .two-col summary {
        font-size: 1.38889vw; } }
    @media (min-width: 1441px) {
      .two-col summary {
        font-size: 20px; } }
    .two-col summary h5 {
      width: auto;
      margin-top: 0;
      display: inline-block; }
  .two-col .list {
    margin-top: 5.33333vw; }
    @media (min-width: 769px) {
      .two-col .list {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      .two-col .list {
        margin-top: 40px; } }

@media (min-width: 769px) {
  .one-col {
    background: #fafaf7; } }

@media (min-width: 769px) {
  .one-col .content {
    max-width: 1440px;
    padding: 0 9.375vw 6.94444vw;
    margin: auto; } }

@media (min-width: 1441px) {
  .one-col .content {
    padding: 0 135px 100px; } }

.one-col h1 {
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    .one-col h1 {
      margin-top: 3.47222vw;
      font-size: 2.84722vw; } }
  @media (min-width: 1441px) {
    .one-col h1 {
      margin-top: 50px;
      font-size: 41px; } }
  @media (min-width: 769px) {
    .one-col h1 + p {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .one-col h1 + p {
      margin-top: 50px; } }

.one-col h2 {
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    .one-col h2 {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .one-col h2 {
      margin-top: 50px; } }
  @media (min-width: 769px) {
    .one-col h2 + p {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    .one-col h2 + p {
      margin-top: 40px; } }
  .one-col h2 + .tab {
    margin-top: 8vw; }
    @media (min-width: 769px) {
      .one-col h2 + .tab {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .one-col h2 + .tab {
        margin-top: 50px; } }

.one-col h3 {
  margin-top: 12vw; }
  @media (min-width: 769px) {
    .one-col h3 {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .one-col h3 {
      margin-top: 50px; } }
  @media (min-width: 769px) {
    .one-col h3 + p {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    .one-col h3 + p {
      margin-top: 40px; } }
  .one-col h3 + .download-list {
    margin-top: 6.66667vw; }
    @media (min-width: 769px) {
      .one-col h3 + .download-list {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .one-col h3 + .download-list {
        margin-top: 50px; } }

.one-col h4 {
  margin-top: 12vw; }
  @media (min-width: 769px) {
    .one-col h4 {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .one-col h4 {
      margin-top: 50px; } }
  @media (min-width: 769px) {
    .one-col h4 + p {
      margin-top: 2.08333vw; } }
  @media (min-width: 1441px) {
    .one-col h4 + p {
      margin-top: 30px; } }

.one-col h5 {
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    .one-col h5 {
      margin-top: 2.08333vw; } }
  @media (min-width: 1441px) {
    .one-col h5 {
      margin-top: 30px; } }
  @media (min-width: 769px) {
    .one-col h5 + p {
      margin-top: 2.08333vw; } }
  @media (min-width: 1441px) {
    .one-col h5 + p {
      margin-top: 30px; } }

.one-col p {
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    .one-col p {
      margin-top: 3.47222vw;
      font-size: 1.11111vw; } }
  @media (min-width: 1441px) {
    .one-col p {
      font-size: 16px;
      margin-top: 50px; } }
  .one-col p + p {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .one-col p + p {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      .one-col p + p {
        margin-top: 20px; } }
  .one-col p + .list {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .one-col p + .list {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      .one-col p + .list {
        margin-top: 20px; } }
  @media (min-width: 769px) {
    .one-col p.note {
      font-size: 0.97222vw; } }
  @media (min-width: 1441px) {
    .one-col p.note {
      font-size: 14px; } }

.one-col .list {
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    .one-col .list {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    .one-col .list {
      margin-top: 40px; } }

.one-col .list {
  margin-top: 5.33333vw; }

.one-col h3 {
  margin-top: 8vw; }

.one-col .article-head {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 0.26667vw solid #eae9e6;
  padding-bottom: 6.66667vw; }
  @media (min-width: 769px) {
    .one-col .article-head {
      padding-bottom: 2.08333vw;
      border-bottom: 0.06944vw solid #eae9e6; } }
  @media (min-width: 1441px) {
    .one-col .article-head {
      padding-bottom: 30px;
      border-bottom: 1px solid #eae9e6; } }
  .one-col .article-head p {
    margin-top: 5.33333vw;
    font-size: 4.53333vw;
    line-height: 2; }
    .one-col .article-head p.name {
      font-weight: bold;
      width: 100%; }
    .one-col .article-head p.university {
      margin-top: 0; }
    @media (min-width: 769px) {
      .one-col .article-head p {
        margin-top: 2.08333vw;
        font-size: 1.11111vw; } }
    @media (min-width: 1441px) {
      .one-col .article-head p {
        margin-top: 30px;
        font-size: 16px; } }

.one-col .article-detail {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4vw;
  align-items: center; }
  @media (min-width: 769px) {
    .one-col .article-detail {
      margin-top: 1.04167vw; } }
  @media (min-width: 1441px) {
    .one-col .article-detail {
      margin-top: 15px; } }
  .one-col .article-detail .info {
    color: #a6a6a6;
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 769px) {
      .one-col .article-detail .info {
        width: auto; } }
    .one-col .article-detail .info span {
      font-size: 3.46667vw;
      padding-right: 3.46667vw;
      display: flex;
      line-height: 4vw; }
      @media (min-width: 769px) {
        .one-col .article-detail .info span {
          font-size: 0.90278vw;
          padding-right: 1.38889vw;
          line-height: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .article-detail .info span {
          font-size: 13px;
          padding-right: 20px;
          line-height: 20px; } }
  .one-col .article-detail .vol {
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 4vw;
    font-family: 'Inter', sans-serif;
    left: 1;
    font-size: 3.46667vw;
    line-height: 1.5; }
    @media (min-width: 769px) {
      .one-col .article-detail .vol {
        font-size: 0.90278vw;
        margin-bottom: 0.69444vw; } }
    @media (min-width: 1441px) {
      .one-col .article-detail .vol {
        font-size: 13px;
        margin-bottom: 10px; } }
  .one-col .article-detail .tag {
    margin-top: 4vw;
    margin-left: 0;
    padding: 0 2.66667vw;
    height: 5.33333vw;
    border-radius: 2.66667vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888888;
    background-color: #e3e3e3;
    font-size: 2.93333vw;
    text-decoration: none; }
    @media (min-width: 769px) {
      .one-col .article-detail .tag {
        margin-top: 0;
        margin-left: 1.38889vw;
        padding: 0 0.69444vw;
        height: 1.52778vw;
        border-radius: 0.69444vw;
        font-size: 0.76389vw;
        transition: all 0.3s ease; } }
    @media (min-width: 1441px) {
      .one-col .article-detail .tag {
        margin-left: 20px;
        padding: 0 10px;
        height: 22px;
        border-radius: 10px;
        font-size: 11px; } }
    .one-col .article-detail .tag + .tag {
      margin-left: 2vw; }
      @media (min-width: 769px) {
        .one-col .article-detail .tag + .tag {
          margin-left: 0.34722vw; } }
      @media (min-width: 1441px) {
        .one-col .article-detail .tag + .tag {
          margin-left: 5px; } }
  .one-col .article-detail a.tag {
    text-decoration: none; }
    .one-col .article-detail a.tag:hover {
      background: #65b393;
      color: #fff; }

.one-col .article-content {
  margin-top: 13.33333vw;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 769px) {
    .one-col .article-content {
      margin-top: 4.16667vw; } }
  @media (min-width: 1441px) {
    .one-col .article-content {
      margin-top: 60px; } }
  .one-col .article-content h4 {
    margin-top: 0;
    border-left: 0;
    padding-left: 0;
    line-height: 1.6; }
  .one-col .article-content p {
    font-size: 4.26667vw;
    line-height: 2;
    width: 100%; }
    @media (min-width: 769px) {
      .one-col .article-content p {
        font-size: 1.11111vw;
        line-height: 2; } }
    @media (min-width: 1441px) {
      .one-col .article-content p {
        font-size: 16px; } }
    .one-col .article-content p.head {
      font-size: 5.33333vw;
      font-weight: bold;
      line-height: 1.3;
      width: 100%;
      margin-top: 0; }
      @media (min-width: 769px) {
        .one-col .article-content p.head {
          font-size: 1.66667vw;
          margin-top: 0; } }
      @media (min-width: 1441px) {
        .one-col .article-content p.head {
          font-size: 24px;
          margin-top: 0; } }
    .one-col .article-content p.title {
      font-weight: bold;
      font-size: 5.06667vw;
      line-height: 1.6;
      width: 100%; }
      .one-col .article-content p.title + p {
        margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .one-col .article-content p.title {
          font-size: 1.38889vw; }
          .one-col .article-content p.title + p {
            margin-top: 2.08333vw; } }
      @media (min-width: 1441px) {
        .one-col .article-content p.title {
          font-size: 20px; }
          .one-col .article-content p.title + p {
            margin-top: 30px; } }

.one-col .row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%; }
  @media (min-width: 769px) {
    .one-col .row {
      width: 100%; } }
  .one-col .row .col {
    width: 100%; }
    @media (min-width: 769px) {
      .one-col .row .col {
        max-width: 700px;
        margin: auto; } }
    .one-col .row .col img {
      width: 100%; }
      @media (min-width: 769px) {
        .one-col .row .col img {
          width: auto; } }
      .one-col .row .col img + .big {
        margin-top: 2vw; }
        @media (min-width: 769px) {
          .one-col .row .col img + .big {
            margin-top: 0.69444vw; } }
        @media (min-width: 1441px) {
          .one-col .row .col img + .big {
            margin-top: 10px; } }
    .one-col .row .col + p {
      margin-top: 4vw; }
      @media (min-width: 769px) {
        .one-col .row .col + p {
          margin-top: 3.47222vw; } }
      @media (min-width: 1441px) {
        .one-col .row .col + p {
          margin-top: 50px; } }
    .one-col .row .col .big {
      font-weight: bold;
      font-size: 4.26667vw;
      text-align: left;
      line-height: 1.5; }
      @media (min-width: 769px) {
        .one-col .row .col .big {
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        .one-col .row .col .big {
          font-size: 16px; } }
    .one-col .row .col a {
      text-decoration: none; }
    .one-col .row .col .caption {
      font-size: 3.46667vw;
      color: #666666;
      text-align: left;
      margin-top: 4vw;
      line-height: 2; }
      @media (min-width: 769px) {
        .one-col .row .col .caption {
          margin-top: 1.04167vw;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        .one-col .row .col .caption {
          margin-top: 15px;
          font-size: 13px; } }
      .one-col .row .col .caption.text-center {
        text-align: center; }
  .one-col .row .post-image {
    position: relative;
    line-height: 0;
    width: 100%; }
    .one-col .row .post-image img {
      width: 100%; }
  @media (min-width: 769px) {
    .one-col .row.p-60 {
      flex-wrap: nowrap; } }
  @media (min-width: 769px) {
    .one-col .row.p-60 .col {
      margin-left: 4.16667vw;
      margin-top: 0;
      width: 100%; } }
  @media (min-width: 1441px) {
    .one-col .row.p-60 .col {
      margin-left: 60px; } }
  @media (min-width: 769px) {
    .one-col .row.p-60 .col:first-child {
      margin-left: 0; } }
  .one-col .row.p-30 .col {
    width: calc((100% - 5.33333vw) / 2);
    margin-right: 5.33333vw; }
    @media (min-width: 769px) {
      .one-col .row.p-30 .col {
        width: calc((100% - 6.25vw) / 4);
        margin-left: 2.08333vw;
        margin-top: 0;
        margin-right: 0; } }
    @media (min-width: 1441px) {
      .one-col .row.p-30 .col {
        width: calc((100% - 90px) / 4);
        margin-left: 30px; } }
    @media (min-width: 769px) {
      .one-col .row.p-30 .col:nth-child(4n+1), .one-col .row.p-30 .col:first-child {
        margin-left: 0; } }
    .one-col .row.p-30 .col a img {
      transition: all 0.3s ease; }
    .one-col .row.p-30 .col a:hover img {
      opacity: 0.6; }
    .one-col .row.p-30 .col .caption {
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .one-col .row.p-30 .col .caption {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .row.p-30 .col .caption {
          margin-top: 20px; } }
    .one-col .row.p-30 .col:nth-child(even) {
      margin-right: 0; }
    .one-col .row.p-30 .col img {
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        .one-col .row.p-30 .col img {
          margin-top: 2.77778vw;
          max-width: 100%;
          max-height: 80vh;
          object-fit: cover; } }
      @media (min-width: 1441px) {
        .one-col .row.p-30 .col img {
          margin-top: 40px; } }
    .one-col .row.p-30 .col .article-detail {
      margin-top: 4vw; }
      @media (min-width: 769px) {
        .one-col .row.p-30 .col .article-detail {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .row.p-30 .col .article-detail {
          margin-top: 20px; } }
      .one-col .row.p-30 .col .article-detail .tag {
        margin: 0 2.66667vw 2.66667vw 0; }
        @media (min-width: 769px) {
          .one-col .row.p-30 .col .article-detail .tag {
            margin: 0 0.34722vw 0.34722vw 0; } }
        @media (min-width: 1441px) {
          .one-col .row.p-30 .col .article-detail .tag {
            margin: 0 5px 5px 0; } }
      .one-col .row.p-30 .col .article-detail + p {
        margin-top: 0; }
  .one-col .row.relate .col {
    width: calc((100% - 5.33333vw) / 2);
    margin-right: 5.33333vw; }
    @media (min-width: 769px) {
      .one-col .row.relate .col {
        width: calc((100% - 6.25vw) / 4);
        margin-left: 2.08333vw;
        margin-top: 0;
        margin-right: 0; } }
    @media (min-width: 1441px) {
      .one-col .row.relate .col {
        width: calc((100% - 90px) / 4);
        margin-left: 30px; } }
    @media (min-width: 769px) {
      .one-col .row.relate .col:nth-child(4n+1), .one-col .row.relate .col:first-child {
        margin-left: 0; } }
    .one-col .row.relate .col a img {
      transition: all 0.3s ease;
      height: 29.33333vw;
      object-fit: cover;
      width: 100%; }
      @media (min-width: 769px) {
        .one-col .row.relate .col a img {
          height: 13.19444vw; } }
      @media (min-width: 1441px) {
        .one-col .row.relate .col a img {
          height: 190px; } }
    .one-col .row.relate .col a:hover img {
      opacity: 0.6; }
    .one-col .row.relate .col .caption {
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .one-col .row.relate .col .caption {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .row.relate .col .caption {
          margin-top: 20px; } }
    .one-col .row.relate .col:nth-child(even) {
      margin-right: 0; }
    .one-col .row.relate .col img {
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        .one-col .row.relate .col img {
          margin-top: 2.77778vw;
          max-width: 100%;
          max-height: 80vh;
          object-fit: cover; } }
      @media (min-width: 1441px) {
        .one-col .row.relate .col img {
          margin-top: 40px; } }
    .one-col .row.relate .col .article-detail {
      margin-top: 4vw; }
      @media (min-width: 769px) {
        .one-col .row.relate .col .article-detail {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .row.relate .col .article-detail {
          margin-top: 20px; } }
      .one-col .row.relate .col .article-detail .tag {
        margin: 0 2.66667vw 2.66667vw 0; }
        @media (min-width: 769px) {
          .one-col .row.relate .col .article-detail .tag {
            margin: 0 0.34722vw 0.34722vw 0; } }
        @media (min-width: 1441px) {
          .one-col .row.relate .col .article-detail .tag {
            margin: 0 5px 5px 0; } }
      .one-col .row.relate .col .article-detail + p {
        margin-top: 0; }

.one-col .column {
  width: 100%;
  padding: 18.66667vw 5.33333vw 13.33333vw;
  background-color: #ecece7; }
  @media (min-width: 769px) {
    .one-col .column {
      padding: 6.94444vw 9.375vw; } }
  @media (min-width: 1441px) {
    .one-col .column {
      padding: 100px 135px; } }
  .one-col .column .content {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    padding: 13.33333vw 5.33333vw 2.66667vw; }
    @media (min-width: 769px) {
      .one-col .column .content {
        padding: 4.86111vw 6.94444vw 6.25vw;
        max-width: 1170px; } }
    @media (min-width: 1441px) {
      .one-col .column .content {
        padding: 70px 100px 90px; } }
    .one-col .column .content:before {
      content: "COLUMN";
      font-size: 14.66667vw;
      font-weight: bold;
      font-family: 'Inter', sans-serif;
      color: #bebeb2;
      line-height: 10.66667vw;
      position: absolute;
      left: 5.33333vw;
      top: -5.33333vw; }
      @media (min-width: 769px) {
        .one-col .column .content:before {
          font-size: 4.72222vw;
          line-height: 3.47222vw;
          left: 6.94444vw;
          top: -1.73611vw; } }
      @media (min-width: 1441px) {
        .one-col .column .content:before {
          font-size: 68px;
          line-height: 50px;
          left: 100px;
          top: -25px; } }
    .one-col .column .content h3 {
      margin-top: 0; }
    .one-col .column .content .detail {
      border-top: 0.8vw solid #eae9e6;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding-top: 10.66667vw;
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        .one-col .column .content .detail {
          border-top: 0.20833vw solid #eae9e6;
          padding-top: 2.77778vw;
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .column .content .detail {
          border-top: 3px solid #eae9e6;
          padding-top: 40px;
          margin-top: 20px; } }
      .one-col .column .content .detail .head {
        font-size: 5.33333vw;
        font-weight: bold;
        line-height: 1;
        margin-top: 0; }
        @media (min-width: 769px) {
          .one-col .column .content .detail .head {
            font-size: 1.66667vw; } }
        @media (min-width: 1441px) {
          .one-col .column .content .detail .head {
            font-size: 24px; } }
      .one-col .column .content .detail p {
        font-size: 4.26667vw;
        line-height: 2; }
        @media (min-width: 769px) {
          .one-col .column .content .detail p {
            font-size: 1.11111vw; } }
        @media (min-width: 1441px) {
          .one-col .column .content .detail p {
            font-size: 16px; } }
        .one-col .column .content .detail p + p {
          margin-top: 4vw; }
          @media (min-width: 769px) {
            .one-col .column .content .detail p + p {
              margin-top: 1.38889vw; } }
          @media (min-width: 1441px) {
            .one-col .column .content .detail p + p {
              margin-top: 20px; } }

.one-col .question {
  margin-top: 8vw;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  border-top: 0.26667vw solid #e3e2df;
  border-bottom: 0.26667vw solid #e3e2df;
  background: #fff;
  padding-top: 5.33333vw;
  padding-bottom: 5.33333vw;
  margin-bottom: 0; }
  @media (min-width: 769px) {
    .one-col .question {
      margin-top: 3.47222vw;
      border-top: 0.06944vw solid #e3e2df;
      border-bottom: 0.06944vw solid #e3e2df;
      padding-top: 1.38889vw;
      padding-bottom: 1.38889vw; } }
  @media (min-width: 1441px) {
    .one-col .question {
      margin-top: 50px;
      border-top: 1px solid #e3e2df;
      border-bottom: 1px solid #e3e2df;
      padding-top: 20px;
      padding-bottom: 20px; } }
  .one-col .question + h2 {
    margin-top: 10.66667vw; }
    @media (min-width: 769px) {
      .one-col .question + h2 {
        margin-top: 4.86111vw; } }
    @media (min-width: 1441px) {
      .one-col .question + h2 {
        margin-top: 70px; } }
  .one-col .question.open dt {
    padding-right: 20vw;
    background: url("../img/open.png") no-repeat;
    background-size: 6.66667vw;
    background-position: top 4.8vw right 5.33333vw; }
    @media (min-width: 769px) {
      .one-col .question.open dt {
        padding-right: 6.94444vw;
        background-size: 1.73611vw;
        background-position: top 50% right 2.77778vw; } }
    @media (min-width: 1441px) {
      .one-col .question.open dt {
        padding-right: 100px;
        background-size: 25px;
        background-position: top 50% right 40px; } }
  .one-col .question dt,
  .one-col .question dd {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0;
    padding: 0 20vw 0 16vw; }
    @media (min-width: 769px) {
      .one-col .question dt,
      .one-col .question dd {
        padding: 0 6.94444vw 0 6.94444vw; } }
    @media (min-width: 1441px) {
      .one-col .question dt,
      .one-col .question dd {
        padding: 0 100px 0 100px; } }
    .one-col .question dt p,
    .one-col .question dd p {
      margin-top: 0; }
  .one-col .question dt {
    background: url("../img/close.png") no-repeat;
    background-size: 6.66667vw;
    background-position: top 2.13333vw right 5.33333vw;
    cursor: pointer;
    font-weight: bold;
    line-height: 2;
    font-size: 4.53333vw; }
    @media (min-width: 769px) {
      .one-col .question dt {
        background-size: 1.73611vw;
        background-position: top 50% right 2.77778vw;
        font-size: 1.25vw; } }
    @media (min-width: 1441px) {
      .one-col .question dt {
        background-size: 25px;
        background-position: top 50% right 40px;
        font-size: 18px; } }
    .one-col .question dt:before {
      content: "Q";
      font-size: 6.66667vw;
      font-weight: bold;
      position: absolute;
      color: #a6a6a6;
      left: 5.33333vw;
      top: 1.46667vw;
      line-height: 1; }
      @media (min-width: 769px) {
        .one-col .question dt:before {
          font-size: 2.36111vw;
          left: 2.77778vw;
          top: calc(50% - 1.18056vw);
          line-height: 1; } }
      @media (min-width: 1441px) {
        .one-col .question dt:before {
          font-size: 34px;
          top: calc(50% - 17px);
          left: 40px; } }
  .one-col .question dd {
    display: none;
    overflow: hidden;
    transition: max-height 1s ease;
    margin-top: 7.2vw;
    font-size: 4.26667vw; }
    @media (min-width: 769px) {
      .one-col .question dd {
        margin-top: 2.08333vw;
        font-size: 1.11111vw; } }
    @media (min-width: 1441px) {
      .one-col .question dd {
        margin-top: 30px;
        font-size: 16px; } }
    .one-col .question dd:before {
      content: "A";
      font-size: 6.66667vw;
      font-weight: bold;
      position: absolute;
      color: #e29f92;
      left: 5.33333vw;
      top: 1.46667vw;
      line-height: 1; }
      @media (min-width: 769px) {
        .one-col .question dd:before {
          font-size: 2.36111vw;
          left: 2.77778vw;
          top: calc(50% - 1.18056vw);
          line-height: 1; } }
      @media (min-width: 1441px) {
        .one-col .question dd:before {
          font-size: 34px;
          left: 40px;
          top: calc(50% - 17px); } }
  .one-col .question + .question {
    margin-top: 0;
    border-top: 0; }

.one-col .big-button {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 8vw; }
  @media (min-width: 769px) {
    .one-col .big-button {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .one-col .big-button {
      margin-top: 50px; } }
  .one-col .big-button .button {
    width: calc((100% - 5.33333vw) / 2);
    margin-top: 5.33333vw;
    margin-right: 5.33333vw; }
    @media (min-width: 769px) {
      .one-col .big-button .button {
        width: calc((100% - 11.66667vw) / 4);
        margin-top: 4.16667vw;
        margin-right: 3.88889vw; } }
    @media (min-width: 1441px) {
      .one-col .big-button .button {
        width: calc((100% - 168px) / 4);
        margin-top: 60px;
        margin-right: 56px; } }
    .one-col .big-button .button:nth-child(-n+2) {
      margin-top: 0; }
    @media (min-width: 769px) {
      .one-col .big-button .button:nth-child(-n+4) {
        margin-top: 0; } }
    .one-col .big-button .button:nth-child(even) {
      margin-right: 0; }
      @media (min-width: 769px) {
        .one-col .big-button .button:nth-child(even) {
          margin-right: 3.88889vw; } }
      @media (min-width: 1441px) {
        .one-col .big-button .button:nth-child(even) {
          margin-right: 56px; } }
    @media (min-width: 769px) {
      .one-col .big-button .button:nth-child(4n) {
        margin-right: 0; } }
    @media (min-width: 1441px) {
      .one-col .big-button .button:nth-child(4n) {
        margin-right: 0; } }
    .one-col .big-button .button.disable a {
      color: #e3e2df;
      border-color: #e3e2df;
      pointer-events: none; }
      .one-col .big-button .button.disable a:after {
        background: url("../img/disable-right-arrow.png") 50% 50% no-repeat;
        background-size: 99%; }
  .one-col .big-button a {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 37.33333vw;
    text-decoration: none;
    text-align: center;
    border-radius: 5.33333vw;
    border: 0.8vw solid #65b393;
    background: #fff; }
    @media (min-width: 769px) {
      .one-col .big-button a {
        height: 11.11111vw;
        border-radius: 1.38889vw;
        border: 0.20833vw solid #65b393; } }
    @media (min-width: 1441px) {
      .one-col .big-button a {
        height: 160px;
        border-radius: 20px;
        border: 3px solid #65b393; } }
    .one-col .big-button a p {
      font-size: 4.53333vw;
      line-height: 1.5;
      font-weight: bold;
      text-align: center;
      margin-top: 0; }
      @media (min-width: 769px) {
        .one-col .big-button a p {
          font-size: 1.25vw; } }
      @media (min-width: 1441px) {
        .one-col .big-button a p {
          font-size: 18px; } }
      .one-col .big-button a p span {
        display: block; }
        .one-col .big-button a p span + span {
          margin-top: 0.66667vw; }
          @media (min-width: 769px) {
            .one-col .big-button a p span + span {
              margin-top: 0.34722vw; } }
          @media (min-width: 1441px) {
            .one-col .big-button a p span + span {
              margin-top: 5px; } }
      .one-col .big-button a p .small {
        font-weight: normal;
        font-size: 3.73333vw;
        display: inline-flex;
        text-align: center; }
        @media (min-width: 769px) {
          .one-col .big-button a p .small {
            font-size: 0.97222vw; } }
        @media (min-width: 1441px) {
          .one-col .big-button a p .small {
            font-size: 14px; } }
      .one-col .big-button a p .big {
        font-size: 5.6vw;
        display: inline-flex;
        text-align: center; }
        @media (min-width: 769px) {
          .one-col .big-button a p .big {
            font-size: 1.52778vw; } }
        @media (min-width: 1441px) {
          .one-col .big-button a p .big {
            font-size: 22px; } }
    .one-col .big-button a:hover:after {
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
    .one-col .big-button a:after {
      content: "";
      display: block;
      position: absolute;
      width: 2.8vw;
      height: 3.06667vw;
      background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
      background-size: 99%;
      bottom: 2.66667vw;
      right: 2.66667vw; }
      @media (min-width: 769px) {
        .one-col .big-button a:after {
          width: 0.76389vw;
          height: 0.83333vw;
          bottom: 1.38889vw;
          right: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .big-button a:after {
          width: 11px;
          height: 12px;
          bottom: 20px;
          right: 20px; } }
    .one-col .big-button a.pink {
      border: 0.8vw solid #e29f92; }
      @media (min-width: 769px) {
        .one-col .big-button a.pink {
          border: 0.20833vw solid #e29f92; } }
      @media (min-width: 1441px) {
        .one-col .big-button a.pink {
          border: 3px solid #e29f92; } }
      .one-col .big-button a.pink:after {
        background: url("../img/pink-right-arrow.png") 50% 50% no-repeat;
        background-size: 99%; }
  .one-col .big-button .caption {
    font-size: 3.73333vw;
    text-align: center;
    line-height: 1.5;
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      .one-col .big-button .caption {
        font-size: 0.97222vw;
        margin-top: 0.34722vw; } }
    @media (min-width: 1441px) {
      .one-col .big-button .caption {
        font-size: 14px;
        margin-top: 5px; } }
  .one-col .big-button + h2 {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      .one-col .big-button + h2 {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      .one-col .big-button + h2 {
        margin-top: 50px; } }

.one-col .timeline {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 4vw;
  padding-left: 8vw;
  padding-bottom: 2.66667vw; }
  @media (min-width: 769px) {
    .one-col .timeline {
      margin-top: 2.08333vw;
      padding-left: 4.16667vw;
      padding-bottom: 1.38889vw; } }
  @media (min-width: 1441px) {
    .one-col .timeline {
      margin-top: 30px;
      padding-left: 60px;
      padding-bottom: 20px; } }
  .one-col .timeline:before {
    content: "";
    display: block;
    width: 1.33333vw;
    height: 100%;
    background: #e3e2df;
    position: absolute;
    top: 0;
    left: 1.33333vw; }
    @media (min-width: 769px) {
      .one-col .timeline:before {
        width: 0.34722vw;
        left: 1.73611vw; } }
    @media (min-width: 1441px) {
      .one-col .timeline:before {
        width: 5px;
        left: 25px; } }
  .one-col .timeline .milestones {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 5.33333vw;
    background: #fff;
    border: 0.26667vw solid #e3e2df;
    border-radius: 2.66667vw;
    padding: 5.33333vw 5.33333vw 4vw; }
    @media (min-width: 769px) {
      .one-col .timeline .milestones {
        margin-top: 1.38889vw;
        border: 0.06944vw solid #e3e2df;
        border-radius: 0.69444vw;
        padding: 1.38889vw 1.73611vw; } }
    @media (min-width: 1441px) {
      .one-col .timeline .milestones {
        margin-top: 20px;
        background: #fff;
        border: 1px solid #e3e2df;
        border-radius: 10px;
        padding: 20px 25px; } }
    @media (min-width: 769px) {
      .one-col .timeline .milestones + .milestones {
        margin-top: 2.08333vw; } }
    @media (min-width: 1441px) {
      .one-col .timeline .milestones + .milestones {
        margin-top: 30px; } }
    .one-col .timeline .milestones.pink {
      border: 0.26667vw solid #e29f92;
      color: #e29f92; }
      @media (min-width: 769px) {
        .one-col .timeline .milestones.pink {
          border: 0.06944vw solid #e29f92; } }
      @media (min-width: 1441px) {
        .one-col .timeline .milestones.pink {
          border: 1px solid #e29f92; } }
      .one-col .timeline .milestones.pink:before {
        border-color: #e29f92; }
      .one-col .timeline .milestones.pink:after {
        border-left: 0.26667vw solid #e29f92;
        border-bottom: 0.26667vw solid #e29f92; }
        @media (min-width: 769px) {
          .one-col .timeline .milestones.pink:after {
            border-left: 0.06944vw solid #e29f92;
            border-bottom: 0.06944vw solid #e29f92; } }
        @media (min-width: 1441px) {
          .one-col .timeline .milestones.pink:after {
            border-left: 1px solid #e29f92;
            border-bottom: 1px solid #e29f92; } }
    .one-col .timeline .milestones:before {
      content: "";
      width: 4vw;
      height: 4vw;
      border-radius: 2vw;
      border: 0.8vw solid #c1c0bc;
      background: #fff;
      position: absolute;
      left: -8.26667vw;
      top: 5.33333vw; }
      @media (min-width: 769px) {
        .one-col .timeline .milestones:before {
          width: 1.04167vw;
          height: 1.04167vw;
          border-radius: 0.55556vw;
          border: 0.20833vw solid #c1c0bc;
          left: -2.84722vw;
          top: 1.875vw; } }
      @media (min-width: 1441px) {
        .one-col .timeline .milestones:before {
          width: 15px;
          height: 15px;
          border-radius: 8px;
          border: 3px solid #c1c0bc;
          left: -41px;
          top: 27px; } }
    .one-col .timeline .milestones:after {
      content: "";
      width: 2vw;
      height: 2vw;
      border-left: 0.26667vw solid #e3e2df;
      border-bottom: 0.26667vw solid #e3e2df;
      background: #fff;
      position: absolute;
      left: -1.2vw;
      top: 6.26667vw;
      transform: rotate(45deg); }
      @media (min-width: 769px) {
        .one-col .timeline .milestones:after {
          width: 1.04167vw;
          height: 1.04167vw;
          border-left: 0.06944vw solid #e3e2df;
          border-bottom: 0.06944vw solid #e3e2df;
          left: -0.55556vw;
          top: 1.875vw; } }
      @media (min-width: 1441px) {
        .one-col .timeline .milestones:after {
          width: 15px;
          height: 15px;
          border-left: 1px solid #e3e2df;
          border-bottom: 1px solid #e3e2df;
          left: -8px;
          top: 27px; } }
    .one-col .timeline .milestones .time {
      font-weight: bold;
      font-size: 4.53333vw;
      line-height: 1.1;
      padding-right: 3.33333vw;
      position: relative; }
      .one-col .timeline .milestones .time:after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% + 0.1em);
        top: 0;
        right: 0;
        background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 0.26667vw 1.2vw;
        background-repeat: repeat-y; }
        @media (min-width: 769px) {
          .one-col .timeline .milestones .time:after {
            background-size: 0.06944vw 0.27778vw; } }
        @media (min-width: 1441px) {
          .one-col .timeline .milestones .time:after {
            background-size: 1px 4px; } }
      @media (min-width: 769px) {
        .one-col .timeline .milestones .time {
          font-size: 1.25vw;
          padding-right: 1.73611vw;
          line-height: 1.6;
          width: 9.72222vw; } }
      @media (min-width: 1441px) {
        .one-col .timeline .milestones .time {
          font-size: 18px;
          padding-right: 25px;
          width: 140px; } }
    .one-col .timeline .milestones .detail {
      width: 100%;
      position: relative;
      display: block;
      flex-wrap: wrap;
      font-size: 4.53333vw;
      line-height: 2;
      font-weight: bold;
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        .one-col .timeline .milestones .detail {
          font-size: 1.25vw;
          line-height: 1.6;
          margin-top: 0;
          padding-left: 1.73611vw;
          width: calc(100% - 9.72222vw); } }
      @media (min-width: 1441px) {
        .one-col .timeline .milestones .detail {
          font-size: 18px;
          padding-left: 25px;
          width: calc(100% - 140px); } }

.one-col .list-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 8vw; }
  @media (min-width: 769px) {
    .one-col .list-item {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    .one-col .list-item {
      margin-top: 50px; } }
  .one-col .list-item .item {
    width: 100%;
    position: relative;
    display: block;
    flex-wrap: wrap;
    padding: 8vw 5.33333vw;
    background: #fff; }
    .one-col .list-item .item:hover {
      box-shadow: 0 0 2.66667vw 0 rgba(0, 0, 0, 0.1); }
      @media (min-width: 769px) {
        .one-col .list-item .item:hover {
          box-shadow: 0 0 0.69444vw 0 rgba(0, 0, 0, 0.1); } }
      @media (min-width: 1441px) {
        .one-col .list-item .item:hover {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); } }
    @media (min-width: 769px) {
      .one-col .list-item .item {
        display: block;
        width: calc((100% - 8.33333vw) / 3);
        margin-right: 4.16667vw;
        padding: 4.16667vw 2.08333vw 9.02778vw; } }
    @media (min-width: 1441px) {
      .one-col .list-item .item {
        width: calc((100% - 120px) / 3);
        margin-right: 60px;
        padding: 60px 30px 130px; } }
    @media (min-width: 769px) {
      .one-col .list-item .item:nth-child(3n) {
        margin-right: 0; } }
    @media (min-width: 1441px) {
      .one-col .list-item .item:nth-child(3n) {
        margin-right: 0; } }
    .one-col .list-item .item + .item {
      margin-top: 8vw; }
      @media (min-width: 769px) {
        .one-col .list-item .item + .item {
          margin-top: 0; } }
    .one-col .list-item .item .image {
      width: 100%;
      position: relative;
      display: block;
      height: 44vw; }
      @media (min-width: 769px) {
        .one-col .list-item .item .image {
          height: 15.97222vw; } }
      @media (min-width: 1441px) {
        .one-col .list-item .item .image {
          height: 230px; } }
      .one-col .list-item .item .image img {
        width: auto;
        margin: auto;
        height: 100%; }
    .one-col .list-item .item .item-content {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 6.66667vw;
      text-align: center; }
      @media (min-width: 769px) {
        .one-col .list-item .item .item-content {
          margin-top: 3.47222vw; } }
      @media (min-width: 1441px) {
        .one-col .list-item .item .item-content {
          margin-top: 50px; } }
      .one-col .list-item .item .item-content .title {
        font-weight: bold;
        font-size: 5.06667vw;
        line-height: 1.5;
        width: 100%; }
        @media (min-width: 769px) {
          .one-col .list-item .item .item-content .title {
            font-size: 1.38889vw; } }
        @media (min-width: 1441px) {
          .one-col .list-item .item .item-content .title {
            font-size: 20px; } }
      .one-col .list-item .item .item-content p {
        font-size: 4vw;
        margin-top: 4vw;
        text-align: left; }
        @media (min-width: 769px) {
          .one-col .list-item .item .item-content p {
            font-size: 1.04167vw;
            margin-top: 1.38889vw; } }
        @media (min-width: 1441px) {
          .one-col .list-item .item .item-content p {
            font-size: 15px;
            margin-top: 20px; } }
    @media (min-width: 769px) {
      .one-col .list-item .item .button-block {
        margin-top: 0;
        position: absolute;
        bottom: 2.77778vw;
        left: 0; } }
    @media (min-width: 1441px) {
      .one-col .list-item .item .button-block {
        margin-top: 0;
        bottom: 60px; } }

.one-col .survey {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 2.66667vw; }
  @media (min-width: 769px) {
    .one-col .survey {
      padding-bottom: 0.69444vw; } }
  @media (min-width: 1441px) {
    .one-col .survey {
      padding-bottom: 10px; } }
  .one-col .survey .survey-control {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 8vw; }
    @media (min-width: 769px) {
      .one-col .survey .survey-control {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      .one-col .survey .survey-control {
        margin-top: 40px; } }
    .one-col .survey .survey-control select {
      appearance: none;
      width: 62.66667vw;
      height: 12vw;
      line-height: 12vw;
      border: 0.26667vw solid #e3e2df;
      border-radius: 1.33333vw;
      padding: 0 8vw 0 4vw;
      outline: none;
      font-weight: bold;
      margin-right: 4vw;
      font-size: 4.26667vw;
      background: #fff url("../img/select-icon.png") 56vw 50% no-repeat;
      background-size: 3.06667vw; }
      .one-col .survey .survey-control select::-ms-expand {
        display: none; }
      @media (min-width: 769px) {
        .one-col .survey .survey-control select {
          width: 20.83333vw;
          height: 2.77778vw;
          line-height: 2.77778vw;
          border: 0.06944vw solid #e3e2df;
          border-radius: 0.69444vw;
          padding: 0 2.08333vw 0 1.04167vw;
          outline: none;
          margin-right: 1.38889vw;
          font-size: 1.11111vw;
          background: #fff url("../img/select-icon.png") 19.23611vw 50% no-repeat;
          background-size: 0.83333vw; } }
      @media (min-width: 1441px) {
        .one-col .survey .survey-control select {
          width: 300px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #e3e2df;
          border-radius: 10px;
          padding: 0 30px 0 15px;
          outline: none;
          margin-right: 20px;
          font-size: 16px;
          background: #fff url("../img/select-icon.png") 277px 50% no-repeat;
          background-size: 12px; } }
    .one-col .survey .survey-control .survey-button {
      width: calc(100% - 66.66667vw);
      appearance: none;
      text-decoration: none;
      text-align: center;
      height: 12vw;
      line-height: 12vw;
      border-radius: 6vw;
      background-color: #65b393;
      color: #fff;
      border: none;
      outline: none;
      font-size: 4.26667vw; }
      @media (min-width: 769px) {
        .one-col .survey .survey-control .survey-button {
          width: 6.94444vw;
          height: 2.77778vw;
          line-height: 2.77778vw;
          border-radius: 1.38889vw;
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        .one-col .survey .survey-control .survey-button {
          width: 100px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          font-size: 16px; } }
    .one-col .survey .survey-control .notice {
      width: 100%;
      position: relative;
      font-size: 4.26667vw;
      margin-top: 4vw;
      line-height: 1.6; }
      @media (min-width: 769px) {
        .one-col .survey .survey-control .notice {
          width: auto;
          font-size: 1.11111vw;
          margin-top: 0;
          margin-left: 2.77778vw;
          line-height: 2.77778vw; } }
      @media (min-width: 1441px) {
        .one-col .survey .survey-control .notice {
          font-size: 16px;
          margin-left: 40px;
          line-height: 40px; } }
  .one-col .survey h4 {
    margin-top: 10.66667vw; }
    @media (min-width: 769px) {
      .one-col .survey h4 {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      .one-col .survey h4 {
        margin-top: 40px; } }
  @media (min-width: 769px) {
    .one-col .survey .list {
      margin-top: 1.38889vw; } }
  @media (min-width: 1441px) {
    .one-col .survey .list {
      margin-top: 20px; } }

.one-col .voice {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5.33333vw;
  background-color: #fff;
  margin-top: 6.66667vw; }
  @media (min-width: 769px) {
    .one-col .voice {
      margin-top: 3.47222vw;
      padding: 5.55556vw; } }
  @media (min-width: 1441px) {
    .one-col .voice {
      margin-top: 50px;
      padding: 80px; } }
  .one-col .voice .head-box {
    padding-bottom: 6.66667vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 0.8vw solid #eae9e6; }
    @media (min-width: 769px) {
      .one-col .voice .head-box {
        border-bottom: 0.20833vw solid #eae9e6;
        padding-bottom: 2.08333vw; } }
    @media (min-width: 1441px) {
      .one-col .voice .head-box {
        border-bottom: 3px solid #eae9e6;
        padding-bottom: 30px; } }
    .one-col .voice .head-box .detail {
      font-weight: bold;
      font-size: 4.26667vw;
      line-height: 2; }
      @media (min-width: 769px) {
        .one-col .voice .head-box .detail {
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        .one-col .voice .head-box .detail {
          font-size: 16px; } }
    .one-col .voice .head-box .head {
      font-weight: bold;
      font-size: 6.13333vw;
      line-height: 1.6;
      margin-top: 4vw;
      width: 100%; }
      @media (min-width: 769px) {
        .one-col .voice .head-box .head {
          font-size: 2.08333vw;
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .voice .head-box .head {
          font-size: 30px;
          margin-top: 20px; } }
    .one-col .voice .head-box .author {
      font-size: 4vw;
      color: #666666;
      margin-top: 5.33333vw;
      line-height: 1.6; }
      @media (min-width: 769px) {
        .one-col .voice .head-box .author {
          font-size: 1.25vw;
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .voice .head-box .author {
          font-size: 15px;
          margin-top: 20px; } }
      .one-col .voice .head-box .author span + span {
        margin-left: 5.33333vw; }
        @media (min-width: 769px) {
          .one-col .voice .head-box .author span + span {
            margin-left: 1.04167vw; } }
        @media (min-width: 1441px) {
          .one-col .voice .head-box .author span + span {
            margin-left: 15px; } }
  .one-col .voice .content-box {
    margin-top: 8vw; }
    @media (min-width: 769px) {
      .one-col .voice .content-box {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      .one-col .voice .content-box {
        margin-top: 40px; } }
    .one-col .voice .content-box p {
      font-size: 4.26667vw;
      line-height: 2; }
      @media (min-width: 769px) {
        .one-col .voice .content-box p {
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        .one-col .voice .content-box p {
          font-size: 16px; } }
      .one-col .voice .content-box p:first-child {
        margin-top: 0; }

.one-col .table-box {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 8vw;
  overflow: auto; }
  @media (min-width: 769px) {
    .one-col .table-box {
      margin-top: 3.47222vw;
      padding: 0 6.25vw; } }
  @media (min-width: 1441px) {
    .one-col .table-box {
      margin-top: 50px;
      padding: 0 90px; } }
  .one-col .table-box table {
    width: 100%;
    position: relative;
    border: 0.26667vw solid #dcdcdc; }
    @media (min-width: 769px) {
      .one-col .table-box table {
        border: 0.06944vw solid #dcdcdc; } }
    @media (min-width: 1441px) {
      .one-col .table-box table {
        border: 1px solid #dcdcdc;
        border-collapse: collapse; } }
    .one-col .table-box table tr,
    .one-col .table-box table td,
    .one-col .table-box table th {
      line-height: 1.6;
      border: 0.26667vw solid #dcdcdc;
      position: relative; }
      @media (min-width: 769px) {
        .one-col .table-box table tr,
        .one-col .table-box table td,
        .one-col .table-box table th {
          border: 0.06944vw solid #dcdcdc; } }
      @media (min-width: 1441px) {
        .one-col .table-box table tr,
        .one-col .table-box table td,
        .one-col .table-box table th {
          border: 1px solid #dcdcdc;
          border-collapse: collapse; } }
      .one-col .table-box table tr .list:first-child,
      .one-col .table-box table td .list:first-child,
      .one-col .table-box table th .list:first-child {
        margin-top: 0; }
        .one-col .table-box table tr .list:first-child li:first-child,
        .one-col .table-box table td .list:first-child li:first-child,
        .one-col .table-box table th .list:first-child li:first-child {
          margin-top: 0; }
    .one-col .table-box table td,
    .one-col .table-box table th {
      vertical-align: top;
      padding: 5.33333vw; }
      @media (min-width: 769px) {
        .one-col .table-box table td,
        .one-col .table-box table th {
          padding: 1.38889vw; } }
      @media (min-width: 1441px) {
        .one-col .table-box table td,
        .one-col .table-box table th {
          padding: 20px; } }
      .one-col .table-box table td .small,
      .one-col .table-box table th .small {
        font-size: 3.46667vw; }
        @media (min-width: 769px) {
          .one-col .table-box table td .small,
          .one-col .table-box table th .small {
            font-size: 0.90278vw; } }
        @media (min-width: 1441px) {
          .one-col .table-box table td .small,
          .one-col .table-box table th .small {
            font-size: 13px; } }
    .one-col .table-box table th {
      text-align: left;
      font-weight: bold;
      background-color: #e5e5e5;
      font-size: 4.8vw; }
      @media (min-width: 769px) {
        .one-col .table-box table th {
          font-size: 1.25vw; } }
      @media (min-width: 1441px) {
        .one-col .table-box table th {
          font-size: 18px; } }
    .one-col .table-box table td {
      text-align: left;
      background-color: #ffffff;
      font-size: 4.26667vw; }
      @media (min-width: 769px) {
        .one-col .table-box table td {
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        .one-col .table-box table td {
          font-size: 16px; } }
      .one-col .table-box table td.head {
        font-weight: bold;
        background-color: #f6f6f6;
        font-size: 4.26667vw;
        width: 53.33333vw;
        white-space: nowrap; }
        @media (min-width: 769px) {
          .one-col .table-box table td.head {
            font-size: 1.25vw;
            width: 17.36111vw;
            white-space: normal; } }
        @media (min-width: 1441px) {
          .one-col .table-box table td.head {
            font-size: 18px;
            width: 250px; } }
    .one-col .table-box table .small-table {
      border: none;
      border-collapse: collapse; }
      .one-col .table-box table .small-table tr,
      .one-col .table-box table .small-table td,
      .one-col .table-box table .small-table th {
        border: none;
        background: none;
        font-size: 3.46667vw;
        white-space: nowrap; }
        @media (min-width: 769px) {
          .one-col .table-box table .small-table tr,
          .one-col .table-box table .small-table td,
          .one-col .table-box table .small-table th {
            font-size: 0.90278vw;
            white-space: normal; } }
        @media (min-width: 1441px) {
          .one-col .table-box table .small-table tr,
          .one-col .table-box table .small-table td,
          .one-col .table-box table .small-table th {
            font-size: 13px; } }
        .one-col .table-box table .small-table tr:first-child,
        .one-col .table-box table .small-table td:first-child,
        .one-col .table-box table .small-table th:first-child {
          padding-left: 0; }
      .one-col .table-box table .small-table tr {
        border-bottom: 0.26667vw solid #dcdcdc; }
        @media (min-width: 769px) {
          .one-col .table-box table .small-table tr {
            border-bottom: 0.06944vw solid #dcdcdc; } }
        @media (min-width: 1441px) {
          .one-col .table-box table .small-table tr {
            border-bottom: 1px solid #dcdcdc;
            border-collapse: collapse; } }
    .one-col .table-box table .text-right {
      width: 100%;
      text-align: right;
      display: block;
      margin-top: 5.33333vw;
      font-size: 3.2vw;
      line-height: 1.5; }
      @media (min-width: 769px) {
        .one-col .table-box table .text-right {
          margin-top: 1.38889vw;
          font-size: 0.83333vw; } }
      @media (min-width: 1441px) {
        .one-col .table-box table .text-right {
          margin-top: 20px;
          font-size: 12px; } }

.pagination {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20vw; }
  @media (min-width: 769px) {
    .pagination {
      margin-top: 6.94444vw; } }
  @media (min-width: 1441px) {
    .pagination {
      margin-top: 100px; } }
  .pagination .sumpage {
    width: 100%;
    text-align: center;
    font-size: 3.73333vw;
    line-height: 2; }
    @media (min-width: 769px) {
      .pagination .sumpage {
        font-size: 0.97222vw;
        text-align: left; } }
    @media (min-width: 1441px) {
      .pagination .sumpage {
        font-size: 14px; } }
    .pagination .sumpage .big {
      font-weight: bold;
      font-size: 4vw;
      line-height: 2; }
      @media (min-width: 769px) {
        .pagination .sumpage .big {
          font-size: 1.52778vw; } }
      @media (min-width: 1441px) {
        .pagination .sumpage .big {
          font-size: 22px; } }
  .pagination .list-page {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.66667vw; }
    @media (min-width: 769px) {
      .pagination .list-page {
        width: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0;
        top: 0.41667vw; } }
    @media (min-width: 1441px) {
      .pagination .list-page {
        top: 6px; } }
    .pagination .list-page .detail-page {
      width: 100%;
      padding: 0 16vw;
      height: 10.66667vw;
      line-height: 10.66667vw;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4vw;
      z-index: 1; }
      @media (min-width: 769px) {
        .pagination .list-page .detail-page {
          font-size: 1.04167vw;
          padding: 0 4.16667vw;
          height: 2.77778vw;
          line-height: 2.77778vw; } }
      @media (min-width: 1441px) {
        .pagination .list-page .detail-page {
          font-size: 15px;
          padding: 0 60px;
          height: 40px;
          line-height: 40px; } }
      .pagination .list-page .detail-page span {
        font-weight: bold; }
  .pagination .prev,
  .pagination .next {
    width: 10.66667vw;
    height: 10.66667vw;
    border-radius: 5.33333vw;
    background: #65b393;
    position: absolute;
    z-index: 2; }
    .pagination .prev.hide,
    .pagination .next.hide {
      display: none; }
    @media (min-width: 769px) {
      .pagination .prev,
      .pagination .next {
        width: 2.77778vw;
        height: 2.77778vw;
        border-radius: 1.38889vw; } }
    @media (min-width: 1441px) {
      .pagination .prev,
      .pagination .next {
        width: 40px;
        height: 40px;
        border-radius: 20px; } }
    .pagination .prev:hover:after,
    .pagination .next:hover:after {
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
    .pagination .prev:after,
    .pagination .next:after {
      content: "";
      display: block;
      position: absolute;
      width: 2.8vw;
      height: 3.06667vw;
      background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
      background-size: 99%;
      bottom: 4vw;
      right: 4vw; }
      @media (min-width: 769px) {
        .pagination .prev:after,
        .pagination .next:after {
          width: 0.76389vw;
          height: 0.83333vw;
          bottom: 0.97222vw;
          right: 0.97222vw; } }
      @media (min-width: 1441px) {
        .pagination .prev:after,
        .pagination .next:after {
          width: 11px;
          height: 12px;
          bottom: 14px;
          right: 14px; } }
  .pagination .prev {
    top: 0;
    left: 0; }
    .pagination .prev:after {
      transform: rotate(180deg); }
    .pagination .prev:hover:after {
      animation: arrowSlideleft 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 backwards; }
  .pagination .next {
    top: 0;
    right: 0; }
  .pagination ul {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 10.66667vw;
    height: 10.66667vw; }
    @media (min-width: 769px) {
      .pagination ul {
        width: auto;
        padding: 0 5.55556vw;
        height: 2.77778vw; } }
    @media (min-width: 1441px) {
      .pagination ul {
        padding: 0 80px;
        height: 40px; } }
    .pagination ul li {
      width: 10.66667vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
      @media (min-width: 769px) {
        .pagination ul li {
          width: 2.77778vw; } }
      @media (min-width: 1441px) {
        .pagination ul li {
          width: 40px; } }
      .pagination ul li span,
      .pagination ul li a {
        text-decoration: none;
        font-size: 4vw;
        color: #cccccc; }
        @media (min-width: 769px) {
          .pagination ul li span,
          .pagination ul li a {
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          .pagination ul li span,
          .pagination ul li a {
            font-size: 15px; } }
        .pagination ul li span.active,
        .pagination ul li a.active {
          color: #222222;
          font-weight: bold; }
        .pagination ul li span.dot,
        .pagination ul li a.dot {
          padding-bottom: 2.66667vw; }
          @media (min-width: 769px) {
            .pagination ul li span.dot,
            .pagination ul li a.dot {
              padding-bottom: 0.69444vw; } }
          @media (min-width: 1441px) {
            .pagination ul li span.dot,
            .pagination ul li a.dot {
              padding-bottom: 10px; } }
  .pagination + .button-block {
    margin-top: 18.66667vw; }
    @media (min-width: 769px) {
      .pagination + .button-block {
        margin-top: 5.55556vw; } }
    @media (min-width: 1441px) {
      .pagination + .button-block {
        margin-top: 80px; } }

.news-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10.66667vw;
  padding-bottom: 4vw; }
  @media (min-width: 769px) {
    .news-list {
      margin-top: 2.77778vw;
      padding-bottom: 0; } }
  @media (min-width: 1441px) {
    .news-list {
      margin-top: 40px; } }
  .news-list .item {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    border-bottom: 0.26667vw solid #eae9e6; }
    .news-list .item.arrow:hover:after {
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
    @media (min-width: 769px) {
      .news-list .item.arrow:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
        background-size: 99%;
        width: 0.76389vw;
        height: 0.83333vw;
        top: calc((100% - 0.83333vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.arrow:after {
        width: 11px;
        height: 12px;
        top: calc((100% - 12px)/2); } }
    @media (min-width: 769px) {
      .news-list .item.pdf:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/PDF.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 1.38889vw;
        height: 1.38889vw;
        top: calc((100% - 1.38889vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.pdf:after {
        width: 20px;
        height: 20px;
        top: calc((100% - 20px)/2); } }
    @media (min-width: 769px) {
      .news-list .item.excel:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/excel.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 1.38889vw;
        height: 1.38889vw;
        top: calc((100% - 1.38889vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.excel:after {
        width: 20px;
        height: 20px;
        top: calc((100% - 20px)/2); } }
    @media (min-width: 769px) {
      .news-list .item.pp:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/pp.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 1.38889vw;
        height: 1.38889vw;
        top: calc((100% - 1.38889vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.pp:after {
        width: 20px;
        height: 20px;
        top: calc((100% - 20px)/2); } }
    @media (min-width: 769px) {
      .news-list .item.word:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/word.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 1.38889vw;
        height: 1.38889vw;
        top: calc((100% - 1.38889vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.word:after {
        width: 20px;
        height: 20px;
        top: calc((100% - 20px)/2); } }
    @media (min-width: 769px) {
      .news-list .item.zip:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/zip.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 1.38889vw;
        height: 1.38889vw;
        top: calc((100% - 1.38889vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.zip:after {
        width: 20px;
        height: 20px;
        top: calc((100% - 20px)/2); } }
    @media (min-width: 769px) {
      .news-list .item.window:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/ico_blank.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 0.97222vw;
        height: 0.97222vw;
        top: calc((100% - 0.97222vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      .news-list .item.window:after {
        width: 14px;
        height: 14px;
        top: calc((100% - 14px)/2); } }
    @media (min-width: 769px) {
      .news-list .item {
        padding-top: 2.08333vw;
        padding-bottom: 2.08333vw;
        border-bottom: 0.06944vw solid #eae9e6; } }
    @media (min-width: 1441px) {
      .news-list .item {
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #eae9e6; } }
    .news-list .item:first-child {
      margin-top: 0; }
    .news-list .item a {
      text-decoration: none;
      position: relative; }
      .news-list .item a:hover.item-title {
        border-bottom: 1px solid #222; }
    .news-list .item .item-detail {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .news-list .item .item-detail .date {
        font-family: 'Inter', sans-serif;
        font-weight: normal;
        color: #a6a6a6;
        font-size: 3.73333vw; }
        @media (min-width: 769px) {
          .news-list .item .item-detail .date {
            font-size: 0.97222vw; } }
        @media (min-width: 1441px) {
          .news-list .item .item-detail .date {
            font-size: 14px; } }
      .news-list .item .item-detail .tag {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        line-height: 1;
        margin-left: 2.66667vw;
        height: 5.33333vw;
        padding: 0 2.66667vw 0.26667vw;
        border-radius: 2.66667vw;
        background: #e3e3e3;
        color: #888888;
        font-size: 2.93333vw; }
        @media (min-width: 769px) {
          .news-list .item .item-detail .tag {
            margin-left: 0.69444vw;
            height: 1.38889vw;
            padding: 0 0.69444vw 0;
            border-radius: 0.69444vw;
            font-size: 0.76389vw; } }
        @media (min-width: 1441px) {
          .news-list .item .item-detail .tag {
            margin-left: 10px;
            height: 20px;
            padding: 0 10px 0;
            border-radius: 10px;
            font-size: 11px; } }
      .news-list .item .item-detail a.tag {
        text-decoration: none; }
        .news-list .item .item-detail a.tag:hover {
          background: #65b393;
          color: #fff; }
        .news-list .item .item-detail a.tag.pink:hover {
          background: #E29F92; }
    .news-list .item .item-title {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      margin-top: 3.73333vw;
      font-size: 4vw;
      line-height: 1.6;
      border-bottom: 1px solid transparent; }
      @media (min-width: 769px) {
        .news-list .item .item-title {
          margin-top: 1.04167vw;
          font-size: 1.04167vw; } }
      @media (min-width: 1441px) {
        .news-list .item .item-title {
          margin-top: 15px;
          font-size: 15px; } }

.lightboxOverlay {
  background: #f9f9f5;
  opacity: 1; }

.lightbox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0 !important;
  overflow: auto;
  padding-bottom: 50px; }
  .lightbox .lb-outerContainer {
    height: auto !important;
    width: 62.93333vw !important; }
    @media (min-width: 769px) {
      .lightbox .lb-outerContainer {
        width: 28.47222vw !important; } }
    @media (min-width: 1441px) {
      .lightbox .lb-outerContainer {
        width: 410px !important; } }
  .lightbox .lb-closeContainer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%; }
    .lightbox .lb-closeContainer .lb-close {
      width: 13.33333vw;
      height: 13.33333vw;
      background: #65b393 url("../img/close-icon.png") 50% 50% no-repeat;
      background-size: 5.33333vw;
      border-radius: 6.66667vw;
      position: absolute;
      top: 5.33333vw;
      right: 5.33333vw;
      opacity: 1; }
      @media (min-width: 769px) {
        .lightbox .lb-closeContainer .lb-close {
          width: 3.47222vw;
          height: 3.47222vw;
          background-size: 1.38889vw;
          border-radius: 1.73611vw;
          top: 2.08333vw;
          right: 2.08333vw; } }
      @media (min-width: 1441px) {
        .lightbox .lb-closeContainer .lb-close {
          width: 50px;
          height: 50px;
          background-size: 20px;
          border-radius: 25px;
          top: 30px;
          right: 30px; } }

.lb-container {
  margin-top: 20vh;
  position: relative; }
  @media (min-width: 769px) {
    .lb-container {
      margin-top: 11.11111vw; } }
  @media (min-width: 1441px) {
    .lb-container {
      margin-top: 160px; } }
  .lb-container .lb-image {
    width: 63.33333vw !important;
    height: auto !important; }
    @media (min-width: 769px) {
      .lb-container .lb-image {
        width: 28.47222vw !important; } }
    @media (min-width: 1441px) {
      .lb-container .lb-image {
        width: 410px !important; } }
  .lb-container .lb-nav {
    position: absolute;
    bottom: -9.33333vw;
    display: flex;
    margin: 6.66667vw auto 0;
    width: 46.66667vw;
    height: 0;
    left: 8.26667vw;
    top: inherit; }
    @media (min-width: 769px) {
      .lb-container .lb-nav {
        width: 48.61111vw;
        top: calc(50% - 1.38889vw);
        left: 50%;
        transform: translateX(-50%);
        margin: 0; } }
    @media (min-width: 1441px) {
      .lb-container .lb-nav {
        width: 700px;
        top: calc(50% - 20px); } }
    .lb-container .lb-nav .lb-prev,
    .lb-container .lb-nav .lb-next {
      width: 10.66667vw;
      height: 10.66667vw;
      border-radius: 5.33333vw;
      background: #65b393;
      position: absolute;
      opacity: 1; }
      .lb-container .lb-nav .lb-prev.hide,
      .lb-container .lb-nav .lb-next.hide {
        display: none; }
      @media (min-width: 769px) {
        .lb-container .lb-nav .lb-prev,
        .lb-container .lb-nav .lb-next {
          width: 2.77778vw;
          height: 2.77778vw;
          border-radius: 1.38889vw; } }
      @media (min-width: 1441px) {
        .lb-container .lb-nav .lb-prev,
        .lb-container .lb-nav .lb-next {
          width: 40px;
          height: 40px;
          border-radius: 20px; } }
      .lb-container .lb-nav .lb-prev:hover:after,
      .lb-container .lb-nav .lb-next:hover:after {
        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
      .lb-container .lb-nav .lb-prev:after,
      .lb-container .lb-nav .lb-next:after {
        content: "";
        display: block;
        position: absolute;
        width: 2.8vw;
        height: 3.06667vw;
        background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
        background-size: 99%;
        bottom: 3.73333vw;
        right: 4vw; }
        @media (min-width: 769px) {
          .lb-container .lb-nav .lb-prev:after,
          .lb-container .lb-nav .lb-next:after {
            width: 0.76389vw;
            height: 0.83333vw;
            bottom: 0.97222vw;
            right: 0.97222vw; } }
        @media (min-width: 1441px) {
          .lb-container .lb-nav .lb-prev:after,
          .lb-container .lb-nav .lb-next:after {
            width: 11px;
            height: 12px;
            bottom: 14px;
            right: 14px; } }
      .lb-container .lb-nav .lb-prev:before,
      .lb-container .lb-nav .lb-next:before {
        display: none; }
    .lb-container .lb-nav .lb-prev {
      top: 50%;
      transform: translateY(-50%);
      left: 0; }
      .lb-container .lb-nav .lb-prev:after {
        transform: rotate(180deg); }
      .lb-container .lb-nav .lb-prev:hover:after {
        animation: arrowSlideleft 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 backwards; }
    .lb-container .lb-nav .lb-next {
      top: 50%;
      transform: translateY(-50%);
      right: 0; }

.lb-dataContainer .lb-data .lb-details {
  width: 100%;
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    .lb-dataContainer .lb-data .lb-details {
      margin-top: 2.08333vw; } }
  @media (min-width: 1441px) {
    .lb-dataContainer .lb-data .lb-details {
      margin-top: 30px; } }

.lb-dataContainer .lb-data .lb-number {
  text-align: center;
  color: #222222;
  font-size: 4vw; }
  @media (min-width: 769px) {
    .lb-dataContainer .lb-data .lb-number {
      font-size: 1.04167vw; } }
  @media (min-width: 1441px) {
    .lb-dataContainer .lb-data .lb-number {
      font-size: 15px; } }

.collapse .collapse-box {
  max-height: 0;
  transition: all 0.5s ease;
  overflow: hidden; }

.collapse label {
  position: relative; }
  .collapse label h5 {
    position: relative; }
    .collapse label h5:after {
      content: "";
      display: inline-block;
      margin-left: 2.66667vw;
      height: 4vw;
      width: 4vw;
      background: url(../img/close.png) 0 50% no-repeat;
      background-size: 4vw;
      position: absolute;
      top: calc((100% - 4vw)/2); }
      @media (min-width: 769px) {
        .collapse label h5:after {
          height: 1.04167vw;
          width: 1.04167vw;
          margin-left: 0.69444vw;
          background-size: 1.04167vw;
          vertical-align: middle;
          top: calc((100% - 1.04167vw)/2); } }
      @media (min-width: 1441px) {
        .collapse label h5:after {
          height: 15px;
          width: 15px;
          margin-left: 10px;
          background-size: 15px;
          top: calc((100% - 15px)/2); } }
  .collapse label span {
    width: auto;
    min-height: 14.66667vw;
    border-radius: 14.66667vw;
    background-color: #65b393;
    color: #fff;
    position: relative;
    display: inline-block;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
    font-weight: normal;
    font-size: 4vw;
    text-decoration: none;
    padding: 4vw 10.66667vw 4vw 5.33333vw;
    line-height: 1.5;
    margin-top: 4vw; }
    @media (min-width: 769px) {
      .collapse label span {
        width: auto;
        min-height: 3.47222vw;
        border-radius: 4.16667vw;
        font-size: 1.04167vw;
        text-decoration: none;
        padding: 1.04167vw 3.47222vw 1.04167vw 2.08333vw;
        margin-bottom: 1.38889vw;
        margin-right: 1.38889vw;
        margin-top: 6.66667vw; } }
    @media (min-width: 1441px) {
      .collapse label span {
        min-height: 50px;
        border-radius: 60px;
        font-size: 15px;
        text-decoration: none;
        padding: 15px 50px 15px 30px;
        margin-bottom: 20px;
        margin-right: 20px;
        margin-top: 50px; } }
    .collapse label span:after {
      content: "";
      display: block;
      position: absolute;
      width: 2.8vw;
      height: 3.06667vw;
      background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
      background-size: 99%;
      top: calc(50% - 1.6vw);
      right: 5.33333vw;
      transform: rotate(90deg); }
      @media (min-width: 769px) {
        .collapse label span:after {
          width: 0.76389vw;
          height: 0.83333vw;
          top: calc(50% - 0.41667vw);
          right: 1.38889vw; } }
      @media (min-width: 1441px) {
        .collapse label span:after {
          width: 11px;
          height: 12px;
          top: calc(50% - 6px);
          right: 20px; } }

.collapse input:checked ~ label h5:after {
  background: url(../img/open.png) 0 50% no-repeat;
  background-size: 4vw; }
  @media (min-width: 769px) {
    .collapse input:checked ~ label h5:after {
      background-size: 1.04167vw; } }
  @media (min-width: 1441px) {
    .collapse input:checked ~ label h5:after {
      background-size: 15px; } }

.collapse input:checked ~ label span:after {
  transform: rotate(-90deg); }

.collapse input:checked ~ .collapse-box {
  max-height: 1500px; }

#backnumber h1 {
  margin-top: 13.33333vw; }
  @media (min-width: 769px) {
    #backnumber h1 {
      margin-top: 5.55556vw; } }
  @media (min-width: 1441px) {
    #backnumber h1 {
      margin-top: 80px; } }
  #backnumber h1 + p {
    margin-top: 9.33333vw;
    font-size: 4.26667vw; }
    @media (min-width: 769px) {
      #backnumber h1 + p {
        margin-top: 2.77778vw;
        font-size: 1.11111vw; } }
    @media (min-width: 1441px) {
      #backnumber h1 + p {
        margin-top: 40px;
        font-size: 16px; } }

#backnumber h3 {
  margin-top: 20vw; }
  @media (min-width: 769px) {
    #backnumber h3 {
      margin-top: 6.94444vw; } }
  @media (min-width: 1441px) {
    #backnumber h3 {
      margin-top: 100px; } }

#backnumber .book-hightlight {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8vw; }
  @media (min-width: 769px) {
    #backnumber .book-hightlight {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    #backnumber .book-hightlight {
      margin-top: 40px; } }
  #backnumber .book-hightlight a {
    position: relative;
    width: 100%;
    text-decoration: none; }
  #backnumber .book-hightlight .highlight-item {
    width: 100%;
    height: 122vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.3s ease; }
    @media (min-width: 769px) {
      #backnumber .book-hightlight .highlight-item {
        height: 31.25vw;
        padding: 0 6.94444vw 0 13.47222vw; } }
    @media (min-width: 1441px) {
      #backnumber .book-hightlight .highlight-item {
        height: 450px;
        padding: 0 100px 0 194px; } }
    #backnumber .book-hightlight .highlight-item .background-image {
      position: absolute;
      width: 600%;
      max-width: initial;
      top: -40vw;
      left: -200%;
      filter: blur(9.33333vw) brightness(0.95);
      pointer-events: none;
      border: none;
      outline: none;
      display: block;
      -webkit-transform: translateZ(0);
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
      transition: all 0.3s ease; }
      @media (min-width: 769px) {
        #backnumber .book-hightlight .highlight-item .background-image {
          top: -27.77778vw;
          filter: blur(3.95833vw) brightness(0.95);
          pointer-events: none; } }
      @media (min-width: 1441px) {
        #backnumber .book-hightlight .highlight-item .background-image {
          top: -400px;
          filter: blur(57px) brightness(0.95); } }
    #backnumber .book-hightlight .highlight-item .content-box {
      z-index: 2;
      position: relative;
      display: block; }
      @media (min-width: 769px) {
        #backnumber .book-hightlight .highlight-item .content-box {
          display: flex;
          flex-wrap: wrap;
          align-items: center; } }
      #backnumber .book-hightlight .highlight-item .content-box img {
        width: 36.53333vw;
        margin: auto;
        transition: all 0.3s ease; }
        @media (min-width: 769px) {
          #backnumber .book-hightlight .highlight-item .content-box img {
            width: 16.11111vw; } }
        @media (min-width: 1441px) {
          #backnumber .book-hightlight .highlight-item .content-box img {
            width: 232px; } }
      @media (min-width: 769px) {
        #backnumber .book-hightlight .highlight-item .content-box .right-content {
          width: calc(100% - 16.11111vw);
          position: relative;
          display: block;
          align-items: center;
          padding-left: 4.86111vw; } }
      @media (min-width: 1441px) {
        #backnumber .book-hightlight .highlight-item .content-box .right-content {
          width: calc(100% - 232px);
          padding-left: 70px; } }
      #backnumber .book-hightlight .highlight-item .content-box .vol {
        width: 100%;
        text-align: center;
        padding: 0 8vw;
        font-family: 'Inter', sans-serif;
        font-size: 5.33333vw;
        line-height: 1.2;
        margin-top: 6.66667vw;
        color: #fff; }
        @media (min-width: 769px) {
          #backnumber .book-hightlight .highlight-item .content-box .vol {
            padding: 0;
            font-size: 1.66667vw;
            margin-top: 0;
            text-align: left; } }
        @media (min-width: 1441px) {
          #backnumber .book-hightlight .highlight-item .content-box .vol {
            font-size: 24px; } }
      #backnumber .book-hightlight .highlight-item .content-box .title {
        width: 100%;
        text-align: center;
        padding: 0 8vw;
        font-size: 8vw;
        line-height: 1.5;
        margin-top: 4vw;
        font-weight: bold;
        color: #fff; }
        @media (min-width: 769px) {
          #backnumber .book-hightlight .highlight-item .content-box .title {
            padding: 0;
            font-size: 3.19444vw;
            margin-top: 1.38889vw;
            text-align: left; } }
        @media (min-width: 1441px) {
          #backnumber .book-hightlight .highlight-item .content-box .title {
            font-size: 46px;
            margin-top: 20px; } }
    @media (min-width: 769px) {
      #backnumber .book-hightlight .highlight-item:hover .background-image {
        filter: blur(3.95833vw) brightness(0.95);
        opacity: 0.6; } }
    @media (min-width: 1441px) {
      #backnumber .book-hightlight .highlight-item:hover .background-image {
        filter: blur(57px) brightness(0.95); } }
    #backnumber .book-hightlight .highlight-item:hover:after {
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
    #backnumber .book-hightlight .highlight-item:after {
      content: "";
      display: block;
      position: absolute;
      width: 2.8vw;
      height: 3.06667vw;
      background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
      background-size: cover;
      bottom: 4vw;
      right: 4vw; }
      @media (min-width: 769px) {
        #backnumber .book-hightlight .highlight-item:after {
          width: 0.76389vw;
          height: 0.83333vw;
          bottom: 2.08333vw;
          right: 2.08333vw; } }
      @media (min-width: 1441px) {
        #backnumber .book-hightlight .highlight-item:after {
          width: 11px;
          height: 12px;
          bottom: 30px;
          right: 30px; } }

#backnumber .book-list {
  margin-top: 8vw;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 769px) {
    #backnumber .book-list {
      margin-top: 0; } }
  #backnumber .book-list .item {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 12vw; }
    @media (min-width: 769px) {
      #backnumber .book-list .item {
        margin-top: 2.77778vw;
        padding-bottom: 1.38889vw;
        width: calc(100%/3); } }
    @media (min-width: 1441px) {
      #backnumber .book-list .item {
        margin-top: 40px;
        padding-bottom: 20px; } }
    #backnumber .book-list .item a {
      position: relative;
      width: 100%;
      text-decoration: none;
      display: block;
      text-align: center;
      flex-wrap: wrap; }
      #backnumber .book-list .item a:hover img {
        box-shadow: 0 0 2.66667vw 0 rgba(0, 0, 0, 0.1); }
        @media (min-width: 769px) {
          #backnumber .book-list .item a:hover img {
            box-shadow: 0 0 0.69444vw 0 rgba(0, 0, 0, 0.1); } }
        @media (min-width: 1441px) {
          #backnumber .book-list .item a:hover img {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); } }
      #backnumber .book-list .item a:hover .title:after {
        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
    #backnumber .book-list .item img {
      width: 40vw;
      margin: auto;
      transition: all 0.3s ease; }
      @media (min-width: 769px) {
        #backnumber .book-list .item img {
          width: 10.41667vw; } }
      @media (min-width: 1441px) {
        #backnumber .book-list .item img {
          width: 10.41667vw; } }
    #backnumber .book-list .item .vol {
      width: 100%;
      padding: 0 8vw;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 4vw;
      line-height: 1.2;
      margin-top: 6.66667vw; }
      @media (min-width: 769px) {
        #backnumber .book-list .item .vol {
          padding: 0 1.38889vw;
          font-size: 1.04167vw;
          margin-top: 2.08333vw; } }
      @media (min-width: 1441px) {
        #backnumber .book-list .item .vol {
          padding: 0 20px;
          font-size: 15px;
          margin-top: 30px; } }
    #backnumber .book-list .item .title {
      padding: 0 0 0 5.33333vw;
      text-align: center;
      font-size: 5.33333vw;
      line-height: 1.5;
      margin-top: 4vw;
      font-weight: bold;
      position: relative;
      display: inline-block; }
      @media (min-width: 769px) {
        #backnumber .book-list .item .title {
          padding: 0 0 0 1.38889vw;
          font-size: 1.38889vw;
          margin-top: 0.69444vw; } }
      @media (min-width: 1441px) {
        #backnumber .book-list .item .title {
          padding: 0 0 0 20px;
          font-size: 20px;
          margin-top: 10px; } }
      #backnumber .book-list .item .title:after {
        content: "";
        display: block;
        position: absolute;
        width: 2.8vw;
        height: 3.06667vw;
        background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
        background-size: cover;
        top: 2.66667vw;
        left: 0; }
        @media (min-width: 769px) {
          #backnumber .book-list .item .title:after {
            width: 0.76389vw;
            height: 0.83333vw;
            top: 0.69444vw; } }
        @media (min-width: 1441px) {
          #backnumber .book-list .item .title:after {
            width: 11px;
            height: 12px;
            bottom: 20px;
            top: 10px; } }
    #backnumber .book-list .item:first-child {
      margin-top: 0; }
      @media (min-width: 769px) {
        #backnumber .book-list .item:first-child {
          margin-top: 2.77778vw; } }
      @media (min-width: 1441px) {
        #backnumber .book-list .item:first-child {
          margin-top: 40px; } }

#backnumber .search-box {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #backnumber .search-box {
      width: 34.72222vw;
      margin: 0 auto 0;
      position: absolute;
      top: 9.30556vw;
      right: 6.94444vw; } }
  @media (min-width: 1441px) {
    #backnumber .search-box {
      width: 500px;
      margin: 0 auto 0;
      top: 134px;
      right: 100px; } }
  #backnumber .search-box .search-input {
    width: 100%;
    position: relative;
    display: flex;
    height: 14.66667vw;
    padding: 0 14.66667vw 0 6.66667vw;
    border-radius: 8vw;
    border: 0.26667vw solid #eae9e6;
    outline: none;
    font-size: 4vw;
    appearance: none; }
    @media (min-width: 769px) {
      #backnumber .search-box .search-input {
        height: 3.81944vw;
        padding: 0 3.81944vw 0 3.33333vw;
        border-radius: 2.08333vw;
        border: 0.06944vw solid #eae9e6;
        font-size: 1.04167vw; } }
    @media (min-width: 1441px) {
      #backnumber .search-box .search-input {
        height: 55px;
        padding: 0 55px 0 25px;
        border-radius: 30px;
        border: 1px solid #eae9e6;
        font-size: 15px; } }
  #backnumber .search-box .search-button {
    position: absolute;
    width: 14.66667vw;
    height: 14.66667vw;
    outline: none;
    appearance: none;
    border: none;
    background: none;
    border-radius: 8vw;
    right: 0;
    top: 0;
    background: url("../img/icn_search.png") 50% 50% no-repeat;
    background-size: 4.93333vw;
    cursor: pointer; }
    @media (min-width: 769px) {
      #backnumber .search-box .search-button {
        width: 3.81944vw;
        height: 3.81944vw;
        border-radius: 2.08333vw;
        background-size: 1.31944vw; } }
    @media (min-width: 1441px) {
      #backnumber .search-box .search-button {
        width: 55px;
        height: 55px;
        border-radius: 30px;
        background-size: 19px; } }

#sp2014 .main a,
#backnumberdetail .main a {
  transition: all 0.3s ease; }
  #sp2014 .main a:hover,
  #backnumberdetail .main a:hover {
    opacity: 0.6; }

#sp2014 .top-banner,
#backnumberdetail .top-banner {
  width: 100%;
  position: relative;
  display: block;
  margin-top: 6.66667vw; }
  @media (min-width: 769px) {
    #sp2014 .top-banner,
    #backnumberdetail .top-banner {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    #sp2014 .top-banner,
    #backnumberdetail .top-banner {
      margin-top: 40px; } }
  #sp2014 .top-banner img,
  #backnumberdetail .top-banner img {
    width: 100%;
    margin-top: 0; }

#sp2014 .main-info,
#backnumberdetail .main-info {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 6.66667vw; }
  @media (min-width: 769px) {
    #sp2014 .main-info,
    #backnumberdetail .main-info {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    #sp2014 .main-info,
    #backnumberdetail .main-info {
      margin-top: 40px; } }
  #sp2014 .main-info .image,
  #backnumberdetail .main-info .image {
    order: 1;
    width: 40.53333vw;
    margin: 0 auto; }
    #sp2014 .main-info .image img,
    #backnumberdetail .main-info .image img {
      margin: 0; }
    @media (min-width: 769px) {
      #sp2014 .main-info .image,
      #backnumberdetail .main-info .image {
        order: 2;
        width: 21.11111vw; } }
    @media (min-width: 1441px) {
      #sp2014 .main-info .image,
      #backnumberdetail .main-info .image {
        width: 304px; } }
  #sp2014 .main-info .info,
  #backnumberdetail .main-info .info {
    order: 2;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 6.66667vw; }
    @media (min-width: 769px) {
      #sp2014 .main-info .info,
      #backnumberdetail .main-info .info {
        order: 1;
        margin-top: 0;
        width: calc(100% - 21.11111vw);
        padding-right: 6.94444vw; } }
    @media (min-width: 1441px) {
      #sp2014 .main-info .info,
      #backnumberdetail .main-info .info {
        width: calc(100% - 304px);
        padding-right: 100px; } }
    #sp2014 .main-info .info .vol,
    #backnumberdetail .main-info .info .vol {
      width: 100%;
      position: relative;
      display: flex;
      font-size: 4.26667vw;
      font-weight: bold;
      line-height: 2;
      font-family: 'Inter', sans-serif; }
      @media (min-width: 769px) {
        #sp2014 .main-info .info .vol,
        #backnumberdetail .main-info .info .vol {
          font-size: 1.11111vw; } }
      @media (min-width: 1441px) {
        #sp2014 .main-info .info .vol,
        #backnumberdetail .main-info .info .vol {
          font-size: 16px; } }
    #sp2014 .main-info .info .title,
    #backnumberdetail .main-info .info .title {
      width: 100%;
      margin-top: 1.33333vw;
      line-height: 1.5; }
      @media (min-width: 769px) {
        #sp2014 .main-info .info .title,
        #backnumberdetail .main-info .info .title {
          margin-top: 0.69444vw; } }
      @media (min-width: 1441px) {
        #sp2014 .main-info .info .title,
        #backnumberdetail .main-info .info .title {
          margin-top: 10px; } }
    #sp2014 .main-info .info .subtitle,
    #backnumberdetail .main-info .info .subtitle {
      font-size: 5.6vw;
      font-weight: bold;
      margin-top: 8vw;
      width: 100%;
      line-height: 2; }
      @media (min-width: 769px) {
        #sp2014 .main-info .info .subtitle,
        #backnumberdetail .main-info .info .subtitle {
          font-size: 1.80556vw;
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        #sp2014 .main-info .info .subtitle,
        #backnumberdetail .main-info .info .subtitle {
          font-size: 26px;
          margin-top: 20px; } }
    #sp2014 .main-info .info .detail,
    #backnumberdetail .main-info .info .detail {
      margin-top: 2.66667vw;
      line-height: 2; }
      @media (min-width: 769px) {
        #sp2014 .main-info .info .detail,
        #backnumberdetail .main-info .info .detail {
          margin-top: 0; } }

#sp2014 .article-detail .tag:hover,
#backnumberdetail .article-detail .tag:hover {
  opacity: 1; }

#sp2014 .button-block a:hover,
#backnumberdetail .button-block a:hover {
  opacity: 1; }

#sp2014 h3,
#backnumberdetail h3 {
  flex-wrap: wrap; }
  #sp2014 h3 .note,
  #backnumberdetail h3 .note {
    width: 100%;
    font-weight: normal; }
    @media (min-width: 769px) {
      #sp2014 h3 .note,
      #backnumberdetail h3 .note {
        position: absolute;
        right: 0;
        bottom: 1.04167vw;
        width: auto;
        font-size: 0.90278vw; } }
    @media (min-width: 1441px) {
      #sp2014 h3 .note,
      #backnumberdetail h3 .note {
        bottom: 15px;
        font-size: 13px; } }

#postnumber h1 {
  margin-top: 13.33333vw; }
  @media (min-width: 769px) {
    #postnumber h1 {
      margin-top: 5.55556vw; } }
  @media (min-width: 1441px) {
    #postnumber h1 {
      margin-top: 80px; } }

#sitemap h1 {
  padding: 25.33333vw 10.66667vw; }
  @media (min-width: 769px) {
    #sitemap h1 {
      padding: 15.27778vw 3.81944vw 0; } }
  @media (min-width: 1441px) {
    #sitemap h1 {
      padding: 220px 55px 0; } }

#sitemap .breadcrumb + .row {
  margin-top: 6.66667vw; }
  @media (min-width: 769px) {
    #sitemap .breadcrumb + .row {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    #sitemap .breadcrumb + .row {
      margin-top: 50px; } }
  #sitemap .breadcrumb + .row .col a {
    font-size: 4.26667vw;
    font-weight: bold; }
    @media (min-width: 769px) {
      #sitemap .breadcrumb + .row .col a {
        font-size: 1.11111vw; } }
    @media (min-width: 1441px) {
      #sitemap .breadcrumb + .row .col a {
        font-size: 16px; } }

#sitemap .row {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  #sitemap .row .col {
    width: calc((100% - 5.33333vw) / 2);
    margin-right: 5.33333vw; }
    @media (min-width: 769px) {
      #sitemap .row .col {
        width: calc((100% - 6.94444vw) / 3);
        margin-right: 3.47222vw; } }
    @media (min-width: 1441px) {
      #sitemap .row .col {
        width: calc((100% - 100px) / 3);
        margin-right: 50px; } }
    #sitemap .row .col a {
      text-decoration: none;
      font-size: 3.46667vw;
      color: #65b393;
      line-height: 1.5;
      display: flex;
      width: 100%;
      margin-top: 4vw;
      transition: all 0.3s ease; }
      @media (min-width: 769px) {
        #sitemap .row .col a {
          margin-top: 1.38889vw;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        #sitemap .row .col a {
          margin-top: 20px;
          font-size: 13px; } }
      #sitemap .row .col a:hover {
        opacity: 0.6;
        transition: all 0.3s ease; }
      #sitemap .row .col a.parent {
        margin-top: 8vw;
        font-weight: bold;
        font-size: 4vw;
        padding-bottom: 2.66667vw;
        border-bottom: 0.26667vw solid #eae9e6; }
        @media (min-width: 769px) {
          #sitemap .row .col a.parent {
            margin-top: 2.08333vw;
            border-bottom: 0.06944vw solid #eae9e6;
            padding-bottom: 1.04167vw;
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          #sitemap .row .col a.parent {
            margin-top: 30px;
            border-bottom: 1px solid #eae9e6;
            padding-bottom: 15px;
            font-size: 15px; } }
    #sitemap .row .col:nth-child(even) {
      margin-right: 0; }
      @media (min-width: 769px) {
        #sitemap .row .col:nth-child(even) {
          margin-right: 3.47222vw; } }
      @media (min-width: 1441px) {
        #sitemap .row .col:nth-child(even) {
          margin-right: 50px; } }
    @media (min-width: 769px) {
      #sitemap .row .col:nth-child(3n) {
        margin-right: 0 !important; } }
  #sitemap .row + .row {
    margin-top: 5.33333vw; }
    @media (min-width: 769px) {
      #sitemap .row + .row {
        margin-top: 4.86111vw; } }
    @media (min-width: 1441px) {
      #sitemap .row + .row {
        margin-top: 70px; } }

#sitemap h3.line a {
  text-decoration: none;
  padding-left: 5.33333vw;
  transition: all 0.3s ease; }
  @media (min-width: 769px) {
    #sitemap h3.line a {
      padding-left: 1.38889vw; } }
  @media (min-width: 1441px) {
    #sitemap h3.line a {
      padding-left: 20px; } }
  #sitemap h3.line a:after {
    content: "";
    display: block;
    position: absolute;
    width: 2.8vw;
    height: 3.06667vw;
    background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
    background-size: cover;
    top: 3.33333vw;
    left: 0; }
    @media (min-width: 769px) {
      #sitemap h3.line a:after {
        width: 0.76389vw;
        height: 0.83333vw;
        top: 1.38889vw; } }
    @media (min-width: 1441px) {
      #sitemap h3.line a:after {
        width: 11px;
        height: 12px;
        bottom: 20px;
        top: 20px; } }
  #sitemap h3.line a:hover {
    opacity: 0.6; }
    #sitemap h3.line a:hover:after {
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }

#admt h5 {
  border-bottom: 0.26667vw solid #222;
  display: inline-block; }
  @media (min-width: 769px) {
    #admt h5 {
      border-bottom: 0.06944vw solid #222; } }
  @media (min-width: 1441px) {
    #admt h5 {
      border-bottom: 1px solid #222; } }
  #admt h5 + h3 {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      #admt h5 + h3 {
        margin-top: 0.69444vw; } }
    @media (min-width: 1441px) {
      #admt h5 + h3 {
        margin-top: 10px; } }

#admt .box-image {
  align-self: center; }
  #admt .box-image .center {
    overflow: hidden;
    position: relative;
    margin: 8vw auto 0; }
    @media (min-width: 769px) {
      #admt .box-image .center {
        margin: 3.47222vw auto 0; } }
    @media (min-width: 1441px) {
      #admt .box-image .center {
        margin: 50px auto 0; } }
    #admt .box-image .center img {
      margin-top: 0; }
    #admt .box-image .center .sidebg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      background: #E29F92; }
  #admt .box-image .logo {
    max-width: 55.06667vw;
    align-self: center; }
    @media (min-width: 769px) {
      #admt .box-image .logo {
        max-width: 19.44444vw; } }
    @media (min-width: 1441px) {
      #admt .box-image .logo {
        max-width: 280px; } }
  #admt .box-image .col {
    overflow: hidden;
    position: relative;
    margin-top: 8vw; }
    @media (min-width: 769px) {
      #admt .box-image .col {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      #admt .box-image .col {
        margin-top: 50px; } }
    #admt .box-image .col img {
      margin-top: 0; }
    #admt .box-image .col .sidebg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      background: #E29F92; }
  #admt .box-image + h5 {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      #admt .box-image + h5 {
        margin-top: 6.94444vw; } }
    @media (min-width: 1441px) {
      #admt .box-image + h5 {
        margin-top: 100px; } }

#adstudies .top-box {
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  #adstudies .top-box p {
    font-size: 4.26667vw;
    order: 3;
    margin-top: 16vw; }
    @media (min-width: 769px) {
      #adstudies .top-box p {
        font-size: 1.11111vw;
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      #adstudies .top-box p {
        font-size: 16px;
        margin-top: 40px; } }
  #adstudies .top-box h1 {
    width: calc(100% + 10.66667vw);
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 5.33333vw;
    height: 60vw;
    background: #ecece7;
    margin: 0 -5.33333vw 0;
    order: 1; }
    @media (min-width: 769px) {
      #adstudies .top-box h1 {
        height: auto;
        width: 100%;
        background-color: none;
        padding: 0;
        order: 2;
        background: none;
        margin: 3.47222vw 0 0; } }
    @media (min-width: 1441px) {
      #adstudies .top-box h1 {
        margin-top: 50px; } }
  #adstudies .top-box .breadcrumb {
    order: 2; }
    @media (min-width: 769px) {
      #adstudies .top-box .breadcrumb {
        order: 1; } }
  #adstudies .top-box + .filter-box {
    margin-top: 18.66667vw;
    border-top: 0.26667vw solid #eae9e6; }
    @media (min-width: 769px) {
      #adstudies .top-box + .filter-box {
        border-top: 0.06944vw solid #eae9e6;
        margin-top: 4.86111vw; } }
    @media (min-width: 1441px) {
      #adstudies .top-box + .filter-box {
        border-top: 1px solid #eae9e6;
        margin-top: 70px; } }

#adstudies .filter-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.26667vw solid #eae9e6;
  padding: 5.33333vw 0 6.66667vw; }
  @media (min-width: 769px) {
    #adstudies .filter-box {
      border-bottom: 0.06944vw solid #eae9e6;
      padding: 2.77778vw 0; } }
  @media (min-width: 1441px) {
    #adstudies .filter-box {
      border-bottom: 1px solid #eae9e6;
      padding: 40px 0; } }
  #adstudies .filter-box .filter-head {
    width: 100%;
    position: relative;
    display: flex;
    font-size: 4.26667vw;
    font-weight: bold;
    line-height: 1.5; }
    @media (min-width: 769px) {
      #adstudies .filter-box .filter-head {
        width: 13.88889vw;
        font-size: 1.11111vw; }
        #adstudies .filter-box .filter-head:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          border-right: 0.06944vw solid #eae9e6;
          height: 1.73611vw;
          top: 0.13889vw;
          right: 0; } }
    @media (min-width: 1441px) {
      #adstudies .filter-box .filter-head {
        font-size: 16px;
        width: 200px; }
        #adstudies .filter-box .filter-head:after {
          border-right: 1px solid #eae9e6;
          height: 25px;
          top: 2px; } }
  #adstudies .filter-box .filter-list {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 769px) {
      #adstudies .filter-box .filter-list {
        width: calc(100% - 13.88889vw); } }
    @media (min-width: 1441px) {
      #adstudies .filter-box .filter-list {
        width: calc(100% - 200px); } }
  #adstudies .filter-box .filter-item {
    width: auto;
    display: flex;
    flex-wrap: wrap; }
    #adstudies .filter-box .filter-item input {
      display: none; }
      #adstudies .filter-box .filter-item input:checked ~ label {
        color: #fff;
        background: #65b393; }
    #adstudies .filter-box .filter-item label {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 8vw;
      line-height: 6.66667vw;
      padding: 0 6.66667vw;
      border-radius: 4vw;
      border: 0.26667vw solid #65b393;
      color: #65b393;
      font-size: 3.46667vw;
      margin-top: 4vw;
      margin-right: 4.66667vw; }
      #adstudies .filter-box .filter-item label:hover {
        color: #fff;
        background: #65b393; }
      @media (min-width: 769px) {
        #adstudies .filter-box .filter-item label {
          margin-top: 0;
          margin-right: 0;
          margin-left: 1.38889vw;
          height: 2.08333vw;
          line-height: 1;
          padding: 0 1.73611vw;
          border-radius: 1.04167vw;
          border: 0.13889vw solid #65b393;
          font-size: 0.90278vw; } }
      @media (min-width: 1441px) {
        #adstudies .filter-box .filter-item label {
          margin-left: 20px;
          height: 30px;
          padding: 0 25px;
          border-radius: 15px;
          border: 2px solid #65b393;
          font-size: 13px; } }

#adstudies .search-box {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #adstudies .search-box {
      width: 34.72222vw;
      margin: 0 auto 0;
      position: absolute;
      top: 7.36111vw;
      right: 0; } }
  @media (min-width: 1441px) {
    #adstudies .search-box {
      width: 500px;
      margin: 0 auto 0;
      top: 106px;
      right: 0; } }
  #adstudies .search-box .search-input {
    width: 100%;
    position: relative;
    display: flex;
    height: 14.66667vw;
    padding: 0 14.66667vw 0 6.66667vw;
    border-radius: 8vw;
    border: 0.26667vw solid #eae9e6;
    outline: none;
    font-size: 4vw;
    appearance: none; }
    @media (min-width: 769px) {
      #adstudies .search-box .search-input {
        height: 3.81944vw;
        padding: 0 3.81944vw 0 3.33333vw;
        border-radius: 2.08333vw;
        border: 0.06944vw solid #eae9e6;
        font-size: 1.04167vw; } }
    @media (min-width: 1441px) {
      #adstudies .search-box .search-input {
        height: 55px;
        padding: 0 55px 0 25px;
        border-radius: 30px;
        border: 1px solid #eae9e6;
        font-size: 15px; } }
  #adstudies .search-box .search-button {
    position: absolute;
    width: 14.66667vw;
    height: 14.66667vw;
    outline: none;
    appearance: none;
    border: none;
    background: none;
    border-radius: 8vw;
    right: 0;
    top: 0;
    background: url("../img/icn_search.png") 50% 50% no-repeat;
    background-size: 4.93333vw;
    cursor: pointer; }
    @media (min-width: 769px) {
      #adstudies .search-box .search-button {
        width: 3.81944vw;
        height: 3.81944vw;
        border-radius: 2.08333vw;
        background-size: 1.31944vw; } }
    @media (min-width: 1441px) {
      #adstudies .search-box .search-button {
        width: 55px;
        height: 55px;
        border-radius: 30px;
        background-size: 19px; } }

#adstudies .highlight {
  margin-top: 18.66667vw;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 6.66667vw 6.66667vw 13.33333vw;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  text-decoration: none; }
  @media (min-width: 769px) {
    #adstudies .highlight {
      margin-top: 5.55556vw;
      padding: 4.16667vw 5.20833vw 4.16667vw 4.16667vw; } }
  @media (min-width: 1441px) {
    #adstudies .highlight {
      margin-top: 80px;
      padding: 60px 75px 60px 60px; } }
  #adstudies .highlight .img-box {
    overflow: hidden;
    width: 100%; }
    @media (min-width: 769px) {
      #adstudies .highlight .img-box {
        width: 37.5vw;
        max-height: 25vw; } }
    @media (min-width: 1441px) {
      #adstudies .highlight .img-box {
        width: 540px;
        max-height: 360px; } }
    #adstudies .highlight .img-box img {
      margin: auto;
      max-height: 100%;
      max-width: 100%; }
  @media (min-width: 769px) {
    #adstudies .highlight .detail-box {
      width: calc(100% - 37.5vw);
      padding-left: 3.47222vw;
      padding-bottom: 1.38889vw; } }
  @media (min-width: 1441px) {
    #adstudies .highlight .detail-box {
      width: calc(100% - 540px);
      padding-left: 50px;
      padding-bottom: 20px; } }
  #adstudies .highlight img {
    margin: 0;
    transition: all 0.3s ease;
    display: block; }
  #adstudies .highlight .vol {
    margin-top: 6.66667vw;
    font-size: 3.73333vw;
    line-height: 1.2;
    font-family: 'Inter', sans-serif; }
    @media (min-width: 769px) {
      #adstudies .highlight .vol {
        margin-top: 0;
        font-size: 0.97222vw; } }
    @media (min-width: 1441px) {
      #adstudies .highlight .vol {
        font-size: 14px; } }
  #adstudies .highlight .article-detail {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      #adstudies .highlight .article-detail {
        margin-top: 0.69444vw; } }
    @media (min-width: 1441px) {
      #adstudies .highlight .article-detail {
        margin-top: 10px; } }
    #adstudies .highlight .article-detail .tag {
      margin-top: 2.66667vw;
      height: 6.66667vw;
      line-height: 6.4vw;
      border-radius: 4vw;
      padding: 0 4vw;
      margin-right: 1.33333vw;
      margin-left: 0;
      font-size: 3.2vw; }
      @media (min-width: 769px) {
        #adstudies .highlight .article-detail .tag {
          margin-top: 0.69444vw;
          height: 1.73611vw;
          line-height: 1.66667vw;
          border-radius: 1.04167vw;
          padding: 0 1.25vw;
          margin-right: 0.34722vw;
          font-size: 0.83333vw; } }
      @media (min-width: 1441px) {
        #adstudies .highlight .article-detail .tag {
          margin-top: 10px;
          height: 25px;
          line-height: 24px;
          border-radius: 15px;
          padding: 0 18px;
          margin-right: 5px;
          font-size: 12px; } }
    #adstudies .highlight .article-detail .vol {
      margin-bottom: 0; }
  #adstudies .highlight .title {
    font-size: 5.86667vw;
    width: 100%;
    position: relative;
    text-decoration: none;
    line-height: 1.6;
    margin-top: 6.66667vw;
    font-weight: bold; }
    @media (min-width: 769px) {
      #adstudies .highlight .title {
        font-size: 1.52778vw;
        margin-top: 2.08333vw; } }
    @media (min-width: 1441px) {
      #adstudies .highlight .title {
        font-size: 22px;
        margin-top: 30px; } }
  #adstudies .highlight p {
    font-size: 4.26667vw;
    line-height: 2;
    margin-top: 9.33333vw;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (min-width: 769px) {
      #adstudies .highlight p {
        font-size: 1.11111vw;
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      #adstudies .highlight p {
        font-size: 16px;
        margin-top: 40px; } }
  #adstudies .highlight a {
    text-decoration: none;
    transition: all 0.3s ease;
    display: block; }
  @media (min-width: 769px) {
    #adstudies .highlight:hover {
      box-shadow: 0 0 0.69444vw 0 rgba(0, 0, 0, 0.2); } }
  @media (min-width: 1441px) {
    #adstudies .highlight:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); } }
  #adstudies .highlight:hover img {
    opacity: 0.6; }
  #adstudies .highlight:hover:after {
    animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
  #adstudies .highlight:after {
    content: "";
    display: block;
    position: absolute;
    width: 2.8vw;
    height: 3.06667vw;
    background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
    background-size: cover;
    bottom: 4vw;
    right: 4vw; }
    @media (min-width: 769px) {
      #adstudies .highlight:after {
        width: 0.76389vw;
        height: 0.83333vw;
        bottom: 4.16667vw;
        right: 4.16667vw; } }
    @media (min-width: 1441px) {
      #adstudies .highlight:after {
        width: 11px;
        height: 12px;
        bottom: 60px;
        right: 60px; } }

@media (min-width: 769px) {
  #adstudies .row {
    width: calc(100% + 2.08333vw);
    margin: 1.38889vw -1.04167vw 0; } }

@media (min-width: 1441px) {
  #adstudies .row {
    width: calc(100% + 30px);
    margin: 20px -15px 0; } }

#adstudies .row.relate {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #adstudies .row.relate {
      margin-top: 3.47222vw;
      margin-bottom: -2.08333vw; } }
  @media (min-width: 1441px) {
    #adstudies .row.relate {
      margin-top: 50px;
      margin-bottom: -30px; } }
  @media (min-width: 769px) {
    #adstudies .row.relate img {
      margin-top: 0; } }
  @media (min-width: 1441px) {
    #adstudies .row.relate img {
      margin-top: 0; } }
  #adstudies .row.relate .col {
    width: calc((100% - 5.33333vw) / 2);
    margin-right: 5.33333vw; }
    @media (min-width: 769px) {
      #adstudies .row.relate .col {
        width: calc(100% / 4);
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        padding: 1.38889vw 1.04167vw;
        margin-bottom: 2.08333vw; } }
    @media (min-width: 1441px) {
      #adstudies .row.relate .col {
        width: calc(100% / 4);
        margin-right: 0;
        margin-left: 0;
        padding: 20px 15px;
        margin-bottom: 30px; } }
    @media (min-width: 769px) {
      #adstudies .row.relate .col:nth-child(4n+1), #adstudies .row.relate .col:first-child {
        margin-left: 0; } }
    #adstudies .row.relate .col:nth-child(even) {
      margin-right: 0; }

#adstudies .row .col {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); }
  @media (min-width: 769px) {
    #adstudies .row .col:hover {
      transition: all 0.3s ease;
      box-shadow: 0 0 0.69444vw 0 rgba(0, 0, 0, 0.2); } }
  @media (min-width: 1441px) {
    #adstudies .row .col:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); } }
  #adstudies .row .col .article-detail {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      #adstudies .row .col .article-detail {
        margin-top: 0.69444vw; } }
    @media (min-width: 1441px) {
      #adstudies .row .col .article-detail {
        margin-top: 10px; } }
    #adstudies .row .col .article-detail .vol {
      margin-bottom: 0; }
  @media (min-width: 769px) {
    #adstudies .row .col .big {
      width: 100%;
      display: block;
      min-height: 3.47222vw; } }
  @media (min-width: 1441px) {
    #adstudies .row .col .big {
      min-height: 50px; } }

@media (min-width: 769px) {
  #postnumber .main .col {
    width: 38.54167vw; } }

@media (min-width: 1441px) {
  #postnumber .main .col {
    width: 555px; } }

#postnumber .main .col .post-image {
  width: 100%; }
  @media (min-width: 769px) {
    #postnumber .main .col .post-image {
      height: 25.69444vw;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3.47222vw auto 0;
      flex-direction: column; } }
  @media (min-width: 1441px) {
    #postnumber .main .col .post-image {
      height: 370px;
      margin: 50px auto 0; } }
  #postnumber .main .col .post-image img {
    width: 100%; }
    @media (min-width: 769px) {
      #postnumber .main .col .post-image img {
        margin: 0;
        max-width: 100%;
        max-height: 100%;
        width: auto; } }

@media (min-width: 769px) {
  #postnumber .main .row.col-3 .col {
    width: 24.30556vw; } }

@media (min-width: 1441px) {
  #postnumber .main .row.col-3 .col {
    width: 350px; } }

#postnumber .main .row.col-3 .col .post-image {
  width: 100%; }
  @media (min-width: 769px) {
    #postnumber .main .row.col-3 .col .post-image {
      width: 24.30556vw;
      height: 23.125vw;
      object-fit: cover; } }
  @media (min-width: 1441px) {
    #postnumber .main .row.col-3 .col .post-image {
      width: 350px;
      height: 333px; } }

@media (min-width: 769px) {
  #postnumber .main .row.col-4 .col {
    width: 17.15278vw; } }

@media (min-width: 1441px) {
  #postnumber .main .row.col-4 .col {
    width: 247px; } }

#postnumber .main .row.col-4 .col .post-image {
  width: 100%; }
  @media (min-width: 769px) {
    #postnumber .main .row.col-4 .col .post-image {
      height: 12.91667vw; } }
  @media (min-width: 1441px) {
    #postnumber .main .row.col-4 .col .post-image {
      height: 186px; } }

@media (min-width: 769px) {
  #postnumber .main .row.p-60 {
    flex-wrap: nowrap; } }

@media (min-width: 769px) {
  #postnumber .main .row.p-60 .col {
    margin-left: 4.16667vw;
    margin-top: 0;
    width: 100%; } }

@media (min-width: 1441px) {
  #postnumber .main .row.p-60 .col {
    margin-left: 60px; } }

@media (min-width: 769px) {
  #postnumber .main .row.p-60 .col:first-child {
    margin-left: 0; } }

#postnumber .main .row.p-30 .col {
  width: calc((100% - 5.33333vw) / 2);
  margin-right: 5.33333vw; }
  @media (min-width: 769px) {
    #postnumber .main .row.p-30 .col {
      width: calc((100% - 6.25vw) / 4);
      margin-left: 2.08333vw;
      margin-top: 0;
      margin-right: 0; } }
  @media (min-width: 1441px) {
    #postnumber .main .row.p-30 .col {
      width: calc((100% - 90px) / 4);
      margin-left: 30px; } }
  @media (min-width: 769px) {
    #postnumber .main .row.p-30 .col:nth-child(4n+1), #postnumber .main .row.p-30 .col:first-child {
      margin-left: 0; } }
  #postnumber .main .row.p-30 .col a img {
    transition: all 0.3s ease; }
  #postnumber .main .row.p-30 .col a:hover img {
    opacity: 0.6; }
  #postnumber .main .row.p-30 .col .caption {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      #postnumber .main .row.p-30 .col .caption {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      #postnumber .main .row.p-30 .col .caption {
        margin-top: 20px; } }
  #postnumber .main .row.p-30 .col:nth-child(even) {
    margin-right: 0; }
  #postnumber .main .row.p-30 .col img {
    margin-top: 5.33333vw; }
    @media (min-width: 769px) {
      #postnumber .main .row.p-30 .col img {
        margin-top: 2.77778vw;
        max-width: 100%;
        max-height: 80vh;
        object-fit: cover; } }
    @media (min-width: 1441px) {
      #postnumber .main .row.p-30 .col img {
        margin-top: 40px; } }
  #postnumber .main .row.p-30 .col .article-detail {
    margin-top: 4vw; }
    @media (min-width: 769px) {
      #postnumber .main .row.p-30 .col .article-detail {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      #postnumber .main .row.p-30 .col .article-detail {
        margin-top: 20px; } }
    #postnumber .main .row.p-30 .col .article-detail .tag {
      margin: 0 2.66667vw 2.66667vw 0; }
      @media (min-width: 769px) {
        #postnumber .main .row.p-30 .col .article-detail .tag {
          margin: 0 0.34722vw 0.34722vw 0; } }
      @media (min-width: 1441px) {
        #postnumber .main .row.p-30 .col .article-detail .tag {
          margin: 0 5px 5px 0; } }
    #postnumber .main .row.p-30 .col .article-detail + p {
      margin-top: 0; }

#aid-awards .note {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #aid-awards .note {
      margin-top: 3.125vw; } }
  @media (min-width: 1441px) {
    #aid-awards .note {
      margin-top: 45px; } }
  #aid-awards .note + h3.line {
    margin-top: 8vw; }
    @media (min-width: 769px) {
      #aid-awards .note + h3.line {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      #aid-awards .note + h3.line {
        margin-top: 40px; } }

@media (min-width: 769px) {
  #aid-awards h3 {
    margin-top: 5.55556vw; } }

@media (min-width: 1441px) {
  #aid-awards h3 {
    margin-top: 80px; } }

@media (min-width: 769px) {
  #aid-awards .main img {
    margin-top: 2.77778vw; } }

@media (min-width: 1441px) {
  #aid-awards .main img {
    margin-top: 40px; } }

#aid-awards .main img + .achievement {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #aid-awards .main img + .achievement {
      margin-top: 3.61111vw; } }
  @media (min-width: 1441px) {
    #aid-awards .main img + .achievement {
      margin-top: 52px; } }

#aid-awards .main img.full {
  width: 70%; }

#aid-awards .winner .affiliation {
  color: #a6a6a6; }

#aid-awards .winner + .pdf_link {
  margin-top: 10vw; }
  @media (min-width: 769px) {
    #aid-awards .winner + .pdf_link {
      margin-top: 2.43056vw; } }
  @media (min-width: 1441px) {
    #aid-awards .winner + .pdf_link {
      margin-top: 35px; } }

#aid-awards_publication .box-image {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #aid-awards_publication .box-image {
      margin-top: 0; } }
  @media (min-width: 1441px) {
    #aid-awards_publication .box-image {
      margin-top: 0; } }
  @media (min-width: 769px) {
    #aid-awards_publication .box-image img {
      width: 12.5vw;
      height: 17.70833vw;
      object-fit: cover; } }
  @media (min-width: 1441px) {
    #aid-awards_publication .box-image img {
      width: 180px;
      height: 255px; } }

#aid-awards_publication .achievement {
  padding-bottom: 0; }

#aid-awards_entity .achievement {
  padding-bottom: 0;
  margin-top: 8vw; }
  @media (min-width: 769px) {
    #aid-awards_entity .achievement {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    #aid-awards_entity .achievement {
      margin-top: 40px; } }
  #aid-awards_entity .achievement .archivement_link {
    margin-top: 0; }

#sp2014 .main-info {
  align-items: flex-start; }

#aid-data-detail .top-note {
  margin-top: 0;
  position: absolute;
  top: 2.66667vw;
  right: 5.33333vw;
  font-size: 3.2vw; }
  @media (min-width: 769px) {
    #aid-data-detail .top-note {
      top: 0.69444vw;
      right: 1.38889vw;
      font-size: 0.83333vw; } }
  @media (min-width: 1441px) {
    #aid-data-detail .top-note {
      top: 10px;
      right: 20px;
      font-size: 12px; } }

@media (min-width: 769px) {
  #book .button-block a {
    min-width: 23.26389vw; } }

@media (min-width: 1441px) {
  #book .button-block a {
    min-width: 335px; } }

#book-detail .book-detail {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 769px) {
    #book-detail .book-detail {
      margin-top: 4.51389vw;
      min-height: 47.91667vw;
      align-items: flex-start;
      justify-content: flex-start; } }
  @media (min-width: 1441px) {
    #book-detail .book-detail {
      margin-top: 65px;
      min-height: 690px; } }
  #book-detail .book-detail h1 {
    margin-top: 12vw; }
    @media (min-width: 769px) {
      #book-detail .book-detail h1 {
        margin-top: 0; } }
    @media (min-width: 769px) {
      #book-detail .book-detail h1 + .row-book {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      #book-detail .book-detail h1 + .row-book {
        margin-top: 40px; } }
  #book-detail .book-detail .box-image {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 9.33333vw;
    padding-bottom: 4vw; }
    @media (min-width: 769px) {
      #book-detail .book-detail .box-image {
        position: relative;
        top: 1.04167vw;
        left: 0;
        width: 38.88889vw;
        margin-top: 0;
        padding: 0; } }
    @media (min-width: 1441px) {
      #book-detail .book-detail .box-image {
        top: 15px;
        width: 560px; } }
    #book-detail .book-detail .box-image .top-slideshow {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding: 0 12vw;
      height: 78.66667vw; }
      @media (min-width: 769px) {
        #book-detail .book-detail .box-image .top-slideshow {
          padding: 0 3.125vw;
          height: 36.11111vw; } }
      @media (min-width: 1441px) {
        #book-detail .book-detail .box-image .top-slideshow {
          padding: 0 45px;
          height: 520px; } }
      #book-detail .book-detail .box-image .top-slideshow .slick-track {
        display: flex;
        flex-wrap: wrap; }
      #book-detail .book-detail .box-image .top-slideshow .slick-slide {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; }
        #book-detail .book-detail .box-image .top-slideshow .slick-slide > div {
          line-height: 0; }
      #book-detail .book-detail .box-image .top-slideshow .item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; }
        #book-detail .book-detail .box-image .top-slideshow .item a {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          margin: auto;
          outline: none; }
          @media (min-width: 769px) {
            #book-detail .book-detail .box-image .top-slideshow .item a:after {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -46%);
              width: 3.47222vw;
              height: 3.47222vw;
              border-radius: 3.33333vw;
              background: #fafaf7 url("../img/icon_zoom.png") 50% 50% no-repeat;
              background-size: 1.38889vw;
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
              opacity: 0;
              transition: all 0.3s ease; } }
          @media (min-width: 1441px) {
            #book-detail .book-detail .box-image .top-slideshow .item a:after {
              width: 50px;
              height: 50px;
              background-size: 20px;
              border-radius: 25px;
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); } }
          @media (min-width: 769px) {
            #book-detail .book-detail .box-image .top-slideshow .item a:hover:after {
              box-shadow: 0 0 0.69444vw 0 rgba(0, 0, 0, 0.2);
              opacity: 1;
              transition: all 0.3s ease; } }
          @media (min-width: 1441px) {
            #book-detail .book-detail .box-image .top-slideshow .item a:hover:after {
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); } }
          @media (min-width: 769px) {
            #book-detail .book-detail .box-image .top-slideshow .item a:hover img {
              opacity: 0.6; } }
          #book-detail .book-detail .box-image .top-slideshow .item a img {
            margin: auto;
            width: auto;
            max-height: 78.66667vw;
            max-width: 60vw;
            height: auto; }
            @media (min-width: 769px) {
              #book-detail .book-detail .box-image .top-slideshow .item a img {
                max-height: 36.11111vw;
                max-width: 25.27778vw;
                transition: all 0.3s ease; } }
            @media (min-width: 1441px) {
              #book-detail .book-detail .box-image .top-slideshow .item a img {
                max-height: 520px;
                max-width: 364px; } }
    #book-detail .book-detail .box-image .bottom-slideshow {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10.66667vw; }
      @media (min-width: 769px) {
        #book-detail .book-detail .box-image .bottom-slideshow {
          margin-top: 2.43056vw; } }
      @media (min-width: 1441px) {
        #book-detail .book-detail .box-image .bottom-slideshow {
          margin-top: 35px; } }
      #book-detail .book-detail .box-image .bottom-slideshow .item {
        width: 12.66667vw;
        height: 12.66667vw;
        position: relative;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background: #fff;
        border-style: solid;
        border-width: 0.26667vw;
        border-color: #eae9e6; }
        @media (min-width: 769px) {
          #book-detail .book-detail .box-image .bottom-slideshow .item {
            width: 5.83333vw;
            height: 5.83333vw;
            border-width: 0.06944vw;
            justify-content: space-between; } }
        @media (min-width: 1441px) {
          #book-detail .book-detail .box-image .bottom-slideshow .item {
            width: 84px;
            height: 84px;
            border-width: 1px; } }
        #book-detail .book-detail .box-image .bottom-slideshow .item img {
          width: 7.33333vw;
          margin: auto; }
          @media (min-width: 769px) {
            #book-detail .book-detail .box-image .bottom-slideshow .item img {
              width: 3.33333vw; } }
          @media (min-width: 1441px) {
            #book-detail .book-detail .box-image .bottom-slideshow .item img {
              width: 48px; } }
      #book-detail .book-detail .box-image .bottom-slideshow .slick-track {
        width: 100% !important;
        transform: none !important; }
      #book-detail .book-detail .box-image .bottom-slideshow .slick-slide {
        width: 12.66667vw !important;
        height: 12.66667vw;
        margin-right: 2.66667vw;
        margin-bottom: 2.66667vw;
        transform: none !important; }
        @media (min-width: 769px) {
          #book-detail .book-detail .box-image .bottom-slideshow .slick-slide {
            width: 5.83333vw !important;
            height: 5.83333vw;
            margin-right: 0.69444vw;
            margin-bottom: 0.69444vw; } }
        @media (min-width: 1441px) {
          #book-detail .book-detail .box-image .bottom-slideshow .slick-slide {
            width: 84px !important;
            height: 84px;
            margin-right: 10px;
            margin-bottom: 10px; } }
        #book-detail .book-detail .box-image .bottom-slideshow .slick-slide:nth-child(6n) {
          margin-right: 0; }
        #book-detail .book-detail .box-image .bottom-slideshow .slick-slide.slick-current .item {
          border-style: solid;
          border-width: 0.26667vw;
          border-color: #65b393;
          background-color: white; }
          @media (min-width: 769px) {
            #book-detail .book-detail .box-image .bottom-slideshow .slick-slide.slick-current .item {
              border-width: 0.06944vw; } }
          @media (min-width: 1441px) {
            #book-detail .book-detail .box-image .bottom-slideshow .slick-slide.slick-current .item {
              border-width: 1px; } }
    #book-detail .book-detail .box-image .show-all {
      position: relative;
      display: flex;
      color: #65b393;
      font-size: 3.46667vw;
      text-decoration: none;
      border-bottom: 0.26667vw solid #65b393;
      line-height: 1.5;
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        #book-detail .book-detail .box-image .show-all {
          font-size: 0.83333vw;
          border-width: 0.06944vw;
          margin-top: 0; } }
      @media (min-width: 1441px) {
        #book-detail .book-detail .box-image .show-all {
          font-size: 13px;
          border-width: 1px; } }
  @media (min-width: 769px) {
    #book-detail .book-detail .box-content-detail {
      position: relative;
      display: inline-flex;
      flex-wrap: wrap;
      width: calc(100% - 43.05556vw);
      margin-left: 4.09722vw; } }
  @media (min-width: 1441px) {
    #book-detail .book-detail .box-content-detail {
      width: calc(100% - 620px);
      margin-left: 59px; } }
  #book-detail .book-detail .row-book {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.66667vw; }
    @media (min-width: 769px) {
      #book-detail .book-detail .row-book {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      #book-detail .book-detail .row-book {
        margin-top: 20px; } }
    #book-detail .book-detail .row-book .head {
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      font-size: 4vw;
      font-weight: bold;
      line-height: 1.6; }
      @media (min-width: 769px) {
        #book-detail .book-detail .row-book .head {
          font-size: 1.04167vw;
          width: 9.72222vw; } }
      @media (min-width: 1441px) {
        #book-detail .book-detail .row-book .head {
          font-size: 15px;
          width: 140px; } }
    #book-detail .book-detail .row-book .detail {
      width: 100%;
      position: relative;
      display: block;
      font-size: 4vw;
      line-height: 1.6;
      margin-top: 2.66667vw; }
      @media (min-width: 769px) {
        #book-detail .book-detail .row-book .detail {
          font-size: 1.04167vw;
          width: calc(100% - 9.72222vw);
          margin-top: 0; } }
      @media (min-width: 1441px) {
        #book-detail .book-detail .row-book .detail {
          font-size: 15px;
          width: calc(100% - 140px); } }
      #book-detail .book-detail .row-book .detail p {
        font-size: 4vw; }
        @media (min-width: 769px) {
          #book-detail .book-detail .row-book .detail p {
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          #book-detail .book-detail .row-book .detail p {
            font-size: 15px; } }
        #book-detail .book-detail .row-book .detail p:first-child {
          margin-top: 0; }
      #book-detail .book-detail .row-book .detail .note {
        font-size: 3.46667vw;
        margin-top: 2.66667vw;
        padding-left: 4vw;
        text-indent: -4vw;
        line-height: 2; }
        @media (min-width: 769px) {
          #book-detail .book-detail .row-book .detail .note {
            font-size: 0.90278vw;
            margin-top: 0.69444vw;
            padding-left: 1.11111vw;
            text-indent: -1.11111vw; } }
        @media (min-width: 1441px) {
          #book-detail .book-detail .row-book .detail .note {
            font-size: 13px;
            margin-top: 10px;
            padding-left: 16px;
            text-indent: -16px; } }
      #book-detail .book-detail .row-book .detail a {
        margin-top: 2.66667vw;
        color: #65b393;
        font-size: 3.46667vw;
        text-decoration: none;
        border-bottom: 0.26667vw solid #65b393; }
        @media (min-width: 769px) {
          #book-detail .book-detail .row-book .detail a {
            font-size: 0.90278vw;
            margin-top: 0.69444vw;
            border-bottom: 0.06944vw solid #65b393; } }
        @media (min-width: 1441px) {
          #book-detail .book-detail .row-book .detail a {
            font-size: 13px;
            margin-top: 10px;
            border-bottom: 1px solid #65b393; } }
    #book-detail .book-detail .row-book + p {
      margin-top: 12vw; }
      @media (min-width: 769px) {
        #book-detail .book-detail .row-book + p {
          margin-top: 2.77778vw; } }
      @media (min-width: 1441px) {
        #book-detail .book-detail .row-book + p {
          margin-top: 40px; } }
  #book-detail .book-detail p {
    margin-top: 2.66667vw;
    line-height: 1.6; }
    @media (min-width: 769px) {
      #book-detail .book-detail p {
        margin-top: 1.38889vw;
        font-size: 1.04167vw; } }
    @media (min-width: 1441px) {
      #book-detail .book-detail p {
        margin-top: 20px;
        font-size: 15px; } }
    @media (min-width: 769px) {
      #book-detail .book-detail p:first-child {
        margin-top: 0; } }
    @media (min-width: 1441px) {
      #book-detail .book-detail p:first-child {
        margin-top: 0; } }

#book-detail .relate-book {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16vw; }
  @media (min-width: 769px) {
    #book-detail .relate-book {
      margin-top: 6.25vw; } }
  @media (min-width: 1441px) {
    #book-detail .relate-book {
      margin-top: 90px; } }
  #book-detail .relate-book .head {
    font-size: 5.33333vw;
    font-weight: bold;
    width: 100%;
    padding-bottom: 5.33333vw;
    border-bottom: 0.26667vw solid #eae9e6;
    line-height: 1.2; }
    @media (min-width: 769px) {
      #book-detail .relate-book .head {
        font-size: 1.66667vw;
        padding-bottom: 1.73611vw;
        border-bottom: 0.06944vw solid #eae9e6; } }
    @media (min-width: 1441px) {
      #book-detail .relate-book .head {
        font-size: 24px;
        padding-bottom: 25px;
        border-bottom: 1px solid #eae9e6; } }
  #book-detail .relate-book .list-item {
    margin-top: 2.66667vw; }
    @media (min-width: 769px) {
      #book-detail .relate-book .list-item {
        margin-top: 0.69444vw; } }
    @media (min-width: 1441px) {
      #book-detail .relate-book .list-item {
        margin-top: 10px; } }
    #book-detail .relate-book .list-item .item {
      margin-top: 5.33333vw;
      width: calc((100% - 5.33333vw) / 2);
      margin-right: 5.33333vw;
      padding: 8vw 2.66667vw; }
      @media (min-width: 769px) {
        #book-detail .relate-book .list-item .item {
          width: calc((100% - 8.33333vw) / 5);
          margin-right: 2.08333vw;
          padding: 2.77778vw;
          margin-top: 2.08333vw; } }
      @media (min-width: 1441px) {
        #book-detail .relate-book .list-item .item {
          width: calc((100% - 120px) / 5);
          margin-right: 30px;
          padding: 40px;
          margin-top: 30px; } }
      #book-detail .relate-book .list-item .item .image {
        padding: 0 7.46667vw;
        height: auto; }
        @media (min-width: 769px) {
          #book-detail .relate-book .list-item .item .image {
            padding: 0 0.69444vw; } }
        @media (min-width: 1441px) {
          #book-detail .relate-book .list-item .item .image {
            padding: 0 10px; } }
        #book-detail .relate-book .list-item .item .image img {
          height: auto; }
      #book-detail .relate-book .list-item .item .item-content {
        justify-content: center;
        margin-top: 4vw; }
        @media (min-width: 769px) {
          #book-detail .relate-book .list-item .item .item-content {
            margin-top: 2.08333vw; } }
        @media (min-width: 1441px) {
          #book-detail .relate-book .list-item .item .item-content {
            margin-top: 30px; } }
        #book-detail .relate-book .list-item .item .item-content .title {
          font-weight: bold;
          font-size: 4.53333vw;
          line-height: 1.5;
          text-decoration: none; }
          @media (min-width: 769px) {
            #book-detail .relate-book .list-item .item .item-content .title {
              font-size: 1.18056vw; } }
          @media (min-width: 1441px) {
            #book-detail .relate-book .list-item .item .item-content .title {
              font-size: 17px; } }
      #book-detail .relate-book .list-item .item:nth-child(even) {
        margin-right: 0; }
        @media (min-width: 769px) {
          #book-detail .relate-book .list-item .item:nth-child(even) {
            margin-right: 2.08333vw; } }
        @media (min-width: 1441px) {
          #book-detail .relate-book .list-item .item:nth-child(even) {
            margin-right: 30px; } }
      @media (min-width: 769px) {
        #book-detail .relate-book .list-item .item:nth-child(5n) {
          margin-right: 0; } }
      @media (min-width: 1441px) {
        #book-detail .relate-book .list-item .item:nth-child(5n) {
          margin-right: 0; } }

.slick-arrow {
  width: 10.66667vw;
  height: 10.66667vw;
  border-radius: 5.33333vw;
  background: #65b393;
  position: absolute;
  transition: all 0.3s ease;
  opacity: 1; }
  .slick-arrow.hide {
    display: none; }
  @media (min-width: 769px) {
    .slick-arrow {
      width: 2.77778vw;
      height: 2.77778vw;
      border-radius: 1.38889vw; } }
  @media (min-width: 1441px) {
    .slick-arrow {
      width: 40px;
      height: 40px;
      border-radius: 20px; } }
  .slick-arrow:hover:after {
    animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
  .slick-arrow:after {
    content: "";
    display: block;
    position: absolute;
    width: 2.8vw;
    height: 3.06667vw;
    background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
    background-size: cover;
    bottom: 4vw;
    right: 4vw; }
    @media (min-width: 769px) {
      .slick-arrow:after {
        width: 0.76389vw;
        height: 0.83333vw;
        bottom: 0.97222vw;
        right: 0.97222vw; } }
    @media (min-width: 1441px) {
      .slick-arrow:after {
        width: 11px;
        height: 12px;
        bottom: 14px;
        right: 14px; } }
  .slick-arrow:before {
    display: none; }

.slick-disabled {
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none; }

.slick-prev {
  top: 50%;
  transform: translateY(-50%);
  left: 0; }
  .slick-prev:after {
    transform: rotate(180deg); }
  .slick-prev:hover:after {
    animation: arrowSlideleft 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 backwards; }

.slick-next {
  top: 50%;
  transform: translateY(-50%);
  right: 0; }

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  background: #f9f9f5;
  overflow: auto;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease; }
  .modal.show {
    opacity: 1;
    transform: translateY(0); }
  .modal .close-button {
    width: 13.33333vw;
    height: 13.33333vw;
    background: #65b393 url("../img/close-icon.png") 50% 50% no-repeat;
    background-size: 5.33333vw;
    border-radius: 6.66667vw;
    position: fixed;
    top: 5.33333vw;
    right: 5.33333vw;
    opacity: 1;
    display: block; }
    @media (min-width: 769px) {
      .modal .close-button {
        width: 3.47222vw;
        height: 3.47222vw;
        background-size: 1.38889vw;
        border-radius: 1.73611vw;
        top: 2.08333vw;
        right: 2.08333vw; } }
    @media (min-width: 1441px) {
      .modal .close-button {
        width: 50px;
        height: 50px;
        background-size: 20px;
        border-radius: 25px;
        top: 30px;
        right: 30px; } }
  .modal .modal-content {
    position: relative;
    margin: 20vh auto 0;
    padding-bottom: 6.66667vw;
    width: 62.93333vw; }
    @media (min-width: 769px) {
      .modal .modal-content {
        width: 55.55556vw;
        padding-bottom: 6.94444vw;
        margin: 15vh auto 0; } }
    @media (min-width: 1441px) {
      .modal .modal-content {
        padding-bottom: 100px;
        width: 800px; } }
    .modal .modal-content .profile-modal {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
      .modal .modal-content .profile-modal a, .modal .modal-content .profile-modal p {
        width: 100%;
        text-align: center;
        margin-top: 8vw;
        text-underline-offset: 0.1em; }
        @media (min-width: 769px) {
          .modal .modal-content .profile-modal a, .modal .modal-content .profile-modal p {
            margin-top: 2.08333vw; } }
        @media (min-width: 1441px) {
          .modal .modal-content .profile-modal a, .modal .modal-content .profile-modal p {
            margin-top: 30px; } }
      .modal .modal-content .profile-modal .summary {
        width: 100%;
        position: relative;
        display: block;
        margin-top: 5.33333vw; }
        @media (min-width: 769px) {
          .modal .modal-content .profile-modal .summary {
            width: 100%;
            padding-left: 0;
            margin-top: 3.47222vw; } }
        @media (min-width: 1441px) {
          .modal .modal-content .profile-modal .summary {
            padding-left: 0;
            margin-top: 50px; } }
        .modal .modal-content .profile-modal .summary .head {
          font-family: 'Inter', sans-serif;
          font-weight: bold;
          font-size: 5.06667vw;
          width: 100%;
          position: relative;
          display: block;
          padding-bottom: 1.33333vw;
          line-height: 1.5;
          border-bottom: 0.26667vw solid #eae9e6;
          text-align: center; }
          @media (min-width: 769px) {
            .modal .modal-content .profile-modal .summary .head {
              top: -0.3em;
              font-size: 1.25vw;
              padding-bottom: 0.69444vw;
              border-bottom: 0.06944vw solid #eae9e6; } }
          @media (min-width: 1441px) {
            .modal .modal-content .profile-modal .summary .head {
              font-size: 18px;
              padding-bottom: 10px;
              border-bottom: 1px solid #eae9e6; } }
        .modal .modal-content .profile-modal .summary .text {
          position: relative;
          display: block;
          width: 100%;
          font-size: 4vw;
          line-height: 2;
          margin-top: 1.33333vw; }
          @media (min-width: 769px) {
            .modal .modal-content .profile-modal .summary .text {
              font-size: 1.04167vw;
              margin-top: 0.69444vw;
              text-align: left; } }
          @media (min-width: 1441px) {
            .modal .modal-content .profile-modal .summary .text {
              font-size: 15px;
              margin-top: 10px; } }
    .modal .modal-content .slick-track {
      display: flex; }
    .modal .modal-content .slick-slide {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100% !important; }
      .modal .modal-content .slick-slide img {
        margin-top: 0; }
        @media (min-width: 769px) {
          .modal .modal-content .slick-slide img {
            max-height: 60vh;
            max-width: 100%; } }
    .modal .modal-content .slick-arrow {
      bottom: -18.66667vw;
      top: auto; }
      @media (min-width: 769px) {
        .modal .modal-content .slick-arrow {
          top: 50%; } }
    .modal .modal-content .slick-prev {
      left: 9.33333vw; }
      @media (min-width: 769px) {
        .modal .modal-content .slick-prev {
          left: -10.41667vw; } }
      @media (min-width: 1441px) {
        .modal .modal-content .slick-prev {
          left: -150px; } }
    .modal .modal-content .slick-next {
      right: 9.33333vw; }
      @media (min-width: 769px) {
        .modal .modal-content .slick-next {
          right: -10.41667vw; } }
      @media (min-width: 1441px) {
        .modal .modal-content .slick-next {
          right: -150px; } }
    .modal .modal-content .count {
      margin-top: 5.33333vw;
      text-align: center;
      color: #222222;
      font-size: 4vw; }
      @media (min-width: 769px) {
        .modal .modal-content .count {
          font-size: 1.04167vw;
          margin-top: 2.08333vw; } }
      @media (min-width: 1441px) {
        .modal .modal-content .count {
          margin-top: 30px;
          font-size: 15px; } }

#news .news-list {
  margin-top: 2.66667vw; }
  @media (min-width: 769px) {
    #news .news-list {
      margin-top: 0.69444vw; } }
  @media (min-width: 1441px) {
    #news .news-list {
      margin-top: 10px; } }
  #news .news-list .item.arrow {
    padding-right: 18.66667vw; }
    @media (min-width: 769px) {
      #news .news-list .item.arrow {
        padding-right: 0; } }
    @media (min-width: 1441px) {
      #news .news-list .item.arrow {
        padding-right: 0; } }
    #news .news-list .item.arrow:hover:after {
      animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
    #news .news-list .item.arrow:after {
      content: "";
      display: block;
      position: absolute;
      background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
      background-size: cover;
      width: 2.93333vw;
      height: 3.06667vw;
      top: calc((100% - 3.06667vw)/2);
      right: 0; }
      @media (min-width: 769px) {
        #news .news-list .item.arrow:after {
          width: 0.76389vw;
          height: 0.83333vw;
          top: calc((100% - 0.83333vw)/2);
          right: 0; } }
      @media (min-width: 1441px) {
        #news .news-list .item.arrow:after {
          width: 11px;
          height: 12px;
          top: calc((100% - 12px)/2); } }
  #news .news-list .item.pdf {
    padding-right: 18.66667vw; }
    @media (min-width: 769px) {
      #news .news-list .item.pdf {
        padding-right: 0; } }
    @media (min-width: 1441px) {
      #news .news-list .item.pdf {
        padding-right: 0; } }
    @media (min-width: 769px) {
      #news .news-list .item.pdf:after {
        content: "";
        display: block;
        position: absolute;
        background: url("../img/PDF.png") 50% 50% no-repeat;
        background-size: 99%;
        width: 1.38889vw;
        height: 1.38889vw;
        top: calc((100% - 1.38889vw)/2);
        right: 0; } }
    @media (min-width: 1441px) {
      #news .news-list .item.pdf:after {
        width: 20px;
        height: 20px;
        top: calc((100% - 20px)/2); } }
  #news .news-list .item.window {
    padding-right: 18.66667vw; }
    @media (min-width: 769px) {
      #news .news-list .item.window {
        padding-right: 0; } }
    @media (min-width: 1441px) {
      #news .news-list .item.window {
        padding-right: 0; } }
    #news .news-list .item.window:after {
      content: "";
      display: block;
      position: absolute;
      background: url("../img/ico_blank.png") 50% 50% no-repeat;
      background-size: contain;
      width: 3.86667vw;
      height: 3.86667vw;
      top: calc((100% - 3.73333vw)/2);
      right: 0; }
      @media (min-width: 769px) {
        #news .news-list .item.window:after {
          width: 1.04167vw;
          height: 1.04167vw;
          top: calc((100% - 0.97222vw)/2);
          right: 0; } }
      @media (min-width: 1441px) {
        #news .news-list .item.window:after {
          width: 15px;
          height: 15px;
          top: calc((100% - 14px)/2); } }

#application-aid_outline .page-anchor ul {
  width: 100%; }
  #application-aid_outline .page-anchor ul li {
    width: 100%; }

#application-aid_outline h1 {
  margin-top: 13.33333vw; }
  @media (min-width: 769px) {
    #application-aid_outline h1 {
      margin-top: 5.55556vw; } }
  @media (min-width: 1441px) {
    #application-aid_outline h1 {
      margin-top: 80px; } }

#application-aid_outline h2 {
  margin-top: 9.33333vw; }
  @media (min-width: 769px) {
    #application-aid_outline h2 {
      margin-top: 4.16667vw; } }
  @media (min-width: 1441px) {
    #application-aid_outline h2 {
      margin-top: 60px; } }
  #application-aid_outline h2 + h3 {
    margin-top: 6.66667vw; }
    @media (min-width: 769px) {
      #application-aid_outline h2 + h3 {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      #application-aid_outline h2 + h3 {
        margin-top: 50px; } }

#application-aid_outline h3 {
  margin-top: 12vw; }
  @media (min-width: 769px) {
    #application-aid_outline h3 {
      margin-top: 4.16667vw; } }
  @media (min-width: 1441px) {
    #application-aid_outline h3 {
      margin-top: 60px; } }
  #application-aid_outline h3 + .list {
    margin-top: 6.66667vw; }
    @media (min-width: 769px) {
      #application-aid_outline h3 + .list {
        margin-top: 2.77778vw; } }
    @media (min-width: 1441px) {
      #application-aid_outline h3 + .list {
        margin-top: 40px; } }

@media (min-width: 769px) {
  #application-aid_outline p {
    font-size: 1.18056vw; } }

@media (min-width: 1441px) {
  #application-aid_outline p {
    font-size: 17px; } }

@media (min-width: 769px) {
  #application-aid_outline p.note {
    font-size: 0.97222vw; } }

@media (min-width: 1441px) {
  #application-aid_outline p.note {
    font-size: 14px; } }

#application-aid_outline table {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8vw;
  border-top: 0.26667vw solid #dcdcdc; }
  @media (min-width: 769px) {
    #application-aid_outline table {
      margin-top: 2.77778vw;
      display: table;
      border-top: 0.06944vw solid #dcdcdc; } }
  @media (min-width: 1441px) {
    #application-aid_outline table {
      margin-top: 40px;
      border-top: 1px solid #dcdcdc; } }
  #application-aid_outline table tr,
  #application-aid_outline table td {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-collapse: collapse;
    vertical-align: text-top; }
    @media (min-width: 769px) {
      #application-aid_outline table tr,
      #application-aid_outline table td {
        display: table; } }
  #application-aid_outline table td {
    font-size: 4.8vw;
    padding: 4vw;
    border: 0.26667vw solid #dcdcdc;
    border-top: 0;
    background: #fff;
    line-height: 1.6; }
    @media (min-width: 769px) {
      #application-aid_outline table td {
        width: calc(100% - 17.36111vw);
        display: table-cell;
        border: 0.06944vw solid #dcdcdc;
        font-size: 1.25vw;
        padding: 1.73611vw;
        border-top: 0; } }
    @media (min-width: 1441px) {
      #application-aid_outline table td {
        width: calc(100% - 250px);
        font-size: 18px;
        padding: 25px;
        border: 1px solid #dcdcdc;
        border-top: 0; } }
    @media (min-width: 769px) {
      #application-aid_outline table td:first-child {
        width: 17.36111vw;
        border: 0.06944vw solid #dcdcdc;
        border-top: 0; } }
    @media (min-width: 1441px) {
      #application-aid_outline table td:first-child {
        width: 250px;
        border: 1px solid #dcdcdc;
        border-top: 0; } }
    #application-aid_outline table td p {
      font-size: 4.26667vw;
      margin-top: 1.33333vw;
      line-height: 1.6; }
      @media (min-width: 769px) {
        #application-aid_outline table td p {
          font-size: 1.18056vw;
          margin-top: 0.69444vw;
          width: 100%; } }
      @media (min-width: 1441px) {
        #application-aid_outline table td p {
          font-size: 17px;
          margin-top: 10px; } }
      #application-aid_outline table td p.small {
        font-size: 4vw; }
        @media (min-width: 769px) {
          #application-aid_outline table td p.small {
            font-size: 1.04167vw; } }
        @media (min-width: 1441px) {
          #application-aid_outline table td p.small {
            font-size: 15px; } }
      #application-aid_outline table td p.big {
        font-weight: bold;
        margin-top: 2vw; }
        #application-aid_outline table td p.big:first-child {
          margin-top: 0; }
        @media (min-width: 769px) {
          #application-aid_outline table td p.big {
            margin-top: 0.69444vw; } }
        @media (min-width: 1441px) {
          #application-aid_outline table td p.big {
            margin-top: 10px; } }
        #application-aid_outline table td p.big + p {
          margin-top: 0.66667vw; }
          @media (min-width: 769px) {
            #application-aid_outline table td p.big + p {
              margin-top: 0; } }
          @media (min-width: 1441px) {
            #application-aid_outline table td p.big + p {
              margin-top: 0; } }
      #application-aid_outline table td p:first-child {
        margin-top: 0; }
    #application-aid_outline table td .list .pdf {
      font-weight: bold; }
    #application-aid_outline table td:first-child {
      font-weight: bold;
      background: #f6f6f6; }
  @media (min-width: 769px) {
    #application-aid_outline table + h3 {
      margin-top: 5.20833vw; } }
  @media (min-width: 1441px) {
    #application-aid_outline table + h3 {
      margin-top: 75px; } }

#application-aid_outline .list {
  margin-top: 0; }
  #application-aid_outline .list .pdf {
    margin-top: 4vw;
    font-weight: bold; }
    @media (min-width: 769px) {
      #application-aid_outline .list .pdf {
        margin-top: 1.38889vw; } }
    @media (min-width: 1441px) {
      #application-aid_outline .list .pdf {
        margin-top: 20px; } }

#application-aid_outline .application {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 5.6vw;
  font-weight: bold;
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    #application-aid_outline .application {
      margin-top: 2.08333vw;
      font-size: 1.80556vw; } }
  @media (min-width: 1441px) {
    #application-aid_outline .application {
      margin-top: 30px;
      font-size: 26px; } }

#application-aid_outline .button-block {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #application-aid_outline .button-block {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    #application-aid_outline .button-block {
      margin-top: 50px; } }

#application-publication_outline p span.note {
  display: block; }

@media (min-width: 769px) {
  #application-publication_outline p {
    font-size: 1.18056vw; } }

@media (min-width: 1441px) {
  #application-publication_outline p {
    font-size: 17px; } }

@media (min-width: 769px) {
  #application-publication_outline p.note {
    font-size: 0.97222vw; } }

@media (min-width: 1441px) {
  #application-publication_outline p.note {
    font-size: 14px; } }

#application-publication_outline h3 + .num-list {
  margin-top: 5.33333vw; }
  @media (min-width: 769px) {
    #application-publication_outline h3 + .num-list {
      margin-top: 2.77778vw; } }
  @media (min-width: 1441px) {
    #application-publication_outline h3 + .num-list {
      margin-top: 40px; } }

#application-publication_outline .fixedwidth {
  display: inline-block;
  width: 13.33333vw; }
  @media (min-width: 769px) {
    #application-publication_outline .fixedwidth {
      width: 4.16667vw; } }
  @media (min-width: 1441px) {
    #application-publication_outline .fixedwidth {
      width: 60px; } }

#application-outline h2 {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #application-outline h2 {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    #application-outline h2 {
      margin-top: 50px; } }

#application-outline .step-box {
  width: 100%;
  position: relative;
  display: block;
  margin-top: 10.66667vw;
  margin-bottom: 16vw; }
  @media (min-width: 769px) {
    #application-outline .step-box {
      margin-top: 2.08333vw;
      margin-bottom: 5.55556vw; } }
  @media (min-width: 1441px) {
    #application-outline .step-box {
      margin-top: 30px;
      margin-bottom: 80px; } }
  #application-outline .step-box .step-wrap {
    display: none; }
    #application-outline .step-box .step-wrap.active {
      display: block; }
    #application-outline .step-box .step-wrap .step:first-child {
      margin-top: 20vw; }
      @media (min-width: 769px) {
        #application-outline .step-box .step-wrap .step:first-child {
          margin-top: 4.86111vw; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step-wrap .step:first-child {
          margin-top: 70px; } }
      #application-outline .step-box .step-wrap .step:first-child:before {
        display: block; }
  #application-outline .step-box > :first-child {
    margin-top: 0; }
    @media (min-width: 769px) {
      #application-outline .step-box > :first-child .title {
        justify-content: flex-start; } }
    @media (min-width: 769px) {
      #application-outline .step-box > :first-child .title p {
        margin-left: 0; } }
    @media (min-width: 1441px) {
      #application-outline .step-box > :first-child .title p {
        margin-left: 0; } }
    #application-outline .step-box > :first-child:before {
      display: none; }
  #application-outline .step-box .step {
    margin-top: 20vw;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 5.33333vw 5.33333vw 13.33333vw;
    background: #ffffff; }
    @media (min-width: 769px) {
      #application-outline .step-box .step {
        margin-top: 4.86111vw;
        padding: 2.08333vw 2.77778vw 4.16667vw; } }
    @media (min-width: 1441px) {
      #application-outline .step-box .step {
        margin-top: 70px;
        padding: 30px 40px 60px; } }
    #application-outline .step-box .step:before {
      content: "";
      height: 4vw;
      width: 6.66667vw;
      background: url("../img/step.svg") no-repeat;
      background-size: 99% 99%;
      left: calc(50% - 3.33333vw);
      top: -12vw;
      position: absolute;
      display: block; }
      @media (min-width: 769px) {
        #application-outline .step-box .step:before {
          height: 1.04167vw;
          width: 1.80556vw;
          left: calc(50% - 0.90278vw);
          top: -2.91667vw; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step:before {
          height: 15px;
          width: 26px;
          left: calc(50% - 13px);
          top: -42px; } }
    #application-outline .step-box .step:last-child {
      padding-bottom: 8vw; }
      @media (min-width: 769px) {
        #application-outline .step-box .step:last-child {
          padding-bottom: 4.16667vw; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step:last-child {
          padding-bottom: 60px; } }
    #application-outline .step-box .step .head {
      width: 100%;
      position: relative;
      display: block;
      text-align: center;
      font-family: 'Inter', Noto Sans JP, sans-serif;
      font-weight: bold;
      color: #222;
      font-size: 6.13333vw;
      line-height: 1.2;
      padding-bottom: 4vw;
      border-bottom: 0.8vw solid #eae9e6; }
      @media (min-width: 769px) {
        #application-outline .step-box .step .head {
          font-size: 2.08333vw;
          border-bottom: 0.20833vw solid #eae9e6;
          padding-bottom: 1.73611vw; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step .head {
          font-size: 2.08333vw;
          border-bottom: 0.20833vw solid #eae9e6;
          padding-bottom: 25px; } }
    #application-outline .step-box .step .title {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 8vw; }
      #application-outline .step-box .step .title:first-child {
        margin-top: 2.66667vw; }
        @media (min-width: 769px) {
          #application-outline .step-box .step .title:first-child {
            margin-top: 2.08333vw; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step .title:first-child {
            margin-top: 30px; } }
      @media (min-width: 769px) {
        #application-outline .step-box .step .title {
          margin-top: 2.77778vw; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step .title {
          margin-top: 40px; } }
      #application-outline .step-box .step .title .tag {
        height: 6vw;
        border-radius: 3.33333vw;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        padding: 0 5.33333vw;
        color: #fff;
        font-size: 3.46667vw;
        background: #65b393; }
        @media (min-width: 769px) {
          #application-outline .step-box .step .title .tag {
            height: 1.59722vw;
            border-radius: 0.83333vw;
            padding: 0 0.90278vw;
            font-size: 0.90278vw;
            min-width: 4.86111vw; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step .title .tag {
            height: 23px;
            border-radius: 12px;
            padding: 0 13px;
            font-size: 13px;
            min-width: 70px; } }
        #application-outline .step-box .step .title .tag + .tag {
          margin-left: 2.66667vw; }
          @media (min-width: 769px) {
            #application-outline .step-box .step .title .tag + .tag {
              margin-left: 0.69444vw; } }
          @media (min-width: 1441px) {
            #application-outline .step-box .step .title .tag + .tag {
              margin-left: 10px; } }
      #application-outline .step-box .step .title p {
        width: 100%;
        position: relative;
        display: flex;
        font-weight: bold;
        font-size: 5.06667vw;
        margin-top: 4vw;
        line-height: 1.6;
        justify-content: center;
        color: #222;
        text-align: center; }
        @media (min-width: 769px) {
          #application-outline .step-box .step .title p {
            margin-top: 0;
            font-size: 1.38889vw;
            margin-left: 1.38889vw;
            width: auto; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step .title p {
            margin-top: 0;
            font-size: 20px;
            margin-left: 20px; } }
        #application-outline .step-box .step .title p:first-child {
          margin-top: 0; }
          @media (min-width: 769px) {
            #application-outline .step-box .step .title p:first-child {
              margin-left: 0; } }
          @media (min-width: 1441px) {
            #application-outline .step-box .step .title p:first-child {
              margin-left: 0; } }
    #application-outline .step-box .step .box-button {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 8vw; }
      @media (min-width: 769px) {
        #application-outline .step-box .step .box-button {
          margin-top: 1.38889vw; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step .box-button {
          margin-top: 20px; } }
      #application-outline .step-box .step .box-button a {
        width: 100%;
        height: 14.66667vw;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 4.53333vw;
        font-weight: bold;
        border: 0.8vw solid #e3e2df;
        margin-top: 5.33333vw;
        text-decoration: none;
        border-radius: 5.33333vw;
        color: #e3e2df; }
        @media (min-width: 769px) {
          #application-outline .step-box .step .box-button a {
            margin-top: 0;
            width: calc((100% - 2.77778vw)/3);
            height: 5.06944vw;
            font-size: 1.25vw;
            border: 0.20833vw solid #e3e2df;
            border-radius: 1.38889vw;
            margin-right: 1.38889vw; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step .box-button a {
            margin-top: 0;
            width: calc((100% - 40px)/3);
            height: 73px;
            font-size: 18px;
            border: 3px solid #e3e2df;
            border-radius: 20px;
            margin-right: 20px; } }
        @media (min-width: 769px) {
          #application-outline .step-box .step .box-button a:nth-child(3n) {
            margin-right: 0; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step .box-button a:nth-child(3n) {
            margin-right: 0; } }
        #application-outline .step-box .step .box-button a:first-child {
          margin-top: 0; }
        #application-outline .step-box .step .box-button a.active {
          color: #222;
          border-color: #65b393;
          pointer-events: none; }
    #application-outline .step-box .step > p {
      font-size: 4vw;
      color: rgba(34, 34, 34, 0.5);
      width: 100%;
      line-height: 1.6;
      margin-top: 3.33333vw; }
      @media (min-width: 769px) {
        #application-outline .step-box .step > p {
          font-size: 1.04167vw;
          margin-top: 1.38889vw;
          text-align: center; } }
      @media (min-width: 1441px) {
        #application-outline .step-box .step > p {
          font-size: 15px;
          margin-top: 20px; } }
      #application-outline .step-box .step > p.big {
        font-weight: bold;
        font-size: 5.06667vw;
        color: #222;
        margin: 2.66667vw 0 1.33333vw; }
        @media (min-width: 769px) {
          #application-outline .step-box .step > p.big {
            font-size: 1.38889vw;
            margin-top: 2.08333vw;
            margin-bottom: 0;
            text-align: left; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step > p.big {
            font-size: 20px;
            margin-top: 30px;
            margin-bottom: 0; } }
      #application-outline .step-box .step > p.center {
        text-align: center;
        color: #222; }
      #application-outline .step-box .step > p + .title {
        margin-top: 2.66667vw; }
        @media (min-width: 769px) {
          #application-outline .step-box .step > p + .title {
            margin-top: 2.08333vw; } }
        @media (min-width: 1441px) {
          #application-outline .step-box .step > p + .title {
            margin-top: 30px; } }

#application-outline .big-button {
  margin-top: 10.66667vw; }
  @media (min-width: 769px) {
    #application-outline .big-button {
      margin-top: 2.08333vw; } }
  @media (min-width: 1441px) {
    #application-outline .big-button {
      margin-top: 30px; } }
  @media (min-width: 769px) {
    #application-outline .big-button .button {
      width: calc((100% - 8.33333vw) / 3);
      margin-right: 4.16667vw; } }
  @media (min-width: 1441px) {
    #application-outline .big-button .button {
      width: calc((100% - 120px) / 3);
      margin-right: 60px; } }
  @media (min-width: 769px) {
    #application-outline .big-button .button:nth-child(3n) {
      margin-right: 0; } }
  @media (min-width: 1441px) {
    #application-outline .big-button .button:nth-child(3n) {
      margin-right: 0; } }
  @media (min-width: 769px) {
    #application-outline .big-button .button:nth-child(4n) {
      margin-right: 4.16667vw; } }
  @media (min-width: 1441px) {
    #application-outline .big-button .button:nth-child(4n) {
      margin-right: 60px; } }
  #application-outline .big-button .button a {
    color: #e3e2df;
    border-color: #e3e2df;
    pointer-events: none; }
    #application-outline .big-button .button a:after {
      background: url("../img/disable-right-arrow.png") 50% 50% no-repeat;
      background-size: 99%; }
  #application-outline .big-button .button.active a {
    color: #222;
    border: 0.8vw solid #e29f92;
    pointer-events: all; }
    @media (min-width: 769px) {
      #application-outline .big-button .button.active a {
        border: 0.41667vw solid #e29f92; } }
    @media (min-width: 1441px) {
      #application-outline .big-button .button.active a {
        border: 3px solid #e29f92; } }
    #application-outline .big-button .button.active a:after {
      background: url("../img/pink-right-arrow.png") 50% 50% no-repeat;
      background-size: 99%; }

#application-outline .button-block {
  margin-top: 13.33333vw; }
  @media (min-width: 769px) {
    #application-outline .button-block {
      margin-top: 3.47222vw; } }
  @media (min-width: 1441px) {
    #application-outline .button-block {
      margin-top: 50px; } }

#application-outline .download-list .item a {
  padding: 6.66667vw;
  justify-content: center;
  align-items: center; }
  @media (min-width: 769px) {
    #application-outline .download-list .item a {
      padding: 1.73611vw; } }
  @media (min-width: 1441px) {
    #application-outline .download-list .item a {
      padding: 25px; } }
  #application-outline .download-list .item a:after {
    position: relative;
    order: -1;
    margin-right: 2.66667vw;
    top: 0;
    left: 0; }
    @media (min-width: 769px) {
      #application-outline .download-list .item a:after {
        margin-right: 0.69444vw; } }
    @media (min-width: 1441px) {
      #application-outline .download-list .item a:after {
        margin-right: 10px; } }

@media (min-width: 769px) {
  #application-outline #groupA .step .title {
    justify-content: flex-start;
    padding-left: 33%; } }

@media (min-width: 1441px) {
  #application-outline #groupA .step .title {
    justify-content: flex-start;
    padding-left: 33%; } }

@media (min-width: 769px) {
  #application-outline #groupC .step .title {
    justify-content: flex-start;
    padding-left: 40.2%; } }

@media (min-width: 1441px) {
  #application-outline #groupC .step .title {
    justify-content: flex-start;
    padding-left: 40.2%; } }

@media (min-width: 769px) {
  #application-voice .big-button .button {
    width: calc((100% - 8.33333vw) / 3);
    margin-right: 4.16667vw; } }

@media (min-width: 1441px) {
  #application-voice .big-button .button {
    width: calc((100% - 120px) / 3);
    margin-right: 60px; } }

@media (min-width: 769px) {
  #application-voice .big-button .button:nth-child(3n) {
    margin-right: 0; } }

@media (min-width: 1441px) {
  #application-voice .big-button .button:nth-child(3n) {
    margin-right: 0; } }

@media (min-width: 769px) {
  #application-voice .big-button .button:nth-child(4n) {
    margin-right: 4.16667vw; } }

@media (min-width: 1441px) {
  #application-voice .big-button .button:nth-child(4n) {
    margin-right: 60px; } }

@media (min-width: 769px) {
  #application-entity_outline p {
    font-size: 1.18056vw; } }

@media (min-width: 1441px) {
  #application-entity_outline p {
    font-size: 17px; } }

@media (min-width: 769px) {
  #application-entity_outline p.note {
    font-size: 0.97222vw; } }

@media (min-width: 1441px) {
  #application-entity_outline p.note {
    font-size: 14px; } }

@media (min-width: 769px) {
  #application .big-button .button {
    width: calc((100% - 8.33333vw) / 3);
    margin-right: 4.16667vw;
    border-width: 0.41667vw;
    margin-top: 4.16667vw; } }

@media (min-width: 1441px) {
  #application .big-button .button {
    width: calc((100% - 120px) / 3);
    margin-right: 60px;
    border-width: 6px;
    margin-top: 60px; } }

@media (min-width: 769px) {
  #application .big-button .button:nth-child(-n+3) {
    margin-top: 0; } }

@media (min-width: 1441px) {
  #application .big-button .button:nth-child(-n+3) {
    margin-top: 0; } }

@media (min-width: 769px) {
  #application .big-button .button:nth-child(3n) {
    margin-right: 0; } }

@media (min-width: 1441px) {
  #application .big-button .button:nth-child(3n) {
    margin-right: 0; } }

@media (min-width: 769px) {
  #application .big-button .button:nth-child(4n) {
    margin-right: 4.16667vw; } }

@media (min-width: 1441px) {
  #application .big-button .button:nth-child(4n) {
    margin-right: 60px; } }

@media (min-width: 769px) {
  #application .big-button .button a {
    border-width: 0.41667vw;
    margin-bottom: 2.08333vw; } }

@media (min-width: 1441px) {
  #application .big-button .button a {
    border-width: 6px;
    margin-bottom: 30px; } }

@media (min-width: 769px) {
  #application .big-button .button a.pink {
    margin-bottom: 0; } }

@media (min-width: 1441px) {
  #application .big-button .button a.pink {
    margin-bottom: 0; } }

#outline-history .top-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 5.33333vw;
  background: #ecece7;
  margin: 0;
  z-index: 9; }
  @media (min-width: 769px) {
    #outline-history .top-box {
      width: 100%;
      padding: 0;
      margin-top: -0.34722vw; } }
  @media (min-width: 1441px) {
    #outline-history .top-box {
      margin-top: -5px; } }
  #outline-history .top-box .breadcrumb {
    width: 100%; }
    @media (min-width: 769px) {
      #outline-history .top-box .breadcrumb {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 9.375vw; } }
    @media (min-width: 1441px) {
      #outline-history .top-box .breadcrumb {
        padding: 0 135px; } }
  #outline-history .top-box h1 {
    display: flex;
    align-items: center;
    margin-top: 0;
    height: 47.33333vw;
    width: 100%;
    padding-bottom: 5.33333vw; }
    @media (min-width: 769px) {
      #outline-history .top-box h1 {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        height: 14.58333vw;
        padding: 0 9.375vw 2.08333vw; } }
    @media (min-width: 1441px) {
      #outline-history .top-box h1 {
        height: 210px;
        padding: 0 135px 30px; } }

#outline-history .timeline {
  padding-left: 0;
  padding-bottom: 0;
  margin-top: 17.33333vw; }
  @media (min-width: 769px) {
    #outline-history .timeline {
      margin-top: 6.25vw; } }
  @media (min-width: 1441px) {
    #outline-history .timeline {
      margin-top: 90px; } }
  #outline-history .timeline:before {
    left: 23.6vw;
    display: none; }
    @media (min-width: 769px) {
      #outline-history .timeline:before {
        left: 13.54167vw; } }
    @media (min-width: 1441px) {
      #outline-history .timeline:before {
        left: 195px; } }
  #outline-history .timeline .line {
    display: block;
    width: 1.33333vw;
    height: 0;
    background: #e3e2df;
    position: absolute;
    top: 0;
    left: 23.6vw;
    transition: height 0.3s ease;
    z-index: 1;
    max-height: 100%; }
    @media (min-width: 769px) {
      #outline-history .timeline .line {
        width: 0.34722vw;
        left: 13.54167vw; } }
    @media (min-width: 1441px) {
      #outline-history .timeline .line {
        width: 5px;
        left: 195px; } }
  #outline-history .timeline .milestones {
    background: none;
    border: none;
    margin-top: 19.33333vw;
    padding: 0; }
    #outline-history .timeline .milestones.current:before {
      background: #65b393;
      border-color: #65b393; }
    @media (min-width: 769px) {
      #outline-history .timeline .milestones {
        margin-top: 6.25vw;
        padding-right: 6.94444vw; } }
    @media (min-width: 1441px) {
      #outline-history .timeline .milestones {
        margin-top: 90px;
        padding-right: 100px; } }
    #outline-history .timeline .milestones:first-child {
      margin-top: 0; }
    #outline-history .timeline .milestones:before {
      left: 22.13333vw;
      top: 0;
      z-index: 2; }
      @media (min-width: 769px) {
        #outline-history .timeline .milestones:before {
          left: 13.19444vw; } }
      @media (min-width: 1441px) {
        #outline-history .timeline .milestones:before {
          left: 190px; } }
    #outline-history .timeline .milestones:after {
      display: none; }
    #outline-history .timeline .milestones .time {
      width: 23.6vw;
      font-size: 7.2vw;
      margin-top: -2.53333vw; }
      @media (min-width: 769px) {
        #outline-history .timeline .milestones .time {
          width: 13.19444vw;
          font-size: 2.77778vw;
          margin-top: -1.875vw;
          padding-left: 4.16667vw; } }
      @media (min-width: 1441px) {
        #outline-history .timeline .milestones .time {
          width: 190px;
          font-size: 40px;
          margin-top: -27px;
          padding-left: 60px; } }
      #outline-history .timeline .milestones .time:after {
        display: none; }
    #outline-history .timeline .milestones .detail {
      width: calc(100% - 23.6vw);
      margin-top: 0;
      padding-left: 8vw; }
      @media (min-width: 769px) {
        #outline-history .timeline .milestones .detail {
          width: calc(100% - 13.19444vw);
          margin-top: 0;
          padding-left: 3.47222vw; } }
      @media (min-width: 1441px) {
        #outline-history .timeline .milestones .detail {
          width: calc(100% - 190px);
          margin-top: 0;
          padding-left: 50px; } }
      #outline-history .timeline .milestones .detail :first-child {
        margin-top: -2.66667vw; }
        @media (min-width: 769px) {
          #outline-history .timeline .milestones .detail :first-child {
            margin-top: -0.69444vw; } }
        @media (min-width: 1441px) {
          #outline-history .timeline .milestones .detail :first-child {
            margin-top: -10px; } }
      #outline-history .timeline .milestones .detail p {
        font-size: 4.26667vw;
        line-height: 2; }
        @media (min-width: 769px) {
          #outline-history .timeline .milestones .detail p {
            width: 100%;
            font-size: 1.11111vw; } }
        @media (min-width: 1441px) {
          #outline-history .timeline .milestones .detail p {
            font-size: 16px; } }
      #outline-history .timeline .milestones .detail img {
        max-width: 100%;
        margin-top: 5.33333vw; }
        @media (min-width: 769px) {
          #outline-history .timeline .milestones .detail img {
            margin-top: 2.77778vw; } }
        @media (min-width: 1441px) {
          #outline-history .timeline .milestones .detail img {
            margin-top: 40px; } }
        #outline-history .timeline .milestones .detail img + p {
          margin-top: 5.33333vw; }
          @media (min-width: 769px) {
            #outline-history .timeline .milestones .detail img + p {
              margin-top: 2.77778vw; } }
          @media (min-width: 1441px) {
            #outline-history .timeline .milestones .detail img + p {
              margin-top: 40px; } }

#outline-history .button-block {
  margin-top: 0;
  background: #ecece7;
  padding: 13.33333vw 5.33333vw 18.66667vw; }
  @media (min-width: 769px) {
    #outline-history .button-block {
      padding: 4.16667vw 0 6.94444vw; } }
  @media (min-width: 1441px) {
    #outline-history .button-block {
      padding: 60px 0 100px; } }

#outline-history .content {
  max-width: 100%; }
  @media (min-width: 769px) {
    #outline-history .content {
      max-width: 100%;
      padding: 0 0 6.94444vw; } }
  @media (min-width: 1441px) {
    #outline-history .content {
      padding: 0 0 100px; } }
  #outline-history .content .wrap-box .left-box {
    display: none; }
    #outline-history .content .wrap-box .left-box.absolute {
      position: absolute;
      bottom: 0;
      top: auto; }
    @media (min-width: 769px) {
      #outline-history .content .wrap-box .left-box {
        display: block;
        position: fixed;
        left: 0;
        width: 18.61111vw;
        top: 0;
        height: 100vh;
        background: #ecece7;
        padding-top: 27.08333vw;
        overflow: auto; }
        #outline-history .content .wrap-box .left-box::-webkit-scrollbar {
          display: none; } }
    @media (min-width: 1441px) {
      #outline-history .content .wrap-box .left-box {
        width: 268px;
        padding-top: 390px; } }
    #outline-history .content .wrap-box .left-box .timeline {
      padding-left: 0;
      padding-bottom: 0;
      margin-top: 0; }
      #outline-history .content .wrap-box .left-box .timeline a {
        text-decoration: none; }
      #outline-history .content .wrap-box .left-box .timeline:before {
        left: 14.09722vw;
        width: 1px;
        background-image: linear-gradient(black 20%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 0.06944vw 0.34722vw;
        background-repeat: repeat-y;
        display: block; }
        @media (min-width: 1441px) {
          #outline-history .content .wrap-box .left-box .timeline:before {
            left: 203px;
            background-size: 1px 5px; } }
      #outline-history .content .wrap-box .left-box .timeline .milestones {
        background: none;
        border: none;
        margin-top: 1.73611vw;
        padding: 0; }
        @media (min-width: 1441px) {
          #outline-history .content .wrap-box .left-box .timeline .milestones {
            margin-top: 25px; } }
        #outline-history .content .wrap-box .left-box .timeline .milestones:first-child {
          margin-top: 0; }
        #outline-history .content .wrap-box .left-box .timeline .milestones:before {
          left: 13.81944vw;
          width: 0.625vw;
          height: 0.625vw;
          border-radius: 0.34722vw;
          border-width: 0.13889vw;
          z-index: 2; }
          @media (min-width: 1441px) {
            #outline-history .content .wrap-box .left-box .timeline .milestones:before {
              left: 199px;
              width: 9px;
              height: 9px;
              border-radius: 5px;
              border-width: 2px; } }
        #outline-history .content .wrap-box .left-box .timeline .milestones:after {
          display: none; }
        #outline-history .content .wrap-box .left-box .timeline .milestones .time {
          width: 14.16667vw;
          font-size: 1.11111vw;
          padding-left: 9.375vw;
          line-height: 1;
          margin-top: -0.34722vw; }
          #outline-history .content .wrap-box .left-box .timeline .milestones .time.active {
            color: #65b393; }
          @media (min-width: 1441px) {
            #outline-history .content .wrap-box .left-box .timeline .milestones .time {
              padding-left: 135px;
              width: 204px;
              font-size: 16px;
              margin-top: -5px; } }
          #outline-history .content .wrap-box .left-box .timeline .milestones .time:after {
            display: block;
            width: 1.38889vw;
            height: 0.06944vw;
            background-image: linear-gradient(to left, black 20%, rgba(255, 255, 255, 0) 0%);
            background-position: top;
            background-size: 0.34722vw 0.06944vw;
            background-repeat: repeat-X;
            top: 50%; }
            @media (min-width: 1441px) {
              #outline-history .content .wrap-box .left-box .timeline .milestones .time:after {
                width: 20px;
                height: 1px;
                background-size: 5px 1px; } }
  @media (min-width: 769px) {
    #outline-history .content .wrap-box .right-box {
      width: calc(100% - 18.61111vw);
      margin-left: 18.61111vw; } }
  @media (min-width: 1441px) {
    #outline-history .content .wrap-box .right-box {
      width: calc(100% - 268px);
      margin-left: 268px; } }

#outline-biography {
  background: #fafaf7; }
  @media (min-width: 769px) {
    #outline-biography {
      background: #fffffd; } }
  @media (min-width: 769px) {
    #outline-biography .main {
      padding-top: 0; } }
  @media (min-width: 1441px) {
    #outline-biography .main {
      padding-top: 0; } }
  @media (min-width: 769px) {
    #outline-biography .main.en .first-view .wrap-box .content-box {
      padding: 6.80556vw 6.94444vw 0;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%; } }
  @media (min-width: 1441px) {
    #outline-biography .main.en .first-view .wrap-box .content-box {
      padding: 98px 100px 0; } }
  #outline-biography section {
    padding-bottom: 0; }
    @media (min-width: 769px) {
      #outline-biography section:nth-child(2):before {
        height: 0;
        top: 50%; } }
    @media (min-width: 769px) {
      #outline-biography section:nth-last-child(2):before {
        height: 50% !important;
        top: 0; } }
    #outline-biography section.fp-auto-height:before {
      display: none; }
    @media (min-width: 769px) {
      #outline-biography section:before {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 0;
        left: calc(25% - 1px);
        background: #eae9e6;
        z-index: 1;
        transition: height 1.5s ease; } }
    @media (min-width: 769px) {
      #outline-biography section.done:before {
        height: 100%; } }
    #outline-biography section.footeractive .wrap-box .image {
      z-index: 999;
      opacity: 1; }
    @media (min-width: 769px) {
      #outline-biography section.active:before {
        height: 100%; } }
    @media (min-width: 769px) {
      #outline-biography section.active:nth-child(2):before {
        height: 50%; } }
    @media (min-width: 769px) {
      #outline-biography section.active:last-child:before {
        height: 50%; } }
    #outline-biography section.active .wrap-box .image {
      z-index: 999;
      opacity: 1;
      transition: all 0.5s ease; }
  #outline-biography .first-view {
    background: #bdb5a3; }
    #outline-biography .first-view:before {
      display: none; }
    #outline-biography .first-view .fp-tableCell {
      vertical-align: top;
      position: relative; }
    @media (min-width: 769px) {
      #outline-biography .first-view .breadcrumb {
        margin-left: -3.47222vw; } }
    @media (min-width: 1441px) {
      #outline-biography .first-view .breadcrumb {
        margin-left: -50px; } }
    #outline-biography .first-view .breadcrumb ul li span {
      color: rgba(255, 255, 255, 0.6); }
    #outline-biography .first-view .breadcrumb ul li a {
      color: #FFF; }
      #outline-biography .first-view .breadcrumb ul li a:hover {
        opacity: 0.6; }
    #outline-biography .first-view .breadcrumb ul li:after {
      color: rgba(255, 255, 255, 0.6); }
    #outline-biography .first-view .wrap-box {
      background: #bdb5a3; }
      @media (min-width: 769px) {
        #outline-biography .first-view .wrap-box {
          align-items: flex-start;
          height: 100%; } }
      #outline-biography .first-view .wrap-box .center-box {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center; }
      #outline-biography .first-view .wrap-box .content-box {
        background: #bdb5a3;
        transition: all 0.3s ease; }
        @media (min-width: 769px) {
          #outline-biography .first-view .wrap-box .content-box {
            padding: 8.75vw 6.94444vw 0;
            align-items: flex-start;
            justify-content: flex-start;
            height: 100%; } }
        @media (min-width: 1441px) {
          #outline-biography .first-view .wrap-box .content-box {
            padding: 126px 100px 0; } }
      #outline-biography .first-view .wrap-box .head {
        position: relative;
        display: inline-flex;
        margin: 13.33333vw auto 0;
        border: 0.26667vw solid #fff;
        font-size: 5.06667vw;
        line-height: 1;
        padding: 3.33333vw 12vw;
        color: #fff; }
        @media (min-width: 769px) {
          #outline-biography .first-view .wrap-box .head {
            margin: 15vh auto 0;
            border: 0.06944vw solid #fff;
            font-size: 1.38889vw;
            line-height: 1;
            padding: 0.83333vw 3.125vw;
            color: #fff; } }
        @media (min-width: 1441px) {
          #outline-biography .first-view .wrap-box .head {
            margin: 15vh auto 0;
            border: 1px solid #fff;
            font-size: 20px;
            line-height: 1;
            padding: 12px 45px;
            color: #fff; } }
      #outline-biography .first-view .wrap-box .title {
        width: 100%;
        text-align: center;
        font-size: 7.46667vw;
        font-weight: bold;
        color: #fff;
        line-height: 7.46667vw; }
        @media (min-width: 769px) {
          #outline-biography .first-view .wrap-box .title {
            font-size: 2.84722vw;
            line-height: 2.84722vw; } }
        @media (min-width: 1441px) {
          #outline-biography .first-view .wrap-box .title {
            font-size: 41px;
            line-height: 41px; } }
      #outline-biography .first-view .wrap-box .subtitle {
        width: 100%;
        text-align: center;
        font-size: 4.26667vw;
        line-height: 4.26667vw;
        color: #fff; }
        @media (min-width: 769px) {
          #outline-biography .first-view .wrap-box .subtitle {
            font-size: 1.11111vw;
            line-height: 1.11111vw; } }
        @media (min-width: 1441px) {
          #outline-biography .first-view .wrap-box .subtitle {
            font-size: 16px;
            line-height: 16px; } }
      #outline-biography .first-view .wrap-box .line {
        display: block;
        width: 21.33333vw;
        height: 0.8vw;
        background: #fff;
        margin-top: 6.66667vw;
        margin-left: auto;
        margin-right: auto; }
        @media (min-width: 769px) {
          #outline-biography .first-view .wrap-box .line {
            width: 5.55556vw;
            height: 0.20833vw;
            margin-top: 2.77778vw; } }
        @media (min-width: 1441px) {
          #outline-biography .first-view .wrap-box .line {
            width: 80px;
            height: 3px;
            margin-top: 40px; } }
      #outline-biography .first-view .wrap-box p {
        color: #fff; }
      #outline-biography .first-view .wrap-box .next {
        padding-bottom: 0 !important;
        margin-left: auto;
        margin-right: auto;
        width: 10.66667vw;
        height: 10.66667vw;
        border-radius: 5.33333vw;
        margin-top: 5.33333vw;
        border: 0.26667vw solid #fff;
        position: relative; }
        @media (min-width: 769px) {
          #outline-biography .first-view .wrap-box .next {
            width: 2.77778vw;
            height: 2.77778vw;
            border-radius: 1.38889vw;
            margin-top: 0;
            position: absolute;
            bottom: 2.08333vw;
            left: calc(25% - 1.38889vw);
            z-index: 10;
            border: 0.06944vw solid #fff; } }
        @media (min-width: 1441px) {
          #outline-biography .first-view .wrap-box .next {
            width: 40px;
            height: 40px;
            bottom: 30px;
            left: calc(25% - 20px);
            border: 1px solid #fff; } }
        #outline-biography .first-view .wrap-box .next:after {
          position: absolute;
          top: calc((100% - 3.33333vw)/2);
          left: calc((100% - 3.33333vw)/2);
          display: block;
          width: 3.33333vw;
          height: 3.33333vw;
          background: url("/img/i-arrow-right-white.svg") center/contain no-repeat;
          transform: rotate(90deg);
          content: ""; }
          @media (min-width: 769px) {
            #outline-biography .first-view .wrap-box .next:after {
              top: calc(50% - 0.41667vw);
              left: calc(50% - 0.41667vw);
              width: 0.83333vw;
              height: 0.83333vw; } }
          @media (min-width: 1441px) {
            #outline-biography .first-view .wrap-box .next:after {
              top: calc(50% - 6px);
              left: calc(50% - 6px);
              width: 12px;
              height: 12px; } }
        #outline-biography .first-view .wrap-box .next:hover:after {
          animation: arrowSlideDown 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards; }
      @media (max-width: 768px) {
        #outline-biography .first-view .wrap-box .image img {
          width: 100%;
          margin-top: 5.33333vw; } }
      @media (min-width: 769px) {
        #outline-biography .first-view .wrap-box .image img {
          object-position: center top; } }
  #outline-biography .timeline {
    padding-left: 0;
    padding-bottom: 0;
    margin-top: 8vw; }
    @media (min-width: 769px) {
      #outline-biography .timeline {
        margin-top: 3.47222vw; } }
    @media (min-width: 1441px) {
      #outline-biography .timeline {
        margin-top: 50px; } }
    #outline-biography .timeline:first-child {
      margin-top: 18.66667vw; }
      @media (min-width: 769px) {
        #outline-biography .timeline:first-child {
          margin-top: 0; } }
    #outline-biography .timeline:before {
      left: 29.06667vw;
      display: none; }
      @media (min-width: 769px) {
        #outline-biography .timeline:before {
          left: 13.54167vw; } }
      @media (min-width: 1441px) {
        #outline-biography .timeline:before {
          left: 195px; } }
    #outline-biography .timeline .milestones {
      background: none;
      border: none;
      margin-top: 0;
      padding-top: 12vw;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0; }
      @media (min-width: 769px) {
        #outline-biography .timeline .milestones {
          padding-top: 3.47222vw; } }
      @media (min-width: 1441px) {
        #outline-biography .timeline .milestones {
          padding-top: 50px; } }
      #outline-biography .timeline .milestones:first-child {
        padding-top: 0; }
        #outline-biography .timeline .milestones:first-child:before {
          top: 0; }
      #outline-biography .timeline .milestones:last-child:after {
        height: 13.33333vw; }
        @media (min-width: 769px) {
          #outline-biography .timeline .milestones:last-child:after {
            height: 3.47222vw; } }
        @media (min-width: 1441px) {
          #outline-biography .timeline .milestones:last-child:after {
            height: 50px; } }
      #outline-biography .timeline .milestones:after {
        content: "";
        display: block;
        width: 1.33333vw;
        height: 100%;
        background: #e3e2df;
        position: absolute;
        top: 0;
        left: 28.8vw;
        transform: none;
        z-index: 1; }
        @media (min-width: 769px) {
          #outline-biography .timeline .milestones:after {
            width: 0.34722vw;
            left: 6.94444vw; } }
        @media (min-width: 1441px) {
          #outline-biography .timeline .milestones:after {
            width: 5px;
            left: 100px; } }
      #outline-biography .timeline .milestones:before {
        left: 27.46667vw;
        top: 12vw;
        z-index: 2; }
        @media (min-width: 769px) {
          #outline-biography .timeline .milestones:before {
            left: 6.59722vw;
            top: 3.47222vw; } }
        @media (min-width: 1441px) {
          #outline-biography .timeline .milestones:before {
            left: 95px;
            top: 50px; } }
      #outline-biography .timeline .milestones .time {
        width: 29.06667vw;
        font-size: 4.26667vw;
        margin-top: -0.8vw; }
        @media (min-width: 769px) {
          #outline-biography .timeline .milestones .time {
            width: 6.94444vw;
            font-size: 1.11111vw;
            margin-top: -0.34722vw;
            padding-left: 0;
            padding-right: 0; } }
        @media (min-width: 1441px) {
          #outline-biography .timeline .milestones .time {
            width: 100px;
            font-size: 16px;
            margin-top: -5px;
            padding-left: 0; } }
        #outline-biography .timeline .milestones .time:after {
          display: none; }
      #outline-biography .timeline .milestones .detail {
        width: calc(100% - 29.06667vw);
        margin-top: 0;
        padding-left: 6.66667vw; }
        @media (min-width: 769px) {
          #outline-biography .timeline .milestones .detail {
            width: calc(100% - 6.94444vw);
            margin-top: 0;
            padding-left: 2.77778vw; } }
        @media (min-width: 1441px) {
          #outline-biography .timeline .milestones .detail {
            width: calc(100% - 100px);
            margin-top: 0;
            padding-left: 40px; } }
        #outline-biography .timeline .milestones .detail :first-child {
          margin-top: -2.4vw; }
          @media (min-width: 769px) {
            #outline-biography .timeline .milestones .detail :first-child {
              margin-top: -0.55556vw; } }
          @media (min-width: 1441px) {
            #outline-biography .timeline .milestones .detail :first-child {
              margin-top: -8px; } }
        #outline-biography .timeline .milestones .detail p {
          font-size: 4vw;
          line-height: 2; }
          @media (min-width: 769px) {
            #outline-biography .timeline .milestones .detail p {
              width: 100%;
              font-size: 1.04167vw; } }
          @media (min-width: 1441px) {
            #outline-biography .timeline .milestones .detail p {
              font-size: 15px; } }
        @media (min-width: 769px) {
          #outline-biography .timeline .milestones .detail .button-block {
            margin-top: 2.08333vw; } }
        @media (min-width: 1441px) {
          #outline-biography .timeline .milestones .detail .button-block {
            margin-top: 30px; } }
        #outline-biography .timeline .milestones .detail .button-block a {
          font-size: 3.2vw;
          padding: 2.66667vw 9.33333vw 2.66667vw 4vw; }
          @media (min-width: 769px) {
            #outline-biography .timeline .milestones .detail .button-block a {
              width: auto;
              margin-left: 0;
              font-size: 0.97222vw;
              padding: 1.04167vw 3.47222vw; } }
          @media (min-width: 1441px) {
            #outline-biography .timeline .milestones .detail .button-block a {
              font-size: 14px;
              padding: 15px 50px; } }
          #outline-biography .timeline .milestones .detail .button-block a:after {
            right: 4vw; }
            @media (min-width: 769px) {
              #outline-biography .timeline .milestones .detail .button-block a:after {
                right: 1.38889vw; } }
            @media (min-width: 1441px) {
              #outline-biography .timeline .milestones .detail .button-block a:after {
                right: 20px; } }
        #outline-biography .timeline .milestones .detail img {
          max-width: 100%; }
          @media (min-width: 769px) {
            #outline-biography .timeline .milestones .detail img {
              margin-top: 2.08333vw; } }
          @media (min-width: 1441px) {
            #outline-biography .timeline .milestones .detail img {
              margin-top: 30px; } }
  #outline-biography .wrap-box {
    display: block;
    width: 100%; }
    @media (min-width: 769px) {
      #outline-biography .wrap-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center; } }
    #outline-biography .wrap-box .content-box {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 5.33333vw; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .content-box {
          width: 50%;
          padding: 8.33333vw 6.94444vw;
          background: #fffffd;
          z-index: 2; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box .content-box {
          width: 50%;
          padding: 120px 100px; } }
      #outline-biography .wrap-box .content-box > :last-child {
        padding-bottom: 10.66667vw; }
        @media (min-width: 769px) {
          #outline-biography .wrap-box .content-box > :last-child {
            padding-bottom: 0; } }
    @media (min-width: 769px) {
      #outline-biography .wrap-box .image {
        width: 50%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 50%;
        opacity: 0;
        transition: all 0.5s ease;
        padding-top: 5.27778vw; }
        #outline-biography .wrap-box .image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-top: 0;
          object-position: left top; } }
    @media (min-width: 1441px) {
      #outline-biography .wrap-box .image {
        padding-top: 76px;
        width: 50%; } }
    #outline-biography .wrap-box .image.absolute {
      position: absolute;
      bottom: 0;
      top: 0; }
    #outline-biography .wrap-box .head {
      position: relative;
      display: inline-flex;
      margin: 18.66667vw auto 0;
      border: 0.26667vw solid #444444;
      font-size: 4.8vw;
      line-height: 1;
      padding: 2.66667vw 6.66667vw;
      font-weight: bold; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .head {
          margin: auto;
          border: 0.06944vw solid #444444;
          font-size: 1.25vw;
          padding: 0.69444vw 1.73611vw; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box .head {
          margin: auto;
          border: 1px solid #444444;
          font-size: 18px;
          padding: 10px 25px; } }
      #outline-biography .wrap-box .head + p {
        margin-top: 6.66667vw; }
        @media (min-width: 769px) {
          #outline-biography .wrap-box .head + p {
            margin-top: 2.08333vw; } }
        @media (min-width: 1441px) {
          #outline-biography .wrap-box .head + p {
            margin-top: 30px; } }
    #outline-biography .wrap-box .title {
      width: 100%;
      text-align: center;
      font-size: 6.4vw;
      font-weight: bold;
      line-height: 1;
      margin-top: 6.66667vw; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .title {
          font-size: 2.5vw;
          margin-top: 2.08333vw; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box .title {
          font-size: 36px;
          margin-top: 30px; } }
      #outline-biography .wrap-box .title:first-child {
        margin-top: 18.66667vw; }
        @media (min-width: 769px) {
          #outline-biography .wrap-box .title:first-child {
            margin-top: 0; } }
        @media (min-width: 1441px) {
          #outline-biography .wrap-box .title:first-child {
            margin-top: 0; } }
    #outline-biography .wrap-box .subtitle {
      width: 100%;
      text-align: center;
      font-size: 4.26667vw;
      line-height: 1;
      margin-top: 5.33333vw; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .subtitle {
          font-size: 1.11111vw;
          margin-top: 0.69444vw; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box .subtitle {
          font-size: 16px;
          margin-top: 10px; } }
    #outline-biography .wrap-box p {
      font-size: 4vw;
      margin-top: 6.66667vw;
      width: 100%; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box p {
          font-size: 1.04167vw;
          margin-top: 2.77778vw; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box p {
          font-size: 15px;
          margin-top: 40px; } }
      #outline-biography .wrap-box p.center {
        text-align: center; }
    #outline-biography .wrap-box .list {
      margin-top: 0; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .list {
          margin-top: 2.43056vw; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box .list {
          margin-top: 35px; } }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .list li:last-child {
          margin-left: auto;
          margin-right: 0; } }
      #outline-biography .wrap-box .list li a {
        font-weight: bold; }
      #outline-biography .wrap-box .list + .button-block {
        margin-top: 13.33333vw; }
        @media (min-width: 769px) {
          #outline-biography .wrap-box .list + .button-block {
            margin-top: 4.16667vw; } }
        @media (min-width: 1441px) {
          #outline-biography .wrap-box .list + .button-block {
            margin-top: 60px; } }
    #outline-biography .wrap-box .button-block {
      width: 100%; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .button-block + .button-block {
          margin-top: 2.08333vw; } }
      @media (min-width: 1441px) {
        #outline-biography .wrap-box .button-block + .button-block {
          margin-top: 30px; } }
      #outline-biography .wrap-box .button-block a {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 4vw 13.33333vw 4vw 11.33333vw; }
        #outline-biography .wrap-box .button-block a.center {
          padding: 4vw 10.66667vw 4vw 10.66667vw;
          text-align: center; }
          @media (min-width: 769px) {
            #outline-biography .wrap-box .button-block a.center {
              padding: 1.04167vw 3.47222vw; } }
          @media (min-width: 1441px) {
            #outline-biography .wrap-box .button-block a.center {
              padding: 15px 50px; } }
        @media (min-width: 769px) {
          #outline-biography .wrap-box .button-block a {
            text-align: center;
            min-width: 22.91667vw;
            padding: 1.04167vw 3.47222vw; } }
        @media (min-width: 1441px) {
          #outline-biography .wrap-box .button-block a {
            min-width: 330px;
            padding: 15px 50px; } }
    #outline-biography .wrap-box .list li {
      margin-top: 4vw; }
      @media (min-width: 769px) {
        #outline-biography .wrap-box .list li {
          width: auto;
          margin-top: 0; } }
  @media (min-width: 769px) {
    #outline-biography #fp-nav {
      transform: none;
      bottom: 6.94444vw;
      top: auto;
      z-index: 9991; } }
  @media (min-width: 1441px) {
    #outline-biography #fp-nav {
      bottom: 100px;
      top: auto; } }
  #outline-biography #fp-nav ul li:first-child {
    display: none; }
  #outline-biography #fp-nav ul li a {
    margin: 10px 0; }
    #outline-biography #fp-nav ul li a span {
      background: rgba(255, 255, 255, 0.4); }
      @media (min-width: 769px) {
        #outline-biography #fp-nav ul li a span {
          width: 0.41667vw;
          height: 0.41667vw;
          margin: auto; } }
      @media (min-width: 1441px) {
        #outline-biography #fp-nav ul li a span {
          width: 6px;
          height: 6px; } }
    #outline-biography #fp-nav ul li a:hover span, #outline-biography #fp-nav ul li a.active span {
      background: #fff; }
      @media (min-width: 769px) {
        #outline-biography #fp-nav ul li a:hover span, #outline-biography #fp-nav ul li a.active span {
          width: 0.41667vw;
          height: 0.41667vw; } }
      @media (min-width: 1441px) {
        #outline-biography #fp-nav ul li a:hover span, #outline-biography #fp-nav ul li a.active span {
          width: 6px;
          height: 6px; } }
