#adstudies{
  .top-box{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    p{
      font-size: vwSp(32);
      order: 3;
      margin-top: vwSp(120);
      @include pc-layout(){
        font-size: vwPc(16);
        margin-top: vwPc(40);
      }
      @include large-layout(){
        font-size: 16px;
        margin-top: 40px;
      }
    }
    h1{
      width: calc(100% + #{vwSp(80)});
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 vwSp(40);
      height: vwSp(450);
      background: #ecece7;
      margin: 0 vwSp(-40) 0;
      order: 1;
      @include pc-layout(){
        height: auto;
        width: 100%;
        background-color: none;
        padding: 0;
        order: 2;
        background:none;
        margin: vwPc(50) 0 0;
      }
      @include large-layout(){
        margin-top: 50px;
      }
    }
    .breadcrumb{
      order: 2;
      @include pc-layout(){
       order: 1;
      }
    }
    +.filter-box{
      margin-top: vwSp(140);
      border-top: vwSp(2) solid #eae9e6;
      @include pc-layout(){
        border-top: vwPc(1) solid #eae9e6;
        margin-top: vwPc(70);
      }
      @include large-layout(){
        border-top: 1px solid #eae9e6;
        margin-top: 70px;
      }
    }
  }
  .filter-box{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-bottom: vwSp(2) solid #eae9e6;
    padding: vwSp(40) 0 vwSp(50);
    @include pc-layout(){
      border-bottom: vwPc(1) solid #eae9e6;
      padding: vwPc(40) 0;
    }
    @include large-layout(){
      border-bottom: 1px solid #eae9e6;
      padding: 40px 0;
    }
    .filter-head{
      width: 100%;
      position: relative;
      display: flex;
      font-size: vwSp(32);
      font-weight: bold;
      line-height: 1.5;
      @include pc-layout(){
        width: vwPc(200);
        font-size: vwPc(16);
        &:after{
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          border-right: vwPc(1) solid #eae9e6;
          height: vwPc(25);
          top:vwPc(2);
          right: 0;
        }
      }
      @include large-layout(){
        font-size: 16px;
        width: 200px;
        &:after{
          border-right: 1px solid #eae9e6;
          height: 25px;
          top:2px;
        }
      }
    }
    .filter-list{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      @include pc-layout(){
        width: calc(100% - #{vwPc(200)});
      }
      @include large-layout(){
        width: calc(100% - 200px);
      }
    }
    .filter-item{
      width: auto;
      display: flex;
      flex-wrap: wrap;
      input{
        display: none;
        &:checked{
          ~ label{
            color: #fff;
            background: #65b393;
          }
        }
      }
      label{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: vwSp(60);
        line-height: vwSp(50);
        padding: 0 vwSp(50);
        border-radius: vwSp(30);
        border: vwSp(2) solid #65b393;
        color: #65b393;
        font-size: vwSp(26);
        margin-top: vwSp(30);
        margin-right: vwSp(35);
        &:hover{
          color: #fff;
          background: #65b393;
        }
        @include pc-layout(){
          margin-top: 0;
          margin-right: 0;
          margin-left: vwPc(20);
          height: vwPc(30);
          line-height: 1;
          padding: 0 vwPc(25);
          border-radius: vwPc(15);
          border: vwPc(2) solid #65b393;
          font-size: vwPc(13);
        }
        @include large-layout(){
          margin-left: 20px;
          height: 30px;
          padding: 0 25px;
          border-radius: 15px;
          border: 2px solid #65b393;
          font-size: 13px;
        }
      }
    }
  }
  .search-box{
    width: 100%;
    position: relative;
    display: flex;
    margin-top: vwSp(80);
    @include pc-layout(){
      width: vwPc(500);
      margin: 0 auto 0;
      position: absolute;
      top: vwPc(106);
      right: 0;
    }
    @include large-layout(){
      width: 500px;
      margin: 0 auto 0;
      top:106px;
      right: 0;
    }
    .search-input{
      width: 100%;
      position: relative;
      display: flex;
      height: vwSp(110);
      padding: 0 vwSp(110) 0 vwSp(50);
      border-radius: vwSp(60);
      border: vwSp(2) solid #eae9e6;
      outline: none;
      font-size: vwSp(30);
      appearance: none;
      @include pc-layout(){
        height: vwPc(55);
        padding: 0 vwPc(55) 0 vwSp(25);
        border-radius: vwPc(30);
        border: vwPc(1) solid #eae9e6;
        font-size: vwPc(15);
      }
      @include large-layout(){
        height: 55px;
        padding: 0 55px 0 25px;
        border-radius: 30px;
        border: 1px solid #eae9e6;
        font-size: 15px;
      }
    }
    .search-button{
      position: absolute;
      width: vwSp(110);
      height: vwSp(110);
      outline: none;
      appearance: none;
      border: none;
      background: none;
      border-radius: vwSp(60);
      right: 0;
      top:0;
      background: url("../img/icn_search.png")50% 50% no-repeat;
      background-size: vwSp(37);
      cursor:pointer;
      @include pc-layout(){
        width: vwPc(55);
        height: vwPc(55);
        border-radius: vwPc(30);
        background-size: vwPc(19);
      }
      @include large-layout(){
        width: 55px;
        height: 55px;
        border-radius: 30px;
        background-size: 19px;
      }
    }
  }
  .highlight{
    margin-top: vwSp(140);
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    padding: vwSp(50) vwSp(50) vwSp(100);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    text-decoration: none;
    @include pc-layout(){
      margin-top: vwPc(80);
      padding: vwPc(60) vwPc(75) vwPc(60) vwPc(60);
    }
    @include large-layout(){
      margin-top: 80px;
      padding: 60px 75px 60px 60px;
    }
    .img-box{
        overflow: hidden;
        width: 100%;
      @include pc-layout(){
        width: vwPc(540);
        max-height: vwPc(360);
      }
      @include large-layout(){
        width: 540px;
        max-height: 360px;
      }
        img{
            margin: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }
    .detail-box{
      @include pc-layout(){
        width: calc(100% - #{vwPc(540)});
        padding-left: vwPc(50);
        padding-bottom: vwPc(20);
      }
      @include large-layout(){
        width: calc(100% - 540px);
        padding-left: 50px;
        padding-bottom: 20px;
      }
    }
    img{
      margin: 0;
      transition: all 0.3s ease;
      display: block;
    }
    .vol{
      margin-top: vwSp(50);
      font-size: vwSp(28);
      line-height: 1.2;
      font-family: 'Inter', sans-serif;
      @include pc-layout(){
        margin-top: 0;
        font-size: vwPc(14);
      }
      @include large-layout(){
        font-size: 14px;
      }
    }
    .article-detail{
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(10);
      }
      @include large-layout(){
        margin-top: 10px;
      }
      .tag{
        margin-top: vwSp(20);
        height: vwSp(50);
        line-height: vwSp(48);
        border-radius: vwSp(30);
        padding: 0 vwSp(30);
        margin-right: vwSp(10);
        margin-left: 0;
        font-size: vwSp(24);
        @include pc-layout(){
          margin-top: vwPc(10);
          height: vwPc(25);
          line-height: vwPc(24);
          border-radius: vwPc(15);
          padding: 0 vwPc(18);
          margin-right: vwPc(5);
          font-size: vwPc(12);
        }
        @include large-layout(){
          margin-top: 10px;
          height: 25px;
          line-height: 24px;
          border-radius: 15px;
          padding: 0 18px;
          margin-right: 5px;
          font-size: 12px;
        }
      }
      .vol{
        margin-bottom: 0;
      }
    }
    .title{
      font-size: vwSp(44);
      width: 100%;
      position: relative;
      text-decoration: none;
      line-height: 1.6;
      margin-top: vwSp(50);
      font-weight: bold;
      @include pc-layout(){
        font-size: vwPc(22);
        margin-top: vwPc(30);
      }
      @include large-layout(){
        font-size: 22px;
        margin-top: 30px;
      }
    }
    p{
      font-size: vwSp(32);
      line-height: 2;
      margin-top: vwSp(70);
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include pc-layout(){
        font-size: vwPc(16);
        margin-top: vwPc(40);
      }
      @include large-layout(){
        font-size: 16px;
        margin-top: 40px;
      }
    }
    a{
      text-decoration: none;
      transition: all 0.3s ease;
      display: block;
    }
    &:hover{
      @include pc-layout(){
        box-shadow: 0 0 vwPc(10) 0 rgba(0, 0, 0, 0.2);
      }
      @include large-layout(){
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      }
      img{
        opacity: 0.6;
      }
      &:after{
        animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: vwSp(21);
      height: vwSp(23);
      background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
      background-size: cover;
      bottom: vwSp(30);
      right: vwSp(30);
      @include pc-layout() {
        width: vwPc(11);
        height: vwPc(12);
        bottom: vwPc(60);
        right: vwPc(60);
      }
      @include large-layout() {
        width: 11px;
        height: 12px;
        bottom: 60px;
        right: 60px;
      }
    }
  }
  .row{
    @include pc-layout(){
      width: calc(100% + #{vwPc(30)});
      margin: vwPc(20) vwPc(-15) 0;
    }
    @include large-layout(){
      width: calc(100% + 30px);
      margin: 20px -15px 0;
    }
    &.relate{
      margin-top: vwSp(80);
      @include pc-layout() {
        margin-top: vwPc(50);
        margin-bottom: vwPc(-30);
      }
      @include large-layout() {
        margin-top: 50px;
        margin-bottom: -30px;
      }
      img{
        @include pc-layout() {
          margin-top: 0;
        }
        @include large-layout() {
          margin-top: 0;
        }
      }
      .col{
        width: calc((100% - #{vwSp(40)}) / 2 );
        margin-right: vwSp(40);
        @include pc-layout(){
          width: calc(100% / 4);
          margin-top: 0;
          margin-right: 0;
          margin-left: 0;
          padding:vwPc(20) vwPc(15);
          margin-bottom: vwPc(30);
        }
        @include large-layout(){
          width: calc(100% / 4);
          margin-right: 0;
          margin-left: 0;
          padding: 20px 15px;
          margin-bottom: 30px;
        }
        &:nth-child(4n+1),
        &:first-child{
          @include pc-layout(){
            margin-left: 0;
          }
        }
        &:nth-child(even){
          margin-right: 0;
        }
      }
    }
    .col{
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
      &:hover{
        @include pc-layout(){
          transition: all 0.3s ease;
          box-shadow: 0 0 vwPc(10) 0 rgba(0, 0, 0, 0.2);
        }
        @include large-layout(){
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        }
      }

      .article-detail{
        margin-top: vwSp(20);
        @include pc-layout(){
          margin-top: vwPc(10);
        }
        @include large-layout(){
          margin-top: 10px;
        }
        .vol{
          margin-bottom: 0;
        }
      }
      .big{
        @include pc-layout(){
          width: 100%;
          display: block;
          min-height: vwPc(50);
        }
        @include large-layout(){
          min-height: 50px;
        }
      }
    }
  }
}
#postnumber{
    .main{
        .col{
            @include pc-layout(){
                width: vwPc(555);
            }
            @include large-layout(){
                width: 555px;
            }
            .post-image{
                width: 100%;
                @include pc-layout(){
                  height: vwPc(370);
                  object-fit: cover;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: vwPc(50) auto 0;
                  flex-direction: column;

                }
                @include large-layout(){
                  height: 370px;
                  margin: 50px auto 0;
                }
                img{
                   width: 100%;
                  @include pc-layout(){
                    margin: 0;
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                  }
                }
            }
        }
        .row{
            &.col-3{
              .col{
                @include pc-layout(){
                  width: vwPc(350);
                }
                @include large-layout(){
                  width: 350px;
                }
                .post-image{
                  width: 100%;
                  @include pc-layout(){
                    width: vwPc(350);
                    height: vwPc(333);
                    object-fit: cover;
                  }
                  @include large-layout(){
                    width: 350px;
                    height: 333px;
                  }
                }
              }
            }
          &.col-4{
            .col{
              @include pc-layout(){
                width: vwPc(247);
              }
              @include large-layout(){
                width: 247px;
              }
              .post-image{
                width: 100%;
                @include pc-layout(){
                  height: vwPc(186);
                }
                @include large-layout(){
                  height: 186px;
                }
              }
            }
          }
            &.p-60{
                @include pc-layout(){
                    flex-wrap: nowrap;
                }
                .col{
                    @include pc-layout(){
                        margin-left: vwPc(60);
                        margin-top: 0;
                        width: 100%;
                    }
                    @include large-layout(){
                        margin-left: 60px;
                    }
                    &:first-child{
                        @include pc-layout(){
                            margin-left: 0;
                        }
                    }
                }
            }
            &.p-30{
                .col{
                    width: calc((100% - #{vwSp(40)}) / 2 );
                    margin-right: vwSp(40);
                    @include pc-layout(){
                        width: calc((100% - #{vwPc(90)}) / 4);
                        margin-left: vwPc(30);
                        margin-top: 0;
                        margin-right: 0;
                    }
                    @include large-layout(){
                        width: calc((100% - 90px) / 4);
                        margin-left: 30px;
                    }
                    &:nth-child(4n+1),
                    &:first-child{
                        @include pc-layout(){
                            margin-left: 0;
                        }
                    }
                    a{
                        img{
                            transition: all 0.3s ease;
                        }
                        &:hover{
                            img{
                                opacity: 0.6;
                            }
                        }
                    }
                    .caption{
                        margin-top: vwSp(20);
                        @include pc-layout(){
                            margin-top: vwPc(20);
                        }
                        @include large-layout(){
                            margin-top: 20px;
                        }
                    }
                    &:nth-child(even){
                        margin-right: 0;
                    }
                    img{
                        margin-top: vwSp(40);
                        @include pc-layout(){
                            margin-top: vwPc(40);
                            max-width:100%;
                            max-height: 80vh;
                            object-fit: cover;
                        }
                        @include large-layout(){
                            margin-top: 40px;
                        }
                    }
                    .article-detail{
                        margin-top: vwSp(30);
                        @include pc-layout(){
                            margin-top: vwPc(20);
                        }
                        @include large-layout(){
                            margin-top: 20px;
                        }
                        .tag{
                            margin: 0 vwSp(20) vwSp(20) 0;
                            @include pc-layout(){
                                margin: 0 vwPc(5) vwPc(5) 0;
                            }
                            @include large-layout(){
                                margin: 0 5px 5px 0;
                            }
                        }
                        +p{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
