#backnumber{
  h1{
    margin-top: vwSp(100);
    @include pc-layout() {
      margin-top: vwPc(80);
    }
    @include large-layout() {
      margin-top: 80px;
    }
    +p{
      margin-top: vwSp(70);
      font-size: vwSp(32);
      @include pc-layout() {
        margin-top: vwPc(40);
        font-size: vwPc(16);
      }
      @include large-layout() {
        margin-top: 40px;
        font-size: 16px;
      }
    }
  }
  h3{
    margin-top: vwSp(150);
    @include pc-layout() {
      margin-top: vwPc(100);
    }
    @include large-layout() {
      margin-top: 100px;
    }
  }
  .book-hightlight{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: vwSp(60);
    @include pc-layout() {
      margin-top: vwPc(40);
    }
    @include large-layout() {
      margin-top: 40px;
    }
    a{
      position: relative;
      width: 100%;
      text-decoration: none;
    }
    .highlight-item{
      width: 100%;
      height: vwSp(915);
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: all 0.3s ease;
      @include pc-layout() {
        height: vwPc(450);
        padding: 0 vwPc(100) 0 vwPc(194);
      }
      @include large-layout() {
        height: 450px;
        padding: 0 100px 0 194px;
      }
      .background-image{
        position: absolute;
        width: 600%;
        max-width: initial;
        top: vwSp(-300);
        left: -200%;
        filter: blur(#{vwSp(70)}) brightness(0.95);
        pointer-events: none;
        border: none; outline: none; display: block;
        -webkit-transform: translateZ(0);
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        transition: all 0.3s ease;
        @include pc-layout() {
          top: vwPc(-400);
          filter: blur(#{vwPc(57)}) brightness(0.95);
          pointer-events: none;
        }
        @include large-layout() {
          top: -400px;
          filter: blur(57px) brightness(0.95);
        }
      }
      .content-box{
        z-index: 2;
        position: relative;
        display: block;
        @include pc-layout() {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
        img{
          width: vwSp(274);
          margin: auto;
          transition: all 0.3s ease;
          @include pc-layout() {
            width: vwPc(232);
          }
          @include large-layout() {
            width: 232px;
          }
        }
        .right-content{
          @include pc-layout() {
            width: calc(100% - #{vwPc(232)});
            position: relative;
            display: block;
            align-items: center;
            padding-left: vwPc(70);
          }
          @include large-layout() {
            width: calc(100% - 232px);
            padding-left: 70px;
          }
        }
        .vol{
          width:100%;
          text-align: center;
          padding: 0 vwSp(60);
          font-family: 'Inter', sans-serif;
          font-size: vwSp(40);
          line-height: 1.2;
          margin-top: vwSp(50);
          color: #fff;
          @include pc-layout() {
            padding: 0 ;
            font-size: vwPc(24);
            margin-top: 0;
            text-align: left;
          }
          @include large-layout() {
            font-size: 24px;
          }
        }
        .title{
          width:100%;
          text-align: center;
          padding: 0 vwSp(60);
          font-size: vwSp(60);
          line-height: 1.5;
          margin-top: vwSp(30);
          font-weight: bold;
          color: #fff;
          @include pc-layout() {
            padding: 0 ;
            font-size: vwPc(46);
            margin-top: vwPc(20);
            text-align: left;
          }
          @include large-layout() {
            font-size: 46px;
            margin-top: 20px;
          }
        }
      }
      &:hover{
        .background-image{
          @include pc-layout() {
            filter: blur(#{vwPc(57)}) brightness(0.95);
            opacity: 0.6;
          }
          @include large-layout() {
            filter: blur(57px) brightness(0.95);
          }
        }
        .content-box{

        }
        &:after{
          animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: vwSp(21);
        height: vwSp(23);
        background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
        background-size: cover;
        bottom: vwSp(30);
        right: vwSp(30);
        @include pc-layout() {
          width: vwPc(11);
          height: vwPc(12);
          bottom: vwPc(30);
          right: vwPc(30);
        }
        @include large-layout() {
          width: 11px;
          height: 12px;
          bottom: 30px;
          right: 30px;
        }
      }
    }
  }
  .book-list{
    margin-top: vwSp(60);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @include pc-layout() {
      margin-top: 0;

    }
    .item{
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: vwSp(90);
      @include pc-layout() {
        margin-top: vwPc(40);
        padding-bottom: vwPc(20);
        width: calc(100%/3);
      }
      @include large-layout() {
        margin-top: 40px;
        padding-bottom: 20px;
      }
      a{
        position: relative;
        width: 100%;
        text-decoration: none;
        display: block;
        text-align:center;
        flex-wrap: wrap;
        &:hover{
          img{
            box-shadow: 0 0 vwSp(20) 0 rgba(0, 0, 0, 0.1);
            @include pc-layout() {
              box-shadow: 0 0 vwPc(10) 0 rgba(0, 0, 0, 0.1);
            }
            @include large-layout() {
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            }
          }
          .title{
            &:after{
              animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
            }
          }
        }
      }
      img{
        width: vwSp(300);
        margin: auto;
        transition: all 0.3s ease;
        @include pc-layout() {
          width: vwPc(150);
        }
        @include large-layout() {
          width: vwPc(150);
        }
      }
      .vol{
        width: 100%;
        padding: 0 vwSp(60);
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: vwSp(30);
        line-height: 1.2;
        margin-top: vwSp(50);
        @include pc-layout() {
          padding: 0 vwPc(20) ;
          font-size: vwPc(15);
          margin-top: vwPc(30);
        }
        @include large-layout() {
          padding: 0 20px;
          font-size: 15px;
          margin-top: 30px;
        }
      }
      .title{
        padding: 0 0 0 vwSp(40);
        text-align: center;
        font-size: vwSp(40);
        line-height: 1.5;
        margin-top: vwSp(30);
        font-weight: bold;
        position: relative;
        display: inline-block;
        @include pc-layout() {
          padding: 0 0 0 vwPc(20);
          font-size: vwPc(20);
          margin-top: vwPc(10);
        }
        @include large-layout() {
          padding: 0 0 0 20px;
          font-size: 20px;
          margin-top: 10px;
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          width: vwSp(21);
          height: vwSp(23);
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: cover;
          top: vwSp(20);
          left: 0;
          @include pc-layout(){
            width: vwPc(11);
            height: vwPc(12);
            top: vwPc(10);
          }
          @include large-layout(){
            width: 11px;
            height: 12px;
            bottom: 20px;
            top: 10px;
          }
        }
      }
      &:first-child{
        margin-top: 0;
        @include pc-layout() {
          margin-top: vwPc(40);
        }
        @include large-layout() {
          margin-top: 40px;
        }
      }
    }
  }
  .search-box{
    width: 100%;
    position: relative;
    display: flex;
    margin-top: vwSp(80);
    @include pc-layout(){
      width: vwPc(500);
      margin: 0 auto 0;
      position: absolute;
      top: vwPc(134);
      right: vwPc(100);
    }
    @include large-layout(){
      width: 500px;
      margin: 0 auto 0;
      top:134px;
      right: 100px;
    }
    .search-input{
      width: 100%;
      position: relative;
      display: flex;
      height: vwSp(110);
      padding: 0 vwSp(110) 0 vwSp(50);
      border-radius: vwSp(60);
      border: vwSp(2) solid #eae9e6;
      outline: none;
      font-size: vwSp(30);
      appearance: none;
      @include pc-layout(){
        height: vwPc(55);
        padding: 0 vwPc(55) 0 vwSp(25);
        border-radius: vwPc(30);
        border: vwPc(1) solid #eae9e6;
        font-size: vwPc(15);
      }
      @include large-layout(){
        height: 55px;
        padding: 0 55px 0 25px;
        border-radius: 30px;
        border: 1px solid #eae9e6;
        font-size: 15px;
      }
    }
    .search-button{
      position: absolute;
      width: vwSp(110);
      height: vwSp(110);
      outline: none;
      appearance: none;
      border: none;
      background: none;
      border-radius: vwSp(60);
      right: 0;
      top:0;
      background: url("../img/icn_search.png")50% 50% no-repeat;
      background-size: vwSp(37);
      cursor:pointer;
      @include pc-layout(){
        width: vwPc(55);
        height: vwPc(55);
        border-radius: vwPc(30);
        background-size: vwPc(19);
      }
      @include large-layout(){
        width: 55px;
        height: 55px;
        border-radius: 30px;
        background-size: 19px;
      }
    }
  }
}
#sp2014,
#backnumberdetail{
  .main{
    a{
      transition: all 0.3s ease;
      &:hover{
        opacity: 0.6;
      }
    }
  }
  .top-banner{
    width: 100%;
    position: relative;
    display: block;
    margin-top: vwSp(50);
    @include pc-layout(){
      margin-top: vwPc(40);
    }
    @include large-layout(){
      margin-top: 40px;
    }
    img{
      width: 100%;
      margin-top: 0;
    }
  }
  .main-info{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: vwSp(50);
    @include pc-layout(){
      margin-top: vwPc(40);
    }
    @include large-layout(){
      margin-top: 40px;
    }
    .image{
      order: 1;
      width: vwSp(304);
      margin:0 auto;
      img{
        margin: 0;
      }
      @include pc-layout(){
        order: 2;
        width: vwPc(304);
      }
      @include large-layout(){
        width: 304px;
      }
    }
    .info{
      order: 2;
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: vwSp(50);
      @include pc-layout(){
        order: 1;
        margin-top: 0;
        width: calc(100% - #{vwPc(304)});
        padding-right: vwPc(100);
      }
      @include large-layout(){
        width: calc(100% - 304px);
        padding-right: 100px;
      }
      .vol{
        width: 100%;
        position: relative;
        display: flex;
        font-size: vwSp(32);
        font-weight: bold;
        line-height: 2;
        font-family: 'Inter', sans-serif;
        @include pc-layout(){
          font-size: vwPc(16);
        }
        @include large-layout(){
          font-size: 16px;
        }
      }
      .title{
        width: 100%;
        margin-top: vwSp(10);
        line-height: 1.5;
        @include pc-layout() {
          margin-top: vwPc(10);
        }
        @include large-layout(){
          margin-top: 10px;
        }
      }
      .subtitle{
        font-size: vwSp(42);
        font-weight: bold;
        margin-top: vwSp(60);
        width: 100%;
        line-height: 2;
        @include pc-layout() {
          font-size: vwPc(26);
          margin-top: vwPc(20);
        }
        @include large-layout(){
          font-size: 26px;
          margin-top: 20px;
        }
      }
      .detail{
        margin-top: vwSp(20);
        line-height: 2;
        @include pc-layout() {
          margin-top: 0;
        }
      }
    }
  }
  .article-detail{
    .tag{
      &:hover{
        opacity: 1;
      }
    }
  }
  .button-block{
    a{
      &:hover{
        opacity: 1;
      }
    }
  }
  h3{
    flex-wrap: wrap;
    .note{
      width: 100%;
      font-weight: normal;
      @include pc-layout() {
        position: absolute;
        right: 0;
        bottom: vwPc(15);
        width: auto;
        font-size: vwPc(13);
      }
      @include large-layout(){
        bottom: 15px;
        font-size: 13px;
      }
    }
  }
}
#postnumber{
  h1{
    margin-top: vwSp(100);
    @include pc-layout() {
      margin-top: vwPc(80);
    }
    @include large-layout() {
      margin-top: 80px;
    }
  }
}