@mixin pc-layout {
    @media (min-width: $desktop) {
        @content;
    }
}

@mixin large-layout {
    @media (min-width: $largedestop) {
        @content;
    }
}

@mixin lg-layout {
    @media (min-width: $largedestop) {
        @content;
    }
}

@mixin sp-layout {
    @media (max-width: $mobile) {
        @content;
    }
}

@mixin pcwidth($pixel) {
    width: vwPc($pixel);
    max-width: $pixel * 1px;
}

@mixin pcheight($pixel) {
    height: vwPc($pixel);
    max-height: $pixel * 1px;
}
