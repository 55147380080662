section{
  position: relative;
  width: 100%;
  padding-bottom: vwSp(140);
  @include pc-layout(){
    padding-bottom: vwPc(100);
  }
  @include large-layout(){
    padding-bottom: 100px;
  }
  &.flex-box{
    display: flex;
    flex-wrap: wrap;
    background: #fafaf7;
      @include pc-layout(){
          background: none;
      }
  }
  &.book-box{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: unset;
      padding-bottom: 0;
      overflow: hidden;
      @include pc-layout(){
      }
      .book-wrap{
          width: 100%;
          @include pc-layout(){
              margin: auto;
              display: flex;
              flex-wrap: wrap;
          }
          .right-box{
              background: #fafaf7;
              padding-bottom: vwSp(140);
              @include pc-layout(){
                  padding-bottom: vwPc(100);
              }
              @include large-layout(){
                  padding-bottom: 100px;
                  &:after{
                      display: block;
                      content: "";
                      height: 100%;
                      position: absolute;
                      top:0;
                      width: calc((100vw - 1440px)/2);
                      right: calc((-100vw + 1440px)/2);
                      background: #fafaf7;
                  }
              }
          }
      }
  }
  &.first-box{
    padding-top: vwSp(140);
    @include pc-layout(){
      padding-top: vwPc(150);
    }
    @include large-layout(){
      padding-top: 150px;
    }
    .left-box{
      @include pc-layout(){
        padding-top: vwPc(150);
        display: block;
      }
      @include large-layout(){
        padding-top: 150px;
      }
    }
    .right-box{
      padding-top: vwSp(90);
      @include pc-layout(){
        padding-top: 20vh;
        height: calc(100vh - #{vwPc(150)});
      }
      @include large-layout(){
        padding-top: 25vh;
        height: calc(100vh - 150px);
      }
      .content-block {
        padding: 0 vwSp(40) vwSp(50);
        @include pc-layout(){
          padding: 0 0 vwPc(50);
        }
        @include large-layout(){
          padding: 0 0 50px;
        }
        p{
          @include pc-layout(){
            font-size: vwPc(21);
          }
          @include large-layout(){
            font-size: 21px;
          }
        }
      }

    }
  }
  &.gray-box{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: #bdb5a3;
    margin-bottom: vwSp(140);
    padding-bottom: 0;
    @include pc-layout(){
      margin-bottom: vwPc(30);
      max-width: 100%;
    }
    @include large-layout(){
      margin-bottom: 30px;
    }
    &.last{
      @include pc-layout(){
        margin-bottom: 0;
      }
      @include large-layout(){
        margin-bottom: 0;
      }
    }
    .wrap-content{
        width: 100%;
        @include pc-layout(){
            margin: auto;
        }
    }
    .scroll-text{
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      overflow: hidden;
      &:last-child{
        span:first-child{
          margin-left: vwSp(-1500);
            @include pc-layout(){
                margin-left: vwPc(-1500);
            }
            @include large-layout(){
                margin-left:-1500px;
            }
        }
      }
      span{
        font-size: vwSp(220);
        display: block;
        width: auto;
        white-space: nowrap;
        line-height: 0.9;
        //animation: pcScroll 40s linear infinite;
        margin-right: vwSp(50);
        color: #b7af9d;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
          @include pc-layout(){
              font-size: vwPc(220);
              margin-right: vwPc(70);
          }
          @include large-layout(){
              font-size: 220px;
              margin-right:70px;
          }
      }
    }
    .box-items{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: vwSp(110) vwSp(40) vwSp(80);
        @include pc-layout(){
            max-width: 1440px;
            margin: auto;
            padding: vwPc(80) vwPc(100) vwPc(100);
        }
        @include large-layout(){
            padding: 80px 100px 100px;
        }
      a{
        width: 100%;
        text-decoration: none;
      }
      .item{
        width: 100%;
        margin-top: vwSp(110);
          @include pc-layout(){
              width: 50%;
              margin-top: 0;
              padding: 0 35px;
          }
        &:first-child{
          margin-top: 0;
        }
        a{
          width: 100%;
          &:hover{
            .item-img{
              img{
               opacity: 0.6;
              }
            }
            .item-title{
              &:after{
                animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
              }
            }
          }
        }
        .item-img{
          width: 100%;
          position: relative;
          display: block;
          overflow: hidden;
          img{
            max-width: 100%;
            width: 100%;
            transition: all 0.5s ease;
          }
        }
        .item-title{
          position: relative;
          width: 100%;
          height: vwSp(110);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-weight: bold;
          color: #fff;
          font-size: vwSp(38);
          border-bottom: vwSp(4) solid #fff;
            @include pc-layout(){
                height: vwPc(80);
                font-size: vwPc(20);
                border-bottom: vwPc(2) solid #fff;
            }
            @include large-layout(){
                height: 80px;
                font-size: 20px;
                border-bottom: 2px solid #fff;
            }
          &:after{
            content: "";
            display: block;
            position: absolute;
            width: vwSp(21);
            height: vwSp(23);
            background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
            background-size: 99%;
            top: vwSp(44);
            right: 0;
              @include pc-layout(){
                  width: vwPc(11);
                  height: vwPc(12);
                  top: vwPc(34);
                  right: 0;
              }
              @include large-layout(){
                  width: 11px;
                  height: 12px;
                  top: 34px;
              }
          }
        }
      }
    }
  }
  .img-box{
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: vwSp(90);
    @include pc-layout(){
      opacity: 0;
    }
    &.absolute{
      @include pc-layout(){
        position: absolute;
        bottom:0;
        top:auto;
        padding: 0;
        height: auto;
      }
    }
    @include pc-layout(){
      width: vwPc(600);
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: vwPc(78);
      padding-bottom:0;
      top:0;
      height: 100vh;
      position: fixed;
      opacity: 0;
      background: #fff;
      pointer-events: none;
    }
    &.show{
      @include pc-layout(){
        transition: opacity 1s ease;
        opacity: 0.7;
      }
    }
    @include large-layout(){
      padding-top: 78px;
      width: 600px;
    }
    img{
      max-width: 100%;
      @include pc-layout(){
        width: 100%;
        height: calc(100vh - #{vwPc(78)});
        object-fit: cover;
      }
      @include large-layout(){
        height: calc(100vh - 78px);
      }
    }
  }
  .left-box{
    position: relative;
    display: flex;
    width: 100%;
    padding:0 vwSp(40);
    min-height: vwSp(775);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #ecece7;
    transition: none;
    &.show{
      opacity: 1;
      transition: opacity 1s ease;
    }
    @include pc-layout(){
      padding:0 vwPc(50);
      height: 100vh;
      position: fixed;
      top:0;
      width: vwPc(600);
      min-height: auto;
      align-items: flex-start;
      justify-content: flex-start;
        background: none;
      opacity: 0;
    }
    @include large-layout(){
      min-height: auto;
      padding:0 50px;
      width: 600px;
    }
    .title{
      font-size: vwSp(56);
      font-weight: normal;
      line-height: 1.5;
      @include pc-layout(){
        padding-top: vwPc(70);
        font-size: vwPc(41);
      }
      @include large-layout(){
        padding-top: 70px;
        font-size: 41px;
      }
    }
  }
  .right-box{
    position: relative;
    display: block;
    padding: 0 vwSp(40) 0;
    width: 100%;
    @include pc-layout(){
      width: calc(100% - #{vwPc(600)});
      padding: vwPc(70) vwPc(100) 0;
      background: none;
      margin-left: vwPc(600);
    }
    @include large-layout(){
      width: calc(100% - 600px);
      padding: 70px 100px 0;
      margin-left: 600px;
    }
    .content-block{
      padding: 0 0 vwSp(50);
      @include pc-layout(){
        padding: 0 0 vwPc(50);
      }
      @include large-layout(){
        padding: 0 0 50px;
      }
      .head-content{
        font-size: vwSp(48);
        font-weight: 600;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include pc-layout(){
          font-size: vwPc(36);
          line-height: 1.5;
        }
        @include large-layout(){
          font-size: 36px;
        }
        .dash{
          position: relative;
          display: inline-block;
          width: vwSp(140);
          height: vwSp(2);
          background: #222;
          margin-right: vwSp(30);
          @include pc-layout(){
            width: vwPc(80);
            height: vwPc(1);
            margin-right: vwPc(10);
          }
          @include large-layout(){
            width: 80px;
            height: 1px;
            margin-right: 10px;
          }
        }
      }
      p{
        font-size: vwSp(32);
        line-height: 2.3;
        font-weight: 400;
        font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
        margin-top: vwSp(40);
        @include pc-layout(){
          font-size: vwPc(15);
          margin-top: vwPc(25);
        }
        @include large-layout(){
          font-size: 15px;
          margin-top: 25px;
        }
        &:first-child{
          margin-top: 0;
        }
      }
      img{
        max-width: 100%;
      }
      .bussiness-list{
          margin-top: vwSp(80);
        @include pc-layout(){
          margin-top: vwPc(50);
        }
        @include large-layout(){
          margin-top: 50px;
        }
      }
      .bussiness-item{
        margin-top: vwSp(90);
        @include pc-layout(){
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin-top: vwPc(40);
        }
        @include large-layout(){
          margin-top: 40px;
        }
        &:first-child{
          margin-top: 0;
        }
          a{
              text-decoration: none;
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              &:hover{
                .business-image{
                  opacity: 0.6;
                }
              }
          }
        .business-content{
          @include pc-layout(){
            width: calc(100% - #{vwPc(248)});
            padding-left: vwPc(30);
          }
          @include large-layout(){
            width: calc(100% - 248px);
            padding-left: 30px;
          }
        }
        .head-item{
          margin-top: vwSp(50);
          font-size: vwSp(38);
          font-weight: 600;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include pc-layout(){
            font-size: vwPc(20);
            margin-top: 0;
          }
          @include large-layout(){
            font-size: 20px;
          }
        }
        p{
          font-size: vwSp(28);
          line-height: 2;
          font-weight: 400;
          font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
          margin-top: vwSp(30);
          @include pc-layout(){
            font-size: vwPc(14);
            margin-top: vwPc(20);
          }
          @include large-layout(){
            font-size: 14px;
            margin-top: 20px;
          }
          &:first-child{
            margin-top: 0;
          }
        }
        .business-image{
          position: relative;
          display: block;
          opacity: 1;
          transition: all 0.3s ease;
            @include pc-layout(){
                width: vwPc(248);
                height: vwPc(167);

            }
            @include large-layout(){
                width: 248px;
                height: 167px;
            }
        }
        img{
          @include pc-layout(){
              width: 100%;
              height: 100%;
              object-fit: cover;
          }
        }
      }
      .book-item{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-radius: vwSp(20);
        background: #fff;
        margin-top: vwSp(210);
        padding: vwSp(180) 0 vwSp(80);
        margin-bottom: vwSp(30);
        box-shadow: 0 0 vwSp(20) 0 rgba(0, 0, 0, 0.1);
          @include pc-layout(){
              box-shadow: none;
              border-radius: vwPc(10);
              margin-top: vwPc(90);
              padding: vwPc(25) vwPc(35) vwPc(35) vwPc(250);
              min-height: vwPc(200);
              margin-bottom: 0;
          }
          @include large-layout(){
              border-radius: 10px;
              margin-top: 90px;
              padding: 25px 35px 35px 250px;
              min-height: 200px;
          }
        a{
          text-decoration: none;
          width: 100%;
        }
        .book-image{
          width: vwSp(212);
          position: absolute;
          top:vwSp(-150);
          left: calc((100% - #{ vwSp(212)}) / 2);
            @include pc-layout(){
                width: vwPc(160);
                top: vwPc(-55);
                left: vwPc(35);
            }
            @include large-layout(){
                width: 160px;
                top:-55px;
                left: 35px;
            }
          img{
            width: 100%;
          }
        }
        .book-detail{
          width: 100%;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding:0 vwSp(60);
            @include pc-layout(){
                padding: 0;
            }
          .book_tag{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 vwSp(30) vwSp(2);
            height: vwSp(50);
            color: #fff;
            font-size: vwSp(26);
            border-radius: vwSp(50);
            background: #e29f92;
            line-height: 1;
              @include pc-layout(){
                  padding: 0 vwPc(15);
                  height: vwPc(26);
                  font-size: vwPc(13);
                  border-radius: vwPc(13);
                  position: absolute;
                  bottom: vwPc(5);
                  right: vwPc(-15);
              }
              @include large-layout(){
                  padding: 0 15px;
                  height: 26px;
                  font-size: 13px;
                  border-radius: 13px;
                  bottom: 5px;
                  right: -15px;
              }
          }
          .book_title{
            display: flex;
            position: relative;
            margin-left: vwSp(20);
            font-size: vwSp(30);
              @include pc-layout(){
                  font-size: vwPc(15);
                  margin-left: 0;
              }
              @include large-layout(){
                  font-size: 15px;
              }
          }
        }
        .book-title{
          width: 100%;
          padding: 0 vwSp(60);
          position: relative;
          display: flex;
          margin-top: vwSp(30);
          font-size: vwSp(40);
          font-weight: 600;
            @include pc-layout(){
                padding: 0;
                font-size: vwPc(22);
                margin-top: vwPc(25);
            }
            @include large-layout(){
                font-size: 22px;
                margin-top: 25px;
            }
        }
        &:hover{
          box-shadow: 0 0 vwSp(20) 0 rgba(0, 0, 0, 0.1);
            @include pc-layout(){
                box-shadow: 0 0 vwPc(20) 0 rgba(0, 0, 0, 0.1);
            }
            @include large-layout(){
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
            }
          &:after{
            animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
          }
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          width: vwSp(21);
          height: vwSp(23);
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: 99%;
          bottom: vwSp(30);
          right: vwSp(30);
            @include pc-layout(){
                width: vwPc(11);
                height: vwPc(12);
                bottom: vwPc(20);
                right: vwPc(20);
            }
            @include large-layout(){
                width: 11px;
                height: 12px;
                bottom: 20px;
                right: 20px;
            }
        }
      }
      .article{
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-top: vwPc(50);
          @include large-layout(){
              margin-top: 50px;
          }
          .article-head{
              position: relative;
              display: flex;
              width: 100%;
              font-size: vwPc(20);
              font-weight: bold;
          }
          .article-list{
              position: relative;
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin-top: vwPc(40);
              @include large-layout(){
                  margin-top: 40px;
              }
              .article-item{
                  width: 100%;
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: vwPc(30);
                  border-bottom: 1px solid #eae9e6;
                  padding-bottom: vwPc(30);
                  @include large-layout(){
                      margin-top: 30px;
                      padding-bottom: 30px;
                  }
                  &:first-child{
                      margin-top: 0;
                  }
                  &:hover{
                    .article-img{
                      img{
                        opacity: 0.6;
                      }
                    }
                  }
                  a{
                      display: flex;
                      flex-wrap: wrap;
                      text-decoration: none;
                  }
                  .article-img{
                      position: relative;
                      display: block;
                      overflow: hidden;
                      width: vwPc(150);
                      height: vwPc(150);
                      @include large-layout(){
                          width: 150px;
                          height: 150px;
                      }
                      img{
                          width: 100%;
                          height: 100%;
                          transition: all .5s ease;
                      }
                  }
                  .article-content{
                      width: calc(100% - #{vwPc(150)});
                      display: flex;
                      flex-wrap: wrap;
                      padding-left: vwPc(30);
                      @include large-layout(){
                          width: calc(100% - 150px);
                          padding-left: 30px;
                      }
                      .content-tag{
                          display: flex;
                          flex-wrap: wrap;
                          width: 100%;
                          .vol{
                              font-family: 'Inter', sans-serif;
                              font-weight: normal;
                              font-size: vwPc(13);
                              margin-right: vwPc(5);
                              line-height: vwPc(20);
                              @include large-layout(){
                                  font-size: 13px;
                                  margin-right: 5px;
                                  line-height: 20px;
                              }
                          }
                          .tag{
                              position: relative;
                              display: flex;
                              flex-wrap: wrap;
                              align-items: center;
                              line-height: 1;
                              background: #e3e3e3;
                              color: #888888;
                              &:hover{
                                background: #65b393;
                                color: #fff;
                              }
                              @include pc-layout(){
                                  margin-left: vwPc(5);
                                  height: vwPc(20);
                                  padding: 0 vwPc(10) 0;
                                  border-radius: vwPc(10);
                                  font-size: vwPc(11);
                              }
                              @include large-layout(){
                                  margin-left: 5px;
                                  height: 20px;
                                  padding: 0 10px 0;
                                  border-radius: 10px;
                                  font-size: 11px;
                              }
                          }
                      }
                      .content-title{
                          font-size: vwPc(17);
                          font-weight: bold;
                          @include large-layout(){
                              font-size: 17px;
                          }
                      }
                      .content-detail{
                          font-size: vwPc(14);
                          font-weight: normal;
                          margin-top: 0;
                          @include large-layout(){
                              font-size: 14px;
                              margin-top: 0;
                          }
                      }
                  }
              }
          }
      }
    }
    .button-block{
      position: relative;
      display: flex;
      width: 100%;
      @include pc-layout(){
        justify-content: flex-end;
      }
      a{
        width: 100%;
        height: vwSp(110);
        border-radius: vwSp(55);
        background-color: #65b393;
        color: #fff;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
        font-weight: normal;
        font-size: vwSp(30);
        text-decoration: none;
        @include pc-layout(){
          width: vwPc(240);
          height: vwPc(50);
          border-radius: vwPc(25);
          font-size: vwPc(15);
        }
        @include large-layout(){
          width: 240px;
          height: 50px;
          border-radius: 25px;
          font-size: 15px;
        }
        &:hover{
          &:after{
            animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
          }
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          width: vwSp(21);
          height: vwSp(23);
          background: url("../img/i-arrow-right-white.svg") 50% 50% no-repeat;
          background-size: 99%;
          top: vwSp(44);
          right: vwSp(30);
          @include pc-layout(){
            width: vwPc(11);
            height: vwPc(12);
            top: vwPc(19);
            right: vwPc(20);
          }
          @include large-layout(){
            width: 11px;
            height: 12px;
            top: 19px;
            right: 20px;
          }
        }
      }
    }
  }
  .message-box{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding:0 vwSp(40);
    margin-top: vwSp(120);
    z-index: 999;
    &.hide{
      opacity: 0;
    }
    @include pc-layout(){
      position: absolute;
      padding:0 vwPc(50);
      left: 0;
      width: vwPc(600);
      bottom: vwPc(50);
      opacity: 1;
      transition: opacity 0.5s ease;
    }
    @include large-layout(){
      position: absolute;
      padding:0 50px;
      width: 600px;
      bottom: 50px;
    }
    .message-head{
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      font-size: vwSp(32);
      @include pc-layout(){
        font-size: vwPc(16);
      }
      @include large-layout(){
        font-size: 16px;
      }
    }
    .message-content{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: vwSp(20);
      @include pc-layout(){
        margin-top: vwPc(10);
      }
      @include large-layout(){
        margin-top: 10px;
      }
      a{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background: #fff;
        padding: vwSp(40);
        text-decoration: none;
        font-size: vwSp(28);
        line-height: 1.5;
        border-radius: vwSp(20);
        font-family: YakuHanJP, "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", Meiryo, sans-serif;
        @include pc-layout(){
          padding: vwPc(20);
          font-size: vwPc(16);
          border-radius: vwPc(10);
        }
        @include large-layout(){
          padding: 20px;
          font-size: 16px;
          border-radius: 10px;
        }
        &:hover{
          &:after{
            animation: arrowSlide 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 forwards;
          }
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          width: vwSp(21);
          height: vwSp(23);
          background: url("../img/i-arrow-right-green.svg") 50% 50% no-repeat;
          background-size: 99%;
          bottom: vwSp(20);
          right: vwSp(20);
          @include pc-layout(){
            width: vwPc(11);
            height: vwPc(12);
            bottom: vwPc(15);
            right: vwPc(15);
          }
          @include large-layout(){
            width: 11px;
            height: 12px;
            bottom: 15px;
            right: 15px;
          }
        }
      }
    }
  }
}
.bg-left,
.bg-right{
  position: fixed;
  z-index: -1;
  height: 100vh;
  top:0;
}
.bg-left{
  width: vwPc(600);
  left: 0;
  background: #ecece7;
  @include large-layout(){
    width: 600px;
  }
}
.bg-right{
  width: calc(100% - #{vwPc(600)});
  right: 0;
  background: #fafaf7;
  @include large-layout(){
    width: calc(100% - 600px);
  }
}
@keyframes pcScroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - #{vwSp(50)}));
      @include pc-layout(){
          transform: translateX(calc(-100% - #{vwPc(70)}));
      }
      @include large-layout(){
          transform: translateX(calc(-100% - 70px));
      }
  } }
#top{
    .start-page{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999999;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 90%;
            opacity: 0;
            @include pc-layout(){
                max-width: vwPc(354);
                @include large-layout(){
                    max-width: 354px;
                }
            }
        }
    }
}

