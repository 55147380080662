@keyframes arrowSlide {

    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    50.0% {
        transform: translate3d(60%, 0, 0);
        opacity: 0;
    }

    50.1% {
        transform: translate3d(-60%, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
@keyframes arrowSlideleft {

    0% {
        transform: translate3d(0, 0, 0) rotate(180deg);
        opacity: 1;
    }

    50.0% {
        transform: translate3d(-60%, 0, 0) rotate(180deg);
        opacity: 0;
    }

    50.1% {
        transform: translate3d(60%, 0, 0) rotate(180deg);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0) rotate(180deg);
        opacity: 1;
    }
}
@keyframes arrowSlideUp {

    0% {
        transform: translate3d(0, 0, 0) rotate(-90deg);
        opacity: 1;
    }

    50.0% {
        transform: translate3d(0, -60%, 0) rotate(-90deg);
        opacity: 0;
    }

    50.1% {
        transform: translate3d(0, 60%, 0) rotate(-90deg);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0) rotate(-90deg);
        opacity: 1;
    }
}

@keyframes arrowSlideDown {

    0% {
        transform: translate3d(0, 0, 0) rotate(90deg);
        opacity: 1;
    }

    50.0% {
        transform: translate3d(0, 60%, 0) rotate(90deg);
        opacity: 0;
    }

    50.1% {
        transform: translate3d(0, -60%, 0) rotate(90deg);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0) rotate(90deg);
        opacity: 1;
    }
}

@-webkit-keyframes admtimg {
    0%{
        opacity: 0;
        transform: translateX(-20%);
    }

    49%{
        overflow: hidden;
        opacity: 0;
        transform: translateX(-20%);
    }
    50%{
        overflow: hidden;
        opacity: 1;
        transform: translateX(-20%);
    }
    100%{
        overflow: hidden;
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes admtimg {
    0%{
        opacity: 0;
        transform: translateX(-20%);
    }

    49%{
        overflow: hidden;
        opacity: 0;
        transform: translateX(-20%);
    }
    50%{
        overflow: hidden;
        opacity: 1;
        transform: translateX(-20%);
    }
    100%{
        overflow: hidden;
        opacity: 1;
        transform: translateX(0);
    }
}

.admtimg {
    -webkit-animation-name: admtimg;
    animation-name: admtimg;
    animation-timing-function: ease;
}

@-webkit-keyframes admtbg {
    0%{
        left: 100%;
    }
    50%{
        left: 0;
    }
    100%{
        left: 100%;
    }
}

@keyframes admtbg {
    0%{
        left: 100%;
    }
    50%{
        left: 0;
    }
    100%{
        left: 100%;
    }
}

.admtbg {
    -webkit-animation-name: admtbg;
    animation-name: admtbg;
    animation-timing-function: cubic-bezier(.81,.15,.26,.99);
}
@-webkit-keyframes pageanchor {
    0%{
        @include pc-layout(){
            top:vwPc(0);
        }
    }
    100%{
        @include pc-layout(){
            top:vwPc(78);
        }
        @include large-layout(){
            top: 78px;
        }
    }
}

@keyframes pageanchor {
    0%{
        @include pc-layout(){
            top:vwPc(0);
        }
    }
    100%{
        @include pc-layout(){
            top:vwPc(78);
        }
        @include large-layout(){
            top: 78px;
        }
    }
}

.pageanchor {
    -webkit-animation-name: pageanchor;
    animation-name: pageanchor;
    animation-timing-function: ease;
}