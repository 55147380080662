.footer {
    line-height: 1.2;
    width: 100%;
    background: #fff;
    padding-top: vwSp(100);
    &__lang {
        font-weight: 500;
        font-size: vwSp(28px);
        font-family: "Inter", sans-serif;
        text-transform: uppercase;
        @include pc-layout(){
            margin-left: vwPc(20px);
            font-size: vwPc(13px);
            margin-top: 0;
            font-weight: bold;
            display: inline-block;
        }
        @include large-layout(){
            margin-left: 20px;
            font-size: 13px;
            margin-top: 0;
        }

        a {
            display: block;
            text-decoration: none;
            @include pc-layout(){
                display: inline-block;
                color: #ccc;
                text-decoration: none;
            }
            @include pc-layout(){
                 &:first-child {

                     &:after {
                         padding: 0 vwPc(7px);
                         content: "/";
                     }
                 }
             }
            @include large-layout(){
                &:first-child {

                    &:after {
                        padding: 0 7px;
                        content: "/";
                    }
                }
            }
        }

        .active {
            display: none;
            @include pc-layout(){
                display: inline-block;
                color: #333;
            }
        }
    }
    &__inner {
        padding: 0 vwSp(40px) vwSp(60px);
    }

    &__content {
    }

    &__left {
    }

    &__logo {
        width: vwSp(451px);
        margin-bottom: vwSp(40px);

        img {
            max-width: 100%;
            width: 100%;
            margin-top: 0!important;
        }
    }

    &__addr {
        margin-bottom: vwSp(40px);
        font-size: vwSp(26px);
        line-height: vwSp(48px);
        color: #666;
    }

    &__right {
    }

    &__nav {

        li {
            display: inline-block;
            min-width: vwSp(260px);
        }
    }

    &__link {
        font-weight: bold;
        font-size: vwSp(20px);
        line-height: vwSp(66px);
        text-decoration: none;

        &:hover {
            color: #65b393;
        }
    }

    &__banner {
        display: flex;
        margin: vwSp(50px) vwSp(-20px) vwSp(95px);

        &-img {
            width: 50%;
            padding: 0 vwSp(20px);

            img {
                max-width: 100%;
                width: 100%;
                margin-top: 0!important;
            }
        }
    }

    &__copyright {
        color: #666;
        font-size: vwSp(20px);
        font-family: "Inter", sans-serif;
        line-height: 2;
        text-align: center;
    }
}

.go-top {
    position: fixed;
    right: vwSp(20px);
    bottom: vwSp(20px);
    z-index: 999;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: vwSp(100px);
    height: vwSp(100px);
    color: #fff;
    font-size: vwSp(16px);
    text-decoration: none;
    background: #65b393;
    border-radius: 100px;

    span.text {
        display: inline-block;
        margin-top: vwSp(5px);
        color: #fff;
    }
}

.icon-arrow-up {
    position: relative;
    display: inline-block;
    width: vwSp(25px);
    height: vwSp(25px);

    &:after {
        position: absolute;
        top: 0;
        top: 0;
        left: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: url("/img/i-arrow-right-white.svg") center/contain no-repeat;
        transform: rotate(-90deg);
        content: "";
    }

    &.arrow-white {

        &:after {
            background-image: url("/img/i-arrow-right-white.svg");
            transform: rotate(-90deg);
        }
    }
}

.arrow-up-hover {

    &:hover {
        opacity: 1!important;
        .icon-arrow-up:after {
            animation: arrowSlideUp .5s cubic-bezier(.215, .61, .355, 1) 0s
                1 forwards;
        }
    }
}

@include pc-layout {

    .footer {
        z-index: 999;
        padding-top: vwPc(50);
        position: relative;

        &__inner {
            margin: auto;
            padding: 0 vwPc(50) vwPc(20px);
        }

        &__content {
            display: flex;
            margin-bottom:0;
        }

        &__left {
            width: vwPc(563px);
            padding-top: vwPc(8);
        }

        &__logo {
            width: vwPc(236px);
            margin-bottom: vwPc(25px);
        }

        &__addr {
            margin-bottom: vwPc(0px);
            font-size: vwPc(13px);
            line-height: vwPc(20px);
        }

        &__right {
            margin-left: auto;
        }

        &__nav {
            display: inline-block;
            li {
                min-width: auto;
                margin-left: vwPc(45px);

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &__link {
            font-size: vwPc(13px);
            line-height: vwPc(28px);
        }

        &__banner {
            display: flex;
            margin: vwPc(30px) vwPc(-10px) vwPc(0px);
            &-img {
                width: auto;
                max-width: vwPc(230px);
                padding: 0 vwPc(10px);
            }

            a{
                transition: all 0.3s ease;
                &:hover{
                    opacity: 0.6;
                }
            }
        }

        &__copyright {
            font-size: vwPc(11px);
            text-align: left;
            margin-top: vwPc(30);
        }
    }

    .go-top {
        right: vwPc(20px);
        bottom: vwPc(20px);
        width: vwPc(60px);
        height: vwPc(60px);
        font-size: vwPc(13px);
        &.fixed{
            position: absolute;
            top:vwPc(-80);
        }
        span.text {
            margin-top: vwPc(7px);
        }
    }

    .icon-arrow-up {
        width: vwPc(12px);
        height: vwPc(12px);
    }
}

@include lg-layout {

    .footer {
        padding-top: 50px;

        &__inner {
            margin: auto;
            padding: 0 50px 20px;
        }

        &__content {
            display: flex;
        }

        &__left {
            width: 563px;
            padding-top: 8px;
        }

        &__logo {
            width: 236px;
            margin-bottom: 25px;
        }

        &__addr {
            margin-bottom: 0px;
            font-size: 13px;
            line-height: 20px;
        }

        &__right {
            margin-left: auto;
        }

        &__nav {

            li {
                min-width: auto;
                margin-left: 45px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &__link {
            font-size: 13px;
            line-height: 28px;
        }

        &__banner {
            display: flex;
            margin: 30px -10px 0px;

            &-img {
                width: auto;
                max-width: 230px;
                padding: 0 10px;
            }
        }

        &__copyright {
            font-size: 11px;
            text-align: left;
            margin-top: 30px;
        }
    }

    .go-top {
        right: 20px;
        bottom: 20px;
        width: 60px;
        height: 60px;
        font-size: 13px;
        &.fixed{
            position: absolute;
            top:-80px;
        }
        span.text {
            margin-top: 7px;
        }
    }

    .icon-arrow-up {
        width: 12px;
        height: 12px;
    }
}
